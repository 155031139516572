import { EThemeType } from "redux/reducers";
import {
  DallEIcon,
  StableDiffusionIcon,
  DownloadIcon,
} from "./icons";
import { FormattedMessage } from "react-intl";

interface IHowToUseeProps {
  themeMode?: EThemeType;
}

const iconClassName =
  "w-[32px] h-[32px] md:w-[44px] md:h-[44px] lg:w-[50px] lg:h-[50px]";

export const HowToUse = ({ themeMode }: IHowToUseeProps) => {
  return (
    <div
      style={{
        backgroundImage: `url(/feature/bgImages/bg-03-${themeMode}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-light dark:text-dark px-[10%] w-fit mx-auto py-14 md:py-28">
        <h2 className="w-fit mx-auto text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20 bg-gradient-primary-to-right bg-clip-text text-transparent">
          <FormattedMessage id="feature.imageGenerator.use.title" />
        </h2>
        <div className="flex flex-col lg:flex-row justify-center gap-12 lg:py-8">
          <div className="rounded-[15px] bg-[rgba(255,255,255,0.3)] dark:border dark:border-solid dark:border-white dark:border-opacity-5 dark:bg-[rgba(255,255,255,0.05)] flex flex-col p-6 max-w-[392px] flex-1">
            <div className="mb-4">
              <img
                src="/feature/imageGenerator/loader.png"
                alt=""
                className={iconClassName}
              />
            </div>
            <p className="text-[16px] md:text-[18px] lg:text-[20px] leading-6 md:leading-7 lg:leading-8 font-bold">
              <FormattedMessage id="feature.imageGenerator.use.hightQualityImageTitle" />
            </p>
          </div>
          <div className="rounded-[15px] bg-[rgba(255,255,255,0.3)] flex flex-col p-6 max-w-[392px] dark:border dark:border-solid dark:border-white dark:border-opacity-5 dark:bg-[rgba(255,255,255,0.05)] flex-1">
            <div className="flex flex-row gap-12 mb-4">
              <DallEIcon className={iconClassName} />
              <StableDiffusionIcon className={iconClassName} />
            </div>
            <p className="text-[16px] md:text-[18px] lg:text-[20px] leading-6 md:leading-7 lg:leading-8 font-bold">
              <FormattedMessage id="feature.imageGenerator.use.sourceTitle" />
            </p>
          </div>
          <div className="rounded-[15px] bg-[rgba(255,255,255,0.3)] flex flex-col p-6 max-w-[392px] flex-1 dark:border dark:border-solid dark:border-white dark:border-opacity-5 dark:bg-[rgba(255,255,255,0.05)] ">
            <div className="mb-4">
              <DownloadIcon className={iconClassName} />
            </div>
            <p className="ttext-[16px] md:text-[18px] lg:text-[20px] leading-6 md:leading-7 lg:leading-8 font-bold">
              <FormattedMessage id="feature.imageGenerator.use.DownloadTitle" />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

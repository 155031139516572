import { theme } from "theme";

export const LightIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3569 5.6433C11.6586 6.94505 11.6586 9.0556 10.3569 10.3573C9.05511 11.6591 6.94456 11.6591 5.64281 10.3573C4.34107 9.0556 4.34107 6.94505 5.64281 5.6433C6.94456 4.34156 9.05511 4.34156 10.3569 5.6433"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99984 2.66683V1.3335"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99984 14.6668V13.3335"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.24 3.75994L12.7133 3.28661"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.28661 12.7136L3.75994 12.2402"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3333 8.00033H14.6666"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.33325 8.00033H2.66659"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.24 12.2402L12.7133 12.7136"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.28661 3.28661L3.75994 3.75994"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

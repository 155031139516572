import { FormattedMessage } from "react-intl";
import { EThemeType } from "redux/reducers";

export interface IFeatureItem {
  icon: React.ReactNode;
  title: string;
  content: string;
}

interface IPerksOfFeatureProps {
  features: IFeatureItem[];
  title?: string;
  themeMode?: EThemeType;
}

export const iconSizeClassName = "w-[32px] h-[32px] md:w-[56px] md:h-[56px] lg:w-[64px] lg:h-[64px]";

export const PerksOfFeature: React.FC<IPerksOfFeatureProps> = ({
  features,
  title,
  themeMode,
}) => {
  return (
    <div
      className="text-light dark:text-dark py-14 md:py-28"
      style={{
        backgroundImage: `url(/feature/bgImages/bg-02-${themeMode}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="w-fit mx-auto">
        <h2 className="text-center text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20">
          <FormattedMessage id={title || "feature.perksOfFeature.title"} />
        </h2>
        {features.map((row) => (
          <div
            className="flex flex-row items-center mt-4 md:mt-8 px-6 md:px-[10%]"
            key={row.title}
          >
            <span className="hidden md:inline-block">{row.icon}</span>
            <div className="pl-6 flex-1">
              <div className="flex items-center mt-3 md:mt-0">
                <span className="inline-block md:hidden">{row.icon}</span>
                <h4 className="text-[16px] md:text-[20px] pl-3 md:pl-0">
                  <FormattedMessage id={row.title} />
                </h4>
              </div>
              <p className="mt-2 md:mt-3 text-[14px] md:text-[16px] opacity-70 font-medium leading-6 md:leading-7">
                <FormattedMessage id={row.content} />
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface IProps {
  width?: number;
}

export const TickIcon = ({ width = 20 }: IProps) => {
  return (
    <svg
      width={`${width}`}
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 1.5L7 12.5L2 7.5"
        stroke="url(#paint0_linear_1066_3926)"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1066_3926"
          x1="2"
          y1="1.5"
          x2="18.5883"
          y2="2.47323"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8C49F7" />
          <stop offset="1" stopColor="#6C53FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "../../store";
import { IActivePlan } from "../planSubscription";
import { ICurrentTeam } from "../teams";
import { setShowUpscaleModal } from "../imageGeneration";
import { EThemeType } from "redux/reducers";

export interface ISignupReq {
  first_name: string;
  surname: string;
  email: string;
  language: string;
}

declare global {
  interface Window {
    fpr: any; // You can use a more specific type if you have one
  }
}

export interface IConfirmEmailRegistrationReq {
  expires: string;
  hash: string;
  id: string;
  signature: string;
  referral_token: string | null;
}

export interface ILoginReq {
  email: string;
}

export interface IVerifyEmailReq {
  token: string;
}

export interface IChangeLanguageReq {
  language: string;
}

export interface ISocialCallback {
  authuser: string;
  code: string;
  prompt: string;
  scope: string;
}

export interface IUserDetail {
  token: string;
  user: IUser;
}

export interface IUser {
  activeSubscription: IActivePlan;
  id: number;
  name: string;
  email: string;
  email_verified_at: string;
  stripe_id: string;
  avatar: boolean;
  two_factor_secret: string;
  two_factor_recovery_codes: string;
  created_at: string;
  updated_at: string;
  language?: string;
  team: ICurrentTeam;
  referral_token: string;
}

export const signup = (data: ISignupReq) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/register", data)
      .then((res: any) => {
        localStorage.setItem('email', res?.data?.data?.email);
        resolve(res.data);
        setShowUpscaleModal({ showUpscaleModal: "show" });
        localStorage.removeItem('GptModel');
        localStorage.removeItem('imageChatGptModel');
        localStorage.removeItem('documentChatGptModel');
        localStorage.removeItem('imageGptModel');
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const confirmEmailRegistration = ({
  expires,
  hash,
  id,
  signature,
  referral_token,
}: IConfirmEmailRegistrationReq) => {
  return new Promise((resolve, reject) => {
    api
      .get(
        `/api/auth/verify-email?expires=${expires}&hash=${hash}&id=${id}&signature=${signature}${referral_token ? `&referral_token=${referral_token}` : ""
        }`
      )
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.VERIFY_EMAIL, payload: res.data.data });
        window.fpr("referral", { email: res.data.data.user.email });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const login = (data: ILoginReq) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/login", data)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.SIGN_IN, payload: res });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const fetchUserDetails = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/user/me")
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.UPDATE_USER_DETAILS, payload: res.data });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const verifyEmail = (data: IVerifyEmailReq) => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/verify", data)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.VERIFY_EMAIL, payload: res.data.data });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const socialRedirect = () => {
  return new Promise((resolve, reject) => {
    api
      .get("/api/auth/social/google")
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const socialCallback = (data: ISocialCallback) => {
  return new Promise((resolve, reject) => {
    const { authuser, code, prompt, scope } = data;

    const referralToken = localStorage.getItem("referralToken");
    const apiUrl = `/api/auth/social/callback/google?code=${code}&scope=${scope.replace(
      / /g,
      "red"
    )}&authuser=${authuser}&prompt=${prompt}`;
    const finalApiUrl = referralToken
      ? `${apiUrl}&referral_token=${referralToken}`
      : apiUrl;

    api
      .get(finalApiUrl)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.SOCIAL_CALLBACK, payload: res.data.data });
        localStorage.removeItem("referralToken");
        window.fpr("referral", { email: res.data.data.user.email });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const changeLanguage = (data: IChangeLanguageReq) => {
  return new Promise((resolve, reject) => {
    api
      .post(`/api/user/language`, data)
      .then((res: any) => {
        resolve(res.data);
        store.dispatch({ type: TYPES.UPDATE_LANGUAGE, payload: data.language });
      })
      .catch((err: any) => {
        reject(err);
      });
  });
};

export const logoutDispatch = () => {
  store.dispatch({ type: TYPES.INIT_USER_DETAILS });
};

export const updateLanguage = (language: string) => {
  const { userDetail, theme } = store.getState().authReducer;
  if (userDetail?.token) {
    changeLanguage({ language });
  } else {
    store.dispatch({ type: TYPES.INIT_USER_DETAILS });
    if (theme) {
      setTheme(theme)
    }
    store.dispatch({ type: TYPES.UPDATE_LANGUAGE, payload: language });
  }
};

export const setTheme = (theme: EThemeType) => {
  store.dispatch({ type: TYPES.SET_SYSTEM_THEME, payload: { theme } });
};

export const logout = () => {
  return new Promise((resolve, reject) => {
    api
      .post("/api/auth/logout")
      .then((res: any) => {
        resolve(res.data);
        logoutDispatch();
      })
      .catch((err: any) => {
        reject(err.response);
      });
  });
};

export const removeAccount = () => {
  return new Promise((resolve, reject) => {
    api
      .delete("/api/user/delete")
      .then((res: any) => {
        resolve(res.data);
        logoutDispatch();
        localStorage.removeItem('GptModel');
        localStorage.removeItem('imageChatGptModel');
        localStorage.removeItem('documentChatGptModel');
        localStorage.removeItem('imageGptModel');
      })
      .catch((err: any) => {
        reject(err.response);
      });
  });
}

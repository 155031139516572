import Lottie from "react-lottie";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./Hero.module.scss";

import { DarkAnimation, LightAnimation } from "json";

import CutsomButton from "components/Button";
import { RoutePaths } from "pages/routePaths";

import useRouter from "hooks/useRouter";
import { useSelector } from "redux/hooks";

export const Hero = () => {
  const { push } = useRouter();
  const theme = useSelector((state) => state.authReducer.theme);
  const { userDetail } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  const defaultOptions = {
    loop: true,
    autoplay: true,
  };

  return (
    <section
      className={classNames(styles.section, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      style={{
        backgroundImage: `url(/landing/${theme}/hero.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className={styles.left}>
        <h1
          className={classNames(styles.h1, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <span className={styles.gradient}>
            <FormattedMessage id="landing.hero.introducing" />
            <FormattedMessage id="landing.hero.deftgpt" />
          </span>
          <br />
          <p className={styles.subtitle}>
            <FormattedMessage id="landing.hero.title" />
          </p>
        </h1>
        <p
          className={classNames(styles.text, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <FormattedMessage id="landing.hero.desc" />
        </p>
        <div className={styles.xs_image}>
          <Lottie
            options={{
              ...defaultOptions,
              animationData: theme === "light" ? LightAnimation : DarkAnimation,
            }}
          />
        </div>
        <CutsomButton
          variant="latest-primary"
          onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
          data-testid='landing-signup'
        >
          <FormattedMessage id="landing.nav.SignUp" />
        </CutsomButton>
      </div>
      <div className={styles.rightSection}>
        <div className={styles.lg_image}>
          <Lottie
            options={{
              ...defaultOptions,
              animationData: theme === "light" ? LightAnimation : DarkAnimation,
            }}
          />
        </div>
      </div>
    </section>
  );
};

export const ClearVisualsIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M35.1601 56.8405C51.6245 56.8405 64.6164 36.4222 65.1618 35.5528C65.3731 35.2146 65.3731 34.786 65.1618 34.4478C64.6164 33.5784 51.6245 13.1602 35.1601 13.1602C32.1325 13.1602 29.0023 13.8691 25.8559 15.2676C25.3308 15.5012 25.0952 16.1156 25.3277 16.6407C25.5624 17.1658 26.1778 17.4045 26.7009 17.1689C29.5792 15.8892 32.426 15.2402 35.1601 15.2402C43.7135 15.2402 51.4747 21.5147 56.6894 27.0901C51.628 23.5303 44.652 19.9414 36.7918 19.4869C36.2553 19.4309 35.7112 19.4002 35.1601 19.4002C34.609 19.4002 34.0648 19.4309 33.5283 19.4869C25.6512 19.9424 18.6627 23.5455 13.5987 27.1128C15.3966 25.1845 17.4871 23.1743 19.822 21.3665C20.276 21.014 20.3593 20.361 20.0079 19.907C19.6565 19.452 19.0034 19.3697 18.5484 19.7211C10.5696 25.9002 5.37566 34.1015 5.15933 34.4478C4.94706 34.785 4.94706 35.2146 5.15831 35.5528C5.70371 36.4222 18.6957 56.8405 35.1601 56.8405ZM35.1601 54.7604C26.6067 54.7604 18.8455 48.4859 13.6307 42.9106C18.6921 46.4703 25.6681 50.0592 33.5283 50.5137C34.0648 50.5697 34.609 50.6004 35.1601 50.6004C35.7112 50.6004 36.2553 50.5697 36.7918 50.5137C44.6603 50.0587 51.6424 46.463 56.705 42.8995C51.4928 48.4776 43.7277 54.7604 35.1601 54.7604ZM21.64 35.0003C21.64 27.5456 27.7053 21.4802 35.1601 21.4802C42.6148 21.4802 48.6802 27.5456 48.6802 35.0003C48.6802 42.455 42.6148 48.5204 35.1601 48.5204C27.7053 48.5204 21.64 42.455 21.64 35.0003ZM62.8411 35.0003C60.8059 37.0605 54.2679 43.1758 45.7455 46.4268C48.8211 43.5757 50.7602 39.5153 50.7602 35.0003C50.7602 30.4853 48.8211 26.4249 45.7455 23.5738C54.2679 26.8248 60.8059 32.9401 62.8411 35.0003ZM19.56 35.0003C19.56 39.5153 21.4991 43.5757 24.5747 46.4268C16.0522 43.1758 9.51423 37.0605 7.47903 35.0003C9.51423 32.9401 16.0522 26.8248 24.5747 23.5738C21.4991 26.4249 19.56 30.4853 19.56 35.0003Z"
      fill="#7C4DFF"
    />
    <path
      d="M44.4157 36.0401H45.5608C46.1357 36.0401 46.6009 35.575 46.6009 35.0001C46.6009 34.4253 46.1357 33.9601 45.5608 33.9601H44.4157C43.9296 29.6389 40.522 26.2313 36.2008 25.7452V24.6001C36.2008 24.0252 35.7356 23.5601 35.1608 23.5601C34.5859 23.5601 34.1208 24.0252 34.1208 24.6001V25.7452C29.7995 26.2313 26.3919 29.6389 25.9058 33.9601H24.7607C24.1859 33.9601 23.7207 34.4253 23.7207 35.0001C23.7207 35.575 24.1859 36.0401 24.7607 36.0401H25.9058C26.3919 40.3614 29.7995 43.769 34.1208 44.2551V45.4002C34.1208 45.9751 34.5859 46.4402 35.1608 46.4402C35.7356 46.4402 36.2008 45.9751 36.2008 45.4002V44.2551C40.522 43.769 43.9296 40.3614 44.4157 36.0401ZM36.2008 42.1751V41.2402C36.2008 40.6653 35.7356 40.2002 35.1608 40.2002C34.5859 40.2002 34.1208 40.6653 34.1208 41.2402V42.1751C30.9448 41.7146 28.4463 39.2162 27.9858 36.0401H28.9207C29.4956 36.0401 29.9607 35.575 29.9607 35.0001C29.9607 34.4253 29.4956 33.9601 28.9207 33.9601H27.9858C28.4463 30.7841 30.9448 28.2857 34.1208 27.8252V28.7601C34.1208 29.3349 34.5859 29.8001 35.1608 29.8001C35.7356 29.8001 36.2008 29.3349 36.2008 28.7601V27.8252C39.3768 28.2857 41.8752 30.7841 42.3357 33.9601H41.4008C40.826 33.9601 40.3608 34.4253 40.3608 35.0001C40.3608 35.575 40.826 36.0401 41.4008 36.0401H42.3357C41.8752 39.2162 39.3768 41.7146 36.2008 42.1751Z"
      fill="#7C4DFF"
    />
    <path
      d="M10.2002 54.7603C9.62532 54.7603 9.16016 55.2254 9.16016 55.8003V59.9603C9.16016 60.5351 9.62532 61.0003 10.2002 61.0003H14.3602C14.935 61.0003 15.4002 60.5351 15.4002 59.9603C15.4002 59.3854 14.935 58.9203 14.3602 58.9203H11.2402V55.8003C11.2402 55.2254 10.775 54.7603 10.2002 54.7603Z"
      fill="#7C4DFF"
    />
    <path
      d="M59.0799 55.8003V58.9203H55.9599C55.3851 58.9203 54.9199 59.3854 54.9199 59.9603C54.9199 60.5351 55.3851 61.0003 55.9599 61.0003H60.12C60.6948 61.0003 61.16 60.5351 61.16 59.9603V55.8003C61.16 55.2254 60.6948 54.7603 60.12 54.7603C59.5451 54.7603 59.0799 55.2254 59.0799 55.8003Z"
      fill="#7C4DFF"
    />
    <path
      d="M55.9599 11.08H59.0799V14.2C59.0799 14.7749 59.5451 15.24 60.12 15.24C60.6948 15.24 61.16 14.7749 61.16 14.2V10.04C61.16 9.46516 60.6948 9 60.12 9H55.9599C55.3851 9 54.9199 9.46516 54.9199 10.04C54.9199 10.6149 55.3851 11.08 55.9599 11.08Z"
      fill="#7C4DFF"
    />
    <path
      d="M10.2002 15.24C10.775 15.24 11.2402 14.7749 11.2402 14.2V11.08H14.3602C14.935 11.08 15.4002 10.6149 15.4002 10.04C15.4002 9.46516 14.935 9 14.3602 9H10.2002C9.62532 9 9.16016 9.46516 9.16016 10.04V14.2C9.16016 14.7749 9.62532 15.24 10.2002 15.24Z"
      fill="#7C4DFF"
    />
    <path
      d="M22.5966 19.205C23.171 19.205 23.6367 18.7394 23.6367 18.165C23.6367 17.5906 23.171 17.125 22.5966 17.125C22.0223 17.125 21.5566 17.5906 21.5566 18.165C21.5566 18.7394 22.0223 19.205 22.5966 19.205Z"
      fill="#7C4DFF"
    />
  </svg>
);

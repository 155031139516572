import { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import { theme, Theme } from "theme";

import links from "core/links";

import { CloseIcon } from "components/icons/CloseIcon";

import CutsomButton from "components/Button";

import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";

const Container = styled.div<{
  left: number;
  themeMode?: EThemeType;
}>`
  width: 360px;
  margin-top: 110px;
  box-shadow: 0px 139px 39px 0px rgba(161, 161, 161, 0),
    0px 89px 36px 0px rgba(161, 161, 161, 0.01),
    0px 50px 30px 0px rgba(161, 161, 161, 0.05),
    0px 22px 22px 0px rgba(161, 161, 161, 0.09),
    0px 6px 12px 0px rgba(161, 161, 161, 0.1);
  backdrop-filter: blur(10px);
  background-color: ${({ themeMode }) =>
    themeMode === "dark" ? "transparent" : "rgba(255, 255, 255, 0.80)"};
  border-radius: 20px;
  padding: 16px 20px;
  flex-direction: column;
  justify-content: space-between;
  bottom: 82px;
  position: fixed;
  z-index: 10;
  display: none;
  left: ${({ left }) => left}px;

  @media screen and (min-width: 769px) {
    display: flex;
  }
`;

const Row = styled.div`
  position: relative;
  margin-top: 10px;
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-top: 10px;
`;

const IconBox = styled.span`
  display: flex;
  align-items: center;
  width: 32px;
  height: 32px;
  margin-top: 4px;
`;

const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
`;

const Text = styled.span<{
  themeMode?: EThemeType;
  theme: Theme;
}>`
  display: inline-block;
  padding-right: 2px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
`;

const CloseIconBox = styled.span`
  position: absolute;
  top: -8px;
  right: 0px;

  &:hover {
    cursor: pointer;
  }
`;

export const ChromeExtension = () => {
  const themeMode = useSelector((state) => state.authReducer.theme);
  const isChrome = navigator.userAgent.indexOf("Chrome") > -1;
  const [show, setShowState] = useState<boolean>(true);
  const [left, setLeft] = useState<number>(0);

  useEffect(() => {
    const storedData = sessionStorage.getItem("ChromeExtensionState");
    if (storedData) {
      setShowState(false);
    } else {
      setShowState(true);
    }
  }, []);

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth;
      if (windowWidth > 679 && windowWidth < 1440) {
        setLeft(windowWidth * 0.05);
      } else if (windowWidth > 1440) {
        setLeft(windowWidth * 0.1);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!isChrome || !show) {
    return null;
  }

  return (
    <Container left={left} themeMode={themeMode}>
      <Row>
        <IconBox>
          <img src="/icons/chrome-icon.svg" alt="Add to Chrome" />
        </IconBox>
        <CloseIconBox
          data-testid='close-chrome-extension'
          onClick={() => {
            sessionStorage.setItem("ChromeExtensionState", "close");
            setShowState(false);
          }}
        >
          <CloseIcon
            color={themeMode === "dark" ? theme.white : theme.textColorLight}
          />
        </CloseIconBox>
      </Row>
      <Content>
        <Text themeMode={themeMode}>
          <FormattedMessage id="landing.installExtension" />
        </Text>

        <Button
          href={links.ChromeStorePluginUrl}
          target="_blank"
          rel="noreferrer"
        >
          <CutsomButton small variant="latest-primary">
            <span className="px-4">
              <FormattedMessage id="landing.install.button" />
            </span>
          </CutsomButton>
        </Button>
      </Content>
    </Container>
  );
};

import { useCallback } from "react";
import { useEffectOnce } from "react-use";

import { AuthContainer } from "../components/AuthContainer";
import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import { verifyEmail, getCreditLimits } from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import { useSelector } from "redux/hooks";

export const VerifyEmailPage = () => {
  const { params, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const {userDetail} = useSelector((state)=> state.authReducer);
  const lang = userDetail?.user?.language? userDetail?.user?.language: "en";

  const onVerifyEmail = useCallback(() => {
    const { token } = params;
    const shareToken = localStorage.getItem('shareChat-Token');
    const TargetURL = shareToken ? `/share-chat/${shareToken}` : `/${RoutePaths.Chat}`;
    verifyEmail({ token })
      .then(() => {
        push(TargetURL);
        getCreditLimits().catch((err) => {
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
      })
      .catch((err: any) => {
        push(`/${lang}/${RoutePaths.Login}`);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }, [params, push, triggerNotification]);

  useEffectOnce(() => onVerifyEmail());

  return (
    <AuthContainer>
      <LoadingPage />
    </AuthContainer>
  );
};

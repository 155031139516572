import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./PriceSwitch.module.scss";
import Switch from "components/Switch";
import { IterFont } from "../IterFont";
import { EPlanDuration } from "redux/actions";
import { useSelector } from "redux/hooks";
// import { PriceLoadMontly } from "../Icons";

interface IProps {
  duration?: EPlanDuration;
  onChange?: (type?: EPlanDuration) => void;
}

export const PriceSwitch = ({ duration, onChange }: IProps) => {
  const themeMode = useSelector((state) => state.authReducer.theme);

  return (
    <div className="flex flex-col">
      <div
        className={
          themeMode === "dark" ? styles.Pricecard : styles.Pricecardwhite
        }
      >
        <span
          className={classNames(
            "text-[32px] leading-[40px] mb-[20px] hidden md:block",
            styles.heading,
            {
              [styles.light]: themeMode === "light",
              [styles.dark]: themeMode === "dark",
            }
          )}
        >
          <FormattedMessage id="landing.price.pricingPlans" />
        </span>
        <div className="flex flex-row justify-between  ">
          {/* <PriceLoadMontly themecolor={themeMode} /> */}
          <div className="flex justify-center">
            <IterFont
              className={classNames(
                "leading-[26px] inline-block mr-3 text-[14px]",
                styles.duration,
                {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                }
              )}
            >
              <FormattedMessage id="landing.price.monthly" />
            </IterFont>
            <Switch onChange={() => onChange?.(duration)} />
            <IterFont
              className={classNames(
                "leading-[26px] inline-block ml-3 text-[14px]",
                styles.duration,
                {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                }
              )}
            >
              <FormattedMessage id="landing.price.annual" />
              <span className={`text-[#7c4dff] font-bold ml-1`}>-20%</span>
            </IterFont>
          </div>
        </div>
        {/* <div>
          <h1
            className={
              themeMode === "light"
                ? styles.setFrquencyhead
                : styles.setFrquencyheaddark
            }
          >
            <FormattedMessage id="settings.plan.feature.paymentFrequency" />
          </h1>
          <p
            className={
              themeMode === "light"
                ? styles.setFrequency
                : styles.setFrequencydark
            }
          >
            <FormattedMessage id="settings.plan.feature.setFrequency" />
          </p>
        </div> */}
      </div>
    </div>
  );
};

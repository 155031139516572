import { useCallback } from "react";
import { useEffectOnce } from "react-use";

import { AuthContainer } from "../components/AuthContainer";
import { LoadingPage } from "components";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";

import { confirmEmailRegistration } from "redux/actions";
import { RoutePaths } from "pages/routePaths";
import { useSelector } from "redux/hooks";

export const ConfirmEmailRegistration = () => {
  const { query, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const {userDetail} = useSelector((state)=> state.authReducer);
  const lang = userDetail?.user?.language? userDetail?.user?.language : "en";

  const onConfirmEmailRegistration = useCallback(() => {
    const { expires, hash, id, signature } = query;
    const referral_token = localStorage.getItem("referralToken") ?? null;
    confirmEmailRegistration({
      expires,
      hash,
      id,
      signature,
      referral_token,
    })
      .then(() => {
        localStorage.removeItem("referralToken");
        const shareToken=localStorage.getItem('shareChat-Token');
        {shareToken ? push(`/share-chat/${shareToken}`) :    push(`/${RoutePaths.Chat}`)}
      })
      .catch((err) => {
        push(`/${lang}/${RoutePaths.Signup}`);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }, [query, push, triggerNotification]);

  useEffectOnce(() => onConfirmEmailRegistration());

  return (
    <AuthContainer>
      <LoadingPage />
    </AuthContainer>
  );
};

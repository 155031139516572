export const NextIcon = () => {
  return (
    <svg
      width="12"
      height="20"
      viewBox="0 0 12 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.47368 20L0 18.575L9.02632 10L0 1.425L1.47368 0L12 10L1.47368 20Z"
        fill="black"
      />
    </svg>
  );
};

import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./upscaleModal.module.scss";

import { Modal } from "components";
import Button from "components/Button";

import { useSelector } from "redux/hooks";

interface IProps {
  onClose: () => void;
  isloading: boolean;
  imageId: number;
  onUpscale: (imageId: number) => void;
}

export const UpscaleModal = ({
  onClose,
  isloading,
  imageId,
  onUpscale,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <Modal size="md" onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.message}>
          <FormattedMessage
            id="upscale.modal.message"
            values={{ credits: 10 }}
          />
        </div>
        <div className={styles.buttonsContainer}>
          <Button data-testid='upscale-close-btn' variant="outlined-primary" onClick={onClose}>
            <div
              className={classNames(styles.cancelBtnText, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="upscale.modal.cancelBtn.text" />
            </div>
          </Button>
          <Button
            variant="primary"
            isloading={isloading}
            onClick={() => onUpscale(imageId)}
            data-testid='upscale-continue-btn'
          >
            <div
              className={styles.buttonText}
              style={{ color: isloading ? "transparent" : "#FFFFFF" }}
            >
              <FormattedMessage id="upscale.modal.continueBtn.text" />
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { EThemeType } from "redux/reducers";

export const CurrentPlanIcon = ({ theme,chatsidebar,account, route,settingsidebar }: { theme?: EThemeType, chatsidebar?:boolean, account?: boolean,  route?: boolean,settingsidebar?:boolean }) => {
  if ((theme === "dark" && !account) || chatsidebar || (theme==='light' && account) || settingsidebar ) {
    const color = route? "#7C4DFF" : 'white'
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M8.82422 12.5585L9.64922 9.92308L7.47214 8.22725H10.1305L11.0013 5.50016L11.8492 8.22725H14.5305L12.3534 9.92308L13.1555 12.5585L11.0013 10.9314L8.82422 12.5585ZM5.59297 21.0835V14.1168C4.90547 13.3988 4.41276 12.612 4.11484 11.7564C3.81693 10.9009 3.66797 10.0377 3.66797 9.16683C3.66797 7.08905 4.37075 5.34738 5.7763 3.94183C7.18186 2.53627 8.92352 1.8335 11.0013 1.8335C13.0791 1.8335 14.8207 2.53627 16.2263 3.94183C17.6319 5.34738 18.3346 7.08905 18.3346 9.16683C18.3346 10.0377 18.1857 10.9009 17.8878 11.7564C17.5898 12.612 17.0971 13.3988 16.4096 14.1168V21.0835L11.0013 19.2731L5.59297 21.0835ZM11.0013 15.1252C12.6666 15.1252 14.076 14.5484 15.2294 13.395C16.3829 12.2415 16.9596 10.8321 16.9596 9.16683C16.9596 7.50155 16.3829 6.09218 15.2294 4.9387C14.076 3.78523 12.6666 3.2085 11.0013 3.2085C9.33602 3.2085 7.92665 3.78523 6.77318 4.9387C5.6197 6.09218 5.04297 7.50155 5.04297 9.16683C5.04297 10.8321 5.6197 12.2415 6.77318 13.395C7.92665 14.5484 9.33602 15.1252 11.0013 15.1252ZM6.96797 19.1585L11.0013 17.8981L15.0346 19.1585V15.2397C14.4235 15.6828 13.7666 16.0036 13.0638 16.2022C12.361 16.4009 11.6735 16.5002 11.0013 16.5002C10.3291 16.5002 9.64158 16.4009 8.9388 16.2022C8.23602 16.0036 7.57908 15.6828 6.96797 15.2397V19.1585Z"
            fill={color}
          />
      </svg>
    );
  } else {
     const color = route? "#7C4DFF" : '#2A2831'
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity={!account?"0.8": '1'}>
          <path
            d="M8.82422 12.5585L9.64922 9.92308L7.47214 8.22725H10.1305L11.0013 5.50016L11.8492 8.22725H14.5305L12.3534 9.92308L13.1555 12.5585L11.0013 10.9314L8.82422 12.5585ZM5.59297 21.0835V14.1168C4.90547 13.3988 4.41276 12.612 4.11484 11.7564C3.81693 10.9009 3.66797 10.0377 3.66797 9.16683C3.66797 7.08905 4.37075 5.34738 5.7763 3.94183C7.18186 2.53627 8.92352 1.8335 11.0013 1.8335C13.0791 1.8335 14.8207 2.53627 16.2263 3.94183C17.6319 5.34738 18.3346 7.08905 18.3346 9.16683C18.3346 10.0377 18.1857 10.9009 17.8878 11.7564C17.5898 12.612 17.0971 13.3988 16.4096 14.1168V21.0835L11.0013 19.2731L5.59297 21.0835ZM11.0013 15.1252C12.6666 15.1252 14.076 14.5484 15.2294 13.395C16.3829 12.2415 16.9596 10.8321 16.9596 9.16683C16.9596 7.50155 16.3829 6.09218 15.2294 4.9387C14.076 3.78523 12.6666 3.2085 11.0013 3.2085C9.33602 3.2085 7.92665 3.78523 6.77318 4.9387C5.6197 6.09218 5.04297 7.50155 5.04297 9.16683C5.04297 10.8321 5.6197 12.2415 6.77318 13.395C7.92665 14.5484 9.33602 15.1252 11.0013 15.1252ZM6.96797 19.1585L11.0013 17.8981L15.0346 19.1585V15.2397C14.4235 15.6828 13.7666 16.0036 13.0638 16.2022C12.361 16.4009 11.6735 16.5002 11.0013 16.5002C10.3291 16.5002 9.64158 16.4009 8.9388 16.2022C8.23602 16.0036 7.57908 15.6828 6.96797 15.2397V19.1585Z"
            fill={color}
          />
        </g>
      </svg>
    );
  }
};

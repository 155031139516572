// // import { getAllTeams, getCreditLimits, ITeam, selectTeam } from "redux/actions";

// // export const SwitchTeam = () => {
// //     getAllTeams().then((res) => {
// //         const response = res as ITeam[]
// //         const team_id = response[0]?.id ,

// //         selectTeam({ team_id })
// //             .then(() => {
// //                 getCreditLimits()
// //                     .then(() => {
// //                         //   setTeam(team_id);
// //                     })
// //                     .catch((err) => {
// //                         //   setTeam(userDetail?.user.team.id);
// //                         triggerNotification({ message: err?.data?.message, type: "error" });
// //                     });
// //             })
// //             .catch(() => {
// //                 //   setTeam(userDetail?.user.team.id);
// //                 // triggerNotification({ message: err?.data?.message, type: "error" });
// //             });
// //     }).catch(() => {
// //         // triggerNotification({ message: err?.data?.message, type: "error" });
// //     });
// // }

// import { useAppNotification } from "hooks/services/AppNotification";
import {  getAllTeams, getCreditLimits, ITeam, selectTeam } from "redux/actions";

export const SwitchTeam = async () => {
    // const { triggerNotification } = useAppNotification();
    try {
        const response = await getAllTeams() as ITeam[];
        const team_id = response[0]?.id;

        if (!team_id) {
            throw new Error("No teams available");
        }

        await selectTeam({ team_id });

        try {
            await getCreditLimits();
            // Optionally call setTeam(team_id) here if needed
        } catch (err) {
            console.error(err,'Failed to retrieve credit limits');
            // triggerNotification({
            //     message: err?.data?.message || "Failed to retrieve credit limits",
            //     type: "error",
            // });
        }
    } catch (err) {
        console.error(err,'Failed to switch teams');
        // triggerNotification({
        //     message: err?.data?.message || "Failed to switch teams",
        //     type: "error",
        // });
    }
};


import { Navigate, Route, Routes } from "react-router-dom";

import { MainView } from "views/layout/MainView";
import { Account } from "./pages/Account";
import { Team } from "./pages/Team";
import { CurrentPlan } from "./pages/CurrentPlan";
import { RoutePaths } from "pages/routePaths";

import { useSelector } from "redux/hooks";

export const SettingsPage = () => {
  const userRole = useSelector((state) => state.authReducer.userDetail)?.user
    .team.role;

  return (
    <MainView>
      <Routes>
        <Route path={RoutePaths.Account} element={<Account />} />
        {userRole !== "member" && (
          <>
            <Route
              path={RoutePaths.Team}
              element={<Team userRole={userRole} />}
            />
            <Route path={RoutePaths.CurrentPlan} element={<CurrentPlan />} />
          </>
        )}
        <Route
          path="*"
          element={<Navigate to={RoutePaths.Account} replace />}
        />
              </Routes>
    </MainView>
  );
};

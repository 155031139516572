import { FormattedMessage } from "react-intl";
import { Dispatch, SetStateAction } from 'react';

import classNames from "classnames";
import styles from "./shareFooter.module.scss";
import Button from "components/Button";
import { LoadingButton } from "components/base";
import { useSelector } from "redux/hooks";
import { CopyShareChat, ShareChat } from "redux/actions";
import { ContinueIcon, PlusIcon } from "../Icons";
import { useWindowSize } from "hooks/useWindowSize";
import { useState } from "react";
import { useAppNotification } from "hooks/services/AppNotification";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute } from "pages/ChatPage";
import {
    startNewChat,
} from "redux/actions";

import useRouter from "hooks/useRouter";
import { Spinner } from "components";

interface IProps {
    selectedMessages?: any,
    toggleShareChat?: () => void;
    handleSelectAllChats?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedChatId?: any;
    loadingShareChat?: boolean;
    setSelectedMessages?: Dispatch<SetStateAction<any[]>>;
    scrollToBottom?: ({ behavior }: ScrollOptions) => void;
}

export const ShareFooter = ({ selectedMessages, toggleShareChat, handleSelectAllChats,
    selectedChatId,
    loadingShareChat, setSelectedMessages, }: IProps) => {
    const { theme } = useSelector(
        (state) => state.authReducer
    );
    const { shareMessages, messages, newMessages } = useSelector((state) => state.chatReducer);
    const isShareChat = window.location.pathname.includes("share-chat");
    const userDetail = useSelector((state) => state.authReducer.userDetail);
    const { width } = useWindowSize();
    const [isLoaing, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const { triggerNotification } = useAppNotification();
    const { push } = useRouter();

    const URL = window.location.pathname.includes("chat/new")

    const onStartNewChat = ({ toChat }: { toChat: boolean }) => {
        if (toChat) {
            push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
        }
        startNewChat();
    }
    const continueChat = () => {
        if (!userDetail?.token) {
            localStorage.setItem('shareChat-Token', shareMessages?.token);
            push(`/${RoutePaths.Chat}/${ChatRoute.History}/${shareMessages?.chat_id}`);
        }

        else {
            setLoading(true)
            CopyShareChat({ token: shareMessages?.token })
                .then((resp: any) => {
                    setLoading(false);
                    if (resp?.data?.redirect === true) {
                        push(`/${RoutePaths.Chat}/${ChatRoute.History}/${resp?.data?.shared_chat?.chat_id}`);
                        triggerNotification({ message: 'Welcome back!', type: "info" });
                        // setTimeout(() => {
                        //     store.dispatch({
                        //         type: TYPES.GET_CHAT_HISTORY,
                        //         payload: resp?.data?.shared_chat.messages as IMessage,
                        //     });
                        //     setGetMessagesLoading(false);
                        //    setTimeout(()=> scrollToBottom?.({ behavior: "smooth" }) ,5) ;
                        // }, 25);
                    }
                    else
                        push(`/${RoutePaths.Chat}/${ChatRoute.History}/${resp?.data?.id}`);
                })
                .catch((err: any) => {
                    setLoading(false);
                    triggerNotification({ message: err?.data?.message, type: "error" });
                })
        }
    }

    const handleCopyShareLink = () => {
        setIsLoading(true);
        const currentURL = window.location.href;
        const segments = currentURL.split('/');
        const chatId = parseInt(segments[segments.length - 1], 10);
        ShareChat({ chat_id: chatId === null || isNaN(chatId) ? selectedChatId : chatId, messages: selectedMessages })
            .then((resp: any) => {
                setIsLoading(false);
                triggerNotification({ message: resp.message, type: "info" });

            })
            .catch(() => {
                setIsLoading(false);
            });
    }

    const handleSelectAll = () => {
        if (messages.length === 0 || URL) {
            let selectedMessagesIds = newMessages.map(message => message.id!)
            setSelectedMessages!(selectedMessagesIds);
        }
        else {
            let selectedMessagesIds = messages.map(message => message.id!)
            setSelectedMessages!(selectedMessagesIds);
        }
    }
    const isAllMessagesSelected = selectedMessages.length === messages.length || selectedMessages.length === newMessages.length || selectedMessages.length === 0;
    const isChecked = messages.length === 0 || URL ? selectedMessages.length === newMessages.length : selectedMessages.length === messages.length;

    return (
        <>
            {isShareChat && !loadingShareChat ?
                <div className="flex flex-col w-full ">
                    <div className={classNames(styles.selectedMessage, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                        [styles.sharefooterbottom]: isShareChat,
                        [styles.sharefooterclr]: isShareChat && theme === 'light',
                    })} >
                        <FormattedMessage id="share.conversation" />
                    </div>
                    <div className='flex justify-center align-center w-full'>
                        <div className="flex items-center w-full justify-between" >
                            <div className="flex justify-center items-center w-full" >
                                <div className={classNames(styles.Continuebtn)} data-testid='continue-btn' onClick={() => continueChat()}>
                                    <LoadingButton
                                        style={{ width: '80%', padding: '0.3em 2em', position: 'relative', background: loading ? 'rgb(203, 200, 255)' : '', borderColor: loading ? 'transparent' : '' }}
                                        size="lg"
                                        type="submit"
                                        data-testid='share-continue-btn'
                                    >
                                        <div className={classNames(styles.btnContent)} >
                                            {loading ? (
                                                <Spinner small />
                                            ) :
                                                <>
                                                    {width >= 768 && <ContinueIcon />}
                                                    <div className={styles.continuetext}>
                                                        <FormattedMessage id="share.continue.chat" />
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </LoadingButton>
                                </div>

                                <button className={classNames(styles.newChatBtn)} data-testid='share-newchat-btn' onClick={() => { onStartNewChat?.({ toChat: true }); }}>
                                    <div className={classNames(styles.btnContent)} >
                                        {width >= 768 && <PlusIcon />}
                                        <div className={styles.text} >
                                            <FormattedMessage id="sidebar.chat.newChat.btn.text" />
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                !isShareChat &&
                <div className="flex flex-col w-full ">
                    {selectedMessages?.length > 0 &&
                        <div className={classNames(styles.selectedMessage, {
                            [styles.light]: theme === "light",
                            [styles.dark]: theme === "dark",
                        })}>
                            {selectedMessages.length} <FormattedMessage id="share.message.selected" />
                        </div>
                    }
                    <div className='flex justify-center align-center w-full'>
                        <div className="flex items-center w-full justify-between" >
                            <div className="flex justify-center items-center w-full" >
                                <div className={classNames(styles.shareLinkbtn)} >
                                    <LoadingButton
                                        full
                                        size="lg"
                                        type="submit"
                                        onClick={handleCopyShareLink}
                                        isloading={isLoaing}
                                        disabled={selectedMessages.length === 0}
                                        data-testid='footer-copy-btn'
                                    >
                                        <FormattedMessage id="footer.copy.link" />
                                    </LoadingButton>
                                </div>
                                <Button className={styles.cancelbtn} small={true} data-testid='share-cancel-btn' onClick={() => { toggleShareChat!(); setSelectedMessages!([]); }}>
                                    <div
                                        className={classNames(styles.btnText, {
                                            [styles.light]: theme === "light",
                                            [styles.dark]: theme === "dark",
                                        })}
                                    >
                                        <FormattedMessage id="gptModal.btn.cancel" />
                                    </div>
                                </Button>
                            </div>
                            <div className={`flex items-center justify-end text-right pb-[25px] ${styles.buttonMainContainer}`} >
                                {isAllMessagesSelected ?
                                    <label className={styles.checkboxContainer}>
                                        <input
                                            type="checkbox"
                                            onChange={handleSelectAllChats}
                                            checked={isChecked ? true : false}
                                            data-testid='Selected-message-checkbox'
                                        />
                                        <span className={styles.checkmark}></span>
                                    </label>
                                    :
                                    <label className={`relative inline rounded-full cursor-pointer py-0 ${styles.RadioContainer}`} onClick={handleSelectAll} >
                                        <input
                                            className={` ${styles.customRadio}`}
                                            type="radio"
                                            checked={selectedMessages.length !== 0 && selectedMessages.length !== messages.length}
                                            data-testid='select-all-message-checkbox'
                                        />
                                    </label>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

import styled from "styled-components";

import { theme } from "theme";

import { UserRoles, UserRolesType } from "redux/actions";
import { FormattedMessage } from "react-intl";

interface IProps {
  type: UserRolesType;
}

const getBackground = (props: IProps) => {
  if (props.type === UserRoles.admin) {
    return "rgba(124, 77, 255, 0.1)";
  } else if (props.type === UserRoles.manager) {
    return "rgba(77, 148, 255, 0.1)";
  } else {
    return "rgba(77, 255, 116, 0.1)";
  }
};

const getTextColor = (props: IProps) => {
  if (props.type === UserRoles.admin) {
    return theme.primaryColor;
  } else if (props.type === UserRoles.manager) {
    return "#4D94FF";
  } else {
    return "#4DFF74";
  }
};

const Tag = styled.span<{ props: IProps }>`
  background: ${({ props }) => getBackground(props)};
  border-radius: 300px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  display: inline-block;
  color: ${({ props }) => getTextColor(props)};
  padding: 2px 8px;
`;

export const UserRole = (props: IProps) => {
  return (
    <Tag props={props}>
      <FormattedMessage id={`team.invite.option.${props.type}`} />
    </Tag>
  );
};

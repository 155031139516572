interface IProps {
  className?: string;
}

export const Star = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.707 10.5258L13.8921 4.42168C14.1076 4.00885 14.5347 3.75 15.0003 3.75C15.466 3.75 15.8931 4.00885 16.1085 4.42168L19.2936 10.5258"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7064 10.5254L4.79348 11.5208C4.33426 11.5981 3.95609 11.9243 3.81218 12.3672C3.66828 12.8101 3.78251 13.2963 4.1086 13.6288L8.5086 18.1148"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.293 10.5254L25.2059 11.5208C25.6652 11.5981 26.0433 11.9243 26.1872 12.3672C26.3311 12.8101 26.2169 13.2963 25.8908 13.6288L21.4908 18.1148"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.50905 18.1152L7.51455 24.814C7.44618 25.2746 7.63955 25.7351 8.0163 26.0088C8.39306 26.2825 8.89073 26.3241 9.30769 26.1167L15.0003 23.2854"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4912 18.1152L22.4857 24.814C22.5541 25.2746 22.3608 25.7351 21.984 26.0088C21.6072 26.2825 21.1096 26.3241 20.6926 26.1167L15 23.2854"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

interface IProps {
  chatSide?: boolean
}

export const DarkIcon = ({chatSide}: IProps) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99983 7.99985C9.26351 6.70236 9.57581 4.75087 8.78016 3.12382C8.73037 3.00537 8.75307 2.86886 8.8385 2.77289C8.92394 2.67692 9.05691 2.63857 9.18032 2.6743C10.1307 2.91927 10.9982 3.41414 11.6927 4.10756C13.8424 6.20175 13.8877 9.64193 11.7941 11.7921C9.64409 13.8859 6.20392 13.8409 4.10954 11.6914C3.41572 10.9973 2.92057 10.13 2.67561 9.17968C2.6397 9.05609 2.6781 8.92287 2.77429 8.83737C2.87048 8.75187 3.00728 8.72935 3.1258 8.77951C4.75213 9.5748 6.70274 9.26277 7.99983 7.99985Z"
      stroke= {chatSide? "#71717A" : "#CFCFCF"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

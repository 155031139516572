export const ManagerIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5 13.75H6.91667C4.89162 13.75 3.25 15.3916 3.25 17.4167V18.3333"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5916 19.2042C11.5915 19.2295 11.571 19.25 11.5457 19.25C11.5204 19.25 11.4999 19.2295 11.4999 19.2042C11.4999 19.1789 11.5204 19.1584 11.5457 19.1584C11.571 19.1583 11.5915 19.1788 11.5916 19.2041V19.2042"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.84157 19.2042C8.84154 19.2295 8.82101 19.25 8.79571 19.25C8.77041 19.25 8.7499 19.2295 8.7499 19.2042C8.7499 19.1789 8.77041 19.1584 8.79571 19.1584C8.82101 19.1583 8.84154 19.1788 8.84157 19.2041V19.2042"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="10.5832"
        cy="6.41667"
        r="3.66667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0453 19.2042H14.7083C14.4552 19.2042 14.25 18.999 14.25 18.7458V17.4089C14.25 17.1658 14.3466 16.9326 14.5185 16.7607L17.3065 13.9727C17.4784 13.8007 17.7116 13.7042 17.9547 13.7042C18.1978 13.7042 18.431 13.8007 18.6029 13.9727L19.4815 14.8513C19.8395 15.2093 19.8395 15.7897 19.4815 16.1476L16.6934 18.9357C16.5215 19.1076 16.2884 19.2042 16.0453 19.2042Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import { components, OptionProps } from "react-select";

import { EThemeType } from "redux/reducers";
import { theme } from "theme";
import styled from "styled-components";

import { TickIcon } from "../../../icons/TickIcon";

import { CheckBox } from "../../index";

import { OptionType } from "../DropDown";
import Text from "./Text";

export type IProps = {
  data: { disabled: boolean; index: number; fullText?: boolean } & IDataItem;
  variant?: EThemeType;
  small?: boolean;
} & OptionProps<OptionType, boolean>;

interface IOptionView {
  isSelected?: boolean;
  isDisabled?: boolean;
  variant?: EThemeType;
}

export interface IDataItem {
  label?: string;
  value?: any;
  text?: string;
  groupValue?: string;
  groupValueText?: string;
  imgNode?: React.ReactNode;
  img?: string;
  primary?: boolean;
  secondary?: boolean;
  config?: any;
}

const setBackgroundColor = (props: IOptionView) => {
  if (props.variant === "dark") {
    if (props.isSelected) return theme.primaryColor;
    else return "#101113";
  } else if (props.variant === "light") {
    if (props.isSelected) return theme.primaryColor;
    else return theme.white;
  } else {
    if (props.isSelected) return "transparent";
    else return "transparent";
  }
};

const setTextColor = (props: IOptionView) => {
  if (props.variant === "dark") {
    if (props.isSelected) return theme.white;
    else return theme.white;
  } else if (props.variant === "light") {
    if (props.isSelected) return theme.white;
    else return theme.textColorLight;
  } else {
    if (props.isSelected) return theme.white;
    else return theme.white;
  }
};

const setHoverBackgroundColor = (props: IOptionView) => {
  if (props.variant === "dark") {
    return theme.primaryColor;
  } else if (props.variant === "light") {
    return theme.primaryColor;
  } else {
    return "transparent";
  }
};

export const OptionView = styled.div<IOptionView>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${(props) => setTextColor(props)};
  background: ${(props) => setBackgroundColor(props)};
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 19px;

  &:hover {
    background: ${(props) => setHoverBackgroundColor(props)};
  }
`;

export const Icon = styled.div<{
  small?: boolean;
}>`
  ${({ small }) => (small ? "max-width: 20px;" : "")}
  margin-right: 6px;
  display: inline-block;
`;

const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Option = (props: IProps) => {
  const dataTestId = props.data.testId
    ? props.data.testId
    : !["object", "array"].includes(typeof props.data.label)
    ? props.data.label
    : `select_value_${props.data.value}`;

  return (
    <components.Option {...props}>
      <OptionView
        data-testid={dataTestId}
        isSelected={props.isSelected && !props.isMulti}
        isDisabled={props.isDisabled}
        variant={props.variant}
      >
        {props.data.img ? (
          <Icon small={ props.small}>
            <img src={props.data.img} alt="" />
          </Icon>
        ) : null}
        {props.data.imgMode || null}
        <Text
          primary={props.data.primary}
          secondary={props.data.secondary}
          fullText={props.data.fullText}
        >
          {props.isMulti && (
            <>
              <CheckBox
                checked={props.isSelected}
                onChange={() => props.selectOption(props.data)}
              />{" "}
            </>
          )}
          {props.variant ? (
            props.label
          ) : (
            <OptionContainer>
              {props.label}
              {props.isSelected && (
                <>
                  <TickIcon />
                </>
              )}
            </OptionContainer>
          )}
        </Text>
      </OptionView>
    </components.Option>
  );
};

export default Option;

import { components, ContainerProps } from "react-select";

import { OptionType } from "./DropDown";

export const SelectContainer = (
  props: ContainerProps<OptionType, false> & {
    selectProps: {
      "data-testid": string;
      role: string;
    };
  }
) => {
  const wrapperProps = {
    "data-testid": props.selectProps["data-testid"],
    role: props.selectProps["role"] || "combobox",
  };
  return (
    <components.SelectContainer
      {...props}
      innerProps={{ ...props.innerProps, ...wrapperProps }}
    />
  );
};

import { useAppNotification } from "hooks/services/AppNotification";
import {
    getAllTeams,
    getCreditLimits,
    ITeam,
    selectTeam
} from "redux/actions";

export const useSwitchTeam = () => {
    const { triggerNotification } = useAppNotification();

    const switchTeam = async () => {
        try {
            // const response = await getTeamMembers() as TeamMembers;
            const response = await getAllTeams() as ITeam[];
            const team_id = response[0]?.id;
            // const team_id = response.members.find((team) => team.role === 'admin')?.id;

            if (!team_id) {
                throw new Error("No teams available");
            }

            await selectTeam({ team_id });

            try {
                await getCreditLimits();
            } catch (err) {
                triggerNotification({
                    message: err?.data?.message || "Failed to retrieve credit limits",
                    type: "error",
                });
            }
        } catch (err) {
            triggerNotification({
                message: err?.data?.message || "Failed to switch teams",
                type: "error",
            });
        }
    };

    return switchTeam;
};

import classNames from "classnames";
import { EThemeType } from "redux/reducers";

export interface IImage {
  src: string;
  alt: string;
  className?: string;
}

const imageClassNames: string[] = [
  "rounded-[10px] md:rounded-[16px] lg:rounded-[20px] translate-y-[40px] md:translate-y-[75px] lg:translate-y-[90px] xl:translate-y-[150px]",
  "rounded-[10px] md:rounded-[16px] lg:rounded-[20px] translate-y-[11px] md:translate-y-[20px] lg:translate-y-[25px] xl:translate-y-[45px]",
  "rounded-[10px] md:rounded-[16px] lg:rounded-[20px] translate-y-[40px] md:translate-y-[75px] lg:translate-y-[90px] xl:translate-y-[150px]",
  "rounded-tl-[10px] md:rounded-tl-[20px] rounded-tr-[10px] md:rounded-tr-[20px]",
  "rounded-tl-[10px] md:rounded-tl-[20px] rounded-tr-[10px] md:rounded-tr-[20px]",
  "rounded-tl-[10px] md:rounded-tl-[20px] rounded-tr-[10px] md:rounded-tr-[20px]",
];

interface IHeroProps {
  images: IImage[];
  title: string;
  content: string;
  themeMode?: EThemeType;
}

export const Hero: React.FC<IHeroProps> = ({ title, content,themeMode, images }) => {
  return (
    <div
      // className="relative overflow-hidden"
      style={{
        backgroundImage: `url(/feature/bgImages/bg-01-${themeMode}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <div className="w-[553px] h-[553px] rounded-full bg-[#4693E8] filter blur-[500px] absolute top-[84px] right-0 translate-x-[200px]"></div> */}
      <div className="relative z-[1]">
        <div className=" text-center flex flex-col items-center text-light dark:text-dark pt-[60px] md:pt-[111px] px-5">
          <h2 className="text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] bg-gradient-to-right bg-clip-text text-transparent">
            {title}
          </h2>
          <p className="opacity-70 text-[14px] md:text-[16px] lg:text-[18px] px-10 font-medium mt-3 leading-7">
            {content}
          </p>
        </div>
        <div className="grid grid-rows-2 grid-cols-3 place-items-end max-w-fit mx-auto py-10 md:py-16 lg:py-28 gap-3 md:gap-4 xl:gap-5">
          {images.map((img, index) => (
            <img
              key={index}
              src={img.src}
              alt=""
              className={classNames(
                "max-w-[100px] md:max-w-[180px] lg:max-w-[220px] xl:max-w-[360px] shadow-xl",
                imageClassNames[index],
                img?.className
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

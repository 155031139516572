import classnames from "classnames";

import styles from "./Spinner.module.scss";

interface SpinnerProps {
  small?: boolean;
  extraSmall?: boolean;
  isBlack?: boolean;
  isWhite?:boolean
}

const Spinner = ({ small, extraSmall, isBlack, isWhite}: SpinnerProps) => (
  <div
    className={classnames(styles.spinner, {
      [styles.small]: small,
      [styles.extraSmall]: extraSmall,
      [styles.isBlack]: isBlack,
      [styles.isWhite]: isWhite,
    })}
  />
);

export default Spinner;

import { FormattedMessage } from "react-intl";
import { EThemeType } from "redux/reducers";
export interface IFaq {
  title: string;
  content: string;
}

interface IFaqProps {
  faqs: IFaq[];
  themeMode?: EThemeType;
}

export const Faqs: React.FC<IFaqProps> = ({ faqs, themeMode }) => {
  return (
    <div
      style={{
        backgroundImage: `url(/feature/bgImages/bg-04-${themeMode}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-light dark:text-dark py-14 md:py-28 w-fit max-w-[1200px] flex flex-col items-center mx-auto">
        <h2 className="text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20 bg-gradient-to-right bg-clip-text text-transparent w-fit">
          <FormattedMessage id="feature.faq.title" />
        </h2>
        <div className="px-[10%] xl:px-0">
          {faqs.map((row) => (
            <div className="py-5" key={row.title}>
              <h4 className="text-[16px] md:text-[20px] leading-7">
                <FormattedMessage id={row.title} />
              </h4>
              <p className="text-[14px] md:text-[16px] mt-3 md:mt-4 opacity-70 font-medium leading-6 md:leading-7">
                <FormattedMessage id={row.content} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

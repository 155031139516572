export const DiscountsIcon = ({ className }: {className?:string}) => (
  <svg
    className={ className}
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <path
      d="M15.5835 22.9319C15.5835 18.8727 18.8725 15.5837 22.9317 15.5804H26.276C28.2162 15.5804 30.0752 14.8102 31.4532 13.4452L33.79 11.1052C36.6532 8.2257 41.3072 8.2127 44.1867 11.0759L44.19 11.0792L44.2192 11.1052L46.5592 13.4452C47.9372 14.8134 49.7962 15.5804 51.7365 15.5804H55.0775C59.1367 15.5804 62.429 18.8694 62.429 22.9319V26.2697C62.429 28.2099 63.196 30.0722 64.5642 31.4502L66.9042 33.7902C69.7837 36.6534 69.8 41.3075 66.9367 44.187L66.9335 44.1902L66.9042 44.2195L64.5642 46.5594C63.196 47.9342 62.429 49.7932 62.429 51.7334V55.0777C62.429 59.137 59.14 62.426 55.0807 62.426H55.0775H51.73C49.7897 62.426 47.9275 63.1962 46.5527 64.5645L44.2127 66.9012C41.3527 69.7807 36.702 69.797 33.8225 66.9402C33.8192 66.937 33.816 66.9337 33.8127 66.9304L33.7835 66.9012L31.4467 64.5645C30.072 63.1962 28.2097 62.4292 26.2695 62.426H22.9317C18.8725 62.426 15.5835 59.137 15.5835 55.0777V51.7269C15.5835 49.7867 14.8132 47.9277 13.445 46.5529L11.1082 44.2129C8.22872 41.3529 8.21247 36.7022 11.0725 33.8227C11.0725 33.8194 11.0757 33.8162 11.079 33.8129L11.1082 33.7837L13.445 31.4437C14.8132 30.0657 15.5835 28.2067 15.5835 26.2632V22.9319"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.6528 47.3581L47.3578 30.6531"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M47.1104 47.125H47.1396"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.8604 30.875H30.8896"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
interface Props{
    DeleteAccount?:boolean,
    settingSideBar?: boolean,
  }
  export const DeleteHistory = ({DeleteAccount, settingSideBar}:Props) => {
      return (
          <svg xmlns="http://www.w3.org/2000/svg" width={ settingSideBar? "22":"15"} height={settingSideBar? "22" :"15"} viewBox="0 0 15 15" fill="none" style={{"marginLeft":settingSideBar? "": "4px" , "marginTop": settingSideBar ? "":"4px"}}>
          <path d="M10.4496 13.1833H5.02441C4.70686 13.1825 4.40253 13.056 4.17798 12.8315C3.95344 12.6069 3.82695 12.3026 3.82617 11.9851V3.53467C3.82617 3.41812 3.87247 3.30634 3.95488 3.22393C4.0373 3.14151 4.14907 3.09521 4.26562 3.09521H11.209C11.3255 3.09521 11.4373 3.14151 11.5197 3.22393C11.6021 3.30634 11.6484 3.41812 11.6484 3.53467V11.9851C11.6477 12.3027 11.5211 12.6071 11.2964 12.8317C11.0717 13.0563 10.7673 13.1827 10.4496 13.1833ZM4.70508 3.97412V11.9851C4.70516 12.0697 4.73882 12.1509 4.79869 12.2108C4.85857 12.2706 4.93974 12.3043 5.02441 12.3044H10.4496C10.5343 12.3044 10.6156 12.2708 10.6755 12.2109C10.7355 12.151 10.7692 12.0698 10.7692 11.9851V3.97412H4.70508Z" fill={DeleteAccount?"#DF3A32":"white"}/>
          <path d="M9.58203 3.97432H5.89062C5.77407 3.97432 5.6623 3.92802 5.57988 3.8456C5.49747 3.76319 5.45117 3.65141 5.45117 3.53486V2.73623C5.45164 2.38877 5.58989 2.05568 5.83561 1.81001C6.08133 1.56435 6.41445 1.42617 6.76191 1.42578H8.71074C9.0582 1.42617 9.39133 1.56435 9.63704 1.81001C9.88276 2.05568 10.021 2.38877 10.0215 2.73623V3.53486C10.0215 3.65141 9.97518 3.76319 9.89277 3.8456C9.81036 3.92802 9.69858 3.97432 9.58203 3.97432ZM6.33008 3.09541H9.14258V2.73623C9.14242 2.62178 9.09687 2.51206 9.01591 2.43115C8.93495 2.35025 8.8252 2.30476 8.71074 2.30469H6.76191C6.64751 2.30484 6.53784 2.35036 6.45694 2.43125C6.37604 2.51215 6.33053 2.62183 6.33037 2.73623L6.33008 3.09541Z" fill={DeleteAccount?"#DF3A32":"white"}/>
          <path d="M7.73633 11.5012C7.61978 11.5012 7.508 11.4549 7.42559 11.3725C7.34317 11.29 7.29688 11.1783 7.29688 11.0617V5.21729C7.29688 5.10073 7.34317 4.98896 7.42559 4.90654C7.508 4.82413 7.61978 4.77783 7.73633 4.77783C7.85288 4.77783 7.96465 4.82413 8.04707 4.90654C8.12948 4.98896 8.17578 5.10073 8.17578 5.21729V11.0617C8.17578 11.1783 8.12948 11.29 8.04707 11.3725C7.96465 11.4549 7.85288 11.5012 7.73633 11.5012Z" fill={DeleteAccount?"#DF3A32":"white"}/>
          <path d="M6.15039 11.5012C6.03384 11.5012 5.92206 11.4549 5.83965 11.3725C5.75724 11.29 5.71094 11.1783 5.71094 11.0617V5.21729C5.71094 5.10073 5.75724 4.98896 5.83965 4.90654C5.92206 4.82413 6.03384 4.77783 6.15039 4.77783C6.26694 4.77783 6.37872 4.82413 6.46113 4.90654C6.54354 4.98896 6.58984 5.10073 6.58984 5.21729V11.0617C6.58984 11.1783 6.54354 11.29 6.46113 11.3725C6.37872 11.4549 6.26694 11.5012 6.15039 11.5012Z" fill={DeleteAccount?"#DF3A32":"white"}/>
          <path d="M9.32422 11.5012C9.20767 11.5012 9.09589 11.4549 9.01348 11.3725C8.93107 11.29 8.88477 11.1783 8.88477 11.0617V5.21729C8.88477 5.10073 8.93107 4.98896 9.01348 4.90654C9.09589 4.82413 9.20767 4.77783 9.32422 4.77783C9.44077 4.77783 9.55255 4.82413 9.63496 4.90654C9.71737 4.98896 9.76367 5.10073 9.76367 5.21729V11.0617C9.76367 11.1783 9.71737 11.29 9.63496 11.3725C9.55255 11.4549 9.44077 11.5012 9.32422 11.5012Z" fill={DeleteAccount?"#DF3A32":"white"}/>
          <path d="M11.8894 3.97412H3.58398C3.46743 3.97412 3.35566 3.92782 3.27324 3.84541C3.19083 3.76299 3.14453 3.65122 3.14453 3.53467C3.14453 3.41812 3.19083 3.30634 3.27324 3.22393C3.35566 3.14151 3.46743 3.09521 3.58398 3.09521H11.8894C12.0059 3.09521 12.1177 3.14151 12.2001 3.22393C12.2825 3.30634 12.3288 3.41812 12.3288 3.53467C12.3288 3.65122 12.2825 3.76299 12.2001 3.84541C12.1177 3.92782 12.0059 3.97412 11.8894 3.97412Z" fill={DeleteAccount?"#DF3A32":"white"}/>
        </svg>
      );
    };
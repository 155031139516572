import { FormattedMessage, useIntl } from "react-intl";

import classNames from "classnames";
import styles from "./freeCreditsModal.module.scss";

import { Modal, LabeledInput } from "components";
import Button from "components/Button";
import { SocialShare } from "./components/SocialShare";

import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";

interface IProps {
  onClose: () => void;
}

export const FreeCreditsModal = ({ onClose }: IProps) => {
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const { triggerNotification } = useAppNotification();
  const { formatMessage } = useIntl();

  const referralLink = `${window.location.origin}/referral/${userDetail?.user?.referral_token}`;
  const totalEarned: number = userDetail?.user?.activeSubscription.total_earned_credits ?? 0;

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(referralLink);
    triggerNotification({ message: "referral link copied", type: "info" });
  };

  return (
    <Modal size="lg" onClose={onClose}>
      <div
        className={classNames(styles.container, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <div className={styles.title}>
          <FormattedMessage id="referral.modal.headline" />
        </div>
        <div className={styles.lableInput}>
          <LabeledInput
            labelAdditionLength={0}
            type="text"
            readOnly
            focusedStyle={false}
            value={referralLink}
            message={formatMessage({ id: "referral.modal.inputLabel.text" })}
            variant={theme}
          />
          <Button data-testid='referral-modal-copy' variant="primary" onClick={handleCopyLink}>
            <FormattedMessage id="referral.modal.copyLink.text" />
          </Button>
          <SocialShare referralLink={referralLink} />
          {totalEarned > 0 && (
            <FormattedMessage id="referral.modal.creditEarned" values={{ credits: totalEarned }} />
          )}
        </div>
      </div>
    </Modal>
  );
};

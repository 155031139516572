

const CheveronDown = () => {
  return (
    <svg width="12" height="8" viewBox="0 0 12 8"  xmlns="http://www.w3.org/2000/svg">
    <path d="M6 7.4L0 1.4L1.4 0L6 4.6L10.6 0L12 1.4L6 7.4Z" />
    </svg>
    
  )
}

export default CheveronDown
import { theme } from "theme";

interface IProp {
  className?: string;
  themecolor?: any;
  viewMore?: boolean;
}

export const RealTimeIcon = ({ className }: IProp) => (
  <svg
    className={className}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.9167 55.4167H17.5C12.6675 55.4167 8.75 51.4992 8.75 46.6667V23.3333C8.75 18.5009 12.6675 14.5833 17.5 14.5833H37.9167"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.6666 5.83337V64.1667"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M46.6667 55.4167H52.5C57.3325 55.4167 61.25 51.4992 61.25 46.6667V23.3333C61.25 18.5009 57.3325 14.5833 52.5 14.5833H46.6667"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.4167 29.1667H32.0834"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.25 29.1667V40.8334"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UserFriendlyIcon = ({ className }: IProp) => (
  <svg
    className={className}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.75 61.25C10.0931 61.25 8.75 59.9068 8.75 58.25L8.75003 23.4166C8.75003 21.7598 10.0932 20.4166 11.75 20.4166L46.5834 20.4167C48.2402 20.4167 49.5834 21.7598 49.5834 23.4167L49.5833 58.25C49.5833 59.9069 48.2402 61.25 46.5833 61.25L11.75 61.25Z"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.75 37.9167H49.5833"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.0833 29.1521L32.0688 29.1667L32.0833 29.1813L32.0979 29.1667L32.0833 29.1521"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7917 29.1521L24.7771 29.1667L24.7917 29.1813L24.8063 29.1667L24.7917 29.1521"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 29.1521L17.4854 29.1667L17.5 29.1813L17.5145 29.1667L17.5 29.1521"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32.0833 29.1521L32.0688 29.1667L32.0833 29.1813L32.0979 29.1667L32.0833 29.1521"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7917 29.1521L24.7771 29.1667L24.7917 29.1813L24.8063 29.1667L24.7917 29.1521"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 29.1521L17.4854 29.1667L17.5 29.1813L17.5145 29.1667L17.5 29.1521"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.3334 11.6667C25.199 9.79619 27.7332 8.7465 30.375 8.75001H52.5C57.3325 8.75001 61.25 12.6675 61.25 17.5V39.625C61.2536 42.2668 60.2039 44.8011 58.3334 46.6667"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MultiLanguageIcon = ({ className }: IProp) => (
  <svg
    className={className}
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3125 35H24.4388C27.8542 35 30.625 32.2292 30.625 28.8137V28.8137C30.625 27.1717 29.9746 25.5996 28.8138 24.4387L23.4442 19.0692"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.7638 49.0817L44.8759 43.2513C44.173 42.1954 42.9888 41.5625 41.72 41.5625V41.5625C40.2821 41.5625 38.9696 40.7517 38.328 39.4654L38.0363 38.885C37.5025 37.8175 37.5025 36.5604 38.0363 35.4929L40.5155 30.5375C41.1571 29.2513 42.4725 28.4404 43.9075 28.4404H53.55"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 15.3125C45.8996 15.3125 54.6875 24.1004 54.6875 35C54.6875 45.8996 45.8996 54.6875 35 54.6875"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35 54.6875C24.1004 54.6875 15.3125 45.8996 15.3125 35C15.3125 24.1004 24.1004 15.3125 35 15.3125"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.3875 16.5462C14.8458 14.84 16.5054 13.3204 18.3225 12.0021"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M50.7996 58.6425C46.2788 61.6671 40.8479 63.4375 35 63.4375C32.1184 63.4375 29.3417 63 26.7196 62.2038"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1441 57.1171C13.0958 53.8446 9.9866 49.4812 8.21619 44.4792"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.21624 25.5208C7.16332 28.49 6.56249 31.6721 6.56249 35"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M58.5813 50.7996C61.6263 46.2788 63.4375 40.8625 63.4375 35C63.4375 19.2937 50.7063 6.5625 35 6.5625"
      stroke={theme.primaryColor}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PriceLoginAccessIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3639 5.63604C21.8787 9.15076 21.8787 14.8492 18.3639 18.3639C14.8492 21.8787 9.15074 21.8787 5.63604 18.3639C2.12132 14.8492 2.12132 9.15074 5.63604 5.63604C9.15076 2.12132 14.8492 2.12132 18.3639 5.63604"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.9891 8.3239C15.0876 9.42244 15.0876 11.2035 13.9891 12.3021C12.8906 13.4006 11.1095 13.4006 10.0109 12.3021C8.91238 11.2035 8.91238 9.42244 10.0109 8.3239C11.1095 7.22537 12.8906 7.22537 13.9891 8.3239"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.707 18.958C16.272 17.447 14.248 16.5 12 16.5C9.75197 16.5 7.72797 17.447 6.29297 18.959"
      stroke={theme.primaryColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PriceCountryIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 3.1001C16.667 8.0271 16.667 15.9731 12 20.9001"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 20.9001C7.33301 15.9731 7.33301 8.0271 12 3.1001"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 3C16.982 3 21 7.018 21 12C21 16.982 16.982 21 12 21"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21C7.018 21 3 16.982 3 12C3 7.018 7.018 3 12 3"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51001 9H20.49"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.51001 15H20.49"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PriceDowntimeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0665 12.1193H11.4146"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12.0169"
        cy="11.5167"
        r="9.00375"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4145 12.1192V6.51465"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PriceQueryTimeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.3658 5.63335V10.3053H20.3525C20.5893 10.2999 20.8093 10.4268 20.9232 10.6345C21.037 10.8422 21.0256 11.096 20.8937 11.2927L15.8256 18.7188C15.6658 18.9524 15.3736 19.0564 15.1022 18.9761C14.8307 18.8958 14.6421 18.6496 14.6351 18.3667V13.6917H10.6505C10.4137 13.6971 10.1936 13.5702 10.0798 13.3625C9.96595 13.1548 9.9773 12.901 10.1092 12.7043L15.1753 5.2812C15.3351 5.04755 15.6273 4.94362 15.8988 5.02391C16.1702 5.1042 16.3589 5.35038 16.3658 5.63335V5.63335Z"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99877 18.0026H3.74658"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.37267 12.5002H1.99585"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99877 6.99801H3.74658"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PriceTeamMembersIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7926 9.52343C21.5826 10.3135 21.5826 11.5944 20.7926 12.3844C20.0026 13.1744 18.7217 13.1744 17.9316 12.3844C17.1416 11.5944 17.1416 10.3135 17.9316 9.52343C18.7217 8.7334 20.0026 8.7334 20.7926 9.52343"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2026 5.91236C15.4191 7.12884 15.4191 9.10115 14.2026 10.3176C12.9862 11.5341 11.0139 11.5341 9.79737 10.3176C8.58089 9.10116 8.58089 7.12885 9.79737 5.91236C11.0138 4.69588 12.9862 4.69588 14.2026 5.91236"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.06846 9.52343C6.85849 10.3135 6.85849 11.5944 6.06846 12.3844C5.27843 13.1744 3.99754 13.1744 3.20751 12.3844C2.41748 11.5944 2.41748 10.3135 3.20751 9.52343C3.99754 8.7334 5.27843 8.7334 6.06846 9.52343"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23 19.0001V17.9041C23 16.5231 21.881 15.4041 20.5 15.4041H19.699"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 19.0001V17.9041C1 16.5231 2.119 15.4041 3.5 15.4041H4.301"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3389 18.9999V17.3989C17.3389 15.4659 15.7719 13.8989 13.8389 13.8989H10.1599C8.22691 13.8989 6.65991 15.4659 6.65991 17.3989V18.9999"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const PriceHistoryRelentionIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 2V6"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 2V6"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 9H21"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4H5C3.895 4 3 4.895 3 6V19C3 20.105 3.895 21 5 21H19C20.105 21 21 20.105 21 19V6C21 4.895 20.105 4 19 4Z"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01292 12.729C6.87492 12.729 6.76292 12.841 6.76392 12.979C6.76392 13.117 6.87592 13.229 7.01392 13.229C7.15192 13.229 7.26392 13.117 7.26392 12.979C7.26392 12.841 7.15192 12.729 7.01292 12.729"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0129 12.729C11.8749 12.729 11.7629 12.841 11.7639 12.979C11.7639 13.117 11.8759 13.229 12.0139 13.229C12.1519 13.229 12.2639 13.117 12.2639 12.979C12.2639 12.841 12.1519 12.729 12.0129 12.729"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0129 12.729C16.8749 12.729 16.7629 12.841 16.7639 12.979C16.7639 13.117 16.8759 13.229 17.0139 13.229C17.1519 13.229 17.2639 13.117 17.2639 12.979C17.2639 12.841 17.1519 12.729 17.0129 12.729"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.01292 16.729C6.87492 16.729 6.76292 16.841 6.76392 16.979C6.76392 17.117 6.87592 17.229 7.01392 17.229C7.15192 17.229 7.26392 17.117 7.26392 16.979C7.26392 16.841 7.15192 16.729 7.01292 16.729"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0129 16.729C11.8749 16.729 11.7629 16.841 11.7639 16.979C11.7639 17.117 11.8759 17.229 12.0139 17.229C12.1519 17.229 12.2639 17.117 12.2639 16.979C12.2639 16.841 12.1519 16.729 12.0129 16.729"
        stroke={theme.primaryColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const ModalIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 24C1.95 24 1.47917 23.8042 1.0875 23.4125C0.695833 23.0208 0.5 22.55 0.5 22V8C0.5 5.77778 1.27778 3.88889 2.83333 2.33333C4.38889 0.777778 6.27778 0 8.5 0H16.5C18.7222 0 20.6111 0.777778 22.1667 2.33333C23.7222 3.88889 24.5 5.77778 24.5 8V22C24.5 22.55 24.3042 23.0208 23.9125 23.4125C23.5208 23.8042 23.05 24 22.5 24H2.5ZM2.5 22H22.5V8C22.5 6.33333 21.9167 4.91667 20.75 3.75C19.5833 2.58333 18.1667 2 16.5 2H8.5C6.83333 2 5.41667 2.58333 4.25 3.75C3.08333 4.91667 2.5 6.33333 2.5 8V22ZM8.49703 11.6667C7.85457 11.6667 7.30556 11.4379 6.85 10.9804C6.39444 10.5229 6.16667 9.97286 6.16667 9.33037C6.16667 8.6879 6.39543 8.13889 6.85297 7.68333C7.31048 7.22778 7.86048 7 8.50297 7C9.14543 7 9.69444 7.22877 10.15 7.6863C10.6056 8.14381 10.8333 8.69381 10.8333 9.3363C10.8333 9.97877 10.6046 10.5278 10.147 10.9833C9.68952 11.4389 9.13952 11.6667 8.49703 11.6667ZM16.497 11.6667C15.8546 11.6667 15.3056 11.4379 14.85 10.9804C14.3944 10.5229 14.1667 9.97286 14.1667 9.33037C14.1667 8.6879 14.3954 8.13889 14.853 7.68333C15.3105 7.22778 15.8605 7 16.503 7C17.1454 7 17.6944 7.22877 18.15 7.6863C18.6056 8.14381 18.8333 8.69381 18.8333 9.3363C18.8333 9.97877 18.6046 10.5278 18.147 10.9833C17.6895 11.4389 17.1395 11.6667 16.497 11.6667ZM6.16667 22V18.3333C6.16667 17.7833 6.3625 17.3125 6.75417 16.9208C7.14583 16.5292 7.61667 16.3333 8.16667 16.3333H16.8333C17.3833 16.3333 17.8542 16.5292 18.2458 16.9208C18.6375 17.3125 18.8333 17.7833 18.8333 18.3333V22H16.8333V18.3333H13.5V22H11.5V18.3333H8.16667V22H6.16667Z"
        fill={theme.primaryColor}
      />
    </svg>
  );
};

export const ImageGenerationIcon = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4875 17.35C13.6958 17.35 14.7083 16.9417 15.525 16.125C16.3417 15.3083 16.75 14.2958 16.75 13.0875C16.75 11.8792 16.3417 10.8708 15.525 10.0625C14.7083 9.25417 13.6958 8.85 12.4875 8.85C11.2792 8.85 10.2708 9.25417 9.4625 10.0625C8.65417 10.8708 8.25 11.8792 8.25 13.0875C8.25 14.2958 8.65417 15.3083 9.4625 16.125C10.2708 16.9417 11.2792 17.35 12.4875 17.35ZM12.4875 15.85C11.6958 15.85 11.0417 15.5875 10.525 15.0625C10.0083 14.5375 9.75 13.8792 9.75 13.0875C9.75 12.2958 10.0083 11.6417 10.525 11.125C11.0417 10.6083 11.6958 10.35 12.4875 10.35C13.2792 10.35 13.9375 10.6083 14.4625 11.125C14.9875 11.6417 15.25 12.2958 15.25 13.0875C15.25 13.8792 14.9875 14.5375 14.4625 15.0625C13.9375 15.5875 13.2792 15.85 12.4875 15.85ZM4 21C3.6 21 3.25 20.85 2.95 20.55C2.65 20.25 2.5 19.9 2.5 19.5V6.675C2.5 6.29167 2.65 5.94583 2.95 5.6375C3.25 5.32917 3.6 5.175 4 5.175H7.675L9.5 3H15.5L17.325 5.175H21C21.3833 5.175 21.7292 5.32917 22.0375 5.6375C22.3458 5.94583 22.5 6.29167 22.5 6.675V19.5C22.5 19.9 22.3458 20.25 22.0375 20.55C21.7292 20.85 21.3833 21 21 21H4ZM21 19.5V6.675H16.625L14.8 4.5H10.2L8.375 6.675H4V19.5H21Z"
        fill={theme.primaryColor}
      />
    </svg>
  );
};

export const PriceLoadMoreIcon = ({ themecolor, viewMore }: IProp) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 15.3748L6 9.3748L7.075 8.2998L12 13.2498L16.925 8.3248L18 9.3998L12 15.3748Z"
      fill={themecolor === "light" && viewMore!==true ? "#262627" : "#fff"}
    />
  </svg>
);

export const PriceFileSizeIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
  >
    <path
      d="M7 17H9V12.825L10.6 14.425L12 13L8 9L4 13L5.425 14.4L7 12.825V17ZM2 20C1.45 20 0.979167 19.8042 0.5875 19.4125C0.195833 19.0208 0 18.55 0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H10L16 6V18C16 18.55 15.8042 19.0208 15.4125 19.4125C15.0208 19.8042 14.55 20 14 20H2ZM9 7V2H2V18H14V7H9Z"
      fill={theme.primaryColor}
    />
  </svg>
);

export const PricePageLimitIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12 20C11.2 19.3667 10.3333 18.875 9.4 18.525C8.46667 18.175 7.5 18 6.5 18C5.8 18 5.1125 18.0917 4.4375 18.275C3.7625 18.4583 3.11667 18.7167 2.5 19.05C2.15 19.2333 1.8125 19.225 1.4875 19.025C1.1625 18.825 1 18.5333 1 18.15V6.1C1 5.91667 1.04583 5.74167 1.1375 5.575C1.22917 5.40833 1.36667 5.28333 1.55 5.2C2.31667 4.8 3.11667 4.5 3.95 4.3C4.78333 4.1 5.63333 4 6.5 4C7.46667 4 8.4125 4.125 9.3375 4.375C10.2625 4.625 11.15 5 12 5.5V17.6C12.85 17.0667 13.7417 16.6667 14.675 16.4C15.6083 16.1333 16.55 16 17.5 16C18.1 16 18.6875 16.05 19.2625 16.15C19.8375 16.25 20.4167 16.4 21 16.6V4.6C21.25 4.68333 21.4958 4.77083 21.7375 4.8625C21.9792 4.95417 22.2167 5.06667 22.45 5.2C22.6333 5.28333 22.7708 5.40833 22.8625 5.575C22.9542 5.74167 23 5.91667 23 6.1V18.15C23 18.5333 22.8375 18.825 22.5125 19.025C22.1875 19.225 21.85 19.2333 21.5 19.05C20.8833 18.7167 20.2375 18.4583 19.5625 18.275C18.8875 18.0917 18.2 18 17.5 18C16.5 18 15.5333 18.175 14.6 18.525C13.6667 18.875 12.8 19.3667 12 20ZM14 15V5.5L19 0.5V10.5L14 15ZM10 16.625V6.725C9.45 6.49167 8.87917 6.3125 8.2875 6.1875C7.69583 6.0625 7.1 6 6.5 6C5.88333 6 5.28333 6.05833 4.7 6.175C4.11667 6.29167 3.55 6.46667 3 6.7V16.625C3.58333 16.4083 4.1625 16.25 4.7375 16.15C5.3125 16.05 5.9 16 6.5 16C7.1 16 7.6875 16.05 8.2625 16.15C8.8375 16.25 9.41667 16.4083 10 16.625Z"
      fill={theme.primaryColor}
    />
  </svg>
);

export const PriceMultipleDocumentsIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M4 20C3.45 20 2.97917 19.8042 2.5875 19.4125C2.19583 19.0208 2 18.55 2 18V6C2 5.45 2.19583 4.97917 2.5875 4.5875C2.97917 4.19583 3.45 4 4 4H10L12 6H20C20.55 6 21.0208 6.19583 21.4125 6.5875C21.8042 6.97917 22 7.45 22 8V18C22 18.55 21.8042 19.0208 21.4125 19.4125C21.0208 19.8042 20.55 20 20 20H4ZM4 18H20V8H11.175L9.175 6H4V18Z"
      fill={theme.primaryColor}
    />
  </svg>
);

export const PriceLoadMontly = ({ themecolor }: IProp) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M26.2545 9.99782H3.74512"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.8778 16.2505C24.9857 16.2505 27.5052 18.7699 27.5052 21.8778C27.5052 24.9857 24.9857 27.5052 21.8778 27.5052C18.7699 27.5052 16.2505 24.9857 16.2505 21.8778C16.2505 18.7699 18.7699 16.2505 21.8778 16.2505"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3424 26.2545H7.49668C5.42475 26.2545 3.74512 24.5749 3.74512 22.5029V7.49668C3.74512 5.42475 5.42475 3.74512 7.49668 3.74512H22.5029C24.5749 3.74512 26.2545 5.42475 26.2545 7.49668V18.3424"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22.155 19.1133V22.1039"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.7192 22.1043H22.1551"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49589 14.3748C8.49589 14.4093 8.4679 14.4373 8.43337 14.4373C8.39883 14.4373 8.37084 14.4093 8.37084 14.3748C8.37084 14.3402 8.39883 14.3122 8.43337 14.3122"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.43335 14.3123C8.46788 14.3123 8.49588 14.3403 8.49588 14.3748"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8741 14.3748C12.8741 14.4093 12.8461 14.4373 12.8115 14.4373C12.777 14.4373 12.749 14.4093 12.749 14.3748C12.749 14.3402 12.777 14.3122 12.8115 14.3122"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8115 14.3123C12.8461 14.3123 12.8741 14.3403 12.8741 14.3748"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49589 18.1262C8.49589 18.1608 8.4679 18.1888 8.43337 18.1888C8.39883 18.1888 8.37084 18.1608 8.37084 18.1262C8.37084 18.0917 8.39883 18.0637 8.43337 18.0637"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.43335 18.0637C8.46788 18.0637 8.49588 18.0917 8.49588 18.1262"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8741 18.1262C12.8741 18.1608 12.8461 18.1888 12.8115 18.1888C12.777 18.1888 12.749 18.1608 12.749 18.1262C12.749 18.0917 12.777 18.0637 12.8115 18.0637"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8115 18.0637C12.8461 18.0637 12.8741 18.0917 12.8741 18.1262"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2496 14.3748C17.2496 14.4093 17.2216 14.4373 17.187 14.4373C17.1525 14.4373 17.1245 14.4093 17.1245 14.3748C17.1245 14.3402 17.1525 14.3122 17.187 14.3122"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.187 14.3123C17.2215 14.3123 17.2495 14.3403 17.2495 14.3748"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.49589 21.8777C8.49589 21.9122 8.4679 21.9402 8.43337 21.9402C8.39883 21.9402 8.37084 21.9122 8.37084 21.8777C8.37084 21.8432 8.39883 21.8152 8.43337 21.8152"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.43335 21.8152C8.46788 21.8152 8.49588 21.8432 8.49588 21.8777"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8741 21.8777C12.8741 21.9122 12.8461 21.9402 12.8115 21.9402C12.777 21.9402 12.749 21.9122 12.749 21.8777C12.749 21.8432 12.777 21.8152 12.8115 21.8152"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8115 21.8152C12.8461 21.8152 12.8741 21.8432 12.8741 21.8777"
      stroke={themecolor === "dark" ? "white" : "black"}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

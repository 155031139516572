import { FormattedMessage } from "react-intl";
import { EThemeType } from "redux/reducers";
export interface IFeature {
  icon: React.ReactNode;
  title: string;
  content: string;
}

interface IHowToUseTheFeatureProps {
  features: IFeature[];
  themeMode?: EThemeType;
}

export const iconClassName =
  "w-[32px] h-[32px] md:w-[56px] md:h-[56px] lg:w-[70px] lg:h-[70px]";

export const HowToUseTheFeature: React.FC<IHowToUseTheFeatureProps> = ({
  features,
  themeMode,
}) => {
  return (
    <div
      style={{
        backgroundImage: `url(/feature/bgImages/bg-03-${themeMode}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-light dark:text-dark px-[10%] w-fit mx-auto py-14 md:py-28">
        {/* <div className="w-full h-[1px]" style={{ backgroundColor: "rgba(255, 255, 255, 0.20)" }}></div> */}
        <h2 className="text-center text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-10 md:mb-20">
          <FormattedMessage id="feature.howToUseFeature.title" />
        </h2>
        <div className="flex flex-col lg:flex-row gap-8 xl:gap-24 md:pt-5">
          {features.map((row) => (
            <div
              className="flex flex-col max-w-[350px] md:max-w-[420px] flex-1"
              key={row.title}
            >
              <span className="hidden lg:inline-block">{row.icon}</span>
              <div className="flex items-center mt-8 lg:mt-7">
                <span className="inline-block lg:hidden">{row.icon}</span>
                <h4 className="text-[16px] md:text-[20px] pl-3 lg:pl-0">
                  <FormattedMessage id={row.title} />
                </h4>
              </div>
              <p className="mt-2 md:mt-4 text-[14px] md:text-[16px] opacity-70 font-medium leading-6 md:leading-7">
                <FormattedMessage id={row.content} />
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

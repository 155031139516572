import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./creditLimitModal.module.scss";

import { Modal, Separator } from "components";
import Button from "components/Button";
import { FreeCreditsIcon } from "views/layout/Sidebar/component/ChatSidebar/components/icons";
import { FreeCreditsModal } from "../freeCreditsModal";

import { useSelector } from "redux/hooks";

interface IProps {
  onCancel?: () => void;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const CreditLimitModal = memo(
  ({ onCancel, onConfirm, onClose }: IProps) => {
    const { theme, userDetail, creditLimits } = useSelector((state) => state.authReducer);

    const [showReferralModal, setShowReferralModal] = useState(false);
    const UserRole = userDetail?.user.team.role !== 'admin';
    const creditExhausted = userDetail?.user.activeSubscription.credits === 0;
    const dailyLimit = creditLimits?.daily_limit === creditLimits?.used_today;

    return (
      <Modal size="sm" onClose={onClose} isPadding>
        <div
          className={classNames(styles.headline, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
            [styles.memberHeader]: UserRole,
          })}
        >
          {UserRole && creditExhausted ?
            <FormattedMessage id="creditLimit.member.modal.headline" /> :
            UserRole && dailyLimit ?
              <FormattedMessage id="creditLimit.manager.modal.headline" /> :
              <FormattedMessage id="creditLimit.modal.headline" />
          }
        </div>                     
        <Separator height="30px" />
        <div className={styles.modalFooter}>
          {!(UserRole) &&
            <Button data-testid='credit-close-modal' variant="outlined-primary" onClick={onCancel}>
              <div
                className={classNames(styles.btnText, {
                  [styles.light]: theme === "light",           
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage id="creditLimit.modal.cancelBtn.text" />
              </div>
            </Button>
          }
          <Button variant="primary" data-testid='credit-limit-btn' onClick={!UserRole ? onConfirm : onCancel} className={UserRole ? classNames(styles.btnOKText) : ''} >
            {UserRole ? <FormattedMessage id="notify.modal.ok.btn" /> :
              <FormattedMessage id="creditLimit.modal.continueBtn.text" />
            }
          </Button>
        </div>
        {!(UserRole) &&
          <div
            className={classNames(styles.freeCredits, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
            onClick={() => setShowReferralModal(true)}
            data-testid='earn-free-credits'
          >
            <FreeCreditsIcon />
            <FormattedMessage id="sidebar.chat.newChat.earnCredits" />
          </div>
        }
        {showReferralModal && (
          <FreeCreditsModal onClose={() => setShowReferralModal(false)} />
        )}
      </Modal>
    );
  }
);

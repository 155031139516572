import { EThemeType } from "redux/reducers";

interface IProp {
  theme?: EThemeType;
}

export const CopyIcon = ({theme}: IProp) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M2.39562 9.26373V9.65858C2.39562 10.3595 2.96384 10.9277 3.66477 10.9277H9.45704C10.158 10.9277 10.7262 10.3595 10.7262 9.65858V3.87687C10.7262 3.17594 10.158 2.60772 9.45704 2.60772H9.07278M0.742188 7.99457V2.21289C0.742188 1.51193 1.31041 0.943734 2.01134 0.943734H7.80363C8.50456 0.943734 9.07278 1.51193 9.07278 2.21289V7.99457C9.07278 8.69551 8.50456 9.26373 7.80363 9.26373H2.01134C1.31041 9.26373 0.742188 8.69551 0.742188 7.99457Z"
      stroke={theme==="dark"? "#fff":"currentColor"}
      strokeWidth="0.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  );
};

export const PlanIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <mask id="mask0_5708_18467" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
        <path d="M0 0.00390816H24V24.0039H0V0.00390816Z" fill="white" />
      </mask>
      <g mask="url(#mask0_5708_18467)">
        <path d="M11.4525 16.4453C11.0251 16.4453 10.6151 16.2714 10.3179 15.9622L7.27149 12.7939C6.66891 12.1672 6.68846 11.1708 7.31508 10.5683C7.94171 9.96578 8.93818 9.98523 9.5408 10.612L11.375 12.5195L14.7632 8.49602C15.323 7.831 16.3161 7.74583 16.981 8.30584C17.6461 8.86586 17.7312 9.85886 17.1712 10.5238L12.6565 15.8851C12.3692 16.2265 11.9504 16.4298 11.5044 16.4444C11.4871 16.445 11.4698 16.4453 11.4525 16.4453Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.8075 12.0045C20.8075 16.869 16.864 20.8125 11.9995 20.8125C7.13491 20.8125 3.19141 16.869 3.19141 12.0045C3.19141 7.14 7.13491 3.1965 11.9995 3.1965C16.864 3.1965 20.8075 7.14 20.8075 12.0045Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M23.5313 12.0041C23.5313 12.8539 22.6319 13.5913 22.4508 14.3864C22.264 15.2074 22.7494 16.2605 22.3916 17.0012C22.0278 17.7541 20.8968 18.0296 20.3797 18.6764C19.86 19.3262 19.8408 20.4877 19.19 21.0067C18.5423 21.523 17.4048 21.2851 16.6508 21.6483C15.909 22.0057 15.3885 23.0455 14.5663 23.2322C13.77 23.4129 12.8511 22.7057 12 22.7057C11.1489 22.7057 10.23 23.4129 9.43374 23.2322C8.61155 23.0455 8.091 22.0057 7.34921 21.6483C6.59518 21.2851 5.45771 21.523 4.81003 21.0066C4.15917 20.4877 4.13996 19.3262 3.6203 18.6763C3.10313 18.0296 1.97222 17.7541 1.60842 17.0012C1.25058 16.2605 1.73602 15.2074 1.54917 14.3864C1.36819 13.5913 0.46875 12.8539 0.46875 12.0041C0.46875 11.1543 1.36819 10.4169 1.54917 9.62177C1.73606 8.80075 1.25062 7.74766 1.60842 7.00699C1.97222 6.25408 3.10317 5.97855 3.6203 5.33182C4.14 4.68199 4.15922 3.52047 4.81003 3.00152C5.45775 2.48519 6.59522 2.72308 7.34925 2.35985C8.091 2.00252 8.61155 0.962643 9.43374 0.776034C10.23 0.595284 11.1489 1.30253 12 1.30253C12.8511 1.30253 13.77 0.595284 14.5663 0.776034C15.3885 0.962643 15.909 2.00252 16.6508 2.35985C17.4048 2.72308 18.5423 2.48519 19.19 3.00156C19.8408 3.52052 19.8601 4.68199 20.3797 5.33186C20.8969 5.9786 22.0278 6.25408 22.3916 7.00703C22.7494 7.74771 22.264 8.80075 22.4508 9.62177C22.6319 10.4169 23.5313 11.1543 23.5313 12.0041Z" stroke="white" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};

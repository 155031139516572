export const FreeCreditsIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
      <path d="M1.6875 9.78125V21.3125H22.3125V9.78125" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M23.5312 5.9375H0.46875V9.6875H23.5312V5.9375Z" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.22 5.90663C9.97555 5.53477 9.82812 5.10891 9.82812 4.69978C9.82812 3.61472 10.607 3.01172 12.0016 3.01172C13.3961 3.01172 14.175 3.61472 14.175 4.69978C14.175 5.10891 14.0276 5.53477 13.7831 5.90663" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.99291 3.84401C7.00988 1.27793 2.3303 -1.44546 1.76147 3.23182C1.59239 4.62228 1.98821 5.43907 2.70488 5.90689" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.85756 5.73283C9.14595 5.27866 7.26969 4.30155 5.57422 3.86523" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.0039 3.84681C16.9868 1.27993 21.6696 -1.44716 22.2386 3.23181C22.4077 4.62226 22.0118 5.43906 21.2952 5.90687" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1406 5.73283C14.8522 5.27866 16.7285 4.30155 18.424 3.86523" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.42188 6.2793V9.45363" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.5781 6.2793V9.45363" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.3594 21.078V9.92188" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M13.6406 21.078V9.92188" stroke="white" strokeWidth="0.937505" strokeMiterlimit="22.9256" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

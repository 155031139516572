import { Main } from "components/Main";
import { useIntl } from "react-intl";
import { useSelector } from "redux/hooks";

import {
  SimplifiedNavigationIcon,
  ClearVisualsIcon,
  StreamlinedWorkflowIcon,
  CustomizableSettingsIcon,
  ConsistentExperienceIcon,
  AccessibleSupportIcon,
  NavigateMenusIcon,
  UtilizeVisualCuesIcon,
  ProvideFeedbackIcon,
} from "pages/Features/IntuitiveInterface/icons";

import {
  Hero,
  IImage,
  PerksOfFeature,
  IFeatureItem,
  HowToUseTheFeature,
  IFeature,
  Faqs,
  IFaq,
  iconSizeClassName,
  iconClassName,
} from "pages/Features/components";

const heroImages: IImage[] = [
  {
    src: "/feature/intuitive/image1.png",
    alt: "",
  },
  {
    src: "/feature/intuitive/image2.png",
    alt: "",
  },
  {
    src: "/feature/intuitive/image3.png",
    alt: "",
  },
  {
    src: "/feature/intuitive/image4.png",
    alt: "",
  },
  {
    src: "/feature/intuitive/image5.png",
    alt: "",
  },
  {
    src: "/feature/intuitive/image6.png",
    alt: "",
  },
];

const featureItems: IFeatureItem[] = [
  {
    icon: <SimplifiedNavigationIcon className={iconSizeClassName} />,
    title: "feature.intuitive.simplifiedNavigationTitle",
    content: "feature.intuitive.simplifiedNavigationContent",
  },
  {
    icon: <ClearVisualsIcon className={iconSizeClassName} />,
    title: "feature.intuitive.clearVisualsTitle",
    content: "feature.intuitive.clearVisualsContent",
  },
  {
    icon: <StreamlinedWorkflowIcon className={iconSizeClassName} />,
    title: "feature.intuitive.streamlinedWorkflowTitle",
    content: "feature.intuitive.streamlinedWorkflowContent",
  },
  {
    icon: <CustomizableSettingsIcon className={iconSizeClassName} />,
    title: "feature.intuitive.customizableSettingsTitle",
    content: "feature.intuitive.customizableSettingsContent",
  },
  {
    icon: <ConsistentExperienceIcon className={iconSizeClassName} />,
    title: "feature.intuitive.consistentExperienceTitle",
    content: "feature.intuitive.consistentExperienceContent",
  },
  {
    icon: <AccessibleSupportIcon className={iconSizeClassName} />,
    title: "feature.intuitive.accessibleSupportTitle",
    content: "feature.intuitive.accessibleSupportContent",
  },
];

const features: IFeature[] = [
  {
    icon: <NavigateMenusIcon className={iconClassName} />,
    title: "feature.intuitive.navigateMenusTitle",
    content: "feature.intuitive.navigateMenusContent",
  },
  {
    icon: <UtilizeVisualCuesIcon className={iconClassName} />,
    title: "feature.intuitive.utilizeVisualCuesTitle",
    content: "feature.intuitive.utilizeVisualCuesContent",
  },
  {
    icon: <ProvideFeedbackIcon className={iconClassName} />,
    title: "feature.intuitive.provideFeedbackTitle",
    content: "feature.intuitive.provideFeedbackContent",
  },
];

const faqs: IFaq[] = [
  {
    title: "feature.intuitive.faq1Title",
    content: "feature.intuitive.faq1Content",
  },
  {
    title: "feature.intuitive.faq2Title",
    content: "feature.intuitive.faq2Content",
  },
  {
    title: "feature.intuitive.faq3Title",
    content: "feature.intuitive.faq3Content",
  },
];

export const IntuitiveInterfacePage = () => {
  const { formatMessage } = useIntl();
  const themeMode = useSelector((state) => state.authReducer.theme);
  return (
    <Main isHasBgOnFooter activeSection="intuitiveInterface">
      <Hero
        themeMode={themeMode}
        images={heroImages}
        title={formatMessage({ id: "feature.intuitive.title" })}
        content={formatMessage({ id: "feature.intuitive.content" })}
      />
      <PerksOfFeature features={featureItems} themeMode={themeMode} />
      <HowToUseTheFeature features={features} themeMode={themeMode} />
      <Faqs faqs={faqs} themeMode={themeMode} />
    </Main>
  );
};

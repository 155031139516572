import classNames from "classnames";
import styles from "./ScrollToBottom.module.scss";

import { BottomArrow } from "./BottomArrow";

import { useSelector } from "redux/hooks";

interface IProps {
  onClick?: () => void;
  dataTestId?:string;
}

export const ScrollToBottomButton = ({ onClick,dataTestId }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <button
      className={classNames(styles.scrollButton, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <BottomArrow />
    </button>
  );
};

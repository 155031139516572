export const NewDocumentIcon = () => {
    return (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_10212_47202)">
      <path d="M9.625 2.625H1.75C0.783562 2.625 0 3.40856 0 4.375V12.25C0 13.2164 0.783562 14 1.75 14H9.625C10.5914 14 11.375 13.2164 11.375 12.25V4.375C11.375 3.40856 10.5914 2.625 9.625 2.625ZM10.5 12.25C10.5 12.7328 10.1078 13.125 9.625 13.125H1.75C1.26766 13.125 0.875 12.7328 0.875 12.25V4.375C0.875 3.89222 1.26766 3.5 1.75 3.5H9.625C10.1078 3.5 10.5 3.89222 10.5 4.375V12.25Z" fill="white"/>
      <path d="M2.625 6.125H8.75V5.25H2.625V6.125Z" fill="white"/>
      <path d="M2.625 7.875H8.75V7H2.625V7.875Z" fill="white"/>
      <path d="M2.625 9.625H8.75V8.75H2.625V9.625Z" fill="white"/>
      <path d="M2.625 11.375H6.125V10.5H2.625V11.375Z" fill="white"/>
      <path d="M12.25 0H4.375C3.40856 0 2.625 0.783562 2.625 1.75H3.5C3.5 1.26722 3.89266 0.875 4.375 0.875H12.25C12.7328 0.875 13.125 1.26722 13.125 1.75V9.625C13.125 10.1078 12.7328 10.5 12.25 10.5V11.375C13.2164 11.375 14 10.5914 14 9.625V1.75C14 0.783562 13.2164 0 12.25 0Z" fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_10212_47202">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>
    );
  };
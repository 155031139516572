export const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.70615 17.2562L4.74365 16.2937L10.0374 11L4.74365 5.70624L5.70615 4.74374L10.9999 10.0375L16.2937 4.74374L17.2562 5.70624L11.9624 11L17.2562 16.2937L16.2937 17.2562L10.9999 11.9625L5.70615 17.2562Z"
        fill="white"
      />
    </svg>
  );
};

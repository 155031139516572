import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./StopGenerateButton.module.scss";

import { StopIcon } from "../../../icons";

import { useSelector } from "redux/hooks";

interface IProps {
  onClick?: () => void;
  dataTestId?: string;
}

export const StopGenerateButton = ({ onClick,dataTestId }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <button
      className={classNames(styles.stopButton, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      onClick={onClick}
      data-testid={dataTestId}
    >
      <StopIcon className={styles.stopIcon} />
      <FormattedMessage id="chatpage.stopGenerating.btn" />
    </button>
  );
};

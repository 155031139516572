export const DarkIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="23"
    height="22"
    viewBox="0 0 23 22"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5011 10.9999C13.2387 9.21587 13.6681 6.53257 12.5741 4.29538C12.5056 4.13251 12.5368 3.9448 12.6543 3.81284C12.7718 3.68088 12.9546 3.62815 13.1243 3.67728C14.4311 4.01411 15.6239 4.69457 16.5788 5.64802C19.5346 8.52753 19.597 13.2578 16.7182 16.2143C13.762 19.0933 9.03176 19.0313 6.152 16.0758C5.19799 15.1214 4.51716 13.9289 4.18034 12.6222C4.13096 12.4523 4.18376 12.2691 4.31602 12.1515C4.44828 12.0339 4.63639 12.003 4.79935 12.0719C7.03555 13.1655 9.71764 12.7364 11.5011 10.9999Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
import { memo, useState, useEffect, useRef } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
  vscDarkPlus,
  prism,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./codeBlock.module.scss";

import { useWindowSize } from "hooks/useWindowSize";

import { useSelector } from "redux/hooks";
import { CodeBlockCopyIcon } from "pages/ChatPage/pages/newChat/icons/CodeBlockCopyIcon";
import { Tooltip } from "components";

interface IProps {
  language?: string;
  content: string;
  searchWord?: string;
  isHighlighted?: boolean;
  highlighted?: boolean;
}

export const CodeBlock = memo(({ language, content, searchWord, isHighlighted, highlighted }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();
  const preRef = useRef<HTMLDivElement | null>(null);

  const [isCopied, setIsCopied] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<number>(16);

  useEffect(() => {
    const intervalID = setTimeout(() => {
      if (isCopied) setIsCopied(false);
    }, 500);

    return () => clearInterval(intervalID);
  }, [isCopied]);

  useEffect(() => {
    if (width <= 576) {
      setFontSize(13);
    } else {
      setFontSize(16);
    }
  }, [width]);

  const highlightText = (node: HTMLElement, text: string) => {
    const regex = new RegExp(text, "gi");
    node.innerHTML = node.innerHTML.replace(
      regex,
      '<span class="bg-primary text-white">$&</span>'
    );
  };

  useEffect(() => {
    const preNode = preRef.current;
    if (preNode && searchWord && isHighlighted && highlighted) {
      highlightText(preNode, searchWord);
    }
  }, [preRef, searchWord]);

  return (
    <div className='px-[15px]'>
    <div ref={preRef}
      className={classNames(styles.container, {
        [styles.containerLight]: theme === "light",
        [styles.containerDark]: theme === "dark",
      })}
    >
      <div className={classNames(styles.header, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}>
        <div className={styles.language}>{language}</div>
        <Tooltip
            regenrate={true}
            tooltipAdjustement={true}
              control={
                <CopyToClipboard
                  text={content}
                  onCopy={() => setIsCopied(true)}
                >
                  <div className='cursor-pointer'>
                    <CodeBlockCopyIcon />
                  </div>
                </CopyToClipboard>
              }
              placement="top"
              theme="light"
            >
              <div>
                <FormattedMessage
                  id={
                    isCopied ? "answer.tooltip.copied" : "answer.tooltip.copy"
                  }
                />
              </div>
            </Tooltip>
      </div>
      <div className={styles.codeContainer}>
        <SyntaxHighlighter
          showInlineLineNumbers
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
          wrapLines={true}
          wrapLongLines={true}
          language={language}
          style={theme === "dark" ? vscDarkPlus : prism}
          customStyle={{
            fontSize: `${fontSize}px`,
          }}
          codeTagProps={{
            style: {
              fontSize: "inherit",
            },
          }}
          PreTag={({ children }) => {
            return (
              <pre className={styles.preTag}>
                <code>{children}</code>
              </pre>
            );
          }}
        >
          {content}
        </SyntaxHighlighter>
      </div>
    </div>
    </div>
  );
});

import { useState, useEffect, memo, SetStateAction, Dispatch } from "react";
import { CloseIcon } from "components/icons/CloseIcon";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import styles from "./SearchField.module.scss";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { SpinAnimation } from "components/base/Button/LoadingButton";

import { Spinner, Tooltip } from "components";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { DocIcon, PDFIcon, CSVIcon, PPTXIcon, TextIcon, PPTIcon, XLSIcon, XLSXIcon, EMLIcon, SRTIcon } from "../icons/DocIcon";
import { useAppNotification } from "hooks/services/AppNotification";
import { DeleteS3Link } from "redux/actions";

interface IProps extends TextareaAutosizeProps {
  selectedFile?: File[] | null;
  uploadingFiles?: IUploadFile[];
  setUploadingFiles?: Dispatch<SetStateAction<IUploadFile[]>>;
  setFileS3Link?: Dispatch<SetStateAction<string[]>>;
}

const UploadSpinner = styled(FontAwesomeIcon)`
  display: inline-block;
  font-size: 12px;
  animation: ${SpinAnimation} 1.5s linear 0s infinite;
  color: ${({ theme }) => theme.primaryColor};

  @media (max-width: 576px) {
    font-size: 10px;
  }
`;

export const FileUploadPreview = memo(({
  // selectedFile,
  uploadingFiles,
  setUploadingFiles,
  setFileS3Link,
}: IProps) => {

  const { triggerNotification } = useAppNotification();
  const [previews, setPreviews] = useState<(string | null)[]>([]);
  const [loading, setLoading] = useState<{ id: string; isLoading: boolean }[]>([]);

  useEffect(() => {
    if (uploadingFiles) {
      const filePreviews = uploadingFiles.map((uploadFile) => {
        const file = uploadFile.file;
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();
          return new Promise<string | null>((resolve) => {
            reader.onloadend = () => resolve(reader.result as string);
            reader.readAsDataURL(file);
          });
        }
        return Promise.resolve(null);
      });

      Promise.all(filePreviews).then(setPreviews);
    }
  }, [uploadingFiles]);

  const handleDelete = (fileId: string, S3Link: string) => {
    setLoading(prev => [...prev, { id: fileId, isLoading: true }]);
    DeleteS3Link(S3Link).then(() => {
      setLoading(prev => prev.filter(state => state.id !== fileId));
      setFileS3Link!(prev => prev.filter(link => link !== S3Link));
      setUploadingFiles!(prev => prev.filter(file => file.id !== fileId));
    }).catch((err) => {
      setLoading(prev => prev.filter(state => state.id !== fileId));
      triggerNotification({ message: err?.data?.message, type: "error" });
    })
  }

  const renderFileIcon = (fileType: string, fileName: string) => {
    if (fileType.includes('pdf')) return <PDFIcon />;
    if (fileType.includes('text/plain')) return <TextIcon />;
    if (fileType.includes('presentation')) return <PPTXIcon />;
    if (fileType.includes('ms-powerpoint')) return <PPTIcon />;
    if (fileType.includes('csv')) return <CSVIcon />;
    if (fileType.includes('sheet')) return <XLSXIcon />;
    if (fileType.includes('ms-excel')) return <XLSIcon />;
    if (fileType.includes('application/x-subrip')) return <SRTIcon />;
    if (fileType.includes('message/rfc822')) return <EMLIcon />;

    // Fallback: check file extension if MIME type is empty
    if (fileType === "" && fileName.endsWith('.srt')) return <SRTIcon />;
    return <DocIcon />;
  };

  const getFileTypeLabel = (fileType: string, fileName: string) => {
    if (fileType.includes('pdf')) return 'PDF';
    if (fileType.includes('csv')) return 'CSV';
    if (fileType.includes('text/plain')) return 'Text';
    if (fileType.includes('presentation')) return 'PPTX';
    if (fileType.includes('ms-powerpoint')) return 'PPT';
    if (fileType.includes('sheet')) return 'XLSX';
    if (fileType.includes('ms-excel')) return 'XLS';
    if (fileType.includes('application/x-subrip')) return 'SRT';
    if (fileType.includes('message/rfc822')) return 'EML';
    if (fileType.startsWith("image/")) return 'Image';
    if (fileType === "" && fileName.endsWith('.srt')) return 'SRT';
    return 'Document';
  };

  return (
    <>
      {uploadingFiles && uploadingFiles.length > 0 && (
        <div className={styles.fileContainer}>
          {(uploadingFiles as IUploadFile[]).map((fil, index) => (
            fil.status !== 'error' &&
            <Tooltip
            regenrate={true}
              control={
                <div key={index} className={styles.fileDisplay}>
                  <div className={styles.fileIcon}>
                    {fil.status === 'validating' || fil.status === 'uploading' ?
                      <div className={styles.loader}>
                        <UploadSpinner icon={faCircleNotch} />
                      </div> :
                      fil.file.type.startsWith("image/") && previews[index] && fil.status === 'uploaded' ? (
                        <img src={previews[index] as string} alt="Uploaded Preview" />
                      ) : (
                        renderFileIcon(fil.file.type, fil.file.name)
                      )}
                  </div>
                  <div className={styles.fileInfo}>
                    <div className={styles.fileName}>{fil.file.name}</div>
                    <div className={styles.fileType}>{getFileTypeLabel(fil.file.type, fil.file.name)}</div>
                  </div>
                  {fil.status === 'uploaded' && (
                     loading.find(state => state.id === fil.id)?.isLoading ?
                      <div className={styles.closeButtonLoader}>
                        <Spinner extraSmall />
                      </div> :
                      <div className={styles.closeButton} onClick={() => { handleDelete(fil?.id, fil?.S3Link ? fil?.S3Link : '') }}>
                        <CloseIcon closeDoc={true} />
                      </div>
                  )}

                </div>
              }
              placement="top"
              theme="light">{fil.file.name}</Tooltip>
          ))}
        </div>
      )}
    </>
  );
});
export const InitiateDiscussionIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M46.1091 29.0448C46.1091 28.4923 45.6615 28.0447 45.109 28.0447H37.9507C37.4131 28.0447 36.9781 27.6072 36.9781 27.0722V13.9864C36.9781 13.4489 37.4157 13.0138 37.9507 13.0138H58.0047C58.5422 13.0138 58.9773 13.4514 58.9773 13.9864V27.0722C58.9773 27.6097 58.5397 28.0447 58.0047 28.0447H56.6896C56.137 28.0447 55.6895 28.4923 55.6895 29.0448V33.2151L50.8191 28.3372C49.8891 27.4172 48.484 28.8223 49.404 29.7523L54.8569 35.2127C55.8395 36.2603 57.7397 35.4752 57.6922 34.0401V30.0473H58.0072C59.6473 30.0473 60.9799 28.7123 60.9799 27.0746V13.9888C60.9799 12.3487 59.6448 11.0161 58.0072 11.0161H37.9532C36.3131 11.0161 34.9805 12.3512 34.9805 13.9888V27.0746C34.9805 28.7147 36.3155 30.0473 37.9532 30.0473H45.1115C45.664 30.0473 46.1116 29.5998 46.1116 29.0473L46.1091 29.0448Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M32.004 40.9802H24.8457C23.5281 41.0052 23.5331 42.9578 24.8457 42.9804H32.004C32.5416 42.9804 32.9766 43.4179 32.9766 43.9529V57.0387C32.9766 57.5763 32.5391 58.0113 32.004 58.0113L11.9475 58.0138C11.4099 58.0138 10.9749 57.5762 10.9749 57.0412V43.9554C10.9749 43.4179 11.4125 42.9829 11.9475 42.9829H13.2626C13.8151 42.9829 14.2627 42.5353 14.2627 41.9828V37.8125L19.133 42.6904C20.0631 43.6129 21.4682 42.2053 20.5481 41.2753L15.0952 35.8149C14.6177 35.3374 13.9076 35.1974 13.2851 35.4549C12.6626 35.7124 12.26 36.315 12.26 36.9875V40.9803C10.5173 40.8353 8.95979 42.1829 8.96977 43.953V57.0388C8.96977 58.6789 10.3049 60.0115 11.9425 60.0115H31.9965C33.6366 60.0115 34.9692 58.6764 34.9692 57.0388V43.953C34.9692 42.3129 33.6341 40.9803 31.9965 40.9803L32.004 40.9802Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M16.9736 50.5386C16.9961 51.8537 18.9513 51.8537 18.9738 50.5386C18.9513 49.2235 16.9962 49.2235 16.9736 50.5386Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M20.9756 50.5386C20.9981 51.8537 22.9532 51.8537 22.9757 50.5386C22.9532 49.2235 20.9981 49.2235 20.9756 50.5386Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M26.9757 50.5386C26.9532 49.2235 24.9981 49.2235 24.9756 50.5386C24.9981 51.8537 26.9532 51.8537 26.9757 50.5386Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M42.9756 20.5283C42.9981 21.8434 44.9532 21.8434 44.9757 20.5283C44.9532 19.2132 42.9981 19.2132 42.9756 20.5283Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M46.9775 20.5283C47 21.8434 48.9552 21.8434 48.9777 20.5283C48.9552 19.2132 47.0001 19.2132 46.9775 20.5283Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M52.9777 20.5283C52.9552 19.2132 51.0001 19.2132 50.9775 20.5283C51 21.8434 52.9552 21.8434 52.9777 20.5283Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M11.264 26.3115C11.6215 26.579 11.834 27.034 11.834 27.5265V30.9718C11.859 32.2844 13.8092 32.2894 13.8342 30.9718V27.5265C13.8342 26.409 13.3217 25.3564 12.4641 24.7114C10.1489 22.9762 8.84886 20.211 8.98135 17.3181C9.18386 12.8678 12.8291 9.22281 17.2796 9.01992C22.105 8.67989 26.5353 12.8902 26.4227 17.7355C26.4227 17.923 26.4752 18.1055 26.5752 18.2655L28.6578 21.5982H27.4227C26.8702 21.5982 26.4227 22.0458 26.4227 22.5983V26.7186C26.4227 27.1737 26.0526 27.5412 25.5976 27.5412H24.3825C22.8249 27.5412 21.5573 28.8088 21.5573 30.364V30.9715C21.5573 31.5241 22.0048 31.9716 22.5573 31.9716C23.1099 31.9716 23.5574 31.5241 23.5574 30.9715V30.364C23.5574 29.9089 23.9275 29.5414 24.3825 29.5414H25.5976C27.1552 29.5414 28.4228 28.2738 28.4228 26.7186V23.5984H29.3654C30.588 23.6384 31.4106 22.1533 30.728 21.1382L28.4203 17.4429C28.3903 11.6025 23.0274 6.62481 17.1919 7.02228C7.23598 7.51732 3.26832 20.3283 11.2665 26.3114L11.264 26.3115Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
    <path
      d="M52.7626 38.0631C46.9122 37.663 41.5695 42.6434 41.5317 48.4837L39.2215 52.179C38.5415 53.1965 39.3615 54.6766 40.5841 54.6392H41.5267V57.7594C41.5267 59.3171 42.7943 60.5822 44.3519 60.5822C45.0795 60.6222 46.3471 60.3097 46.3921 61.4073V62.0148C46.4121 63.3249 48.3697 63.3324 48.3923 62.0148C48.6048 60.2497 47.4197 58.5795 45.567 58.582H44.3519C43.8969 58.582 43.5269 58.212 43.5269 57.7594V53.6391C43.5269 53.0866 43.0793 52.6391 42.5268 52.6391H41.2917L43.3743 49.3063C43.4743 49.1463 43.5268 48.9638 43.5268 48.7763C43.4243 43.9334 47.8246 39.7228 52.6699 40.0607C60.7678 40.4658 63.9911 50.8865 57.4853 55.7547C56.6278 56.3973 56.1152 57.4523 56.1152 58.5699V62.0152C56.1302 63.3228 58.0954 63.3328 58.1154 62.0152V58.5699C58.1154 58.0774 58.3279 57.6223 58.6854 57.3549C66.6809 51.3744 62.7157 38.5585 52.7599 38.0631L52.7626 38.0631Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.5"
    />
  </svg>
);

export const BurgerIcon = () => {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // opacity="0.8"
        d="M0 15.75V13.5H20.25V15.75H0ZM0 9.125V6.875H20.25V9.125H0ZM0 2.5V0.25H20.25V2.5H0Z"
        fill="white"
      />
    </svg>
  );
};

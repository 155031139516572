import { IAction, TYPES } from "../types";
import { produce } from "immer";
import { IChatModel } from "redux/actions";

export interface IChatModelsState {
  chatModels: IChatModel[];
}

interface IGetChatModelsAction extends IAction {
  payload: IChatModel[];
}

type Action = IGetChatModelsAction;

const state: IChatModelsState = {
  chatModels: [],
};

export const chatModelsReducer = (
  mState = { ...state },
  action: Action
): IChatModelsState => {
  switch (action.type) {
    case TYPES.GET_CHAT_MODELS:
      return produce(mState, (dState) => {
        dState.chatModels = action.payload;
      });

    default:
      return { ...mState };
  }
};

export const StartChattingIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M12.7078 55.2579C12.0172 55.2579 11.4549 54.6957 11.4549 54.005V42.4324H8.57622C4.94585 42.4293 2.0061 39.4865 2 35.8561V16.5762C2.00611 12.9459 4.94585 10.0031 8.57622 10H36.406C40.0363 10.0031 42.9791 12.9459 42.9822 16.5762V35.8561C42.9791 39.4865 40.0363 42.4293 36.406 42.4324H26.236L13.5877 54.8976C13.3524 55.1268 13.0378 55.2579 12.7078 55.2579ZM8.5763 12.5034C6.33022 12.5064 4.50588 14.3277 4.50588 16.5768V35.8568C4.50588 38.1059 6.32717 39.9272 8.5763 39.9302H12.7078C13.0409 39.9302 13.3587 40.0616 13.594 40.2969C13.8293 40.5322 13.9607 40.8501 13.9607 41.1801V51.0137L24.8457 40.2875C25.078 40.0583 25.3958 39.9299 25.7228 39.9299H36.4059C38.6551 39.9269 40.4763 38.1026 40.4794 35.8565V16.5765C40.4763 14.3274 38.6551 12.5061 36.4059 12.5031L8.5763 12.5034Z"
      fill="#7C4DFF"
    />
    <path
      d="M33.1755 19.9878H11.7998C11.1092 19.9878 10.5469 19.4255 10.5469 18.7349C10.5469 18.0442 11.1092 17.4819 11.7998 17.4819H33.1755C33.8662 17.4819 34.4285 18.0442 34.4285 18.7349C34.4285 19.4255 33.8662 19.9878 33.1755 19.9878Z"
      fill="#7C4DFF"
    />
    <path
      d="M33.1755 27.4667H11.7998C11.1092 27.4667 10.5469 26.9075 10.5469 26.2138C10.5469 25.5231 11.1092 24.9639 11.7998 24.9639H33.1755C33.8662 24.9639 34.4285 25.5231 34.4285 26.2138C34.4285 26.9074 33.8662 27.4667 33.1755 27.4667Z"
      fill="#7C4DFF"
    />
    <path
      d="M24.6257 34.9486H11.7998C11.1092 34.9486 10.5469 34.3894 10.5469 33.6987C10.5469 33.0081 11.1092 32.4458 11.7998 32.4458H24.6281C25.3218 32.4458 25.881 33.0081 25.881 33.6987C25.881 34.3894 25.3218 34.9486 24.6281 34.9486H24.6257Z"
      fill="#7C4DFF"
    />
    <path
      d="M57.0344 60.0003C56.7013 60.0003 56.3805 59.8659 56.1452 59.6306L45.7796 49.1766H32.8958C30.4755 49.1736 28.5167 47.2147 28.5137 44.7975V41.1793C28.5137 40.4887 29.0729 39.9294 29.7666 39.9294C30.4572 39.9294 31.0165 40.4887 31.0165 41.1793V44.7945C31.0196 45.8304 31.8599 46.6707 32.8958 46.6738H46.339C46.7607 46.6707 47.1549 46.8846 47.3871 47.2391L55.7812 55.7099V47.9266C55.7812 47.236 56.3435 46.6737 57.0342 46.6737H62.7059C63.7418 46.6706 64.5791 45.8333 64.5822 44.7974V33.0229C64.5822 31.9839 63.7418 31.1436 62.7059 31.1405H41.7276C41.037 31.1405 40.4777 30.5813 40.4777 29.8906C40.4777 29.197 41.0369 28.6377 41.7276 28.6377H62.7059C65.1262 28.6438 67.085 30.6026 67.0881 33.0229V44.7943C67.082 47.2114 65.1232 49.1703 62.7059 49.1734H58.2871V58.7472C58.2871 59.0803 58.1527 59.3981 57.9204 59.6334C57.6851 59.8687 57.3675 60.0003 57.0344 60.0003Z"
      fill="#7C4DFF"
    />
    <path
      d="M58.9658 40.1646H56.078C55.3874 40.1646 54.8281 39.6023 54.8281 38.9116C54.8281 38.221 55.3874 37.6587 56.078 37.6587H58.9658C59.6564 37.6587 60.2157 38.221 60.2157 38.9116C60.2157 39.6023 59.6565 40.1646 58.9658 40.1646Z"
      fill="#7C4DFF"
    />
    <path
      d="M49.2471 40.1646H46.3623C45.6686 40.1646 45.1094 39.6023 45.1094 38.9116C45.1094 38.221 45.6686 37.6587 46.3623 37.6587H49.2471C49.9377 37.6587 50.5 38.221 50.5 38.9116C50.5 39.6023 49.9377 40.1646 49.2471 40.1646Z"
      fill="#7C4DFF"
    />
  </svg>
);

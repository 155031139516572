interface IProps {
  className?: string;
}

export const LogoutIcon = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0833 17.4148V6.87773C10.0833 6.24523 9.75792 5.65765 9.22167 5.32307L5.555 3.0314C4.334 2.26873 2.75 3.14598 2.75 4.58607V15.1222C2.75 15.7547 3.07542 16.3423 3.61167 16.6769L7.27833 18.9686C8.49933 19.7321 10.0833 18.854 10.0833 17.4148Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.25 10.0833H13.75"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5833 8.25L13.75 10.0833L15.5833 11.9167"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.083 17.4167H13.7497C14.7626 17.4167 15.583 16.5963 15.583 15.5834V14.6667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.583 5.5V4.58333C15.583 3.57042 14.7626 2.75 13.7497 2.75H4.58301"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

import {
  ReactNode,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { useAppNotification } from "../AppNotification";
import { useIntl } from "react-intl";

export interface INetworkProvider {
  isOnline: boolean;
}

interface IProps {
  children?: ReactNode;
}

const NetworkContext = createContext<INetworkProvider | null>(null);

export const NetworkProvider = ({ children }: IProps) => {
  const { triggerNotification } = useAppNotification();
  const { formatMessage } = useIntl();

  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleStatusChange = (res: any) => {
      setIsOnline(navigator.onLine);
      if (res?.type === "online") {
        triggerNotification({
          message: formatMessage({ id: "network.established" }),
          type: "info",
        });
      }

      if (res?.type === "offline") {
        triggerNotification({
          message: formatMessage({ id: "network.lost" }),
          type: "error",
        });
      }
    };

    window.addEventListener("online", handleStatusChange);
    window.addEventListener("offline", handleStatusChange);

    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <NetworkContext.Provider value={{ isOnline }}>
      {children}
    </NetworkContext.Provider>
  );
};

export const useNetwork = (): INetworkProvider => {
  const networkContext = useContext(NetworkContext);

  if (!networkContext) {
    throw new Error("NetworkContext must be used within NetworkProvider");
  }

  return networkContext;
};


export const EarnAsAffiliateIcon = () => {
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M9.64537 20.1667V17.7579C8.65571 17.5338 7.80143 17.1043 7.08252 16.4694C6.36362 15.8346 5.83611 14.9383 5.5 13.7806L7.57269 12.9403C7.85278 13.8366 8.26825 14.5181 8.8191 14.985C9.36995 15.4518 10.0935 15.6852 10.9898 15.6852C11.7554 15.6852 12.4043 15.5125 12.9365 15.167C13.4686 14.8216 13.7347 14.2847 13.7347 13.5565C13.7347 12.9029 13.5293 12.3848 13.1185 12.002C12.7077 11.6192 11.7554 11.185 10.2616 10.6995C8.65571 10.1954 7.55401 9.59317 6.95648 8.89294C6.35895 8.19271 6.06019 7.33843 6.06019 6.33009C6.06019 5.11636 6.45231 4.17338 7.23657 3.50116C8.02083 2.82894 8.82377 2.44614 9.64537 2.35278V0H11.8861V2.35278C12.8198 2.50216 13.59 2.84294 14.1969 3.37512C14.8037 3.90729 15.2472 4.55617 15.5273 5.32176L13.4546 6.21806C13.2306 5.62052 12.9131 5.17238 12.5023 4.87361C12.0915 4.57485 11.5313 4.42546 10.8218 4.42546C10.0002 4.42546 9.37461 4.60752 8.94514 4.97164C8.51566 5.33576 8.30093 5.78858 8.30093 6.33009C8.30093 6.9463 8.58102 7.43179 9.1412 7.78657C9.70139 8.14136 10.6724 8.51481 12.0542 8.90694C13.3426 9.2804 14.3182 9.87326 14.9811 10.6855C15.644 11.4978 15.9755 12.4361 15.9755 13.5005C15.9755 14.8262 15.5833 15.8346 14.7991 16.5255C14.0148 17.2164 13.0438 17.6458 11.8861 17.8139V20.1667H9.64537Z"
            fill="white"
          />
      </svg>
    );
};

export const DownloadIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.875 20C1.375 20 0.9375 19.8125 0.5625 19.4375C0.1875 19.0625 0 18.625 0 18.125V13.6562H1.875V18.125H18.125V13.6562H20V18.125C20 18.625 19.8125 19.0625 19.4375 19.4375C19.0625 19.8125 18.625 20 18.125 20H1.875ZM10 15.2188L3.96875 9.1875L5.3125 7.84375L9.0625 11.5938V0H10.9375V11.5938L14.6875 7.84375L16.0312 9.1875L10 15.2188Z"
        fill="black"
      />
    </svg>
  );
};

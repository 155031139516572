export const MoneyIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="64"
    viewBox="0 0 38 64"
    fill="none"
  >
    <path
      d="M34.275 19C33.6281 17.1645 32.4467 15.5646 30.8829 14.4062C29.319 13.2478 27.4443 12.584 25.5 12.5H12.5C9.91414 12.5 7.43419 13.5272 5.60571 15.3557C3.77723 17.1842 2.75 19.6641 2.75 22.25C2.75 24.8359 3.77723 27.3158 5.60571 29.1443C7.43419 30.9728 9.91414 32 12.5 32H25.5C28.0859 32 30.5658 33.0272 32.3943 34.8557C34.2228 36.6842 35.25 39.1641 35.25 41.75C35.25 44.3359 34.2228 46.8158 32.3943 48.6443C30.5658 50.4728 28.0859 51.5 25.5 51.5H12.5C10.5557 51.416 8.68096 50.7522 7.11712 49.5938C5.55329 48.4354 4.3719 46.8355 3.725 45"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 2.75V12.5M19 51.5V61.25"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

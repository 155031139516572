import { useEffectOnce } from "react-use";

import { Main } from "components/Main";
import { Hero, Partner, Benefits } from "./components";
import { useSelector } from "redux/hooks";

export const AffiliatePage = () => {

   const theme = useSelector((state) => state.authReducer.theme);
  useEffectOnce(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Main isHasBgOnFooter activeSection="affiliate">
      <Hero theme={theme} />
      <Partner />
      <Benefits theme={theme} />
    </Main>
  );
};

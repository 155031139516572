export const markdownPrivacyContent = `
Sictec Infotech, Inc.(“Sictec Infotech”, “Company”, “we” or “us”) respects your right to privacy and we are committed to protecting it. This privacy policy (“Privacy Policy”) describes our practices of processing data from (i) visitors to our websites that links to this Privacy Policy (“Website”); and (ii) our customers using our Services (“Customer”).

For clarity, the term “Service(s)” means: any Software provided by the Company (downloadable on our website, pre-installed on a device, downloadable through a third party website, obtained on a physical medium such as, but not limited to CD, DVD, Blu-ray, memory stick), resources, including download areas, services offered on one of our websites’ communication forums, product information, updates, enhancements, new features, premium support, extended guarantees, online version of the Software and/or the addition of any new website.

If you have questions, comments or complaints regarding our Privacy Policy or data practices, please contact us at

Sictec Infotech, Inc

848 N. Rainbow Blvd. Ste 9027

Las Vegas, NV 89107

Contact at Support@deftgpt.com

If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our third party U.S.-based dispute resolution provider (free of charge) at https://feedback-form.truste.com/watchdog/request

Sictec Infotech, Inc Data Protection Officer
If you have any requests regarding the data collected under this Privacy Policy, including but without limitation to, requests to remove, delete, amend, modify or transfer the data, please contact our Data Protection Officer at: Support@deftgpt.com
Please include your name, address and email address in any correspondence so that we can respond to your inquiry or request.

Changes to the Policy
We reserve the right to modify this Privacy Policy at any time, at our sole discretion, so please review it frequently, such changes will be effective immediately upon the display of the revised Privacy Policy. The last revision date will be reflected in the “Last Updated” heading. Your continued use of our Services following the amendments constitutes your acknowledgement and consent of such amendments to the Privacy Policy and your agreement to be bound by the terms of such amendments. If we make material changes to this policy, we will make our best efforts to notify you here, by email, or by means of a notice on our Website.

We have included below information about which data is collected, how we process and use your data, but before, we would like to explain the basis on which we do so:

(i) When a Customer uses our Services or purchases the goods and services we offer, we process the Customer’s Personal Information in order to perform our contract with the Customer;

(ii) When visitors access our Website, we collect certain online identifiers; such online identifiers are processed under the lawful basis of legitimate interests;

(iii) For direct marketing to Customers we have legitimate interest to process such data; or

(iv) We will also process your Personal Information where you have provided us with consent to do so.

Information Collection
Depending on your interaction with us (meaning, if you are just browsing our Website, or if you have registered to our Services and opened an account, use our Services, etc.), we may collect two types of information about you, as follows:

• Non-Personal Information: The first type of information is non-identifiable information which may be made available or gathered through your use of the Services. To clarify, we are not aware of your identity due to such information (“Non-Personal Information”). The Non-Personal Information which is being collected may include your aggregated usage information and technical information transmitted such as (but not limited to): the type of operating system, type of browser, language preference, the time and date you access our Website, your actions within our Services (e.g., registration, pages viewed, etc.), internet service provider, browsing data, search queries, browsing history, and approximate geo-location (country level).

• Personal Information: The second type of information is individually identifiable information, namely information that identifies an individual or may with reasonable effort identify an individual (“Personal Information”). Personal Information includes either: (i) contact information such as your name, phone number, address, email address, etc., which you have submitted voluntarily; and (ii) your Internet Protocol (“IP”) address and MAC address or other online identifiers. Note that, while IP and MAC address are considered personally identifiable information in many jurisdictions (such as the EEA), there are some jurisdictions in which such data is not considered as Personal Information. Therefore, we treat such information as Personal Information, in accordance with applicable laws.

You are not required by law to provide us with any information. You can always avoid providing us certain Personal Information; however, you acknowledge that it may prevent us from providing you certain Services.
In the event we combine Personal Information with Non-Personal Information, the combined information will be treated as Personal Information for as long as it remains combined.

Information we collect from visitors of our Website

| Type of Information | How Do We Use It? |
| ------------------- | ----------------- |
|In the event you contact us for support, feedback or other inquiries, request to receive a quote, either through an online form available on the Website, by sending us an email or by other means of communication we make available (such as submitting a bug report or filling in a survey) you will be requested to provide us with your name and email address.|We will use this information solely for the purpose of responding to your inquiries and provide you with the support or information you have requested.|
|A user may provide us with a third party’s information which may include Personal Information. For example, if a user requests that a third party sign an electronic document through our Services, he or she may provide us such third party’s email address and full name.|We will use this information solely for the purpose of providing our users with the requested e-signature service.|
|If you voluntarily subscribe to our email communications, you will be asked to provide us with your email address. You can unsubscribe at any time using the unsubscribe option within the body of the newsletter or rather by contacting our Data Protection Officer at Support@deftgpt.com|We will use your email address in order to send you information related to our Services and to keep you up to date regarding new Services, as well as provide you with tips related to our Service, and promotional and marketing emails.|
|We gather IP addresses, MAC addresses or other online identifiers such as cookies, agents, device identifiers, etc. (“Online Identifiers”)|We may either directly or indirectly collect our visitors’ Online Identifiers. We use this for our legitimate interests of (i) operating, providing, maintaining, protecting, managing, customizing and improving our Website and Services and the way in which we offer them; (ii) enhancing your experience with the Services; and (iii) our legitimate interests of auditing and tracking usage statistics and traffic flow.|
|We also collect certain technical Non-Personal Information (as detailed above) which relates to your use of the Website such as your click stream, type of browser, time and date, etc.|We use this technical data in order to operate and manage our Website.|
|While using the Website you are able to upload certain content to the blog within the Website (“User Generated Content”).|You may voluntarily upload User Generated Content to the Website. In the event that the User Generated Content includes any Personal Data this data will be subject to your use.|
|If you want to access certain materials provided by us such as download a whitepaper or a webinar offered by the Company through our Website, you will be asked to provide us with your full name, email address, company, industry, job role and country.|We will use this information to provide you with the webinar or whitepaper or any additional materials provided by us. We will also use your email address in order to contact you or send you information related to our Services and to keep you up to date regarding new Services.|
|If you want to become a reseller through our Website, you will be asked to provide us with your full name, business email address, company, website URL, phone number and country.|We will use this information to contact you. We will also use your email address in order to send you information related to our Services and to keep you up to date regarding new Services.|
|If you want to request a business trial of our Services, you will be asked to provide us with your full name, email address, company, phone number and country.|We will use this information to contact you to provide you with a free trial of the Services. We will also use your email address in order to send you information related to our Services and to keep you up to date regarding new Services.|
|In the event that you are interested in joining our team, and wish to submit your CV, through ADP, LLC, our third party service provider which provides recruiting service on our behalf, you will be required to provide us with your name, email address, and phone number. For additional information, please see ADP’s privacy policy available at:https://www.adp.com/privacy.aspx.|Your provision of personal information in connection with recruiting is voluntary, and you determine the extent of information you provide us. We do not request or require sensitive personal information concerning religion, health, sexual orientation, or political affiliation in connection with recruiting. We will use the information you have provided solely to communicate with you, to manage our recruiting and hiring processes, and for compliance with corporate governance and legal and regulatory requirements. If you are hired, the information may be used in connection with employment and corporate management.|

Information We Collect from Customers and Business Partners

|Type of Information|How Do We Use It?|
|-------------------|-----------------|
|You can sign up as a Customer through our services, during which you will be required to register and open an account. During the registration flow you will be requested to provide us with certain information such as your full name, email address, you will be required to create a password, at which time you thereby represent and warrant that you are responsible for maintaining the confidentiality of your details and password. You represent and warrant that you will not provide us with inaccurate, misleading or false information.|We will use this information for the purpose of performing our contract with you, provide you with the Services you have requested, and designate your account. We will use your contact details in order to send you required information related to the Services and our business engagement (e.g., send you a Welcome message, to notify you regarding any updates to our Services, send applicable invoices, and additional occasional communications related to the Services) as well as verify your identity.|
|You may also choose to sign in with your social network account (such as Google, Facebook or Microsoft). Such network accounts may provide us with access to certain information about you as stored therein (e.g. full name, e-mail and any other information which you made public).|
|We also collect certain technical Non-Personal Information (as detailed above) as a visitor to the Website and as detailed above.|See the table above.|
|Documents uploaded by you through our online Software might include personal data.|We use this data solely to provide the Service, we do not collect or process this data and it is deleted immediately following the provision of the service and no later than 24 hours, following the last time you accessed the document.|

How We Collect Information
Depending on the nature of your interaction with the Services, we may collect information as follows:

(i) Automatically – we may use Cookies (as elaborated in the section below) to gather some information automatically.

(ii) Provided by you voluntarily – we will collect information if and when you choose to provide us with the information, such as through a registration process, contact us communications, payment process, etc.

User Rights
Individuals have the right to know what information we hold about them and, in some cases, to have such information communicated to them. Individuals may also ask for our confirmation as to whether or not we process their Personal Information. Subject to the limitations in applicable law, individuals may also be entitled to obtain from us the Personal Information they have provided to us in a structured, commonly-used, and machine-readable format, and may have the right to transmit such Personal Information to another party.

The principal rights under applicable data protection law in relation to Personal Information are as follows: the right to access any information which is provided to us; the right to rectification; the right to erasure; the right to restrict processing; the right to object to processing; the right to data portability; the right to complain to a supervisory authority; and the right to withdraw consent (to the extent applicable).

We provide you with the ability to exercise certain choices and controls in connection with our treatment of your Personal Information, depending on your relationship with us. For more information, please review our user right policy and online forms available at: https://www.deftgpt.com/privacy.

If you wish to exercise any or all of the rights above please use our electronic form to contact us directly regarding an applicable user right request available at: https://www.deftgpt.com/privacy.
Where we are not able to provide you with the information for which you have asked, we will endeavor to explain the reasoning for this and inform you of your rights, including the right to complain to the supervisor authority. We reserve the right to ask for reasonable evidence to verify your identity before we provide you with any such information in accordance with applicable law. In addition, the process of locating and deleting the data may take up to one (1) month (following the receipt of the validation proof we require) in accordance with applicable law.

Data privacy and related laws in your jurisdiction may provide you with different or additional rights related to the data we collect from you, which may also apply.

Data Retention
We will retain your information for as long as your account is active or as needed to provide services to you, all in accordance with applicable laws, or until an individual expresses a preference to opt-out. We may at our sole discretion, delete or amend information from our systems, without notice to you, once we deem it is no longer necessary for such purposes.

Minors
If you are under 16 years of age, you are not permitted to use this Website or our Services. If you are 16 years old but not yet 18, or of the age of majority in the jurisdiction where you reside, you may not use this Website or Services without the express and informed consent of your parent or guardian. If you become aware or have any reason to believe that a child has shared any information with us, please contact us at: Support@deftgpt.com

Sharing Personal Data
We do not share any Personal Information collected from you with third parties or any of our partners except in the following events:

(1) Legal Disclosure, Policy Enforcement, Third Party Rights and Company Rights: We reserve the right to disclose your personal information if required by law and/or to comply with a court order or similar legal process or when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request. In certain situations, Sictec Infotech, Inc may be required to disclose personal data in response to lawful requests by public authorities.

(2) Business Transitions: If Sictec Infotech, Inc™ is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our website of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal information.

(3) Third Party service providers: The Company may share your information with third parties that perform services on our behalf (e.g. API providers, payment process, tracking, servers, service functionality and support, marketing, etc.) these third parties may be located in different jurisdictions; and these companies are authorized to use your Personal Information only as necessary to provide these services to us.

We may store Non-Personal and Personal Information on our servers or our cloud servers, use or share Non-Personal Information in any of the above circumstances, as well as for the purpose of providing and improving our Service as detailed above. Furthermore, we reserve the right to use, disclose or transfer (for business purposes or otherwise) aggregated and processed data to third parties, including, inter alia, affiliates, for various purposes including commercial use. This information may be collected, processed and analyzed by us and transferred in a combined, collectively and aggregated manner (i.e., your information is immediately aggregated with other users) to third parties.

Cookies
When you access or use the Website, or interact with our Services, we may use “cookies” (or similar technologies), which store certain information on your device (i.e., locally stored) and which will allow us to customize the Service and content as well as enhance your experience of the Services. The use of cookies is a standard industry-wide practice. A “Cookie” is a small piece of information that a website assigns and stores on your device while you are viewing a website. Cookies are very helpful and can be used for various different purposes such as allowing you to navigate between pages efficiently and making the interaction between you and our Services quicker and easier.

We may permit third parties to use cookies or other technology to collect information about your online and application usage activities across our Services, this data may include Online Identifiers therefore we recommend you review our cookie policy carefully:

|Cookie|Purpose|Privacy Policy and Opt-Out|
|--------------|--------------------|-------------|
|Facebook Analytics|Enable use of the Service|https://www.facebook.com/policies/cookies|
|Facebook Custom Audience|Marketing|https://www.facebook.com/full_data_use_policy|
|Google AdWords|Marketing and Remarketing|https://policies.google.com/privacy|
|Google Analytics|Analytic purposes|www.google.com/policies/privacy/partners https://tools.google.com/dlpage/gaoptout|
|UpClick|Analytics|https://upclick.com/privacy.html|
|Crazyegg|Analytics|https://www.crazyegg.com/privacy|
|Addthis|Marketing|http://www.addthis.com/privacy/privacy-policy|
|Pushengage|Marketing and Enhance the use of the Site|https://www.pushengage.com/privacy|
|Kickfire|IP Lookup|https://id.kickfire.com/privacy-policy|
|Facebook Connect|Enhance the use of the Site|https://www.facebook.com/full_data_use_policy|
|Microsoft|Enhance the use of the Site|https://privacy.microsoft.com/en-us/privacystatement|
|Google|Enhance the use of the Site|https://policies.google.com/privacy|
|TRUSTe|Certification|https://www.trustarc.com/privacy-policy|
|Rating widget|Enhance the use of the Site|http://rating-widget.com/privacy|
|Yahoo|Analytics|https://policies.oath.com/us/en/oath/privacy/topics/analytics/index.html|
|Capterra|Analytics|https://www.capterra.com/legal/privacy-policy|
|VisitorID|Enable the use of the site and service and Analytics Services.||

For more information about cookies and your controls in this regard, you may review the following links:
• For Chrome;
• For Firefox;
• For Opera;
• For Internet Explorer;
• For Safari;
• For Edge.

Most browsers will allow you to erase cookies from your computer’s hard drive, block acceptance of cookies, or receive a warning before a cookie is stored. However, if you block or erase cookies some features of the Services may not operate properly and your online experience may be limited.

We may permit third parties to use cookies or other technology to collect information about your online and application usage activities across our Services, such as:

Google Analytics: this cookie is placed in order to track and analyze the use of our Services, for internal purposes. To learn more about how Google uses data from our use of Google Analytics Cookies, we recommend you review Google’s policies located at: www.google.com/policies/privacy/partners. For additional information, please see Google Analytics’ opt-out web tool available at: https://tools.google.com/dlpage/gaoptout.

As is the case with most websites, we gather certain information automatically and store it in log files. This information may include internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or clickstream data.
We may link this automatically-collected data to other information we collect about you. We may also combine this automatically-collected log information with other information we collect about the services we offer you.

We partner with a third party to either display advertising on our website or to manage our advertising on other sites. Our third party partner may use technologies such as cookies to gather information about your activities on this website and other sites in order to provide you with advertising based on your browsing activities and interests. If you do not wish to have this information used for the purpose of offering you interest-based ads, you may opt-out by clicking here. Please note this does not opt you out of being offered ads. You will continue to receive generic ads.

Security, Intrusion, and Detection
We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once it is received. For website security purposes, and to ensure that our website remains available to all visitors, all network traffic is monitored in order to identify unauthorized attempts to upload or change information, cause damage, or conduct criminal activity. To protect the system from unauthorized use and to ensure that the system is functioning properly, individuals accessing our website are subject to monitoring. Unauthorized attempts to upload or change information, or otherwise cause damage to this service are strictly prohibited and may be punishable under applicable law.

Further, in the event of a data incident, in which we discover your Personal Information may be at risk, then we will take reasonable efforts to notify you and the applicable authority (if required, subject to applicable laws).

Links to third parties Sites
Our website includes links to other websites whose privacy practices may differ from those of Sictec Infotech, Inc™. If you submit personal information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.

Blog
Our website offers publicly accessible blogs or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. To request removal of your personal information from our blog or community forum, contact us at: Support@deftgpt.com

Social Media Widgets
Our website includes social media features such as the Facebook and Twitter buttons and widgets, as well as the “Share this” button and all interactive mini-programs that run on our website. These features may collect your IP address, which pages you are visiting on our website, and may set a cookie to enable the feature to function properly. Social media features and widgets are either hosted by a third party or hosted directly on our website. Your interactions with these features are governed by the privacy policy of the company providing it.

Direct Marketing
We may send our Customers an email or other messages about us or our Services. We may also send our updates or newsletter to individuals who have registered to the newsletter or used our Services. You can remove your Personal Information from our mailing list and stop receiving promotional communications from us by following the unsubscribe link located at the bottom of each communication or by emailing us at :Support@deftgpt.com

Please note that in the event you are our Customer, even if you unsubscribe, we reserve the right to send you service-related communications, including service announcements and administrative messages, relating either to your account or to your transactions of the Services, without offering you the opportunity to opt out of receiving them unless you terminate and delete your account. If you wish to delete your Account, please contact us at: Support@deftgpt.com. Note that, even if we will delete your account, we will not delete certain information required under applicable law or otherwise, required for the legitimate purposes of our business. For example, we will not delete any invoices sent to you as we need it for the purpose of managing our finances.

Data Transfer Outside of the EEA
We may store or process your Personal Information in the United States or in other countries. If you visit our Websites or use our Services from locations outside of the United States, please note that any information you provide to us through your use of our Websites or Service may be transferred to and processed in countries other than the country from which you accessed our Websites or Service, including the United States where our central database is operated. If you are a resident of the European Economic Area (“EEA”) we will take appropriate measures to ensure that your personal data receives an adequate level of data protection upon its transfer outside of the EEA. If you are a resident of a jurisdiction where transfer of your personal data requires your consent, then your consent to this Privacy Policy includes your expressed consent for such transfer of your data.

Do Not Track Disclosure
The Service does not respond to Do Not Track signals. For more information about Do Not Track signals, please see: http://www.allaboutdnt.com/.

Customer’s Branding
As a paying customer of Sictec Infotech, Inc™, we reserve the right and you permit us to use your company-branding and the right to display Customer’s name, logo, trademarks and service marks (“Your Branding”) within the designated area of the Web pages hosted by Sictec Infotech, Inc™. Sictec Infotech, Inc™ may prominently display Your Branding and a phrase substantially similar to “powered by DEFT PDF,” or other phrases such as Sictec Infotech, Inc™ may appear from time to time, as well as any names, logos, trademarks, or service marks of third party co-branding clients of Sictec Infotech, Inc™. You can withdraw, at any time, the permission you give us to use Your Branding by sending us an email at: Support@deftgpt.com


`

import { ISharImgeChat, IMessage } from "redux/actions";
import { clsx } from "clsx";

interface IImagesProps {
  images?: ISharImgeChat[];
  message?: IMessage;
}
export const Images = ({ images, message }: IImagesProps) => {
  return (
    <div
      className={clsx("flex flex-row items-center flex-1 gap-6 overflow-x-auto", {
        "my-4": message?.content,
      })}
    >
      {images?.map(
        (image, index) =>
          image.path && (
            <div
              className="max-w-[180px] max-h-[180px] min-h-[120px] flex flex-row items-center flex-shrink-0"
              key={index}
            >
              <img
                src={image.path}
                alt=""
                className="rounded-md w-full h-auto"
              />
            </div>
          )
      )}
    </div>
  );
};

import { useState } from "react";
import { useEffectOnce } from "react-use";
import { FormattedMessage, useIntl } from "react-intl";

import classNames from "classnames";
import styled from "styled-components";
import styles from "./Pricing.module.scss";
import textStyles from "../components/PriceCard/PriceCard.module.scss";

import CustomButton from "components/Button";
import { LoadingPage } from "components";
import { MobilePricing } from "./MobilePricing";

import { EPlanDuration, getPlan } from "redux/actions";
import { useSelector } from "redux/hooks";
import useRouter from "hooks/useRouter";
import { planTable } from "pages/SettingsPage/pages/CurrentPlan/components/PricingTable/PricingTable";
import { RoutePaths } from "../../routePaths";
import { PriceSwitch } from "../components/PriceSwitch";

const IterFont = styled.span`
  font-family: ${({ theme }) => theme.regularFont};
`;

export const Pricing = () => {
  const { push } = useRouter();
  const { formatMessage } = useIntl();
  const plans = useSelector((state) => state.planSubscriptionReducer.plan);
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  const [duration, setDuration] = useState<EPlanDuration>("month");
  const [isloading, setIsloading] = useState<boolean>(false);
  const [highlightedColumn, setHighlightedColumn] = useState<
    number | undefined
  >(undefined);

  useEffectOnce(() => {
    setIsloading(true);
    getPlan()
      .then(() => {
        setIsloading(false);
      })
      .catch(() => {
        setIsloading(false);
      });
  });

  const onChange = (prev?: EPlanDuration) => {
    const newDuration = prev === "month" ? "year" : "month";
    setDuration(newDuration);
  };

  const thClassNames = (index: number) => {
    return index === highlightedColumn
      ? `flex flex-col p-5 border border-solid border-b-0 border-primary rounded-t-[20px]`
      : "flex flex-col p-5  border border-solid border-b-0 border-transparent";
  };

  const tdClassNames = (index: number, trIndex?: number) => {
    let classes = `p-5 text-center flex items-center justify-center h-16 border border-solid border-b-0 border-t-0 border-transparent`;
    if (index === highlightedColumn) {
      classes = `p-5 border border-solid border-b-0 border-t-0 border-primary flex items-center justify-center h-16`;

      if (trIndex && trIndex + 1 === 12) {
        classes = `p-5 border border-solid border-t-0 border-primary rounded-b-[20px] flex items-center justify-center h-16`;
      }
    }
    return classes;
  };

  const handleMouseOver = (index: number) => {
    setHighlightedColumn(index);
  };

  const handleMouseOut = () => {
    setHighlightedColumn(undefined);
  };

  return (
    <section
      className={classNames(styles.pricingSection, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      style={{
        backgroundImage: `url(/landing/${theme}/price.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {isloading ? (
        <LoadingPage />
      ) : (
        <>
          <div className="mb-16 md:mb-24 lg:mb-40">
            <h2
              className={classNames(styles.title, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.price.title" />
            </h2>
            <p
              className={classNames(styles.desc, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.price.desc" />
            </p>
          </div>
          <MobilePricing
            isLoggedIn={false}
            isSwitch={true}
            plans={plans}
            userDetail={userDetail}
            theme={theme}
            duration={duration}
            onChange={onChange}
          />
          <div className="max-w-[100%] mx-auto w-full px-[15px] md:px-0 hidden lg:block">
            <div className="flex flex-col w-full rounded-[20px] overflow-auto mb-[30px]">
              <table
                className={classNames({
                  [styles.planTableDark]: theme === "dark",
                  [styles.planTableLight]: theme === "light",
                })}
                cellPadding="0"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th className="text-left text-sm leading-6">
                      <PriceSwitch duration={duration} onChange={onChange} />
                    </th>
                    {plans
                      .filter((item) => {
                        if (item.name === "Free") {
                          return item;
                        }
                        if (item.duration === "month" && duration === "month") {
                          return item;
                        }
                        if (item.duration === "year" && duration === "year") {
                          return item;
                        }
                      })
                      .map((planItem, index) => (
                        <th
                          className="text-left text-sm leading-6"
                          key={`th-${index}`}
                          onMouseOver={() => handleMouseOver(index + 2)}
                          onMouseOut={handleMouseOut}
                        >
                          <div className={thClassNames(index + 2)}>
                            <span className="font-semibold mb-[10px]">
                              {planItem.name === "Free" ? (
                                <FormattedMessage id="plan.type.Free.forever" />
                              ) : (
                                <FormattedMessage
                                  id={`plan.type.${planItem.name}`}
                                />
                              )}
                            </span>
                            <span className="text-[32px] leading-[40px] font-[600] font-inter mb-[16px]">
                              {planItem.name === "Free" ? (
                                <FormattedMessage id={`plan.type.Free`} />
                              ) : (
                                `$${planItem.discounted_price}`
                              )}
                              {planItem.name !== "Free" && (
                                <span className="text-xl">/m</span>
                              )}
                            </span>
                            <div className="flex justify-between mb-[16px] text-[14px]">
                              <span className="leading-[26px] opacity-60">
                                <FormattedMessage id="landing.price.credits" />
                              </span>
                              <IterFont className="leading-[26px] font-semibold">
                                {planItem.credits}
                                {planItem.name === "Free"
                                  ? ` / ${formatMessage({
                                      id: "landing.price.table.duration.pre.day",
                                    })}`
                                  : ""}
                              </IterFont>
                            </div>
                            <CustomButton
                              variant="primary"
                              onClick={() =>
                                push(`/${lang}/${RoutePaths.Signup}`)
                              }
                              data-testid={planItem.name}
                            >
                              <FormattedMessage
                                id={
                                  planItem.name === "Free"
                                    ? "landing.price.tryForFree"
                                    : "landing.price.getStarted"
                                }
                              />
                            </CustomButton>
                          </div>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody className="border border-[#000]">
                  {planTable.map(
                    (planRow, index) =>
                      planRow.isShowLangingPage && (
                        <tr key={`td-${index}`}>
                          <td className="text-left flex items-center min-h-[56px] px-4">
                            {planRow.icon}
                            <span className=" color-primary text-[14px] inline-block ml-2 leading-[26px]">
                              {planRow.planName}
                            </span>
                          </td>
                          <td
                            className="text-center"
                            onMouseOver={() => handleMouseOver(2)}
                            onMouseOut={handleMouseOut}
                          >
                            <div className={tdClassNames(2, index)}>
                              {planRow.free ? (
                                <img src="/icons/ico-checked.svg" alt="" />
                              ) : (
                                planRow.freeText
                              )}
                            </div>
                          </td>
                          <td
                            className="text-center"
                            onMouseOver={() => handleMouseOver(3)}
                            onMouseOut={handleMouseOut}
                          >
                            <div className={tdClassNames(3, index)}>
                              {planRow.standard ? (
                                <img src="/icons/ico-checked.svg" alt="" />
                              ) : (
                                planRow.standardText
                              )}
                            </div>
                          </td>

                          <td
                            className="text-center"
                            onMouseOver={() => handleMouseOver(4)}
                            onMouseOut={handleMouseOut}
                          >
                            <div className={tdClassNames(4, index)}>
                              {planRow.professional ? (
                                <img src="/icons/ico-checked.svg" alt="" />
                              ) : (
                                planRow.professionalText
                              )}
                            </div>
                          </td>

                          <td
                            className="text-center"
                            onMouseOver={() => handleMouseOver(5)}
                            onMouseOut={handleMouseOut}
                          >
                            <div className={tdClassNames(5, index)}>
                              {planRow.team ? (
                                <img src="/icons/ico-checked.svg" alt="" />
                              ) : (
                                planRow.teamText
                              )}
                            </div>
                          </td>

                          <td
                            className="text-center"
                            onMouseOver={() => handleMouseOver(6)}
                            onMouseOut={handleMouseOut}
                          >
                            <div className={tdClassNames(6, index)}>
                              {planRow.business ? (
                                <img src="/icons/ico-checked.svg" alt="" />
                              ) : (
                                planRow.businessText
                              )}
                            </div>
                          </td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </div>
            <div className="mx-auto flex flex-col items-center leading-8">
              <p
                className={classNames("text-[16px] my-[15px]", {
                  [textStyles.darkText]: theme === "dark",
                  [textStyles.lightText]: theme === "light",
                })}
              >
                <FormattedMessage
                  id="landing.price.table.bottom"
                  values={{
                    number: (
                      <span className="font-semibold">
                        <IterFont>100000</IterFont>
                      </span>
                    ),                               
                    price: (
                      <span className="font-semibold">
                        <IterFont>$500</IterFont>
                      </span>
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

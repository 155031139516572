import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./CreditItem.module.scss";

import { TickIcon } from "components/icons/TickIcon";

import { ICredit } from "redux/actions";

import { useSelector } from "redux/hooks";

interface IProps {
  creditItem: ICredit;
  isSelected?: boolean;
  onSelect?: () => void;
}

export const CreditItem = ({ creditItem, isSelected, onSelect }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
      onClick={onSelect}
      data-testid='credit-item-select'
    >
      <div className={styles.credits}>{creditItem.credits}</div>
      <div className={styles.rightContainer}>
        <div className={styles.price}>
          <div className={styles.buyFor}>
            <FormattedMessage id="buyCredit.modal.buyCreditFor" />
          </div>
          ${creditItem.price}
        </div>
        <div
          className={classNames(styles.checkbox, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          {isSelected && <TickIcon />}
        </div>
      </div>
    </div>
  );
};

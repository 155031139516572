

const EditIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.13756 1.97559H5.4891C3.31098 1.97559 1.94531 3.51763 1.94531 5.70071V11.5898C1.94531 13.7729 3.3046 15.3149 5.4891 15.3149H11.7394C13.9246 15.3149 15.2839 13.7729 15.2839 11.5898V8.73663"  strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.25075 7.73568L11.5441 2.4423C12.2036 1.78355 13.2725 1.78355 13.9319 2.4423L14.794 3.30435C15.4534 3.9638 15.4534 5.03339 14.794 5.69214L9.47508 11.011C9.18679 11.2993 8.79579 11.4615 8.38779 11.4615H5.73438L5.80096 8.78401C5.81088 8.39018 5.97167 8.01476 6.25075 7.73568Z"  strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.7422 3.25977L13.9764 6.49402" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  )
}

export default EditIcon
import styles from './socialShare.module.scss';
import {
    FacebookIcon,
    WhatsappIcon,
    XIcon,
    EmailIcon,
    LinkedinIcon
} from "../../icons";

interface IProps {
    referralLink: string;
}

export const SocialShare = ({ referralLink }: IProps) => {

    const encodedLink = encodeURIComponent(referralLink);
    const message = `Get 50 credits free when you sign up. DeftGPT is an awesome alternative for ChatGPT: ${referralLink}`;

    const handleShare = (platform: string) => {

        let socialUrl = '';

        switch (platform) {
            case 'whatsapp':
                socialUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
                break;
            case 'X':
                socialUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`;
                break;
            case 'facebook':
                socialUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
                break;
            case 'email':
                const emailSubject = 'DeftGPT- An awesome alternative for chatgpt';
                socialUrl = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(message)}`;
                break;
            case 'linkedin':
                socialUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`;
                break;
            default:
                break;
        }

        window.open(socialUrl, '_blank');
    };

    const socialMediaLinks = [
        { icon: <WhatsappIcon />, platform: 'whatsapp' },
        { icon: <XIcon />, platform: 'X' },
        { icon: <FacebookIcon />, platform: 'facebook' },
        { icon: <EmailIcon />, platform: 'email' },
        { icon: <LinkedinIcon />, platform: 'linkedin' }
    ];

    return (
        <div className={styles.container}>
            {socialMediaLinks.map(({ icon, platform }, index) => (
                <button data-testid={`${platform}-btn`} key={index} className={styles.socialButton} onClick={() => handleShare(platform)}>
                    {icon}
                </button>
            ))}
        </div>
    );
};

import axios, {
  AxiosInstance,
  CancelTokenSource,
  AxiosRequestConfig,
} from "axios";
import { store } from "../store";
import { logoutDispatch } from "../actions";
import { SwitchTeam } from "utils/switchTeam";

const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    "Content-Type": "application/json",
    "Cache-Control": "no-store",
  },
});

let controller: CancelTokenSource | null = null;

const createController = () => {
  controller = axios.CancelToken.source();
  return controller;
};

interface RetryConfig extends AxiosRequestConfig {
  retry: number;
  retryDelay: number;
}

const globalConfig: RetryConfig = {
  retry: 3,
  retryDelay: 1000,
};

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { config } = error;
    
    console.log(error, 'errrrr', config)
    if (!config || !config.retry) {
      return Promise.reject(error);
    }
    config.retry -= 1;
    const delayRetryRequest = new Promise<void>((resolve) => {
      setTimeout(() => resolve(), config.retryDelay || 1000);
    });
    return delayRetryRequest.then(() => api(config));
  }
);

api.interceptors.request.use(
  (config) => {
    {
      config?.url?.includes("/verify-email?expire") ||
      config?.url?.includes("/chat?search")
        ? (config.params = {
            ...config.params,
          })
        : (config.params = {
            ...config.params,
            // nocache: Date.now(),
          });
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.request.use(async (config) => {
  const userDetail = store.getState().authReducer.userDetail;
  if (userDetail?.token) {
    config.headers.Authorization = `Bearer ${userDetail?.token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // SwitchTeam();
    if(error?.response?.data?.admin_team_deleted === true){
      SwitchTeam();
    }
    if (error?.response?.status === 401) {
      logoutDispatch();
      return Promise.reject({
        data: {
          message: "Unauthorized, may be your token is expired",
        },
      });
    }
    if (error?.code === "ERR_NETWORK") {
      return Promise.reject({
        data: {
          message: error?.message ?? "Network error",
        },
      });
    }

    return Promise.reject({
      message: "Request failed",
      status: error?.response?.status,
      data: error?.response?.data,
    });
  }
);

export { api, globalConfig, createController };

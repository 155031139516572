import { ReactNode } from "react";
import styled from "styled-components";
import { Theme } from "theme";

import Header from "../Header";
import Footer from "../Footer";
import { SectionRefs } from "components/Header/Header";

import { EThemeType } from "redux/reducers";
import { useSelector } from "redux/hooks";

const Wrapper = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background-color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? "#141517" : theme.white};
  color: ${({ themeMode }) =>
    themeMode === "dark" ? "rgba(255,255,255,0.8)" : "#2A2831"};
`;

const Content = styled.div`
  padding: 60px 0px 0 0px;
  display: flex;
  flex-direction: column;
`;

interface IProps {
  children?: ReactNode;
  sectionRefs?: SectionRefs;
  isHasBgOnFooter?: boolean;
  activeSection?: string;
}

export const Main = ({
  children,
  sectionRefs,
  isHasBgOnFooter,
  activeSection,
}: IProps) => {
  const theme = useSelector((state) => state.authReducer.theme);

  return (
    <Wrapper themeMode={theme}>
      <Header sectionRefs={sectionRefs} activeSection={activeSection} />
      <Content>{children}</Content>
      <Footer isHasBgOnFooter={isHasBgOnFooter} />
    </Wrapper>
  );
};

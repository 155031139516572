export const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.43742 17.5C5.09367 17.5 4.7994 17.3776 4.55461 17.1328C4.30981 16.888 4.18742 16.5938 4.18742 16.25V4.375H3.33325V3.125H7.24992V2.5H12.7499V3.125H16.6666V4.375H15.8124V16.25C15.8124 16.5833 15.6874 16.875 15.4374 17.125C15.1874 17.375 14.8958 17.5 14.5624 17.5H5.43742ZM14.5624 4.375H5.43742V16.25H14.5624V4.375ZM7.64575 14.4583H8.89575V6.14583H7.64575V14.4583ZM11.1041 14.4583H12.3541V6.14583H11.1041V14.4583Z"
        fill="white"
      />
    </svg>
  );
};

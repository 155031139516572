import { useWindowSize } from "hooks/useWindowSize";
import { useSidebar } from "../ReSizeSidebar/ReSizeSidebar";
import { ReactNode, createContext, useContext, useState, useEffect } from "react";

export interface ToggleSidebar {
  isOpen: boolean;
  toggleSidebar: () => void;
}

interface IProps {
  children?: ReactNode;
}

const ToggleSidebarContext = createContext<ToggleSidebar | null>(null);

export const ToggleSidebarProvider = ({ children }: IProps) => {
  const sidebarStatus = localStorage.getItem('sidebar');
  const { width } = useWindowSize();
  const { setSidebarWidth, setPaneStyle } = useSidebar();
  const [isOpen, setIsOpen] = useState<boolean>(sidebarStatus === 'false'? false : true);
  let maxSidebarWidth = window.innerWidth * 0.45;
  const savedWidth = localStorage.getItem('sidebarWidth');

  const toggleSidebar = () => {
    // setIsOpen((prev) => !prev)
    if (width <= 768) {
      setIsOpen((prev) => {
        const newIsOpen = !prev;
        localStorage.setItem('sidebarSmall', JSON.stringify(newIsOpen));
        localStorage.setItem('sidebar', JSON.stringify(newIsOpen));
        return newIsOpen;
      })
    }
    else {
      setIsOpen((prev) => {
        const newIsOpen = !prev;
        localStorage.setItem('sidebar', JSON.stringify(newIsOpen));
        const calculatedWidth = newIsOpen ? savedWidth !== null && savedWidth !== '110'
          && Number(savedWidth) <= maxSidebarWidth
          ? parseInt(savedWidth, 10)
          : savedWidth !== null && savedWidth !== '110' && Number(savedWidth) > maxSidebarWidth ? maxSidebarWidth
            : !newIsOpen ? 110 : 242 : 110
        if (savedWidth === '110') localStorage.setItem('sidebarWidth', `${calculatedWidth}`);
        setSidebarWidth(calculatedWidth);
        setPaneStyle({ width: `${calculatedWidth}px`, marginRight: '', transition: 'none' });
        return newIsOpen;
      });
    }
  };

  useEffect(() => {
    const sidebarStatus = localStorage.getItem('sidebar');
    if (width > 768) {
      if (sidebarStatus === 'false') {
        setIsOpen((prev) => {
          const newIsOpen = prev;
          localStorage.setItem('sidebar', JSON.stringify(newIsOpen));
          const calculatedWidth = newIsOpen ? savedWidth !== null && savedWidth !== '110'
            && Number(savedWidth) <= maxSidebarWidth
            ? parseInt(savedWidth, 10)
            : savedWidth !== null && savedWidth !== '110' && Number(savedWidth) > maxSidebarWidth ? maxSidebarWidth
              : !newIsOpen ? 110 : 242 : 110
          if (savedWidth === '110') localStorage.setItem('sidebarWidth', `${calculatedWidth}`);
          setSidebarWidth(calculatedWidth);
          setPaneStyle({ width: `${calculatedWidth}px`, marginRight: '', transition: 'none' });
          return newIsOpen;
        });
      }
    }
  }, [])

  useEffect(() => {
    const sidebarStatusSmall = localStorage.getItem('sidebarSmall');
    const sidebarStatus = localStorage.getItem('sidebar');
    if (sidebarStatus === 'true' && sidebarStatusSmall === 'true' && width > 768) {
      const savedWidth = localStorage.getItem('sidebarWidth');
      setSidebarWidth(savedWidth && savedWidth !== '110' ? Number(savedWidth) <= maxSidebarWidth ? Number(savedWidth) : 242 : 242);
      setPaneStyle({ width: `${savedWidth !== '110' ? Number(savedWidth) <= maxSidebarWidth ? Number(savedWidth) : 242 : 242}px`, marginRight: '', transition: 'none' });
      localStorage.setItem('sidebarWidth', `${savedWidth !== '110' ? Number(savedWidth) <= maxSidebarWidth ? Number(savedWidth) : 242 : 242}`);
      localStorage.removeItem('sidebarSmall');
    }
    if (sidebarStatus === 'false' && sidebarStatusSmall === 'false' && width > 768) {
      setSidebarWidth(110);
      setPaneStyle({ width: `${110}px`, marginRight: '', transition: 'none' });
      localStorage.removeItem('sidebarSmall');
    }

  }, [width])

  useEffect(() => {
    let lastScrollTop = 0;

    const preventPullToRefresh = (e: TouchEvent) => {
      const currentScrollTop = e.touches[0].clientY;

      // Check if the page is scrolled to the top
      if (currentScrollTop > lastScrollTop && isOpen && width <= 576) {
        e.preventDefault();
      }
      lastScrollTop = currentScrollTop;
    };

    document.addEventListener('touchmove', preventPullToRefresh, { passive: false });

    return () => {
      document.removeEventListener('touchmove', preventPullToRefresh);
    };
  }, [isOpen, width]);

  return (
    <ToggleSidebarContext.Provider value={{ isOpen, toggleSidebar }}>
      {children}
    </ToggleSidebarContext.Provider>
  );
};

export const useToggleSidebar = (): ToggleSidebar => {
  const toggleSidebarContext = useContext(ToggleSidebarContext);

  if (!toggleSidebarContext) {
    throw new Error(
      "ToggleSidebarContext must be used within ToggleSidebarContext"
    );
  }

  return toggleSidebarContext;
};

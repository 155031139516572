import { TYPES } from "../../types";
import { api } from "../../api";
import { store } from "redux/store";
import { EChatType } from "../chatInterface";
import { setShowUpscaleModal } from "../imageGeneration";
import {  SelectedSubOptions } from "redux/reducers";
import { filterLanguages } from "../chat";

export interface IModelAttributes {
  sizes: ISize[];
  max_image_count?: number;
  default: boolean;
}

export interface ISize {
  value: string;
  width: number;
  height: number;
  credits_per_message: number;
}

export interface IChatModel {
  id: number;
  name: string;
  model: string;
  description: string | null;
  credits_per_message: number;
  image_url: string;
  created_at: string | null;
  updated_at: string | null;
  attributes: IModelAttributes | null;
  type: EChatType[];
}

export type EModelAttributes =
  (typeof ModelAttributes)[keyof typeof ModelAttributes];

export const ModelAttributes = {
  count: "count",
  maxCount: "maxCount",
  size: "size",
} as const;

export interface ISetImageAttribute {
  key: EModelAttributes;
  value: any;
}

export const getModels = () => {
  const { chatModels } = store.getState().chatModelsReducer;
  return new Promise((resolve, reject) => {
    if (chatModels.length === 0) {
      api
        .get("/api/ai-models")
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

export const getChatModels = () => {
  return new Promise((resolve, reject) => {
    getModels()
      .then((res: any) => {
        const { gptModel, showUpscaleModal } = store.getState().authReducer;
        let models: IChatModel[] = res.data.map((model: IChatModel) => {
          return {
            ...model,
            type: model?.type?.filter(
              (modelType: EChatType) =>
                modelType === "document" ||
                modelType === "text" ||
                modelType === "image" ||
                modelType === "image_chat"
            ),
          };
        });
        let model = gptModel;
        if (!gptModel?.type) {
          model = models[0];
        }
        if (!showUpscaleModal) {
          setShowUpscaleModal({ showUpscaleModal: "show" });
        }
        store.dispatch({ type: TYPES.GET_CHAT_MODELS, payload: models });
        store.dispatch({
          type: TYPES.SET_CHAT_MODEL,
          payload: model as IChatModel,
        });
        resolve(models);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const setChatModel = (chatModel: IChatModel) => {
  if (chatModel) {
    store.dispatch({ type: TYPES.SET_CHAT_MODEL, payload: chatModel });
    filterLanguages(chatModel?.model);
  }
  if (chatModel?.type?.includes("image")) {
    if (chatModel?.attributes?.sizes) {
      setImageAttribute({ key: "count", value: 1 });
      setImageAttribute({ key: "size", value: chatModel?.attributes?.sizes[0] });
      setImageAttribute({
        key: "maxCount",
        value: chatModel?.attributes?.max_image_count,
      });
    }
  }
};

export const resetChatModel = () => {
  const { chatModels } = store.getState().chatModelsReducer;
  if (chatModels.length > 0) {
    const GPTModal = localStorage.getItem('GptModel');
    if (GPTModal) {
      const gptModel = JSON.parse(GPTModal);
      store.dispatch({ type: TYPES.SET_CHAT_MODEL, payload: gptModel });
    }
    else
      store.dispatch({ type: TYPES.SET_CHAT_MODEL, payload: chatModels[0] });
  } else {
    getChatModels().catch();
  }
};

export const setImageAttribute = (attribute: ISetImageAttribute) => {
  store.dispatch({ type: TYPES.SET_IMAGE_ATTRIBUTE, payload: attribute });
};

export const setSelectedSubOptions = (attribute: SelectedSubOptions) => {
  store.dispatch({ type: TYPES.SET_SELECTED_OPTIONS, payload: attribute });
};
import { useEffect, useState, Dispatch, SetStateAction, useRef, useCallback } from "react";
import { ChatItem } from "../ChatItem/ChatItem";
import { ChatSearchItem } from "../ChatItem/ChatSearchItem";
import {
  deleteAllChats, deleteAllConversation, deleteSelectedChats, getAllChats, IChat, removeMultipleChat,
  setChatHistoryLoading,
  setcontainerHeight, setPagination, setSearchQuery, setViewChatHistory
} from "redux/actions";
import { LoadingPage } from "components";
import styles from "../../ChatSidebar.module.scss";
import { Spinner } from "components";
import { FormattedMessage } from "react-intl";
import { useAppNotification } from "hooks/services/AppNotification";
import { DownArrow } from "../icons/DownArrow";
import { HistoryIcon } from "views/layout/Navbar/components/icons/HistoryIcon";
import { DeleteChatConfirm } from "../DeleteChatConfirm";
import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute } from "pages/ChatPage";
import classNames from "classnames";
import { DeleteIcon, RemoveIcon } from "../icons";
import chatItemStyle from '../ChatItem/ChatItem.module.scss';
import React from "react";
import { useSelector } from "redux/hooks";
import { RightArrow } from "views/layout/Navbar/components/ChatNav/icons";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
  searchQuery: string;
  toggleSidebar?: any;
  width?: number;
  setSelectedItem: (chatItem: IChat | undefined) => void;
  selectedItem?: IChat | undefined;
  setOpenHistory?: Dispatch<SetStateAction<boolean>>;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
}

export const ChatItemList = ({
  onSelectChatItem,
  searchQuery,
  toggleSidebar,
  width,
  setSelectedItem,
  selectedItem,
  setOpenHistory,
  onStartNewChat,
}: IProps) => {

  const { triggerNotification } = useAppNotification();
  const contentRef = useRef<HTMLDivElement>(null);

  const [isloading, setIsloading] = useState<boolean>(false);
  const [isloadingMore, setIsloadingMore] = useState<boolean>(false);
  const [perPage, setPerPage] = useState(0);
  const [selectedChatIds, setSelectedChatIds] = useState<number[]>([]);
  const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(true);
  const [chatDelConfirm, setChatDelConfirm] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectAllChat, setSelectAllChats] = useState<boolean>(false);
  const { push } = useRouter();

  const [isSelectAllClicked, setIsSelectAllClicked] = useState<boolean>(false);
  const [isContentExpanded, setIsContentExpanded] = useState<boolean>(false);
  // Add a new state to save manually selected chat IDs
  const [manuallySelectedChatIds, setManuallySelectedChatIds] = useState<number[]>([]);

  const showDelete = selectedChatIds.length >= 1 && !isloading && isAccordionOpen;
  const { PrivateChat, containerHeight, pagination, viewHistory, chathistory, chatLoading } = useSelector(
      (state) => state.chatReducer
    );

  useEffect(() => {
    setIsAccordionOpen(viewHistory);
    const contentHeight = contentRef?.current?.clientHeight || 0;
    if (contentHeight > containerHeight + 40 || pagination.page===0) {
      setPerPage(0);
      setcontainerHeight(contentHeight);
      setIsContentExpanded(true);
    }
    const newPerPage = Math.floor(contentHeight / 28);
    setPerPage(newPerPage);
  }, [contentRef?.current?.clientHeight]);

  useEffect(() => {
    if (perPage && !isSelectAllClicked) {
      setSearchQuery(searchQuery);
      setIsloading(true);
      setChatHistoryLoading(true);
      const delayDebounce = setTimeout(() => {
        setIsloading(true);
        setChatHistoryLoading(true);
        deleteAllChats();
        setPagination(0, 0);
        getAllChats({ search: searchQuery, page: 1, perPage })
          .then((resp: any) => {
            setIsSelectAllClicked(true);
            setIsloading(false);
            setChatHistoryLoading(false);
            setPagination(1 + 1, resp?.last_page);
            setIsContentExpanded(false);
            setTimeout(() => setIsSelectAllClicked(false), 500)
          })
          .catch((err: any) => {
            setIsloading(false);
            setChatHistoryLoading(false);
            setIsContentExpanded(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }, 1500);
      return () => clearTimeout(delayDebounce);
    }
  }, [searchQuery, isContentExpanded]);

  useEffect(() => {
    if (selectAllChat === true) {
      const allChatIds = chathistory?.map(chat => chat.id) || [];
      setSelectedChatIds(allChatIds);
    }
  }, [selectAllChat, chathistory])

  const loadMoreChats = () => {
    setIsloadingMore(true);
    getAllChats({ search: searchQuery, page: pagination.page, perPage })
      .then((newChats: any) => {
        setPagination(pagination.page + 1, newChats?.last_page);
      })
      .catch((error) => console.error("Error loading more chats:", error))
      .finally(() => setIsloadingMore(false));
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    event.stopPropagation();
    if ((pagination.page > pagination.lastPage)) {
    } else if (searchQuery === '') {
      const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
      const scrollPositionFromBottom =
        scrollHeight - (scrollTop + clientHeight);
      const loadThreshold = 1;
      if (scrollPositionFromBottom <= loadThreshold && !isloadingMore) {
        loadMoreChats();
      }
    }
  };

  const handleSelectId = (id: number) => {
    setManuallySelectedChatIds(prevSelectedChatIds => {
      if (prevSelectedChatIds.includes(id)) {
        return prevSelectedChatIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedChatIds, id];
      }
    });

    setSelectedChatIds(prevSelectedChatIds => {
      if (prevSelectedChatIds.includes(id)) {
        return prevSelectedChatIds.filter(selectedId => selectedId !== id);
      } else {
        return [...prevSelectedChatIds, id];
      }
    });
    setSelectAllChats(false);
  };

  const onSelectChat = (chatItem: IChat | undefined) => {
    setOpenHistory!(true);
    setSelectedItem(chatItem);
    onSelectChatItem?.(chatItem);
    if (width && width <= 768) {
      setTimeout(() => toggleSidebar(), 500);
    }
  };

  const toggleAccordion = () => {
    setIsAccordionOpen((prev) => {
      const newAccordionState = !prev;
      setViewChatHistory(newAccordionState);
      return newAccordionState;
    });
  };

  const handleCancel = useCallback((cancel: boolean) => {
    setSelectAllChats(false);
    if (cancel) {
      setTimeout(() => {
        setSelectedChatIds(manuallySelectedChatIds)
      }, 15);
    }
    else {
      setTimeout(() => {
        setSelectedChatIds([]);
        setManuallySelectedChatIds([]);
      }, 15);
    }
    setChatDelConfirm(false);
  }, [manuallySelectedChatIds, selectAllChat]);

  const resetStates = () => {
    setLoading(false);
    deleteAllChats();
    setChatDelConfirm(false);
    setSelectedChatIds([]);
    setSelectAllChats(false);
  };

  const checkForRedirect = () => {
    const currentPath = window.location.pathname;

    const pathSegments = currentPath.split('/');
    const chatIdFromUrl = pathSegments[pathSegments.length - 1];

    if (selectedItem && selectedChatIds.includes(selectedItem.id)) {
      push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
    }

    if (selectedChatIds.includes(Number(chatIdFromUrl))) {
      onStartNewChat?.({ toChat: true });
    }
  }

  const handleSubmit = () => {
    setLoading(true);

    if (selectAllChat && searchQuery === '') {
      resetStates();
      setPagination(0, 0)
      onStartNewChat?.({ toChat: true });
      setIsSelectAllClicked(true);
      deleteAllConversation().then(() => {
        setSelectAllChats(false);
        setTimeout(() => setIsSelectAllClicked(false), 100);
      })
        .catch((err) => {
          setLoading(false);
          setChatDelConfirm(false);
          setSelectedChatIds([]);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
    }
    else {
      if (searchQuery && selectAllChat) {
        resetStates();
        setIsSelectAllClicked(false);
        removeMultipleChat(selectedChatIds)
          .then(() => {
            checkForRedirect();
          })
          .catch((err) => {
            setLoading(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }
      else {
        removeMultipleChat(selectedChatIds)
          .then(() => {
            deleteSelectedChats(selectedChatIds);
            setLoading(false);
            setChatDelConfirm(false);
            setSelectedChatIds([]);
            if (pagination.page > pagination.lastPage) {
            }
            else if (!searchQuery) {
              loadMoreChats();
            }
            checkForRedirect();
          })
          .catch((err) => {
            setLoading(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }
    }
  }

  // Memoized function to handle "Select All" logic
  const handleSelectAllChats = useCallback((isChecked: boolean) => {
    if (isChecked) {
      const allChatIds = chathistory?.map(chat => chat.id) || [];
      setSelectedChatIds(allChatIds);
    } else {
      setTimeout(() =>
        setSelectedChatIds([])
        , 15);
    }
    setSelectAllChats(isChecked);
  }, [chathistory]);

  return (
    <>
      <div
        className={classNames(styles.historyIconContainer, {
          [styles.isbackdrop]: PrivateChat,
        })}>
        <HistoryIcon />
        <span className={styles.historyHeading}><FormattedMessage id="sidebar.chat.history" /></span>
        <div className={classNames(styles.delChat, {
          [styles.closeHistory]: !isAccordionOpen
        })}>
          <div onClick={toggleAccordion}>
            {isAccordionOpen ? <DownArrow /> : <RightArrow />}
          </div>
        </div>
      </div>
      {chatDelConfirm && (
        <DeleteChatConfirm onCancel={() => { handleCancel(true) }} onSubmit={handleSubmit} loading={loading} />
      )}

      {showDelete && (
        <div className={`flex pl-[37px] items-center py-1 ${classNames({
          [styles.isbackdrop]: PrivateChat,
        })}`}>
          <div className={chatItemStyle.check}>
            <input type="checkbox"
              checked={selectAllChat}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className={chatItemStyle.visibleCheckbox}
              onChange={(e) => handleSelectAllChats(e.target.checked)}
            />
          </div>
          <span className="text-xs font-medium opacity-90	text-white leading-[28px] pl-1 w-[56%]"><FormattedMessage id="sidebar.chat.select.all" /></span>
          <div className="flex justify-between absolute right-6 gap-3">
            <div data-testid='confirm-edit-btn' className="cursor-pointer" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation(); setChatDelConfirm(true)
            }}>
              <DeleteIcon />
            </div>

            <div data-testid='cancel-edit-btn ' className="cursor-pointer" onClick={() => { handleCancel(false) }}>
              <RemoveIcon />
            </div>
          </div>
        </div>
      )}

      <div className={classNames(styles.content, {
        [styles.isbackdrop]: PrivateChat,
      })} onScroll={handleScroll} ref={contentRef}>
        {isAccordionOpen && (
          <>
            {(isloading || chatLoading) && <LoadingPage smallLoader />}
            {( !chatLoading) && chathistory?.length === 0 && (
              <div
                className={styles.emptySearchMessage}
              >
                <FormattedMessage id="sidebar.chat.noChat.message" />
              </div>
            )}

            {!chatLoading &&
              chathistory &&
              chathistory.length > 0 &&
              chathistory.map((chatItem, key) => (
                <React.Fragment>
                  <ChatItem
                    key={`topic-${key}`}
                    chatItem={chatItem}
                    selectedItem={selectedItem}
                    onSelectChat={onSelectChat}
                    searchQuery={searchQuery}
                    handleSelectId={handleSelectId}
                    selectedChatIds={selectedChatIds}
                    setSelectedChatIds={setSelectedChatIds}
                    onStartNewChat={onStartNewChat}
                    setSelectedItem={setSelectedItem}
                  />
                  {(searchQuery && searchQuery.length > 0) && (
                    <ChatSearchItem
                      chatItem={chatItem}
                      onSelectChat={onSelectChat}
                      searchQuery={searchQuery}
                    />
                  )}
                </React.Fragment>
              )
              )}
            {(isloadingMore && !isloading) && (
              <div className="flex items-center justify-center py-2">
                <Spinner small />
              </div>
            )}
          </>
        )
        }
      </div>
    </>
  );
};
export const UserIcon = () => {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5002 18.3333V17.6458C18.5002 15.4942 16.7559 13.75 14.6043 13.75H7.72933C5.57772 13.75 3.8335 15.4942 3.8335 17.6458V18.3333"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse
        cx="11.1672"
        cy="6.41667"
        rx="3.66667"
        ry="3.66667"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

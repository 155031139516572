import Highlighter from "react-highlight-words";
interface IProps {
  searchQuery: string;
  text: string;
  excerptLength?: number;
}
export const WordHighlighter = ({
  searchQuery,
  text,
  excerptLength,
}: IProps) => {
  if (!searchQuery) {
    // If there is no search query, simply return the whole text.
    return <>{text}</>;
  }
  const searchQueryLower = searchQuery.toLowerCase();
  // Creating array of keywords with no duplicates.
  const searchKeywords = [
    ...new Set([searchQueryLower, ...searchQueryLower.split(" ")]),
  ];
  let highlightedText = text;
  // If there are search keywords and excerptLength is specified and greater than 0.
  if (searchKeywords.length > 0 && excerptLength && excerptLength > 0) {
    const searchQueryLength = searchQueryLower?.length;
    const lowerText = text?.toLowerCase();
    let startIdx = 0;
    let endIdx = searchQueryLength;
    // Find the first keyword in the text to be associated with a highlight.
    const indexOfHighlight = searchKeywords.findIndex((keyword) =>
      lowerText.includes(keyword)
    );
    const highlightStart = lowerText.indexOf(searchKeywords[indexOfHighlight]);
    if (highlightStart !== -1) {
      // If the search query is longer than the expected excerpt...
      if (searchQueryLength > excerptLength) {
        // ...set the start and end indexes to encompass the whole query.
        startIdx = highlightStart;
        endIdx = highlightStart + searchQueryLength;
      } else {
        // Otherwise, pad around the query.
        const paddingLength = Math.floor(
          (excerptLength - searchQueryLength) / 2
        );
        startIdx = Math.max(0, highlightStart - paddingLength);
        endIdx = highlightStart + searchQueryLength + paddingLength;
      }
      // Extract the highlighted text.
      highlightedText = text.slice(startIdx, endIdx);
    }
  }
  // Return a Highlighter component with the cleaned up parameters.
  return (
    <Highlighter
      autoEscape
      highlightClassName="text-white bg-primary"
      searchWords={searchKeywords}
      textToHighlight={highlightedText}
    />
  );
};

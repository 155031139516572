
export const FeedbackIcon = () => {
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9997 19.2499C12.3619 19.2515 13.7031 18.9143 14.9024 18.2684L18.67 19.2275C18.8267 19.2673 18.9928 19.2217 19.1071 19.1074C19.2215 18.993 19.2671 18.8269 19.2272 18.6702L18.2682 14.9027C20.0235 11.6338 19.3805 7.59526 16.697 5.03298C14.0134 2.4707 9.94946 2.01512 6.76516 3.91959C3.58085 5.82406 2.05966 9.62 3.04753 13.1964C4.03541 16.7729 7.28929 19.2499 10.9997 19.2499V19.2499Z"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
      </svg>
    );
};

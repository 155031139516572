import {  useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import TextareaAutosize from "react-textarea-autosize";
import * as yup from "yup";

import styles from "./Prompt.module.scss";
import classNames from "classnames";

import CustomButton from "components/Button";
import { Field, FieldProps, Formik } from "formik";
import { LabeledInput, Separator } from "components";
import { Form } from "pages/AuthPages/components/FormComponents";

import { useAppNotification } from "hooks/services/AppNotification";
import { useSelector } from "redux/hooks";
import { SavePrompts, updateUserPrompt } from "redux/actions";

interface IProps {
  toggleAddPrompt: () => void;
  allowEdit?: boolean;
  editPrompt?: number | null;
  toggleEditPrompt: () => void;
}

export const AddPrompt = ({ toggleAddPrompt, allowEdit, editPrompt, toggleEditPrompt }: IProps) => {
  const { formatMessage } = useIntl();
  const [isFieldFocused, setIsFieldFocused] = useState<boolean>(false);
  const { triggerNotification } = useAppNotification();
  const { theme } = useSelector((state) => state.authReducer);
  const { userPrompts } = useSelector(
    (state) => state.chatReducer
  );

  const AddMemberValidateionSchema = yup.object().shape({
    title: yup
      .string()
      .required("prompt.error.empty.title")
      .max(255, "prompt.title.error.maxlength"),
    prompt: yup
      .string()
      .required("prompt.error.empty.prompt")
      .max(65535, "prompt.error.maxlength"),
  });
  return (
    <>
      <Formik
        onSubmit={(values, { setSubmitting, resetForm }) => {
          {
            allowEdit ?
              updateUserPrompt(editPrompt ? editPrompt : 1, values)
                .then(() => {
                  resetForm();
                  setSubmitting(false);
                  toggleEditPrompt();
                })
                .catch((err: any) => {
                  triggerNotification({
                    message: err?.data?.message,
                    type: "error",
                  });
                  setSubmitting(false);
                }) :
              SavePrompts(values)
                .then(() => {
                  resetForm();
                  setSubmitting(false);
                  toggleAddPrompt();
                })
                .catch((err: any) => {
                  triggerNotification({
                    message: err?.data?.message,
                    type: "error",
                  });
                  setSubmitting(false);
                })
          }
        }}
        initialValues={{ title: allowEdit ? userPrompts.filter((prompt) => prompt.id === editPrompt)[0]?.title : "", prompt: allowEdit ? userPrompts.filter((prompt) => prompt.id === editPrompt)[0]?.prompt : "" }}
        validationSchema={AddMemberValidateionSchema}
        validateOnChange={false}
        validateOnBlur={false} 
      >
        {({ isSubmitting }) => (
          <Form data-testid="add-prompt-form">
            <Field name="title">
              {({ field, meta }: FieldProps<string>) => (
                <LabeledInput
                  {...field}
                  placeholder={formatMessage({
                    id: "prompt.title",
                  })}
                  type="text"
                  error={!!meta.error && meta.touched}
                  message={
                    meta.touched &&
                    meta.error &&
                    formatMessage({ id: meta.error })
                  }
                  variant={theme}
                  autoFocus
                  prompt={true}
                  data-testid="title-input"
                />
              )}
            </Field>
            <Separator />
            <Field name="prompt">
              {({ field, meta }: FieldProps<string>) => (
                <>
                  {meta.touched && meta.error && (
                    <div
                      id="error-text"
                      className="text-[#ff5454] pb-[5px] font-medium text-sm"
                    >
                      {formatMessage({ id: meta.error })}
                    </div>
                  )}
                  <TextareaAutosize
                    {...field}
                    className={classNames(styles.textarea, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                      [styles.error]: meta.touched && meta.error,
                      [styles.activeborder]: isFieldFocused,
                    })}
                    minRows={4}
                    maxRows={7}
                    placeholder={formatMessage({
                      id: "prompt.add.your",
                    })}
                    onFocus={() => setIsFieldFocused(true)}
                    onBlur={() => setIsFieldFocused(false)}
                    data-testid="prompt-textarea"
                  />
                </>
              )}
            </Field>
            <div className="flex justify-center py-7">
              <CustomButton
                className={styles.CustomButtonPrompt}
                variant="primary"
                type="submit"
                isloading={isSubmitting}
                data-testid="prompt-save-button"
              >
                <FormattedMessage id={allowEdit ? "prompt.update" : "prompt.save"} />
              </CustomButton>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

import classNames from "classnames";
import styles from "./message.module.scss";

import { MarkdownComponent } from "../markdown";

import { IMessage, IShareChatMessage } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  message?: IMessage;
  shareMessage?: IShareChatMessage;
  searchQuery?: string;
  isHighlighted?: boolean;
  highlighted?: boolean;
}

export const Message = ({
  message,
  shareMessage,
  searchQuery,
  isHighlighted,
  highlighted,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <div
      className={classNames(styles.message, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
    >
      {/* {message?.file?.path && (
        <>
          You are now chatting with DeftGPT base on uploaded document &nbsp;
          <a
            className="text-purple-500 cursor-pointer hover:underline"
            href={message?.file?.path}
            target="_blank"
          >
            {message?.file?.name}
          </a>
        </>
      )} */}
      <MarkdownComponent
        message={
          message
            ? (message?.content as string)
            : (shareMessage?.content as string)
        }
        searchQuery={searchQuery}
        isHighlighted={isHighlighted}
        highlighted={highlighted}
      />
    </div>
  );
};


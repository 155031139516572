export const IntegratedFeedbackIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M18.926 30.852H51.0743C57.6491 30.852 63.0003 25.501 63.0003 18.926C63.0003 12.3511 57.6493 7 51.0743 7H18.926C12.3513 7 7 12.3511 7 18.926C7 25.501 12.3511 30.852 18.926 30.852ZM18.926 9.07473H51.0743C56.5084 9.07473 60.9263 13.4924 60.9263 18.9267C60.9263 24.3609 56.5086 28.7786 51.0743 28.7786H18.926C13.492 28.7786 9.07406 24.3609 9.07406 18.9267C9.07406 13.4924 13.4918 9.07473 18.926 9.07473Z"
      fill="#7C4DFF"
    />
    <path
      d="M30.9868 20.8136L30.4061 24.2462C30.3438 24.6299 30.4994 25.024 30.8209 25.2624C31.1424 25.4906 31.5572 25.5217 31.9097 25.3454L35.0001 23.7276L38.0904 25.3454C38.2459 25.4283 38.4015 25.4595 38.5674 25.4595C38.7852 25.4595 38.9926 25.3972 39.1793 25.2624C39.5007 25.0343 39.6563 24.6402 39.5941 24.2462L39.0133 20.8136L41.5022 18.3765C41.7822 18.0965 41.8859 17.6921 41.7615 17.3187C41.637 16.9453 41.3155 16.6758 40.9318 16.6136L37.4785 16.1054L35.923 12.984C35.5704 12.2788 34.4193 12.2788 34.0667 12.984L32.5111 16.1054L29.0578 16.6136C28.6637 16.6758 28.3423 16.9454 28.2282 17.3187C28.1141 17.6921 28.2074 18.1069 28.4874 18.3765L30.9763 20.8136H30.9868ZM33.3616 18.0655C33.6934 18.0136 33.9838 17.8062 34.1394 17.5055L35.0001 15.7633L35.8609 17.5055C36.0164 17.8062 36.3068 18.024 36.6387 18.0655L38.5572 18.3455L37.1675 19.704C36.929 19.9425 36.8149 20.2847 36.8668 20.6166L37.1883 22.5247L35.4667 21.6225C35.3216 21.5395 35.1556 21.5084 34.9897 21.5084C34.8238 21.5084 34.6579 21.5499 34.5127 21.6225L32.7912 22.5247L33.1127 20.6166C33.1645 20.2847 33.0608 19.9425 32.8119 19.704L31.4223 18.3455L33.3408 18.0655H33.3616Z"
      fill="#7C4DFF"
    />
    <path
      d="M14.9858 20.8136L14.4051 24.2462C14.3429 24.6299 14.4984 25.024 14.8199 25.2624C15.1414 25.4906 15.5562 25.5217 15.9088 25.3454L18.9991 23.7276L22.0894 25.3454C22.245 25.4283 22.4005 25.4595 22.5664 25.4595C22.7842 25.4595 22.9916 25.3972 23.1783 25.2624C23.4998 25.0343 23.6553 24.6402 23.5931 24.2462L23.0124 20.8136L25.5012 18.3765C25.7812 18.0965 25.8849 17.6921 25.7605 17.3187C25.6361 16.9453 25.3146 16.6758 24.9309 16.6136L21.4776 16.1054L19.922 12.984C19.5694 12.2788 18.4183 12.2788 18.0657 12.984L16.5101 16.1054L13.0568 16.6136C12.6627 16.6758 12.3413 16.9454 12.2272 17.3187C12.1131 17.6921 12.2065 18.1069 12.4864 18.3765L14.9753 20.8136H14.9858ZM17.3606 18.0655C17.6925 18.0136 17.9828 17.8062 18.1384 17.5055L18.9992 15.7633L19.8599 17.5055C20.0155 17.8062 20.3058 18.024 20.6377 18.0655L22.5562 18.3455L21.1665 19.704C20.928 19.9425 20.8139 20.2847 20.8658 20.6166L21.1873 22.5247L19.4658 21.6225C19.3206 21.5395 19.1547 21.5084 18.9887 21.5084C18.8228 21.5084 18.6569 21.5499 18.5117 21.6225L16.7902 22.5247L17.1117 20.6166C17.1635 20.2847 17.0598 19.9425 16.8109 19.704L15.4213 18.3455L17.3398 18.0655H17.3606Z"
      fill="#7C4DFF"
    />
    <path
      d="M46.9888 20.8136L46.408 24.2462C46.3458 24.6299 46.5013 25.024 46.8228 25.2624C47.1443 25.4906 47.5591 25.5217 47.9117 25.3454L51.002 23.7276L54.0923 25.3454C54.2479 25.4283 54.4034 25.4595 54.5694 25.4595C54.7871 25.4595 54.9945 25.3972 55.1812 25.2624C55.5027 25.0343 55.6583 24.6402 55.596 24.2462L55.0153 20.8136L57.5042 18.3765C57.7842 18.0965 57.8879 17.6921 57.7634 17.3187C57.639 16.9453 57.3175 16.6758 56.9338 16.6136L53.4805 16.1054L51.9249 12.984C51.5723 12.2788 50.4212 12.2788 50.0686 12.984L48.513 16.1054L45.0597 16.6136C44.6657 16.6758 44.3442 16.9454 44.2301 17.3187C44.116 17.6921 44.2094 18.1069 44.4894 18.3765L46.9783 20.8136H46.9888ZM49.3635 18.0655C49.6954 18.0136 49.9858 17.8062 50.1413 17.5055L51.0021 15.7633L51.8628 17.5055C52.0184 17.8062 52.3088 18.024 52.6406 18.0655L54.5591 18.3455L53.1695 19.704C52.9309 19.9425 52.8169 20.2847 52.8687 20.6166L53.1902 22.5247L51.4687 21.6225C51.3235 21.5395 51.1576 21.5084 50.9917 21.5084C50.8257 21.5084 50.6598 21.5499 50.5146 21.6225L48.7931 22.5247L49.1146 20.6166C49.1665 20.2847 49.0628 19.9425 48.8139 19.704L47.4242 18.3455L49.3427 18.0655H49.3635Z"
      fill="#7C4DFF"
    />
    <path
      d="M28.3838 40.5792C28.3838 44.2295 31.3497 47.1955 35.0001 47.1955C38.6504 47.1955 41.6164 44.2295 41.6164 40.5792C41.6164 36.9288 38.6504 33.9629 35.0001 33.9629C31.3497 33.9629 28.3838 36.9288 28.3838 40.5792ZM39.5419 40.5792C39.5419 43.0888 37.5093 45.1214 34.9997 45.1214C32.49 45.1214 30.4575 43.0888 30.4575 40.5792C30.4575 38.0695 32.49 36.037 34.9997 36.037C37.5093 36.037 39.5419 38.0695 39.5419 40.5792Z"
      fill="#7C4DFF"
    />
    <path
      d="M35.0002 49.7671C28.2802 49.7671 22.8047 55.2323 22.8047 61.9626C22.8047 62.5329 23.2713 62.9996 23.8417 62.9996C24.4121 62.9996 24.8787 62.5329 24.8787 61.9626C24.8787 56.3834 29.421 51.8412 35.0002 51.8412C40.5794 51.8412 45.1216 56.3834 45.1216 61.9626C45.1216 62.5329 45.5883 62.9996 46.1586 62.9996C46.729 62.9996 47.1957 62.5329 47.1957 61.9626C47.1957 55.2426 41.7201 49.7671 35.0002 49.7671Z"
      fill="#7C4DFF"
    />
    <path
      d="M14.4365 43.0164C14.4365 46.1483 16.9877 48.6994 20.1195 48.6994C23.2513 48.6994 25.8024 46.1482 25.8024 43.0164C25.8024 39.8846 23.2513 37.3335 20.1195 37.3335C16.9877 37.3335 14.4365 39.8846 14.4365 43.0164ZM23.7389 43.0164C23.7389 45.0075 22.1212 46.6253 20.1301 46.6253C18.139 46.6253 16.5212 45.0075 16.5212 43.0164C16.5212 41.0253 18.139 39.4076 20.1301 39.4076C22.1212 39.4076 23.7389 41.0253 23.7389 43.0164Z"
      fill="#7C4DFF"
    />
    <path
      d="M24.495 52.5675C24.7024 52.0387 24.4432 51.4372 23.9143 51.2194C22.7113 50.7424 21.4358 50.5039 20.1292 50.5039C14.4255 50.5039 9.79004 55.1394 9.79004 60.843C9.79004 61.4134 10.2567 61.88 10.8271 61.88C11.3974 61.88 11.8641 61.4134 11.8641 60.843C11.8641 56.2904 15.5663 52.578 20.1292 52.578C21.1765 52.578 22.1929 52.7646 23.1469 53.1483C23.6758 53.3557 24.2773 53.0964 24.495 52.5675Z"
      fill="#7C4DFF"
    />
    <path
      d="M44.1885 43.0164C44.1885 46.1483 46.7396 48.6994 49.8714 48.6994C53.0032 48.6994 55.5543 46.1482 55.5543 43.0164C55.5543 39.8846 53.0032 37.3335 49.8714 37.3335C46.7396 37.3335 44.1885 39.8846 44.1885 43.0164ZM53.4909 43.0164C53.4909 45.0075 51.8731 46.6253 49.882 46.6253C47.8909 46.6253 46.2732 45.0075 46.2732 43.0164C46.2732 41.0253 47.8909 39.4076 49.882 39.4076C51.8731 39.4076 53.4909 41.0253 53.4909 43.0164Z"
      fill="#7C4DFF"
    />
    <path
      d="M49.8713 50.5035C48.5646 50.5035 47.2995 50.742 46.0862 51.219C45.5573 51.4264 45.2877 52.0279 45.5054 52.5671C45.7128 53.096 46.3247 53.3656 46.8535 53.1479C47.818 52.7745 48.8343 52.5775 49.8713 52.5775C54.4239 52.5775 58.1364 56.2797 58.1364 60.8425C58.1364 61.4129 58.603 61.8796 59.1734 61.8796C59.7438 61.8796 60.2104 61.4129 60.2104 60.8425C60.2104 55.1388 55.5749 50.5035 49.8713 50.5035Z"
      fill="#7C4DFF"
    />
  </svg>
);
import { combineReducers } from "redux";

import {
  authReducer,
  chatModelsReducer,
  chatReducer,
  planSubscriptionReducer,
  teamReducer,
} from "./reducers";

const rootReducer = combineReducers({
  authReducer,
  chatModelsReducer,
  chatReducer,
  planSubscriptionReducer,
  teamReducer,
});

export default rootReducer;

export type IRootState = ReturnType<typeof rootReducer>;

import { DropDownRow } from "components/base/DropDown";
// import links from "core/links";

export const languages: DropDownRow.IDataItem[] = [
  {
    label: "English",
    value: "en",
    text: "EN",
    img: `/flags/United States of America (USA).png`,
  },
  {
    label: "Français",
    value: "fr",
    text: "FR",
    img: `/flags/France.png`,
  },
  {
    label: "Deutsch",
    value: "de",
    text: "DE",
    img: `/flags/Germany.png`,
  },
  {
    label: "Portugal",
    value: "pt",
    text: "PT",
    img: `/flags/Portugal.png`,
  },
  {
    label: "简体中文",
    value: "zh",
    text: "中文",
    img: `/flags/China.png`,
  },
  {
    label: "Español",
    value: "es",
    text: "ES",
    img: `/flags/Spain.png`,
  },
  {
    label: "Czech",
    value: "cs",
    text: "CS",
    img: `/flags/Czech Republic.png`,
  },
  {
    label: "Italian",
    value: "it",
    text: "IT",
    img: `/flags/Italy.png`,
  },
  {
    label: "日本語",
    value: "ja",
    text: "JA",
    img: `/flags/Japan.png`,
  },
  {
    label: "Russian",
    value: "ru",
    text: "RU",
    img: `/flags/Russian Federation.png`,
  },
  {
    label: "Turkish",
    value: "tr",
    text: "TR",
    img: `/flags/Turkey.png`,
  },
  {
    label: "繁體中文",
    value: "zh-TW",
    text: "繁體中文",
    img: `/flags/China.png`,
  },
  {
    label: "Danish",
    value: "da",
    img: `/flags/Denmark.png`,
  },
  {
    label: "Dutch",
    value: "nl",
    img: `/flags/Netherlands.png`,
  },
  {
    label: "Finnish",
    value: "fi",
    img: `/flags/Finland.png`,
  },
  {
    label: "Indonesian",
    value: "id",
    img: `/flags/Indonesia.png`,
  },
  {
    label: "Korean",
    value: "ko",
    img: `/flags/South Korea.png`,
  },
  {
    label: "Malay",
    value: "ms",
    img: `/flags/Malaysia.png`,
  },
  {
    label: "Norwegian",
    value: "no",
    img: `/flags/Norway.png`,
  },
  {
    label: "Polish",
    value: "pl",
    img: `/flags/Poland.png`,
  },
  {
    label: "Swedish",
    value: "sv",
    img: `/flags/Sweden.png`,
  },
];
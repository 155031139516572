export const InstallExtensionIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M56.5032 21.2063L43.5937 8.29684C42.7684 7.47158 41.6484 7 40.5284 7H17.3621C16.1832 7 15.1221 7.47158 14.2968 8.29684C13.4716 9.12211 13 10.1832 13 11.3621V58.6379C13 61.0547 14.9453 63 17.3621 63H53.4379C55.8547 63 57.8 61.0547 57.8 58.6379V24.2716C57.8 23.1516 57.3284 22.0316 56.5032 21.2063ZM54.0274 22.2674H44.4779C43.4168 22.2674 42.5326 21.3832 42.5326 20.3221V10.8316L54.0274 22.2674ZM53.4379 60.4653H17.3621C16.36 60.4653 15.5347 59.64 15.5347 58.6379V11.3621C15.5347 10.8905 15.7116 10.4189 16.0653 10.0653C16.4189 9.71158 16.8905 9.53474 17.3621 9.53474H39.9979V20.3221C39.9979 22.7979 42.0021 24.8021 44.4779 24.8021H55.2653V58.6379C55.2653 59.64 54.44 60.4653 53.4379 60.4653Z"
      fill="#7C4DFF"
    />
    <path
      d="M34.5153 45.8464C34.7511 46.0822 35.1048 46.2001 35.3995 46.2001C35.6943 46.2001 36.048 46.0822 36.2837 45.8464L40.7048 41.4253C41.1764 40.9538 41.1764 40.1285 40.7048 39.598C40.2332 39.1264 39.408 39.1264 38.8774 39.598L36.6374 41.838V32.7011C36.6374 31.9938 36.048 31.4043 35.3406 31.4043C34.6332 31.4043 34.0437 31.9938 34.0437 32.7011V41.8969L31.8627 39.598C31.3911 39.1264 30.5659 39.1264 30.0353 39.598C29.5637 40.0696 29.5637 40.8948 30.0353 41.4253L34.5153 45.8464Z"
      fill="#7C4DFF"
    />
    <path
      d="M44.2412 45.8462C43.5338 45.8462 42.9443 46.4357 42.9443 47.143V50.6209H27.7949V47.143C27.7949 46.4357 27.2054 45.8462 26.498 45.8462C25.7906 45.8462 25.2012 46.4357 25.2012 47.143V51.8588C25.2012 52.5662 25.7906 53.1557 26.498 53.1557H44.1822C44.8896 53.1557 45.4791 52.5662 45.4791 51.8588V47.143C45.538 46.4357 44.9485 45.8462 44.2412 45.8462Z"
      fill="#7C4DFF"
    />
  </svg>
);

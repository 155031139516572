interface Iprop{
    model?: boolean;
    theme?: boolean
}
export const OptionsIcon = ({model, theme}:Iprop) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={model? "20":"15"} height={model? "20":"15"} viewBox="0 0 15 15" fill="none">
            <circle cx="7.5" cy="7.5" r="7.5" fill={theme? "#fff":"#2A2831"} fill-opacity="0.1" />
            <path d="M7.49975 6.52148C6.9604 6.52148 6.52148 6.9604 6.52148 7.49975C6.52148 8.03909 6.9604 8.47801 7.49975 8.47801C8.03909 8.47801 8.47801 8.03909 8.47801 7.49975C8.47801 6.9604 8.03909 6.52148 7.49975 6.52148Z" fill={theme? "#fff":"#2A2831"} />
            <path d="M7.49975 3.26074C6.9604 3.26074 6.52148 3.69966 6.52148 4.239C6.52148 4.77835 6.9604 5.21726 7.49975 5.21726C8.03909 5.21726 8.47801 4.77835 8.47801 4.239C8.47801 3.69966 8.03909 3.26074 7.49975 3.26074Z" fill={theme? "#fff":"#2A2831"} />
            <path d="M7.49975 9.78223C6.9604 9.78223 6.52148 10.2211 6.52148 10.7605C6.52148 11.2998 6.9604 11.7387 7.49975 11.7387C8.03909 11.7387 8.47801 11.2998 8.47801 10.7605C8.47801 10.2211 8.03909 9.78223 7.49975 9.78223Z" fill={theme? "#fff":"#2A2831"} />
        </svg>
    )
}
import { FormattedMessage } from 'react-intl';
import styles from '../TeamTable/TeamTable.module.scss';

interface IProp {
    allowActions?: boolean;
}

export const TableHead = ({allowActions}: IProp) => {
    return(
    <thead>
        <tr 
        className={styles.smallscreen}
        >
          <th>
            <FormattedMessage id="team.table.th.name" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.email" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.date" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.status" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.creditsToday" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.dailyCredits" />
          </th>
          <th>
            <FormattedMessage id="team.table.th.totalUsed" />
          </th>
         {allowActions &&  <th></th>}
        </tr>
      </thead>
    )
} 
import { memo } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./ErrorModal.module.scss";

import { Modal, Separator } from "components";
import CustomButton from "components/Button";

import { useSelector } from "redux/hooks";

interface IProps {
  message?: string;
  onClose?: () => void;
  uploadURL?: boolean;
}

export const ErrorModal = memo(({ message, onClose, uploadURL }: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <>
      {uploadURL ?
        <Modal size='xl' onClose={onClose} drag={true} errorModel={true}>
          {/* <NewUploadIcon /> */}
          <div className={styles.uploadFail}>
            <div className={styles.message}>
              <FormattedMessage id="doc.upload.fail" />
            </div>
            <div className={classNames(styles.validationText, {
              [styles.dark]: theme === 'dark',
            })}>
              <FormattedMessage id={message ? message : "documentChat.file.failed"} />
            </div>
          </div>
        </Modal>
        :
        <Modal size="sm" onClose={onClose}>
          <div className={styles.container}>
            <div
              className={classNames(styles.message, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id={message} />
            </div>
            <Separator height="29px" />
            <CustomButton
              variant="primary"
              style={{ minWidth: "140px" }}
              onClick={onClose}
              data-testid='error-close-btn'
            >
              <FormattedMessage id="Ok" />
            </CustomButton>
          </div>
        </Modal>
      }
    </>
  );
});

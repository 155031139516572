import { memo } from "react";
import { CodeBlock } from "../codeBlock";
import { RenderTable } from "../renderTable";
import { preprocessLaTeX } from "utils/functions";
import ReactMarkdown, { Components } from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import styles from "./markdown.module.scss";
import { WordHighlighter } from "views/layout/Sidebar/component/ChatSidebar/components/ChatItem/WordHighlighter";

interface IProps {
  message: string;
  searchQuery?: string;
  highlighted?: boolean;
  isHighlighted?: boolean;
}

export const MarkdownComponent: React.FC<IProps> = memo(
  ({  searchQuery, message, highlighted,isHighlighted }) => {

    const components:  Components = {
      code({ node, className, children, ...props }) {
        if (!node?.position || !children) {
          return <>{children}</>;
        }
        if (node?.position.start.line === node?.position.end.line) {
          return (
            <span className="font-semibold text-[17px] px-1 py-[2px] mx-1 bg-[#e8e8eb] dark:bg-[#2f2d34] rounded-md ">
              <code className={className} {...props}>
                {children}
              </code>
            </span>
          );
        }
      
        const match = /language-(\w+)/.exec(className || "");
        let language = match ? match[1].toLowerCase() : "javascript";
        return (
          <CodeBlock
            content={String(children).replace(/\n$/, "")}
            language={language}
            searchWord={searchQuery}
            isHighlighted={isHighlighted }
             highlighted={highlighted}
          />
        );
      },
      a({ href, children }) {
        const text = children ? children.toString() : "";
        if (text.includes("http")) {
          return <span>{text}</span>;
        } else {
          return (
            <a
              href={href}
              target="_blank"
              rel="noreferrer"
              className="text-primary underline"
            >
              {children}
            </a>
          );
        }
      },
      strong({ children }) {
        return <strong>{children}</strong>;
      },
      ul({ children }) {
        return <ul className={styles.customUl}>{children}</ul>;
      },
    
      ol({ children }) {
        return <ol className={styles.customOl}>{children}</ol>;
      },
     
      table({ children }) {
        return <RenderTable>{children}</RenderTable>;
      },
      
      text({ children }) {
        if (!searchQuery) {
          return <>{children}</>;
        }
        return (
          <WordHighlighter
            searchQuery={searchQuery}
            text={children as string}
            excerptLength={0}
          />
        );
      },
    };
    
    return (
      <div className={styles.markdownContainer}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkMath]}
        rehypePlugins={[rehypeKatex as any]}
        components={components}
        children={preprocessLaTeX(message)}
      ></ReactMarkdown>
      </div>
    );
  }
);
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./ConfirmationModa.module.scss";

import { Modal, Separator } from "components";
import Button from "components/Button";

import { useSelector } from "redux/hooks";

interface IProps {
  heading?: string;
  message?: string;
  memberName?: string;
  onClose?: () => void;
  submitBtnText?: string;
  onSubmit?: () => void;
  submitLoading?: boolean;
}

export const ConfirmationModal = ({
  heading,
  message,
  memberName,
  onClose,
  submitBtnText,
  onSubmit,
  submitLoading,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.modalBody}>
        <div className={styles.headline}>
          <FormattedMessage id={heading} />
        </div>
        <Separator height="6px" />
        <div className={styles.Message}>
          <FormattedMessage id={message} values={{ userName: memberName }} />
        </div>
        <Separator height="40px" />
        <div className={styles.footer}>
          <Button data-testid='close-btn' variant="outlined-primary" onClick={onClose}>
            <div
              className={classNames(styles.buttonText, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="modal.confirmation.btn.cancel" />
            </div>
          </Button>
          <Button
            variant="primary"
            onClick={onSubmit}
            isloading={submitLoading}
            data-testid='submit-btn'
          >
            <FormattedMessage id={submitBtnText} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

import { FormattedMessage } from "react-intl";
import {
  DiscountsIcon,
  MoneyIcon,
  TrendingProductIcon,
} from "pages/AffiliatePage/icons";

const iconClassName = "w-[36px] h-[36px] md:w-[44px] md:h-[44px] lg:w-[50px] lg:h-[50px]";

const partners = [
  {
    icon: <DiscountsIcon className={iconClassName} />,
    heading: "affiliate.partner.section.promoteDiscount",
    text: "affiliate.partner.section.promoteDiscount.desc",
  },
  {
    icon: <MoneyIcon className={iconClassName} />,
    heading: "affiliate.partner.section.moneyLimit",
    text: "affiliate.partner.section.moneyLimit.desc",
  },
  {
    icon: <TrendingProductIcon className={iconClassName} />,
    heading: "affiliate.partner.section.productIntro",
    text: "affiliate.partner.section.productIntro.desc",
  },
];

export const Partner = () => {
  return (
    <div className="text-light dark:text-dark py-14 md:py-28 bg-white dark:bg-[rgba(42,40,49,0.50)]">
      <h2 className="text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] mb-16 md:mb-28 text-center">
        <FormattedMessage id="affilate.partner.section.title" />
      </h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-[40px] max-w-[1440px] mx-auto md:px-[10%] lg:px-0 ">
        {partners.map((partner, index) => (
          <div key={index} className="flex flex-col gap-1 px-[5%] lg:px-0">
            {partner.icon}
            <h4 className="text-[20px] font-bold mt-5 md:mt-7 lg:mt-10 mb-2">
              <FormattedMessage id={partner.heading} />
            </h4>
            <p className="text-opacity-70 text-[15px] md:text-[16px] leading-7 font-medium">
              <FormattedMessage id={partner.text} />
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

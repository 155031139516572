export const ApplyTheModelIcon = ({
  className,
}: {
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="70"
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M50.493 57.5302V59.6791H19.0419V33.6326C21.7116 33.307 24.1209 32.2 26.0744 30.507H44.0465C44.9581 30.507 45.7395 29.7256 45.7395 28.814C45.7395 27.9023 45.0233 27.1209 44.0465 27.1209H28.9395C29.8512 25.5581 30.4372 23.8651 30.6977 21.9767H50.493V40.4698L53.814 36.7581V21.3256C53.814 19.893 52.6419 18.7209 51.2093 18.7209H30.6326C29.8512 12.1442 24.2512 7 17.414 7C9.9907 7 4 12.9907 4 20.414C4 27.2512 9.14419 32.8512 15.7209 33.6326V60.3954C15.7209 61.8279 16.893 63 18.3256 63H51.1442C52.5767 63 53.7488 61.8279 53.7488 60.3954V54.2744L51.6 56.6186C51.3395 57.0744 50.9488 57.3349 50.493 57.5302ZM7.32093 20.414C7.32093 14.8791 11.814 10.3209 17.414 10.3209C23.014 10.3209 27.507 14.814 27.507 20.414C27.507 26.014 23.014 30.507 17.414 30.507C11.814 30.507 7.32093 25.9488 7.32093 20.414Z"
      fill="#7C4DFF"
    />
    <path
      d="M25.4884 38.5159H44.0466C44.9582 38.5159 45.7396 37.7996 45.7396 36.8229C45.7396 35.8462 45.0233 35.1299 44.0466 35.1299H25.4884C24.5768 35.1299 23.7954 35.8462 23.7954 36.8229C23.7954 37.7996 24.5768 38.5159 25.4884 38.5159Z"
      fill="#7C4DFF"
    />
    <path
      d="M45.7396 44.8981C45.7396 43.9865 45.0233 43.2051 44.0466 43.2051H25.4884C24.5768 43.2051 23.7954 43.9214 23.7954 44.8981C23.7954 45.8097 24.5117 46.5911 25.4884 46.5911H44.0466C44.9582 46.526 45.7396 45.8097 45.7396 44.8981Z"
      fill="#7C4DFF"
    />
    <path
      d="M23.7954 52.9074C23.7954 53.819 24.5117 54.6004 25.4884 54.6004H41.507L42.0931 51.2795H25.4884C24.5768 51.2144 23.7954 51.9307 23.7954 52.9074Z"
      fill="#7C4DFF"
    />
    <path
      d="M57.5023 36.6992L47.5952 47.7554L52.2989 51.9702L62.206 40.914L57.5023 36.6992Z"
      fill="#7C4DFF"
    />
    <path
      d="M45.2837 56.2929L49.2558 55.186C49.4511 55.1209 49.5813 55.0557 49.7116 54.9255L50.8837 53.6232L46.1953 49.3906L45.0232 50.693C44.893 50.8232 44.8278 51.0185 44.7627 51.1488L44.0465 55.2511C43.9813 55.9023 44.5674 56.4883 45.2837 56.2929Z"
      fill="#7C4DFF"
    />
    <path
      d="M65.5348 35.7811L62.2789 32.8509C61.8882 32.5253 61.3022 32.5253 60.9115 32.916L58.958 35.1299L63.6464 39.3625L65.5999 37.1485C65.9906 36.7578 65.9254 36.1067 65.5348 35.7811Z"
      fill="#7C4DFF"
    />
    <path
      d="M22.6883 18.721C23.2743 18.0047 23.2092 16.9629 22.5581 16.3768C21.8418 15.7908 20.7999 15.8559 20.2139 16.5071L16.1767 21.1303L14.2232 19.1768C13.572 18.5257 12.5302 18.5257 11.879 19.1768C11.2278 19.828 11.2278 20.8698 11.879 21.521L15.0697 24.7117C15.3953 25.0373 15.786 25.1675 16.2418 25.1675H16.3069C16.7627 25.1675 17.2185 24.9722 17.479 24.5815L22.6883 18.721Z"
      fill="#7C4DFF"
    />
  </svg>
);
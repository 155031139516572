export const InstantTranslationIcon = ({
  width = 64,
  height = 64,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 64 64"
    fill="none"
  >
    <path
      d="M56.8889 28.8889H35.1111V7.11111C35.1111 6.28599 34.7833 5.49467 34.1999 4.91122C33.6164 4.32778 32.8251 4 32 4H7.11111C6.28599 4 5.49467 4.32778 4.91122 4.91122C4.32778 5.49467 4 6.28599 4 7.11111V32C4 32.8251 4.32778 33.6164 4.91122 34.1999C5.49467 34.7833 6.28599 35.1111 7.11111 35.1111H28.8889V56.8889C28.8889 57.714 29.2167 58.5053 29.8001 59.0888C30.3836 59.6722 31.1749 60 32 60H56.8889C57.714 60 58.5053 59.6722 59.0888 59.0888C59.6722 58.5053 60 57.714 60 56.8889V32C60 31.1749 59.6722 30.3836 59.0888 29.8001C58.5053 29.2167 57.714 28.8889 56.8889 28.8889ZM6.07407 32V7.11111C6.07407 6.83607 6.18333 6.5723 6.37782 6.37782C6.5723 6.18333 6.83607 6.07407 7.11111 6.07407H32C32.275 6.07407 32.5388 6.18333 32.7333 6.37782C32.9278 6.5723 33.037 6.83607 33.037 7.11111V32C33.037 32.275 32.9278 32.5388 32.7333 32.7333C32.5388 32.9278 32.275 33.037 32 33.037H7.11111C6.83607 33.037 6.5723 32.9278 6.37782 32.7333C6.18333 32.5388 6.07407 32.275 6.07407 32ZM57.9259 56.8889C57.9259 57.1639 57.8167 57.4277 57.6222 57.6222C57.4277 57.8167 57.1639 57.9259 56.8889 57.9259H32C31.725 57.9259 31.4612 57.8167 31.2667 57.6222C31.0722 57.4277 30.963 57.1639 30.963 56.8889V35.1111H32C32.8251 35.1111 33.6164 34.7833 34.1999 34.1999C34.7833 33.6164 35.1111 32.8251 35.1111 32V30.963H56.8889C57.1639 30.963 57.4277 31.0722 57.6222 31.2667C57.8167 31.4612 57.9259 31.725 57.9259 32V56.8889Z"
      fill="#7C4DFF"
    />
    <path
      d="M49.6301 39.2596H45.3471V38.2226C45.3471 37.9475 45.2378 37.6838 45.0434 37.4893C44.8489 37.2948 44.5851 37.1855 44.3101 37.1855C44.035 37.1855 43.7712 37.2948 43.5768 37.4893C43.3823 37.6838 43.273 37.9475 43.273 38.2226V39.2596H39.2597C38.9847 39.2596 38.7209 39.3689 38.5264 39.5634C38.3319 39.7578 38.2227 40.0216 38.2227 40.2967C38.2227 40.5717 38.3319 40.8355 38.5264 41.03C38.7209 41.2244 38.9847 41.3337 39.2597 41.3337H46.1249C45.9817 43.0663 45.3527 44.7233 44.3101 46.1144C43.8476 45.4698 43.4613 44.7738 43.159 44.0404C43.1065 43.9144 43.0298 43.8 42.9331 43.7037C42.8365 43.6073 42.7218 43.531 42.5957 43.479C42.4695 43.427 42.3344 43.4004 42.1979 43.4006C42.0615 43.4008 41.9264 43.4279 41.8004 43.4804C41.6745 43.5328 41.5601 43.6095 41.4637 43.7062C41.3674 43.8028 41.2911 43.9175 41.2391 44.0436C41.1871 44.1698 41.1604 44.3049 41.1607 44.4414C41.1609 44.5778 41.188 44.7129 41.2404 44.8389C41.6629 45.8619 42.2248 46.8216 42.9101 47.6907C42.0739 48.4865 41.1482 49.1825 40.1515 49.7648C39.9509 49.8784 39.7939 50.0556 39.7053 50.2685C39.6166 50.4813 39.6014 50.7176 39.6621 50.94C39.7228 51.1625 39.8558 51.3583 40.0402 51.4967C40.2246 51.6351 40.4499 51.708 40.6804 51.7041C40.8666 51.704 41.0493 51.6539 41.2093 51.5589C42.3332 50.9122 43.3773 50.1361 44.3204 49.2463C45.2697 50.1287 46.313 50.9042 47.4315 51.5589C47.5916 51.6539 47.7743 51.704 47.9604 51.7041C48.1876 51.704 48.4085 51.6293 48.5892 51.4915C48.7698 51.3537 48.9002 51.1604 48.9603 50.9413C49.0203 50.7221 49.0068 50.4894 48.9217 50.2787C48.8366 50.068 48.6847 49.8911 48.4893 49.7752C47.494 49.1909 46.5685 48.4951 45.7308 47.7011C47.1844 45.8805 48.0457 43.6584 48.199 41.3337H49.6301C49.9051 41.3337 50.1689 41.2244 50.3634 41.03C50.5578 40.8355 50.6671 40.5717 50.6671 40.2967C50.6671 40.0216 50.5578 39.7578 50.3634 39.5634C50.1689 39.3689 49.9051 39.2596 49.6301 39.2596Z"
      fill="#7C4DFF"
    />
    <path
      d="M40.2968 18.5184H41.3338C41.6089 18.5184 41.8727 18.6277 42.0671 18.8222C42.2616 19.0166 42.3709 19.2804 42.3709 19.5554V23.2784L42.0701 22.9673C41.8749 22.772 41.61 22.6623 41.3338 22.6623C41.0577 22.6623 40.7928 22.772 40.5975 22.9673C40.4023 23.1626 40.2926 23.4274 40.2926 23.7036C40.2926 23.9798 40.4023 24.2446 40.5975 24.4399L42.6716 26.514C42.768 26.6112 42.8827 26.6883 43.0091 26.741C43.1355 26.7936 43.271 26.8207 43.4079 26.8207C43.5448 26.8207 43.6804 26.7936 43.8067 26.741C43.9331 26.6883 44.0478 26.6112 44.1442 26.514L46.2183 24.4399C46.4136 24.2446 46.5233 23.9798 46.5233 23.7036C46.5233 23.4274 46.4136 23.1626 46.2183 22.9673C46.023 22.772 45.7582 22.6623 45.482 22.6623C45.2058 22.6623 44.941 22.772 44.7457 22.9673L44.445 23.2784V19.5554C44.445 18.7303 44.1172 17.939 43.5337 17.3556C42.9503 16.7721 42.159 16.4443 41.3338 16.4443H40.2968C40.0218 16.4443 39.758 16.5536 39.5635 16.7481C39.369 16.9426 39.2598 17.2063 39.2598 17.4814C39.2598 17.7564 39.369 18.0202 39.5635 18.2147C39.758 18.4092 40.0218 18.5184 40.2968 18.5184Z"
      fill="#7C4DFF"
    />
    <path
      d="M24.4923 22.4179C24.4672 22.3231 24.4288 22.2323 24.3782 22.1482L20.5931 12.9393C20.5153 12.75 20.3831 12.5879 20.2134 12.4734C20.0436 12.359 19.8437 12.2974 19.639 12.2964C19.4354 12.2966 19.2364 12.3566 19.0668 12.4691C18.8971 12.5816 18.7643 12.7415 18.6849 12.929L13.3856 25.3734C13.2907 25.6241 13.2964 25.9019 13.4018 26.1484C13.5071 26.3949 13.7038 26.591 13.9506 26.6957C14.1975 26.8003 14.4752 26.8053 14.7256 26.7096C14.9761 26.6139 15.1797 26.4249 15.2938 26.1823L16.3308 23.7038H22.7293L23.7664 26.1719C23.8455 26.3646 23.9808 26.529 24.1547 26.6437C24.3286 26.7584 24.5329 26.818 24.7412 26.8149C24.9119 26.8158 25.0802 26.7745 25.2312 26.6947C25.3821 26.6148 25.511 26.499 25.6064 26.3574C25.7018 26.2159 25.7608 26.0529 25.7781 25.8831C25.7954 25.7132 25.7705 25.5417 25.7056 25.3838L24.4923 22.4179ZM17.2331 21.6297L19.6182 16.0297L21.8997 21.6297H17.2331Z"
      fill="#7C4DFF"
    />
    <path
      d="M23.703 45.4815H22.666C22.391 45.4815 22.1272 45.3723 21.9327 45.1778C21.7382 44.9833 21.629 44.7195 21.629 44.4445V40.7215L21.9297 41.0326C22.0261 41.1298 22.1408 41.207 22.2672 41.2596C22.3935 41.3123 22.5291 41.3394 22.666 41.3394C22.8029 41.3394 22.9384 41.3123 23.0648 41.2596C23.1912 41.207 23.3059 41.1298 23.4023 41.0326C23.4995 40.9362 23.5766 40.8215 23.6293 40.6951C23.6819 40.5688 23.709 40.4332 23.709 40.2963C23.709 40.1594 23.6819 40.0239 23.6293 39.8975C23.5766 39.7711 23.4995 39.6564 23.4023 39.56L21.3282 37.486C21.2318 37.3888 21.1171 37.3116 20.9907 37.259C20.8644 37.2063 20.7288 37.1792 20.5919 37.1792C20.455 37.1792 20.3195 37.2063 20.1931 37.259C20.0667 37.3116 19.952 37.3888 19.8556 37.486L17.7815 39.56C17.5863 39.7553 17.4766 40.0202 17.4766 40.2963C17.4766 40.5725 17.5863 40.8373 17.7815 41.0326C17.9768 41.2279 18.2417 41.3376 18.5178 41.3376C18.794 41.3376 19.0589 41.2279 19.2541 41.0326L19.5549 40.7215V44.4445C19.5549 45.2696 19.8827 46.0609 20.4661 46.6444C21.0495 47.2278 21.8409 47.5556 22.666 47.5556H23.703C23.9781 47.5556 24.2418 47.4463 24.4363 47.2518C24.6308 47.0574 24.7401 46.7936 24.7401 46.5185C24.7401 46.2435 24.6308 45.9797 24.4363 45.7852C24.2418 45.5908 23.9781 45.4815 23.703 45.4815Z"
      fill="#7C4DFF"
    />
  </svg>
);

import React, {
  createContext,
  SetStateAction,
  useContext,
  useState,
  Dispatch,
  useRef,
} from "react";

interface AudioContextProps {
  currentAudio: HTMLAudioElement | null;
  playAudio: (audio: HTMLAudioElement, messageId: number) => void;
  stopAudio: () => void;
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  currentMessageId: number | null;
  setCurrentMessageId: Dispatch<SetStateAction<number | null>>;
}

const AudioContext = createContext<AudioContextProps | undefined>(undefined);

export const AudioProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  
  const currentAudioRef = useRef<HTMLAudioElement | null>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentMessageId, setCurrentMessageId] = useState<number | null>(null);

  const handleAudioEnded = () => {
    setIsPlaying(false);
    currentAudioRef.current = null;
    setCurrentMessageId(null);
  };

  const playAudio = (audio: HTMLAudioElement, messageId: number) => {

    if (currentAudioRef.current && currentAudioRef.current !== audio) {
      currentAudioRef.current.pause(); // Stop currently playing audio
      // setCurrentMessageId(null);
      setIsPlaying(false);
     
    }

    audio.removeEventListener("ended", handleAudioEnded);
    audio.addEventListener("ended", handleAudioEnded); // Listen for when the audio finishes playing

    currentAudioRef.current = audio;

    audio
      .play()
      .then(() => {
        setIsPlaying(true);
        setCurrentMessageId(messageId);
      })
      .catch((err) => {
        console.error(err);
        setIsPlaying(false);
      });
  };

  const stopAudio = () => {
    if (currentAudioRef?.current) {
      setIsPlaying(false);
      currentAudioRef.current.pause();
      currentAudioRef.current.removeEventListener("ended", handleAudioEnded); // Clean up the listener
      currentAudioRef.current = null;
    }
  };

  return (
    <AudioContext.Provider
      value={{
        currentAudio: currentAudioRef.current,
        playAudio,
        stopAudio,
        isPlaying,
        setIsPlaying,
        currentMessageId,
        setCurrentMessageId,
      }}
    >
      {children}
    </AudioContext.Provider>
  );
};

export const useAudio = () => {
  const context = useContext(AudioContext);
  if (!context) {
    throw new Error("useAudio must be used within an AudioProvider");
  }
  return context;
};

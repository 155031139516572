import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "./TeamMdHead.module.scss";
import { useWindowSize } from "hooks/useWindowSize";
import { useSelector } from "redux/hooks";

interface IProps {
    invitation?: boolean;
}

export const TeamMdHead = ({ invitation }: IProps) => {
    const { width } = useWindowSize();
    const { theme } = useSelector((state) => state.authReducer);

    return (
        <>
            {(width <= 768 || (invitation && width <= 862)) && (
                <>
                    <div className={classNames(styles.MdNavhead,{
                        [styles.pendingInvite]: invitation,
                        [styles.light]: theme === 'light',
                        [styles.dark]: theme === 'dark', 
                    })}>
                        <div className={classNames(styles.MdText)}>
                            <h1 className={classNames(styles.title, {
                                [styles.dark]: theme === 'dark',
                                [styles.light]: theme === 'light',
                                [styles.pendingInvite] : invitation,
                            })}>
                                {invitation ? <FormattedMessage id="invite.teamMember.pending" /> : <FormattedMessage id="team.page.heading" />}
                            </h1>
                            {!invitation && (
                                <span className={classNames(styles.text, {
                                    [styles.dark]: theme === 'dark',
                                    [styles.light]: theme === 'light',
                                })}>
                                    <FormattedMessage id="team.page.subHeading" />
                                </span>
                            )}
                        </div>
                    </div>
                </>

            )}

        </>
    );
};

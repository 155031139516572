import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "../ModalStyle.module.scss";
import myStyles from "./ChangeRoleModal.module.scss";

import { CloseIcon } from "components/icons/CloseIcon";
import { ManagerIcon, UserIcon } from "../icons";

import { Modal, Separator } from "components";
import CustomButton from "components/Button";

import { UserRolesType, UserRoles, IMember } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  userRole?: UserRolesType;
  member?: IMember;
  onClose?: () => void;
  onSubmit?: (role?: UserRolesType) => void;
  submitLoading?: boolean;
}

export const ChangeRoleModal = ({
  member,
  onClose,
  onSubmit,
  submitLoading,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  const [role, setRole] = useState<UserRolesType | undefined>(undefined);

  useEffect(() => setRole(member?.role), [member]);

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.header}>
        <div className={styles.headerTextContainer}>
          <div className={styles.title}>
            <FormattedMessage id="modal.changeStatus.heading" />
          </div>
          <div className={styles.description}>
            <FormattedMessage
              id="modal.changeStatus.subHeading"
              values={{ userName: member?.name }}
            />
          </div>
        </div>
        <div data-testid='change-role-close-btn' className={styles.crossBtn} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <Separator height="30px" />
      <div
        className={classNames(myStyles.rolesContainer, {
          [myStyles.light]: theme === "light",
          [myStyles.dark]: theme === "dark",
        })}
      >
        <div
          className={classNames(myStyles.roleContainer, {
            [myStyles.active]: role === UserRoles.manager,
          })}
          onClick={() => setRole(UserRoles.manager)}
          data-testid='set-user-role-manager'
        >
          <ManagerIcon />
          <div className={myStyles.role}>
            <FormattedMessage id={`team.invite.option.${UserRoles.manager}`} />
          </div>
        </div>
        <div
          className={classNames(myStyles.roleContainer, {
            [myStyles.active]: role === UserRoles.member,
          })}
          onClick={() => setRole(UserRoles.member)}
          data-testid='set-user-role'
        >
          <UserIcon />
          <div className={myStyles.role}>
            <FormattedMessage id={`team.invite.option.${UserRoles.member}`} />
          </div>
        </div>
      </div>
      <Separator height="30px" />
      <div
        className={classNames(myStyles.footer, {
          [myStyles.light]: theme === "light",
          [myStyles.dark]: theme === "dark",
        })}
      >
        <CustomButton
          style={{ minWidth: "100px" }}
          variant="outlined-primary"
          type="button"
          onClick={onClose}
          data-testid='change-status-close-btn'
        >
          <div
            className={classNames(styles.buttonText, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="modal.changeStatus.btn.cancel" />
          </div>
        </CustomButton>
        <CustomButton
          style={{ minWidth: "100px" }}
          variant="primary"
          type="submit"
          isloading={submitLoading}
          onClick={() => onSubmit?.(role)}
          data-testid='change-status-submit-btn'
        >
          <FormattedMessage id="modal.changeStatus.btn.Submit" />
        </CustomButton>
      </div>
    </Modal>
  );
};

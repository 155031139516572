export const CostEffectivenessIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
  >
    <mask
      id="mask0_5173_54379"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="7"
      y="7"
      width="50"
      height="50"
    >
      <path d="M7.5625 7.55957H56.4585V56.4556H7.5625V7.55957Z" fill="white" />
    </mask>
    <g mask="url(#mask0_5173_54379)">
      <path
        d="M32.0105 7.55957C18.5305 7.55957 7.5625 18.5276 7.5625 32.0076C7.5625 45.4876 18.5305 56.4556 32.0105 56.4556C45.4905 56.4556 56.4585 45.4876 56.4585 32.0076C56.4585 18.5276 45.4905 7.55957 32.0105 7.55957ZM32.0105 54.4182C19.6532 54.4182 9.59983 44.3656 9.59983 32.0076C9.59983 19.6502 19.6532 9.5969 32.0105 9.5969C44.3685 9.5969 54.4212 19.6502 54.4212 32.0076C54.4212 44.3656 44.3685 54.4182 32.0105 54.4182Z"
        fill="#7C4DFF"
      />
    </g>
    <path
      d="M29.9705 19.7838H34.0452C36.8538 19.7838 39.1385 22.0691 39.1385 24.8771H41.1758C41.1758 20.9451 37.9765 17.7464 34.0452 17.7464H33.0265V14.6904H30.9892V17.7464H29.9705C26.0398 17.7464 22.8398 20.9451 22.8398 24.8771V25.8958C22.8398 29.8278 26.0398 33.0264 29.9705 33.0264H34.0452C36.8538 33.0264 39.1385 35.3118 39.1385 38.1198V39.1384C39.1385 41.9471 36.8538 44.2318 34.0452 44.2318H29.9705C27.1625 44.2318 24.8772 41.9471 24.8772 39.1384H22.8398C22.8398 43.0704 26.0398 46.2691 29.9705 46.2691H30.9892V49.3251H33.0265V46.2691H34.0452C37.9765 46.2691 41.1758 43.0704 41.1758 39.1384V38.1198C41.1758 34.1878 37.9765 30.9891 34.0452 30.9891H29.9705C27.1625 30.9891 24.8772 28.7044 24.8772 25.8958V24.8771C24.8772 22.0691 27.1625 19.7838 29.9705 19.7838Z"
      fill="#7C4DFF"
    />
  </svg>
);
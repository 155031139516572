interface IProps {
  color?: string;
  closeDoc?: boolean;
}

export const CloseIcon = ({ color, closeDoc }: IProps) => {
  return (
    <svg
      width={closeDoc? "16":"22"}
      height={closeDoc? "16":"22"}
      viewBox="0 0 22 22"
      // fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={color}
    >
      <path
        d="M5.70624 17.2563L4.74374 16.2938L10.0375 11L4.74374 5.70627L5.70624 4.74377L11 10.0375L16.2937 4.74377L17.2562 5.70627L11.9625 11L17.2562 16.2938L16.2937 17.2563L11 11.9625L5.70624 17.2563Z"
        fill="currentColor"
      />
    </svg>
  );
};

import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import styles from "../../chatFooter.module.scss";
import { useSelector } from "redux/hooks";
import { RememberBot, SetChatSetting, setRememberSetting } from "redux/actions";

const RememberBotSetting = () => {
  const { theme } = useSelector((state) => state.authReducer);
  const { RememberSetting } = useSelector((state) => state.chatReducer);

  const handlecheckbox = (isChecked: boolean) => {
    setRememberSetting(isChecked);
    const data: RememberBot = {
      key: "chat_settings",
      customized_response: isChecked,
    };
     window.postMessage({ settings: data });
    SetChatSetting(data);
  };

  return (
    <div
      className={classNames(styles.RememberSetting, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
    >
      <div className={styles.check}>
        <input
          type="checkbox"
          className={classNames({
            [styles.lightCheckbox]: theme === "light",
            [styles.darkCheckbox]: theme === "dark",
          })}
          checked={RememberSetting}
          onChange={(e) => handlecheckbox(e.target.checked)}
        />
      </div>
      <span className="font-medium">
        {" "}
        <FormattedMessage id="RememberSettings" />
      </span>
    </div>
  );
};

export default RememberBotSetting;

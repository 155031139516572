import { FormattedMessage } from "react-intl";
import styles from "./upscaleErrorModal.module.scss";

import { Modal } from "components";
import Button from "components/Button";

interface IProps {
  onClose: () => void;
  message: string;
}

export const UpscaleErrorModal = ({ onClose, message }: IProps) => {
  return (
    <Modal size="md" onClose={onClose}>
      <div className={styles.container}>
        <div className={styles.message}>{message}</div>
        <Button variant="primary">
          <div data-testid='upscale-error-close-btn' className={styles.buttonText} onClick={onClose}>
            <FormattedMessage id="upscale.err.modal.btnText" />
          </div>
        </Button>
      </div>
    </Modal>
  );
};

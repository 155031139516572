import { Fragment, useState, useEffect } from "react";
import { useIntl } from "react-intl";

import { LazyImage } from "./lazyImage";

import {
  upscaleImage,
  updateCredits,
  updateCreditLimit,
  setShowUpscaleModal,
  IImage,
} from "redux/actions";
import { useSelector } from "redux/hooks";

import useRouter from "hooks/useRouter";

interface IProps {
  messageId: number;
  chatId: number;
  images: IImage[];
  initialIndex: number;
}

export interface IUpscaleErrorModal {
  show: boolean;
  message: string;
}

export const Image = ({ messageId, chatId, images, initialIndex }: IProps) => {
  const { pathname } = useRouter();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const chatIdFromURL = pathname.split("/")[3];
  const { formatMessage } = useIntl();

  const [message, setMessage] = useState<string>("");
  useEffect(() => {
    const intervalID = setTimeout(() => {
      if (message) setMessage("");
    }, 3000);

    return () => clearInterval(intervalID);
  }, [message]);

  const [currentImage, setCurrentImage] = useState<IImage>(
    images[initialIndex]
  );
  const [currentImageIndex, setCurrentImageIndex] =
    useState<number>(initialIndex);

  useEffect(() => {
    setCurrentImageIndex(initialIndex);
    setCurrentImage(images[initialIndex]);
  }, [initialIndex, images]);

  const [upscaleErrorModal, setUpscaleErrorModal] =
    useState<IUpscaleErrorModal>({
      show: false,
      message: "",
    });
  const onCloseUpscaleErrorModal = () => {
    setUpscaleErrorModal({ show: false, message: "" });
  };

  const [upscaleLoading, setUpscaleLoading] = useState<boolean>(false);
  const [upscaleModal, setUpscaleModal] = useState<boolean>(false);
  const toggleUpscaleModal = () => {
    setUpscaleModal((prev) => !prev);
  };

  const onSwap = () => {
    let prevIndex = currentImageIndex - 1;
    if (images[prevIndex]) {
      setCurrentImage(images[prevIndex]);
      setCurrentImageIndex(prevIndex);
      setMessage(
        formatMessage(
          { id: "upscale.swap.message" },
          { size: images[prevIndex].size }
        )
      );
    } else {
      setCurrentImage(images[initialIndex]);
      setCurrentImageIndex(initialIndex);
      setMessage(
        formatMessage(
          { id: "upscale.swap.message" },
          { size: images[initialIndex].size }
        )
      );
    }
  };

  const onUpscale = (imageId: number) => {
    setUpscaleLoading(true);
    upscaleImage({
      messageViewType: chatIdFromURL ? "history" : "new",
      message_id: messageId,
      chat_id: chatId,
      image_id: imageId,
    })
      .then((res: any) => {
        setMessage(
          formatMessage(
            { id: "upscale.success.message" },
            { size: res.image.size }
          )
        );
        setUpscaleModal(false);
        if (userDetail?.user.team.role === "admin") {
          updateCredits(res?.credits);
        } else {
          updateCreditLimit({
            daily_limit: res?.daily_limit,
            used_today: res?.used_today,
          });
        }
        setUpscaleLoading(false);
        setShowUpscaleModal({ showUpscaleModal: "hide" });
      })
      .catch((err) => {
        setUpscaleErrorModal({ show: true, message: err?.data?.message });
        setUpscaleModal(false);
        setUpscaleLoading(false);
      });
  };

  const onDownload = (path: string) => {
    const downloadLink = document.createElement("a");
    downloadLink.href = path;
    downloadLink.download = "downloaded_image.jpg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Fragment>
      {images.map(
        (image) =>
          currentImage.id === image.id && (
            <LazyImage
              key={`lazy-image ${image.id}`}
              message={message}
              images={images}
              image={image}
              onDownload={onDownload}
              onSwap={onSwap}
              upscaleModal={upscaleModal}
              toggleUpscaleModal={toggleUpscaleModal}
              upscaleLoading={upscaleLoading}
              onUpscale={onUpscale}
              upscaleErrorModal={upscaleErrorModal}
              onCloseUpscaleErrorModal={onCloseUpscaleErrorModal}
            />
          )
      )}
    </Fragment>
  );
};

export const ExplorationIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    className={className}
  >
    <mask
      id="mask0_5173_54360"
      style={{ maskType: "luminance" }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="6"
      width="52"
      height="52"
    >
      <path
        d="M6.39844 6.40039H57.5984V57.6004H6.39844V6.40039Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_5173_54360)">
      <path
        d="M32.0265 6.42383C17.9079 6.42383 6.42188 17.9105 6.42188 32.0292C6.42188 46.1472 17.9079 57.6338 32.0265 57.6338C46.1445 57.6338 57.6312 46.1472 57.6312 32.0292C57.6312 17.9105 46.1445 6.42383 32.0265 6.42383ZM32.0265 55.3558C19.1645 55.3558 8.69988 44.8918 8.69988 32.0292C8.69988 19.1665 19.1645 8.70183 32.0265 8.70183C44.8885 8.70183 55.3525 19.1665 55.3525 32.0292C55.3525 44.8918 44.8885 55.3558 32.0265 55.3558Z"
        fill="#7C4DFF"
      />
    </g>
    <path
      d="M32.0284 9.54004C19.6277 9.54004 9.53906 19.628 9.53906 32.0287C9.53906 44.4287 19.6277 54.5174 32.0284 54.5174C44.4291 54.5174 54.5177 44.4287 54.5177 32.0287C54.5177 19.628 44.4284 9.54004 32.0284 9.54004ZM32.0284 52.2394C20.8837 52.2394 11.8171 43.1727 11.8171 32.0294C11.8171 20.8854 20.8837 11.818 32.0284 11.818C43.1724 11.818 52.2391 20.8847 52.2391 32.0287C52.2391 43.172 43.1724 52.2394 32.0284 52.2394Z"
      fill="#7C4DFF"
    />
    <path
      d="M45.1563 18.901C44.7936 18.5377 44.231 18.463 43.7863 18.717L28.0963 27.6717C27.919 27.7737 27.773 27.9203 27.671 28.097L18.7163 43.787C18.4616 44.233 18.537 44.7943 18.9003 45.157C19.1196 45.377 19.411 45.4903 19.7056 45.4903C19.899 45.4903 20.0936 45.4417 20.2703 45.341L35.961 36.3857C36.1376 36.2843 36.2843 36.137 36.3856 35.961L45.341 20.2703C45.595 19.8243 45.5203 19.2637 45.157 18.901H45.1563ZM28.9103 30.521L33.5356 35.1463L22.761 41.2963L28.9103 30.521ZM35.1463 33.5357L30.521 28.911L41.2956 22.761L35.147 33.5357H35.1463Z"
      fill="#7C4DFF"
    />
    <path
      d="M32.03 19.5339C32.6586 19.5339 33.1686 19.0239 33.1686 18.3953V14.4333C33.1686 13.8046 32.6586 13.2939 32.03 13.2939C31.4013 13.2939 30.8906 13.8046 30.8906 14.4333V18.3953C30.8906 19.0239 31.4013 19.5339 32.03 19.5339Z"
      fill="#7C4DFF"
    />
    <path
      d="M32.03 44.5225C31.4013 44.5225 30.8906 45.0325 30.8906 45.6618V49.6238C30.8906 50.2525 31.4013 50.7625 32.03 50.7625C32.6586 50.7625 33.1686 50.2525 33.1686 49.6238V45.6618C33.1686 45.0325 32.6586 44.5225 32.03 44.5225Z"
      fill="#7C4DFF"
    />
    <path
      d="M49.6254 30.8896H45.6628C45.0334 30.8896 44.5234 31.4003 44.5234 32.029C44.5234 32.6576 45.0334 33.1676 45.6628 33.1676H49.6254C50.2541 33.1676 50.7641 32.6576 50.7641 32.029C50.7641 31.4003 50.2541 30.8896 49.6254 30.8896Z"
      fill="#7C4DFF"
    />
    <path
      d="M19.5336 32.029C19.5336 31.4003 19.0236 30.8896 18.395 30.8896H14.4323C13.8036 30.8896 13.293 31.4003 13.293 32.029C13.293 32.6576 13.8036 33.1676 14.4323 33.1676H18.395C19.0236 33.1676 19.5336 32.6576 19.5336 32.029Z"
      fill="#7C4DFF"
    />
    <path
      d="M42.4754 40.8638C42.0314 40.4191 41.3094 40.4191 40.8648 40.8638C40.4201 41.3084 40.4201 42.0298 40.8648 42.4744L43.6668 45.2764C43.8888 45.4991 44.1808 45.6104 44.4721 45.6104C44.7641 45.6104 45.0554 45.4991 45.2774 45.2764C45.7221 44.8318 45.7221 44.1104 45.2774 43.6658L42.4754 40.8638Z"
      fill="#7C4DFF"
    />
    <path
      d="M21.5808 23.1934C21.8028 23.4161 22.0948 23.5274 22.3861 23.5274C22.6781 23.5274 22.9695 23.4161 23.1915 23.1934C23.6361 22.7488 23.6361 22.0281 23.1915 21.5828L20.3895 18.7808C19.9455 18.3361 19.2235 18.3361 18.7788 18.7808C18.3341 19.2254 18.3341 19.9468 18.7788 20.3914L21.5808 23.1934Z"
      fill="#7C4DFF"
    />
  </svg>
);
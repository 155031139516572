import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { RoutePaths } from "../../routePaths";
import { useSelector } from "redux/hooks";

export const ReferralPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const {userDetail} = useSelector((state) => state.authReducer);
  const lang = userDetail?.user?.language? userDetail?.user?.language: "en";

  useEffect(() => {
    if (token) {
      localStorage.setItem("referralToken", token);
      navigate(`/${lang}/${RoutePaths.Signup}`);
    }
    navigate(`/${lang}/${RoutePaths.Signup}`);
  }, [token]);

  return <></>;
};

import { PriceCard } from "../components/PriceCard";
import { PriceSwitch } from "../components/PriceSwitch";

import { EPlanDuration, IPlan, IUserDetail } from "redux/actions";
import { EThemeType } from "redux/reducers";

interface IProps {
  isLoggedIn?: boolean;
  isSwitch?: boolean;
  plans: IPlan[];
  userDetail?: IUserDetail;
  theme?: EThemeType;
  duration?: EPlanDuration;
  requiredPlan?: IPlan;
  upgradePlanLoading?: boolean;
  onUpgradePlan?: (selectedPlan: IPlan) => void;
  onChange?: (type?: EPlanDuration) => void;
}

export const MobilePricing = ({
  isLoggedIn,
  isSwitch,
  plans,
  userDetail,
  theme,
  duration,
  requiredPlan,
  upgradePlanLoading,
  onUpgradePlan,
  onChange,
}: IProps) => {
  return (
    <div className="block lg:hidden">
      {isSwitch && <PriceSwitch duration={duration} onChange={onChange} />}
      {plans
        .filter((item) => {
          if (item.name === "Free") {
            return item;
          }
          if (item.duration === "month" && duration === "month") {
            return item;
          }
          if (item.duration === "year" && duration === "year") {
            return item;
          }
        })
        .map((planItem, index) => (
          <PriceCard
            key={`price-${duration}-${index}`}
            isLoggedIn={isLoggedIn}
            planItem={planItem}
            userDetail={userDetail}
            theme={theme}
            requiredPlan={requiredPlan}
            upgradePlanLoading={upgradePlanLoading}
            onUpgradePlan={onUpgradePlan}
          />
        ))}
    </div>
  );
};

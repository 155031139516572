import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import styled from "styled-components";
import classNames from "classnames";
import styles from "./PriceCard.module.scss";

import { PriceLoadMoreIcon } from "../Icons";
import { CheckedIcon } from "../CheckedIcon";
import { RoutePaths } from "pages/routePaths";

import { IterFont } from "../IterFont";
import { planTable } from "pages/SettingsPage/pages/CurrentPlan/components/PricingTable/PricingTable";
import CutsomButton from "components/Button";

import useRouter from "hooks/useRouter";
import { IPlan, IUserDetail } from "redux/actions";
import { EThemeType } from "redux/reducers";

interface IProps {
  isLoggedIn?: boolean;
  planItem: IPlan;
  userDetail?: IUserDetail;
  theme?: EThemeType;
  requiredPlan?: IPlan;
  upgradePlanLoading?: boolean;
  onUpgradePlan?: (selectedPlan: IPlan) => void;
}

interface IplanTableCol {
  label: React.ReactNode;
  value?: string | React.ReactNode;
  icon?: boolean;
}

const Button = styled(CutsomButton)`
  font-size: 14px;
  padding: 4px 12px;
`;

export const PriceCard = ({
  isLoggedIn,
  planItem,
  userDetail,
  theme,
  requiredPlan,
  upgradePlanLoading,
  onUpgradePlan,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { push } = useRouter();
  let planTableCols: IplanTableCol[] = [];
  const planName = planItem.name.toLocaleLowerCase();
  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  const [show, setShow] = useState<boolean>(false);

  planTable.forEach((planTableRow) => {
    let obj: IplanTableCol = {
      label: planTableRow.planName,
      value: "",
      icon: false,
    };
    for (let key in planTableRow) {
      if (key.includes(planName)) {
        if (key === planName) obj.icon = planTableRow[key];
        else obj.value = planTableRow[key];
      }
    }
    planTableCols.push(obj);
  });
  return (
    <>
      <div className="flex items-center justify-between w-full pb-6 pt-20">
        <div className="flex flex-col flex-1">
          <span className={theme === "dark" ? styles.setFrequencydark : styles.setFrequency}
          >
            {planItem.name === "Free" ? (
              <FormattedMessage id="landing.price.table.freeforever" />
            ) : (
              <FormattedMessage id={`plan.type.${planItem.name}`} />
            )}
          </span>
          <span
            className={classNames("my-4 text-4xl",
              theme === "dark" ? styles.setFrequencydark : styles.setFrequency
            )}
          >
            <IterFont>
              {planItem.name === "Free" ? (
                <FormattedMessage id="plan.type.Free" />
              ) : (
                `$${planItem.discounted_price}/m`
              )}
            </IterFont>
          </span>
          <div className="w-full flex items-center  max-w-[130px]">
            <span
              className={classNames("opacity-70",
                theme === "dark" ? styles.setFrequencydark : styles.setFrequency
              )}
            >
              <FormattedMessage id="landing.price.credits" />
            </span>
            <span
              className={classNames("font-bold",
                theme === "dark" ? styles.setFrequencydark : styles.setFrequency
              )}
            >
              <IterFont>
                <span className="font-semibold ml-1">
                  {`${planItem.credits}/${planItem.name === "Free"
                      ? formatMessage({
                        id: "landing.price.table.duration.day",
                      })
                      : formatMessage({
                        id: "landing.price.table.duration.month",
                      })
                    }`}
                </span>
              </IterFont>
            </span>
          </div>
        </div>
        {!isLoggedIn && (
          <div className="flex flex-1 items-start justify-end">
            {userDetail?.user?.activeSubscription.name === planItem.name &&
              userDetail?.user?.activeSubscription.duration ===
              planItem.duration ? (
              <Button variant="outlined" data-testid={planItem.name}>
                <span className={theme === "dark" ? styles.setFrequencydark : styles.setFrequency}
                >
                  <FormattedMessage id="setting.plan.selected" />
                </span>
              </Button>
            ) : planItem.name === "Free" ? (
              <Button
                type="button"
                variant="primary"
                onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                data-testid={planItem.name}
              >
                <FormattedMessage id="landing.price.tryForFree" />
              </Button>
            ) : (
              <Button
                type="button"
                variant="primary"
                onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                data-testid={planItem.name}
              >
                <FormattedMessage id="landing.price.getStarted" />
              </Button>
            )}
          </div>
        )}
        {isLoggedIn && (
          <div className="flex flex-1 items-start justify-end">
            {planItem.name === userDetail?.user.activeSubscription.name &&
              planItem.duration ===
              userDetail.user.activeSubscription.duration ? (
              <Button variant="outlined" data-testid={planItem.name}>
                <span className={theme === "dark" ? styles.setFrequencydark : styles.setFrequency}>
                  <FormattedMessage id="setting.plan.selected" />
                </span>
              </Button>
            ) : (
              <Button
                variant="primary"
                isloading={
                  planItem.id === requiredPlan?.id && upgradePlanLoading
                }
                onClick={() => {
                  if (!upgradePlanLoading) onUpgradePlan?.(planItem);
                }}
                data-testid={planItem.name}
              >
                <FormattedMessage id="setting.plan.changePlan" />
              </Button>
            )}
          </div>
        )}
      </div>
      <div className={theme === 'dark' ? styles.Pricecard : styles.Pricecardwhite}>
        {planTableCols.map((item, index) => {
          if (show || (!show && index < 2)) {
            return (
              <div
                className="flex items-center justify-between mb-4 "
                key={`rows-${index}`}
              >
                <span className={theme === 'dark' ? styles.pricelable : styles.Pricelablewhite}>{item.label}</span>
                <span
                  className={classNames(styles.text, {
                    [styles.dark]: theme === "dark",
                    [styles.light]: theme === "light",
                  })}
                >
                  {item.icon ? <CheckedIcon /> : item.value}
                </span>
              </div>
            );
          }
          return null;
        })}

        <div
          className={classNames(
            "flex items-center justify-center ",
            // styles.separator,
            {
              [styles.dark]: theme === "dark",
              [styles.light]: theme === "light",
            }
          )}
        >
          {!show ? (
            <div
              className={classNames(
                "flex items-center justify-center ",
                styles.text,
                {
                  [styles.dark]: theme === "dark",
                  [styles.light]: theme === "light",
                }
              )}
              onClick={() => setShow(true)}
              data-testid={`${planItem.name}-more-detail`}
            >
              <span className="text-base inline-block mr-2">
                <FormattedMessage id="landing.price.table.moreDetails" />
              </span>
              <PriceLoadMoreIcon themecolor={theme} />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

import { Modal } from "components/Modal";
import { FormattedMessage } from "react-intl";
import styles from './DeleteConfirm.module.scss'
import Button from "components/Button";
import { Separator } from "components";

interface IProp{
    onSubmit: () => void;
    onCancel: () => void;
    loading: boolean;
    delAccount?: boolean;
    delConversation?: boolean;
}
export const DeleteChatConfirm = ({onCancel, onSubmit, loading, delAccount, delConversation}: IProp) => {

    return (
        <Modal size="md" onClose={onCancel} >
            <div className="py-[20px] px-[30px] text-light dark:text-prompt-dark">
                <div className={`text-center leading-[20px] font-bold py-2  ${styles.textheadSize}`}>
                    <FormattedMessage id={delAccount? "delete.user.Account":delConversation? "delete.all.conversation" : "chat.delete.selected.heading"} />
                </div>
                <div className={`text-center pt-[20px] ${styles.textSize}`}>
                    <FormattedMessage id={delAccount? "delete.user.Account.title" : delConversation? "delete.all.conversation.heading" : "chat.delete.selected.confirm"} />
                </div>
                    <Separator height="40px" />
                    <div className={styles.footer}>
                        <Button data-testid='close-btn' variant="cancel" onClick={onCancel}>
                            <div className={`${styles.buttonText} ${styles.btnpadding} px-[20px]`} >
                                <FormattedMessage id="creditLimit.modal.cancelBtn.text" />
                            </div>
                        </Button>
                        <Button
                            variant="delete"
                            onClick={onSubmit}
                            isloading={loading}
                            data-testid='submit-btn'
                        >
                            <div className={styles.btnpadding}>
                            <FormattedMessage id="modal.deleteMember.btn.delete" />
                            </div>
                        </Button>
                </div>
            </div>

        </Modal>
    );
};

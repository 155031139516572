import styled from "styled-components";
import CutsomButton from "../../../components/Button";
import { FormattedMessage, useIntl } from "react-intl";

const Section = styled.section`
  padding: 60px 15px 0 15px;
  @media screen and (min-width: 769px) {
    padding: 75px 15px;
    padding: 130px 5%;
    background: radial-gradient(
        52.49% 21.62% at 51.74% 100%,
        rgba(82, 0, 255, 0.2) 0%,
        rgba(16, 17, 19, 0.2) 100%
      ),
      #101113;
  }

  @media screen and (min-width: 1440px) {
    padding: 130px 10%;
  }
`;

const SectionHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const H2 = styled.h2`
  text-align: center;
  margin: 0 auto 6px auto;
  font-size: 24px;
  font-weight: 700;

  @media screen and (min-width: 769px) {
    font-size: 40px;
    // text-align: left;
  }
`;
const Text = styled.p`
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  line-height: 26px;
  font-size: 16px;
  padding: 0 15px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 70px;
  @media screen and (min-width: 769px) {
    flex-direction: row;
    padding: 75px 0 20px 0;
    gap: 90px;
  }
`;
const Col = styled.div`
  padding-right: 0px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &:last-child {
    padding-right: 0;
    margin-bottom: 0;
  }
`;
const ImageBox = styled.div`
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  max-width: 90%;
  margin-bottom: 40px;
  @media screen and (min-width: 769px) {
    width: 100%;
  }
`;

const Circle = styled.div`
  top: 14px;
  left: 14px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #141517;
  border-radius: 300px;
  width: 36px;
  height: 36px;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Inter", sans-serif;
`;

const Title = styled.h4`
  font-size: 20px;
  margin-bottom: 12px;
`;

const Content = styled.p`
  color: rgba(255, 255, 255, 0.7);
  line-height: 26px;
  font-size: 14px;
  padding: 0 20px;
  text-align: center;

  @media screen and (min-width: 769px) {
    padding: 0;
  }
`;

const Icon = styled.span`
  display: flex;
  align-items: center;
  margin-left: 8px;
  width: 32px;
  height: 32px;
`;

const LinkRow = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: 70px;
  @media screen and (min-width: 769px) {
    display: flex;
  }
`;

export const Install = () => {
  const { formatMessage } = useIntl();

  return (
    <Section>
      <SectionHead>
        <div>
          <H2>
            <FormattedMessage id="landing.install.title" />
          </H2>
          <Text>
            <FormattedMessage id="landing.install.desc" />
          </Text>
        </div>
      </SectionHead>

      <Row>
        <Col>
          <ImageBox>
            <img src="/step-img-1.png" alt="" />
            <Circle>1</Circle>
          </ImageBox>
          <Title>
            <FormattedMessage id="landing.install.goTo" />
          </Title>
          <Content>
            <FormattedMessage id="landing.install.goTo.desc" />
          </Content>
        </Col>
        <Col>
          <ImageBox>
            <Circle>2</Circle>
            <img src="/step-img-2.png" alt="" />
          </ImageBox>
          <Title>
            <FormattedMessage id="landing.install.search" />
          </Title>
          <Content>
            <FormattedMessage id="landing.install.search.desc" />
          </Content>
        </Col>

        <Col>
          <ImageBox>
            <Circle>3</Circle>
            <img src="/step-img-3.png" alt="" />
          </ImageBox>
          <Title>
            <FormattedMessage id="landing.install.install" />
          </Title>
          <Content>
            <FormattedMessage id="landing.install.install.desc" />
          </Content>
        </Col>
      </Row>

      <LinkRow>
        <a
          href="https://chrome.google.com/webstore/detail/deftgpt-ai-chat-gpt-writi/ofeanjpmjdifbpdcjakglfiphhdibokg?hl=en&authuser=0"
          target="_blank"
          rel="noreferrer"
        >
          <CutsomButton variant="primary">
            <span>
              <FormattedMessage id="landing.button.addToChrome" />
            </span>
            <Icon>
              <img
                src="/icons/chrome-icon.svg"
                alt={formatMessage({ id: "landing.button.addToChrome" })}
              />
            </Icon>
          </CutsomButton>
        </a>
      </LinkRow>
    </Section>
  );
};

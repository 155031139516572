import EditIcon from "pages/ChatPage/pages/chatHistory/components/icons/EditIcon";
import { ArrowRightIcon } from "../Icons";

import { IPrompt, deleteUserPRompts } from "redux/actions";
import DeleteIcon from "pages/ChatPage/pages/chatHistory/components/icons/DeleteIcon";
import CheveronDown from "pages/ChatPage/pages/chatHistory/components/icons/CheveronDown";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useAppNotification } from "hooks/services/AppNotification";
import { Spinner } from "components";
import styles from "./Prompt.module.scss";

interface IProps {
  prompt: IPrompt;
  onSelect: (prompt: string) => void;
  dataTestId: string;
  setEditPrompt?: React.Dispatch<React.SetStateAction<number | null>>;
  setAllowEditPrompt?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PromptItem = ({ prompt, onSelect, dataTestId, setEditPrompt, setAllowEditPrompt }: IProps) => {
  const { theme } = useSelector(
    (state: any) => state.authReducer
  );
  const { triggerNotification } = useAppNotification();
  const [loading, setIsLoading] = useState<boolean>(false);

  const DeletePrompt = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>,id: number) => {
    e.stopPropagation();
    setIsLoading(true)
    deleteUserPRompts(id)
      .then(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  }

  const handleEditClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, id: number) => {
    e.stopPropagation();
    setAllowEditPrompt!(true);
    setEditPrompt!(id);
  };

  return (
    <div
      className={`flex flex-row items-center text-lg animate-all duration-200 ease-in-out hover:rounded-md hover:cursor-pointer hover:text-primary hover:bg-[rgba(124,77,255,0.08)] px-5 py-2`}
      onClick={() => onSelect(prompt?.prompt)}
      data-testid={dataTestId}
    >
      <span className="text-primary flex items-center flex-row mr-3">
        <ArrowRightIcon />
      </span>
      <span className={`${styles.Community} ${" flex-1 font-medium whitespace-nowrap overflow-hidden text-ellipsis opacity-90"}`}>
        <span className="flex justify-between items-center gap-2 pr-4"> <b className="w-4/5 overflow-hidden text-ellipsis">{prompt.title}</b>
          {dataTestId === 'user-prompts' &&
            <span className="flex items-center gap-2"><span className={classNames("", {
              "stroke-[#2A2831]": theme === "light",
              "stroke-white": theme === "dark",
            })} onClick={(e)=>handleEditClick(e,prompt.id)}><EditIcon /></span>
            {loading? <Spinner extraSmall/>:
              <span className={classNames("", {
                "stroke-[#15141E]": theme === "light",
                "stroke-white": theme === "dark",
              })} onClick={(e) => DeletePrompt(e,prompt.id)} ><DeleteIcon /></span>
            }</span>
          }
        </span>
        <div className=" flex-1 font-medium whitespace-nowrap overflow-hidden text-ellipsis">
          {prompt.prompt}
        </div>
      </span>
      <span className={classNames("", {
        "!fill-black": theme === "light",
        "!fill-white": theme === "dark",
      })}><CheveronDown /></span>

    </div>
  );
};

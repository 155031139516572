export const TrendingProductIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="78"
    height="78"
    viewBox="0 0 78 78"
    fill="none"
  >
    <path
      d="M23.5464 48.04L33.2741 35.3969L44.3702 44.1132L53.8896 31.8271"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <ellipse
      cx="64.9849"
      cy="13.6485"
      rx="6.24714"
      ry="6.24714"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M48.5053 10.1372H24.8851C15.0979 10.1372 9.0293 17.0686 9.0293 26.8557V53.1235C9.0293 62.9107 14.979 69.8123 24.8851 69.8123H52.8485C62.6357 69.8123 68.7044 62.9107 68.7044 53.1235V30.247"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

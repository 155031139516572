import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";
import { FormattedMessage } from "react-intl";
import "rc-dropdown/assets/index.css";
import classNames from "classnames";
import styles from "../Header.module.scss";
import { EThemeType } from "redux/reducers";
import { NavRoutesProps } from "../Header";
import { ArrowIcon } from "../Icons";
import { Link } from "react-router-dom";
import { IUserDetail } from "redux/actions";

interface Iprops {
  theme?: EThemeType;
  activeSection: string | null;
  nav: NavRoutesProps;
  userDetail?: IUserDetail;
}

export const FeatureDropdown: React.FC<Iprops> = ({
  theme,
  activeSection,
  nav,
  userDetail,
}) => {
  const lightThemeStyles = {
    backgroundColor: "#fff",
    border: "1px solid rgba(220, 226, 236, 0.20)",
    boxShadow:
      "301px 301px 119px 0px rgba(68, 64, 79, 0.00), 192px 192px 109px 0px rgba(68, 64, 79, 0.01), 108px 108px 92px 0px rgba(68, 64, 79, 0.05), 48px 48px 68px 0px rgba(68, 64, 79, 0.09), 12px 12px 37px 0px rgba(68, 64, 79, 0.10)",
  };

  const darkThemeStyles = {
    backgroundColor: "#2A2831",
    border: "none",
    boxShadow:
      "0px 143px 40px 0px rgba(0, 0, 0, 0.00), 0px 92px 37px 0px rgba(0, 0, 0, 0.01), 0px 52px 31px 0px rgba(0, 0, 0, 0.05), 0px 23px 23px 0px rgba(0, 0, 0, 0.09), 0px 6px 13px 0px rgba(0, 0, 0, 0.10)",
  };

  const commonStyles = {
    padding: "8px 0",
    borderRadius: "10px",
  };

  const menuStyles = theme === "light" ? lightThemeStyles : darkThemeStyles;

  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  if (!nav.subRoutes) {
    return null;
  }
  const menu = (
    <Menu style={{ ...commonStyles, ...menuStyles }}>
      {nav.subRoutes.map((item, index) => (
        <Link
          to={userDetail?.token ? item.path : `/${lang}${item.path}`}
          key={index}
        >
          <MenuItem
            style={{
              padding: "6px 16px",
            }}
            className={classNames(
              "cursor-pointer hover:bg-gradient-to-right hover:bg-clip-text hover:text-transparent dark:hover:bg-gradient-to-right dark:hover:bg-clip-text dark:hover:text-transparent font-medium rounded-sm text-[16px] w-fit font-Raleway",
              {
                "text-light dark:text-dark": activeSection !== item.id,
                "bg-gradient-to-right bg-clip-text text-transparent dark:bg-transparent dark:bg-clip-text dark:text-transparent":
                  activeSection === item.id,
              }
            )}
          >
            <FormattedMessage id={item.name} />
          </MenuItem>
        </Link>
      ))}
    </Menu>
  );

  return (
    //visible
    <Dropdown overlay={menu} trigger={["hover"]}>
      <div
        className={classNames(styles.header__link, {
          [styles.header__link_dark]: theme === "dark",
          [styles.header__link_light]: theme === "light",
          [styles.active]: activeSection === nav.id,
        })}
        data-target={nav.id}
        data-testid={nav.dataTestID}
      >
        <FormattedMessage id={nav.name} />
        <span className="flex items-start">
          <ArrowIcon />
        </span>
      </div>
    </Dropdown>
  );
};

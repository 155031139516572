import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./BuyCreditsModal.module.scss";

import { CrossIcon } from "../icons";

import { Modal, Separator, LoadingPage } from "components";
import Button from "components/Button";
import { CreditItem } from "../CreditItem";

import { ICredit } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  credit?: ICredit;
  onSetCredit?: (credit: ICredit) => void;
  credits?: ICredit[];
  creditsLoading?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  isConfirmLoading?: boolean;
  onClose?: () => void;
}

export const BuyCreditModal = ({
  credit,
  onSetCredit,
  credits,
  creditsLoading,
  onCancel,
  onConfirm,
  isConfirmLoading,
  onClose,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.modalHeader}>
        <div className={styles.heading}>
          <div className={styles.headline}>
            <FormattedMessage id="buyCredit.modal.headline" />
          </div>
          <div className={styles.subHealine}>
            <FormattedMessage id="buyCredit.modal.subHeadline" />
          </div>
        </div>
        <div className={styles.icon} onClick={onClose}>
          <CrossIcon />
        </div>
      </div>
      <Separator height="30px" />
      <div className={styles.modalBody}>
        {creditsLoading ? (
          <LoadingPage />
        ) : (
          credits?.map((item, index) => (
            <>
              <CreditItem
                creditItem={item}
                isSelected={item.id === credit?.id}
                onSelect={() => onSetCredit?.(item)}
              />
              {credits?.length !== index + 1 && <Separator height="16px" />}
            </>
          ))
        )}
      </div>
      <Separator height="30px" />
      <div className={styles.modalFooter}>
        <Button data-testid='buy-credits-modal-cancel-btn' variant="outlined-primary" onClick={onCancel}>
          <div
            className={classNames(styles.buttonText, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="buyCredit.modal.cancelBtn.text" />
          </div>
        </Button>
        <Button
          variant="primary"
          onClick={onConfirm}
          disabled={credit?.id ? false : true}
          isloading={isConfirmLoading}
          data-testid='buy-credits-buy-btn'
        >
          <FormattedMessage id="buyCredit.modal.buyCredit.text" />
        </Button>
      </div>
    </Modal>
  );
};

interface IProps {
  className?: string;
}

export const Plan = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.2002 4.5V6.375"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1551 4.04505C24.5485 8.43845 24.5485 15.5616 20.1551 19.9549C15.7617 24.3483 8.63862 24.3483 4.24525 19.9549C-0.148155 15.5615 -0.148155 8.43842 4.24525 4.04505C8.63865 -0.34835 15.7617 -0.34835 20.1551 4.04505"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.2002 19.5V17.625"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4502 14.8188V14.8188C8.4502 16.3725 9.70895 17.6313 11.2627 17.6313H13.3164C14.7702 17.6313 15.9502 16.4525 15.9502 14.9975V14.9975C15.9502 13.79 15.1289 12.7375 13.9577 12.4438L10.4427 11.5625C9.27145 11.2688 8.4502 10.2163 8.4502 9.00875V9.00875C8.4502 7.55375 9.62895 6.375 11.0839 6.375H13.1377C14.6914 6.375 15.9502 7.63375 15.9502 9.1875V9.1875"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

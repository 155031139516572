import en from "./en.json";
import zh from "./zh.json";
import fr from "./fr.json";
import de from "./de.json";
import pt from "./pt.json";
import es from "./es.json";
import cs from "./cs.json";
import it from "./it.json";
import ja from "./ja.json";
import ru from "./ru.json";
import tr from "./tr.json";
import zhTW from "./zh-tw.json";
import da from "./da.json";
import nl from "./nl.json";
import fi from "./fi.json";
import id from "./id.json";
import ko from "./ko.json";
import ms from "./ms.json";
import pl from "./pl.json";
import sv from "./sv.json";
import no from "./no.json";

export const LOCALES = {
  ENGLISH: "en",
  CHINESE_SIMPLIFIED: "zh",
  FRANCAIS:"fr",
  DEUTSCH:"de",
  PORTUGUESE:"pt",
  SPANISH:"es",
  CZECH:"cs",
  ITALIAN:"it",
  JAPANESE:"ja",
  RUSSIAN:"ru",
  TURKISH:"tr",
  CHINESE_TRADITIONAL:"zh-TW",
  DANISH:"da",
  DUTCH:"nl",
  FINNISH:"fi",
  INDONESIAN:"id",
  KOREAN:"ko",
  MALAY:"ms",
  NORWEGIAN:"no",
  POLISH:'pl',
  SWEDISH:"sv"
};

export const messages = {
  [LOCALES.ENGLISH]: en,
  [LOCALES.CHINESE_SIMPLIFIED]: zh,
  [LOCALES.FRANCAIS]: fr,
  [LOCALES.DEUTSCH]: de,
  [LOCALES.PORTUGUESE]: pt,
  [LOCALES.SPANISH]: es,
  [LOCALES.CZECH]: cs,
  [LOCALES.ITALIAN]: it,
  [LOCALES.JAPANESE]: ja,
  [LOCALES.RUSSIAN]: ru,
  [LOCALES.TURKISH]: tr,
  [LOCALES.CHINESE_TRADITIONAL]: zhTW,
  [LOCALES.DANISH]: da,
  [LOCALES.DUTCH]: nl,
  [LOCALES.FINNISH]: fi,
  [LOCALES.INDONESIAN]: id,
  [LOCALES.KOREAN]: ko,
  [LOCALES.MALAY]: ms,
  [LOCALES.POLISH]: pl,
  [LOCALES.SWEDISH]: sv,
  [LOCALES.NORWEGIAN]: no,
};

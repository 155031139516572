export const XIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="white" />
            <svg width="40" height="36" viewBox="0 0 40 36" x="18%" y="20%" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.3661 0H37.4732L24.131 15.2492L39.827 36H27.5372L17.9113 23.4148L6.89717 36H0.786462L15.0572 19.6892L0 0H12.6018L21.3027 11.5034L31.3661 0ZM29.2227 32.3446H32.6067L10.763 3.46339H7.13163L29.2227 32.3446Z" fill="black" />
            </svg>

        </svg>


    );
};

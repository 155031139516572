import { EThemeType } from "redux/reducers";
interface IProps{
  theme?: EThemeType;
}

const ChatEditIcon = ({theme}:IProps) => {
  return (

    <svg
    xmlns="http://www.w3.org/2000/svg"
    width='12'
    height='11'
    viewBox="0 0 12 11"
    fill="none"
  >
    <path
      d="M6.8999 10.1257H10.9292"
      stroke={theme==="dark"? '#fff':'currentColor'}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.36216 0.876375C6.79308 0.361361 7.56772 0.285842 8.09342 0.708005C8.12249 0.730908 9.05636 1.45638 9.05636 1.45638C9.63387 1.8055 9.81332 2.54769 9.45632 3.11408C9.43737 3.14441 4.15763 9.74859 4.15763 9.74859C3.98198 9.96772 3.71534 10.0971 3.43038 10.1002L1.40846 10.1256L0.952895 8.19736C0.889078 7.92624 0.952895 7.6415 1.12855 7.42237L6.36216 0.876375Z"
      stroke={theme ==="dark"? '#fff':'currentColor'}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.3833 2.10107L8.41239 4.4273"
      stroke={theme==="dark" ? '#fff':'currentColor'}
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>

  )
}

export default ChatEditIcon
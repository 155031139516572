export const PrimaryLogo = () => {
  return (
    <img
      src="/logo-primary.svg"
      alt="logo"
      loading="lazy"
      className="max-w-[70%] md:max-h-[80%] lg:max-w-fit"
    />
  );
};

import { useState, Fragment, Dispatch, SetStateAction } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import styles from "./MDNav.module.scss";
import { theme } from "theme";

import { PlusIcon } from "../icons";
import { FormattedMessage } from "react-intl";
import { RoutePaths } from "pages/routePaths";

import { InviteMemberModal } from "pages/SettingsPage/pages/Team/components/InviteMemberModal/InviteMemberModal";

import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { inviteTeamMember, IChat, IInviteMemberBody } from "redux/actions";
import { useSelector } from "redux/hooks";
import { LogoutIcon } from "../AccountNav/icons";
import { logout } from "redux/actions";
import { Setting } from "../ChatNav/icons/setting";
import CustomButton from "components/Button";
import { ChatModel } from "../ChatNav/components/ChatModel";
import { Link } from "react-router-dom";

interface IProps {
  onSelectChatItem?: (chatItem: IChat | undefined) => void;
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  toggleChatModelsModel?: () => void;
  setChatSetting?: Dispatch<SetStateAction<boolean>>;
  chatItem?: IChat;
}

export const MDNav = ({
  // onSelectChatItem,
  onStartNewChat,
  toggleChatModelsModel,
  setChatSetting,
  chatItem,
}: IProps) => {
  const { pathname, includeRoute, push } = useRouter();
  const { triggerNotification } = useAppNotification();
  const { formatMessage } = useIntl();
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const isShareChat = window.location.pathname.includes("share-chat");

  const lang = userDetail?.user?.language ? userDetail?.user?.language : "en";

  //Logout Function
  const [isloading, setIsloading] = useState<boolean>(false);
  const onLogout = () => {
    setIsloading(true);
    logout()
      .then(() => {
        localStorage.removeItem('email');
        window.postMessage({ logout: true });
        setIsloading(false);
      })
      .catch((err: any) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setIsloading(false);
      });
  };

  // Invite Team Member
  const [inviteLoading, setInviteLoading] = useState<boolean>(false);
  const [inviteModal, setInviteModal] = useState<boolean>(false);
  const toggleInviteModal = () => {
    if (inviteLoading) return;
    setInviteModal((prev) => {
      if (!prev) {
        if (
          userDetail?.user.activeSubscription.name === "Free" ||
          userDetail?.user.activeSubscription.name === "Standard"
        ) {
          triggerNotification({
            message: "invite.teamMember.error",
            type: "info",
          });
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    });
  };
  const onInviteTeamMember = (data: IInviteMemberBody) => {
    setInviteLoading(true);
    inviteTeamMember(data)
      .then(() => {
        setInviteLoading(false);
        toggleInviteModal();
        triggerNotification({
          message: formatMessage(
            { id: "inivation.send.message.success" },
            { email: data.email }
          ),
          type: "info",
        });
      })
      .catch((err) => {
        setInviteLoading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  if (includeRoute(RoutePaths.Chat)) {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
          })}
        >
          {isShareChat &&
              <Link to='/' className={styles.headerLogo} data-testid='share-header-logo'>
              <img  src={require('../../../Sidebar/logo.png')} />
              </Link>
          }
          {userDetail && userDetail.token &&
            <ChatModel toggleChatModelsModel={toggleChatModelsModel} chatItem={chatItem} />
          }
          <div className={!isShareChat ? styles.menuContainer : styles.menuShareContainer}>
            {!isShareChat &&
              <>
                <div
                  data-testid='md-chat-setting-icon'
                  className={styles.icon}
                  onClick={() => setChatSetting!(true)}
                ><Setting />
                </div>
                <div
                  data-testid='md-setting-new-chat'
                  className={styles.icon}
                  onClick={() => {
                    onStartNewChat?.({ toChat: true });
                  }}
                >
                  <PlusIcon
                    color={
                      themeMode === "light" ? theme.textColorLight : theme.white
                    }
                  />
                </div>
              </>
            }
            {isShareChat &&
              <div className={styles.shareChatbtn}>
                <button data-testid='share-chat-try-deftgpt-free' className={styles.shareChatTryBtn}
                  onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
                >
                  <FormattedMessage id='share.chat.header' />
                </button>
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <div
          className={classNames(styles.container, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
            [styles.teamMdNav]: includeRoute(RoutePaths.Team),
          })}
        >
          {!includeRoute(RoutePaths.Team) && <div className={styles.pageTitle}>{pathname.split("/")[2]}</div>}
          {includeRoute(RoutePaths.Account) && (
            <button
              className={classNames(styles.logoutButton, {
                [styles.light]: themeMode === "light",
                [styles.dark]: themeMode === "dark",
              })}
              onClick={onLogout}
              disabled={isloading}
              id="logout"
              data-testid='account-setting-logout'
            >
              <div
                className={classNames(styles.btnContent, {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                })}
              >
                {/* <div className={styles.text}>
                  <FormattedMessage id="settings.account.logout" />
                </div> */}
                <LogoutIcon className={styles.icon} />
              </div>
            </button>
          )}
          {includeRoute(RoutePaths.Team) && (
            <CustomButton teamNav={true} teamMdNav={true} variant="outline-primary" data-testid='toggle-invite-modal' onClick={toggleInviteModal}>
              <div
                className={classNames(styles.btnContent, {
                  [styles.light]: themeMode === "light",
                  [styles.dark]: themeMode === "dark",
                  [styles.teamMdNav]: true,
                })}
              >
                <PlusIcon color={theme.white} />
                <span className={styles.text}>
                  <FormattedMessage id="team.page.inviteTeam.btnText" />
                </span>
              </div>
            </CustomButton>
          )}
        </div>
        {inviteModal && (
          <InviteMemberModal
            userRole={userDetail?.user?.team?.role}
            userDetail={userDetail}
            onClose={toggleInviteModal}
            onSubmit={onInviteTeamMember}
            submitLoading={inviteLoading}
          />
        )}
      </Fragment>
    );
  }
};

import { useSidebar } from 'hooks/services/ReSizeSidebar/ReSizeSidebar';
import React, {  useState } from 'react';
import SplitPane, { SplitPaneProps } from 'react-split-pane';
import styles from '../MainView/MainView.module.scss';

interface CustomSplitPaneProps extends SplitPaneProps {
  children: React.ReactNode;
}

export const CustomSplitPane: React.FC<CustomSplitPaneProps> = (props) => {

  const { sidebarWidth } = useSidebar();
  const [isDragging, setIsDragging] = useState(false);

  // // Calculate the dynamic pane2 style based on sidebarWidth
  // const [pane2Style, setPane2Style] = useState({
  //   // width: '100%', // Default for when sidebarWidth is less than 240px
  //   marginLeft: '', // Default for when sidebarWidth is less than 240px
  //   transition: 'none', // Disable transition during resizing
  //   flexGrow: 1,
  //   minWidth: 0,
  // });

  // useEffect(() => {
  //   const updatePane2Style = () => {
  //     if (sidebarWidth <= 240) {
  //       // When sidebarWidth is <= 240, keep pane2 fixed and unaffected by resizing
  //       setPane2Style({
  //         // width: '100%', // Fixed width for pane2 when sidebar is narrow
  //         marginLeft: '', // No margin, pane2 is aligned normally
  //         transition: 'none', // No transition during resizing
  //         flexGrow: 1,
  //         minWidth: 0,
  //       });
  //     } else {
  //       // When sidebarWidth exceeds 240px, dynamically adjust pane2's width
  //       setPane2Style({
  //         // width: `calc(100% - ${sidebarWidth}px)`, // Adjust pane2 width as sidebar expands
  //         marginLeft: '', // Ensure no margin is added, unless needed
  //         transition: 'none', // Disable transition while resizing
  //         flexGrow: 1,
  //         minWidth: 0,
  //       });
  //     }
  //   };

  //   window.requestAnimationFrame(updatePane2Style); // Make sure the update happens during the next animation frame
  // }, [sidebarWidth]); 

  return <SplitPane {...props}
    // pane2Style={pane2Style}
    resizerStyle={{
      contain: 'strict',
      position: 'absolute',
      left: `${sidebarWidth - 5}px`

    }}
    resizerClassName={`${styles.customResizer} ${isDragging ? styles.customResizerHover : ''}`} // Apply custom resizer class
    onDragStarted={() => setIsDragging(true)}      // Start highlighting on drag
    onDragFinished={() => setIsDragging(false)}
    style={{ height: '100vh' }} />;
};
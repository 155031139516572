

const DeleteIcon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.6848 6.70605C13.6848 6.70605 13.3001 11.4767 13.077 13.4862C12.9708 14.446 12.3779 15.0084 11.4068 15.0261C9.55873 15.0594 7.70856 15.0616 5.86123 15.0226C4.92694 15.0035 4.34398 14.434 4.23985 13.4912C4.01531 11.4639 3.63281 6.70605 3.63281 6.70605"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M14.6682 4.41862H2.65625"  strokeLinecap="round" strokeLinejoin="round"/>
<path d="M12.3517 4.41962C11.7957 4.41962 11.3168 4.02649 11.2077 3.48178L11.0356 2.62045C10.9294 2.22308 10.5695 1.94824 10.1594 1.94824H7.16104C6.75092 1.94824 6.39108 2.22308 6.28483 2.62045L6.11271 3.48178C6.00362 4.02649 5.52479 4.41962 4.96875 4.41962"  strokeLinecap="round" strokeLinejoin="round"/>
</svg>

  )
}

export default DeleteIcon
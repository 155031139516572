import { useIntl } from "react-intl";
import { Helmet } from "react-helmet";

interface IProps {
  title: string;
}

export const PageTitle = ({ title }: IProps) => {
  const intl = useIntl();
  const pageTitle = intl.formatMessage({ id: title });
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{`${pageTitle} | DeftGPT`}</title>
    </Helmet>
  );
};

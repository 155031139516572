import { ReactNode } from "react";
import { Theme } from "theme";
import styled from "styled-components";

import { TickIcon } from "./TickIcon";

import { EThemeType } from "redux/reducers";

interface IProps {
  children: ReactNode;
  variant?: EThemeType;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div<
  {
    themeMode?: EThemeType;
  } & { theme: Theme }
>`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};
`;

export const PageItem = ({ children, variant }: IProps) => {
  return (
    <Container>
      <div style={{ marginRight: "12px" }}>
        <TickIcon />
      </div>
      <Text themeMode={variant}>{children}</Text>
    </Container>
  );
};

import { useSwitchTeam } from "hooks/useSwitchTeam";
import { useRef, useState, useEffect } from "react";
import ReactGA from "react-ga4";

import {
  ISendMessageBody,
  updateCredits,
  updateCreditLimit,
  answerNewChatQuestion,
  answerRegularQuestion,
  getNewChatTopic,
  IUser,
  IChat,
  updatedRegularQuestion,
  updateNewRegularQuestion,
  AddHistoryMessage,
  setAdminAccountDeleted,
} from "redux/actions";
import { useSelector } from "redux/hooks";
// import { SwitchTeam } from "utils/switchTeam";

export const useChatStream = () => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const addHistory = useSelector((state) => state.chatReducer.addHistory);

  // Ref to hold the latest value of addHistory
  const addHistoryRef = useRef(addHistory);

  const switchTeam = useSwitchTeam();

  useEffect(() => {
    // Update the ref whenever addHistory changes
    addHistoryRef.current = addHistory;
  }, [addHistory]);

  let controllerRef = useRef<AbortController | null>();
  const [done, setDone] = useState<boolean | null>(null);
  const [chatHistoryPagination, setChatHistory] = useState<IChat[]>([]);
  let chatId = useRef<number>(0);

  const startStream = (data: ISendMessageBody) => {
    return new Promise(async (resolve, reject) => {
      if (data.chatType === "document") {
        ReactGA.event({
          action: "WebsiteMessageSentDocumentChat",
          category: "WebsiteMessageSentDocumentChat",
        });
      }
      try {
        controllerRef.current = new AbortController();

        let body: any;
        if (data.file_path && data.file_path.length > 0) {
          body = {
            message: data.message,
            newChatBoolean: data.newChatBoolean,
            chatId: data.chatId,
            model: data.model,
            chatType: data.chatType,
            file_path: data.file_path,
            regenerate: data.regenerate,
            isPrivate: data.isPrivate,
          };
        } else if (data.images) {
          body = {
            message: data.message,
            newChatBoolean: data.newChatBoolean,
            chatId: data.chatId,
            model: data.model,
            chatType: data.chatType,
            images: data.images,
            regenerate: data.regenerate,
            isPrivate: data.isPrivate,
          };
        }
        else {
          body = {
            message: data.message,
            newChatBoolean: data.newChatBoolean,
            chatId: data.chatId,
            model: data.model,
            chatType: data.chatType,
            regenerate: data.regenerate,
            isPrivate: data.isPrivate,
          };
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_BASEURL}/api/chat/message`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: `Bearer ${userDetail?.token}`,
            },
            body: JSON.stringify(body),
            signal: controllerRef?.current.signal,
          }
        );

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder();

        let i = 0;
        setDone(false);
        let content = '';
        let texts = '';
        let isJsonParsed = false;
        let isPrivateChat = false;
        let admin_team_deleted = false;
        // let assistanceMessageId = 0;
        while (true) {
          const streamResult = await reader?.read();
          const decoderText = decoder.decode(streamResult?.value);
          content += decoder.decode(streamResult?.value || new Uint8Array(), { stream: !streamResult?.done });

          let completeMessage = decoderText;
          //  let regenerate_model_id=null;
          while (!isJsonParsed) {
            let leftBracket = content.indexOf('{');
            let rightBracket = content.lastIndexOf('}');
            if (leftBracket === -1 || rightBracket === -1) { break; }
            if ((leftBracket !== -1 && rightBracket !== -1)) {
              const jsonDataString = content.substring(leftBracket, rightBracket + 1);
              const jsonData = JSON.parse(jsonDataString);
              if (jsonData.message) {
                texts = content.substring(rightBracket + 1);
                isJsonParsed = true;
              }
            }
            completeMessage = content.substring(leftBracket, rightBracket + 1);
            content = content.substring(rightBracket + 1);
          }

          if (streamResult?.done) {
            setDone(true);
            controllerRef.current = null;
            if (addHistoryRef.current === '') {
              setTimeout(() => {
                if (data.newChatBoolean && chatId.current && isPrivateChat === false) {
                  const ID = chatId.current;
                  getNewChatTopic(chatId.current).then(() => {
                    setTimeout(() => AddHistoryMessage(ID), 100);
                  })
                    .catch((err: any) => console.error(err));

                  setTimeout(() => chatId.current = 0, 110);

                }
              }, 2000);
            }
            // switchTeam();

            if (admin_team_deleted === true) {
              // triggerNotification({ message: 'Team has been deleted by admin', type: "error" });
              setAdminAccountDeleted(true);
              switchTeam();
              reject('Team has been deleted by admin')
            }
            resolve({ ...streamResult, chatId: chatId.current });
            break;
          }
          if (i === 0) {
            try {
              const jsonData = JSON.parse((completeMessage && texts !== '') ? completeMessage : decoderText)
              if (jsonData?.admin_team_deleted === true) {
                admin_team_deleted = true;
              }
              completeMessage = '';
              if (
                jsonData &&
                jsonData.credits !== undefined &&
                jsonData.daily_limit !== undefined &&
                jsonData.used_today !== undefined && !admin_team_deleted
              ) {
                if (userDetail?.user.team.role === "admin") {
                  updateCredits(jsonData.credits);
                } else {
                  updateCreditLimit({
                    daily_limit: jsonData.daily_limit,
                    used_today: jsonData.used_today,
                  });
                }

                if (jsonData.chat && jsonData.message) {
                  chatId.current = jsonData.chat.id;
                  isPrivateChat = jsonData.chat.is_private;

                  const images = data.images && data?.images.map((img) => {
                    return {
                      id: 1,
                      model_type: '',
                      model_id: 1,
                      name: null,
                      path: img,
                      size: '',
                      created_at: '',
                      updated_at: '',
                    }
                  });

                  if (data.messageViewType === "new") {

                    answerNewChatQuestion({
                      type: "assistant",
                      content: "",
                      isNew: true,
                      chat_id: jsonData.chat.id,
                      id: jsonData.assistant_message.id,
                      images: [],
                      regenerate_model_id: jsonData?.assistant_message?.regenerate_model_id,
                      model_id: jsonData?.assistant_message?.model_id,
                    });

                    updateNewRegularQuestion({
                      type: "user",
                      chat_id: jsonData.chat.id,
                      id: jsonData.message.id,
                      images: data?.images ? images ? images : [] : [],
                    });

                  } else {
                    answerRegularQuestion({
                      type: "assistant",
                      content: "",
                      isNew: true,
                      chat_id: jsonData.chat.id,
                      id: jsonData.assistant_message.id,
                      images: [],
                      regenerate_model_id: jsonData?.assistant_message?.regenerate_model_id,
                      model_id: jsonData?.assistant_message?.model_id,
                    });

                    updatedRegularQuestion({
                      type: "user",
                      chat_id: jsonData.chat.id,
                      id: jsonData.message.id,
                      images: data?.images ? images ? images : [] : [],
                    })
                  }
                }
              } else {
                if (jsonData.message && !admin_team_deleted) {
                  if (data.messageViewType === "new") {
                    answerNewChatQuestion({
                      type: "assistant",
                      content: jsonData.message,
                      isNew: true,
                      images: [],
                      model_id: jsonData?.assistant_message?.model_id,
                    });
                  } else {
                    answerRegularQuestion({
                      type: "assistant",
                      content: jsonData.message,
                      isNew: true,
                      images: [],
                    });
                  }
                }
                // triggerNotification({ message: jsonData.message, type: "error" });
                reject(jsonData);
              }

              ReactGA.event({
                action: "WebsiteChatScreenAssistantResponseSuccess_action",
                category: (userDetail?.user as IUser).activeSubscription.name,
                label: new Date().toISOString(),
                value: 5,
              });

              ReactGA.event({
                action: "WebsiteChatScreenCreditUsed_action",
                category: "WebsiteChatScreenCreditUsed_category",
                label: new Date().toISOString(),
              });
            } catch (error: any) {
              console.error("Error parsing JSON:", error);
            }
          } else {

            if (data.messageViewType === "new" && !admin_team_deleted) {
              answerNewChatQuestion({
                type: "assistant",
                content: texts ? texts + decoderText : decoderText,
                isNew: true,
                images: [],
              });
              texts = ''
            }
            else {
              if (!admin_team_deleted) {
                answerRegularQuestion({
                  type: "assistant",
                  content: texts ? texts + decoderText : decoderText,
                  isNew: true,
                  images: [],
                });
              }
              texts = ''
            }
          }
          i++;
        }
      } catch (error) {
        reject(error);
      }
    });
  };
  return { done, setDone, startStream, controllerRef, chatHistoryPagination, setChatHistory };
};

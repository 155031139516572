export const UpscaleIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.692 7.44797L16.7066 5.1563L14.3232 4.10213L16.7066 3.07088L17.692 0.893799L18.6774 3.07088L21.0607 4.10213L18.6774 5.1563L17.692 7.44797ZM17.692 21.0834L16.7066 18.8834L14.3232 17.8521L16.7066 16.8209L17.692 14.5063L18.6774 16.8209L21.0607 17.8521L18.6774 18.8834L17.692 21.0834ZM7.63158 17.5542L5.52324 13.0396L0.916992 10.9771L5.52324 8.91463L7.63158 4.42297L9.76283 8.91463L14.3462 10.9771L9.76283 13.0396L7.63158 17.5542ZM7.63158 14.1625L8.73158 11.9625L10.9774 10.9771L8.73158 9.99171L7.63158 7.79172L6.55449 9.99171L4.28574 10.9771L6.55449 11.9625L7.63158 14.1625Z"
        fill="black"
      />
    </svg>
  );
};

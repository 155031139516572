import { memo } from "react";

import { theme } from "theme";
import classNames from "classnames";
import styles from "../DDMenu.module.scss";

import { DownArrow } from "../Icons";

import Dropdown from "rc-dropdown";
import Menu, { Item as MenuItem } from "rc-menu";

import { IOption } from "utils/constants";

import { useSelector } from "redux/hooks";

interface IProps {
  selectedOption: number;
  options: IOption[];
  maxCount?: number;
  onSelect: (option: IOption) => void;
  dataTestId?: string
}

export const CountDD = memo(
  ({ selectedOption, options, maxCount, onSelect, dataTestId }: IProps) => {
    const themeMode = useSelector((state) => state.authReducer.theme);

    return (
      <Dropdown
        trigger={["click"]}
        overlay={
          <Menu
            style={{
              borderRadius: "10px",
              border: `1px solid ${
                themeMode === "dark" ? theme.borderDark : theme.borderLight
              }`,
              backgroundColor:
                themeMode === "dark"
                  ? "rgba(255, 255, 255, 0.03)"
                  : "rgba(42, 40, 49, 0.03)",
              backdropFilter: "blur(25px)",
              boxShadow: "none",
              padding: "5px 0",
              overflow: "hidden",
              position: "relative",
            }}
          >
            {options
              .filter((option) => {
                if (option.value <= Number(maxCount)) {
                  return option;
                }
              })
              .map((option) => (
                <MenuItem
                  className={classNames(styles.menuItem, {
                    [styles.light]: themeMode === "light",
                    [styles.dark]: themeMode === "dark",
                    [styles.isSelected]: option.value === selectedOption,
                  })}
                  onClick={() => onSelect(option)}
                  key={`${option.label}`}
                  data-testid={dataTestId}
                >
                  <div className={styles.text}>{option.label}</div>
                </MenuItem>
              ))}
          </Menu>
        }
        overlayStyle={{ zIndex: 10003 }}
      >
        <div
          className={classNames(styles.menu, {
            [styles.light]: themeMode === "light",
            [styles.dark]: themeMode === "dark",
          })}
        >
          {selectedOption} <DownArrow />
        </div>
      </Dropdown>
    );
  }
);

export const CheckedIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 6.5L9 17.5L4 12.5"
      stroke="url(#paint0_linear_65_2708)"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_65_2708"
        x1="4"
        y1="6.5"
        x2="20.5883"
        y2="7.47323"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8C49F7" />
        <stop offset="1" stopColor="#6C53FF" />
      </linearGradient>
    </defs>
  </svg>
);

import { useState } from "react";
import { useSelector } from "redux/hooks";
import { IMessage } from "redux/actions";
import useRouter from "../../useRouter";

export interface IGetMessageAgainstKey {
    messagesArray: IMessage[];
    key: any;
  }

// This hook encapsulates functionality related to managing chats
const useChat = () => {

   const {  pathname } = useRouter();
  const chatId = pathname.split("/")[3];

  const { userDetail, creditLimits } = useSelector((state) => state.authReducer);
  const { messages, newMessages } = useSelector((state) => state.chatReducer);
  
  const [selectedMessages, setSelectedMessages] = useState<any[]>([]);
  const [selectedChatId, setSelectedChatId] = useState<number | undefined>();
  const [messageHeight, setMessageHeight] = useState<boolean>(true);

// Function for selecting all chats
const handleSelectAllChats = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (messages || newMessages) {
      const checked: boolean = event.target.checked;
      let selectedMessagesIds: (string | number)[] = [];
      const URL = pathname.includes("chat/new");
      setMessageHeight(false);

      if (checked) {
        {
          messages.length === 0 || URL ? (
            <>
              {
                (selectedMessagesIds = newMessages.map(
                  (message) => message.id! || message.content!
                ))
              }
              {setSelectedChatId(newMessages[0].chat_id)}
            </>
          ) : (
            <>
              {
                (selectedMessagesIds = messages.map(
                  (message) => message.id! || message.content!
                ))
              }
              {setSelectedChatId(messages[0].chat_id)}
            </>
          );
        }
      }
      setSelectedMessages(selectedMessagesIds);
    }
  };

 // Function to calculate remaining credits

  const getReaminingCredits = (): number => {
    const adminCredits = userDetail?.user.activeSubscription.credits ?? 0;
    if (userDetail?.user.team.role === "admin") {
      return adminCredits;
    } else {
      const dailyCredits = creditLimits.daily_limit - creditLimits.used_today;
      return dailyCredits > 0 ? dailyCredits : 0;
    }
  };

   // Function to get a message against a specific key

  const getMessageAgainstKey = ({
    messagesArray,
    key,
  }: IGetMessageAgainstKey): IMessage => {
    const cleanedNewMessages = messagesArray.filter((message) => {
      return message !== undefined;
    });
    return (cleanedNewMessages as IMessage[])?.find(
      (msg) => msg[key as keyof IMessage]
    ) as IMessage;
  };

   // Function to get the last message

  const getLastMessage = (): IMessage => {
    if (chatId) {
      return messages[messages.length - 1];
    } else {
      return newMessages[newMessages.length - 1];
    }
  };

  return {
    handleSelectAllChats,
    getReaminingCredits,
    getMessageAgainstKey,
    getLastMessage,
    selectedMessages,
    selectedChatId,
    messageHeight,
    setMessageHeight,
    setSelectedMessages,
    setSelectedChatId,
  };
};

export default useChat;
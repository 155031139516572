export const ConsistentExperienceIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M26.5695 54.4013C18.9747 51.0872 14.0198 43.6783 13.8513 35.4379H15.7916C16.03 35.4379 16.2291 35.2432 16.2291 35.0004C16.2291 34.9064 16.1985 34.8189 16.1481 34.7467L11.9919 27.5479C11.9131 27.4122 11.7687 27.3291 11.6134 27.3291C11.4581 27.3291 11.3116 27.4122 11.235 27.5479L7.05906 34.782C6.98031 34.9176 6.98031 35.0838 7.05906 35.2195C7.13781 35.3551 7.28219 35.4382 7.4375 35.4382H9.38658C9.4719 40.476 11.0185 45.3211 13.8753 49.4752C16.7453 53.649 20.7309 56.856 25.4058 58.7522C25.4583 58.7741 25.5151 58.785 25.5698 58.785C25.6398 58.785 25.7076 58.7675 25.7711 58.7369C25.8848 58.6778 25.9658 58.5728 25.9964 58.4481L26.8189 54.9044C26.8692 54.6944 26.7642 54.4844 26.5695 54.4013ZM10.2594 35C10.2594 34.7594 10.0626 34.5625 9.82194 34.5625H8.19664L11.6157 28.641L15.0348 34.5625H13.4095C13.1689 34.5625 12.972 34.7594 12.972 35C12.972 43.6386 18.0295 51.4697 25.8868 55.0531L25.2612 57.7416C16.1303 53.8237 10.2594 44.9529 10.2594 35Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.9"
    />
    <path
      d="M11.2507 25.4057C11.2026 25.5238 11.2091 25.6572 11.2682 25.771C11.3273 25.8847 11.4323 25.9657 11.5569 25.9963L15.1007 26.8188C15.1335 26.8276 15.1663 26.8298 15.1991 26.8298C15.3698 26.8298 15.5294 26.7291 15.5994 26.5673C18.9135 18.9725 26.3246 14.0176 34.5628 13.8491V15.7894C34.5628 15.9447 34.6459 16.0912 34.7815 16.1678C34.9172 16.2466 35.0834 16.2466 35.219 16.1678L42.4531 11.9919C42.5888 11.9131 42.6719 11.7687 42.6719 11.6134C42.6719 11.4581 42.5888 11.3116 42.4531 11.235L35.2213 7.05906C35.0856 6.98031 34.9194 6.98031 34.7838 7.05906C34.6481 7.13781 34.565 7.28219 34.565 7.4375V9.38659C29.5272 9.4719 24.6821 11.0185 20.528 13.8753C16.3543 16.7432 13.1469 20.7308 11.2507 25.4057ZM35.44 9.81972V8.19442L41.3614 11.6135L35.44 15.0326V13.4073C35.44 13.1667 35.2431 12.9698 35.0025 12.9698C26.3639 12.9698 18.5328 18.0273 14.9494 25.8846L12.2609 25.2589C16.1787 16.1303 25.0491 10.257 35.002 10.257C35.2427 10.257 35.44 10.0625 35.44 9.81972Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.9"
    />
    <path
      d="M62.5651 34.5627H60.616C60.5307 29.5249 58.9841 24.6798 56.1273 20.5257C53.2573 16.352 49.2718 13.1449 44.5969 11.2487C44.4787 11.2006 44.3453 11.2072 44.2315 11.2662C44.1178 11.3253 44.0369 11.4303 44.0062 11.555L43.1815 15.0987C43.1334 15.3044 43.2384 15.5144 43.4331 15.5997C51.0278 18.9138 55.9827 26.3226 56.1513 34.563H54.211C54.0535 34.563 53.9091 34.6461 53.8326 34.7818C53.7538 34.9174 53.7538 35.0836 53.8326 35.2193L58.0085 42.4534C58.0872 42.589 58.2316 42.6721 58.3869 42.6721C58.5422 42.6721 58.6888 42.589 58.7654 42.4534L62.9413 35.2193C63.02 35.0836 63.02 34.9174 62.9413 34.7818C62.8647 34.6461 62.7204 34.5627 62.5651 34.5627ZM58.3892 41.3595L54.9701 35.438H56.5954C56.836 35.438 57.0329 35.2411 57.0329 35.0005C57.0329 26.3619 51.9754 18.5309 44.1181 14.9474L44.7438 12.259C53.8746 16.1768 59.7457 25.0472 59.7457 35.0001C59.7457 35.2407 59.9425 35.4376 60.1832 35.4376H61.8085L58.3892 41.3595Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.9"
    />
    <path
      d="M58.4472 44.0036L54.9035 43.181C54.6978 43.1329 54.4878 43.2379 54.4025 43.4326C51.0884 51.0273 43.6774 55.9822 35.4392 56.1508V54.2105C35.4392 54.0552 35.3561 53.9086 35.2204 53.8321C35.0848 53.7533 34.9186 53.7533 34.7829 53.8321L27.5488 58.008C27.4132 58.0867 27.3301 58.2311 27.3301 58.3864C27.3301 58.5417 27.4132 58.6883 27.5488 58.7649L34.7829 62.9408C34.8507 62.9802 34.9251 62.9999 35.0017 62.9999C35.0761 62.9999 35.1526 62.9802 35.2204 62.9408C35.3561 62.8621 35.4392 62.7177 35.4392 62.5624V60.6133C40.477 60.528 45.3221 58.9814 49.4762 56.1245C53.6499 53.2545 56.857 49.269 58.7509 44.5941C58.799 44.476 58.7925 44.3425 58.7334 44.2288C58.6787 44.115 58.5719 44.032 58.4472 44.0036ZM35.0038 59.7424C34.7632 59.7424 34.5663 59.9393 34.5663 60.1799V61.8052L28.6449 58.3861L34.5663 54.967V56.5923C34.5663 56.8329 34.7632 57.0298 35.0038 57.0298C43.6424 57.0298 51.4735 51.9723 55.057 44.1151L57.7454 44.7407C53.8276 53.8715 44.9568 59.7424 35.0038 59.7424Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.9"
    />
    <path
      d="M20.8831 46.4366L23.2172 47.1322L25.4484 50.2757L25.4506 50.2779C25.4703 50.3041 25.4922 50.3282 25.5163 50.3479C25.5228 50.3544 25.5316 50.3588 25.5381 50.3654C25.5556 50.3785 25.5731 50.3916 25.5928 50.4026C25.6016 50.4069 25.6125 50.4135 25.6213 50.4179C25.6409 50.4266 25.6628 50.4354 25.6847 50.4419C25.6934 50.4441 25.7022 50.4485 25.7109 50.4485C25.7416 50.4551 25.7722 50.4594 25.805 50.4594H48.9969H48.9991C49.2397 50.4594 49.4366 50.2626 49.4366 50.0219C49.4366 49.9979 49.4344 49.9738 49.43 49.9497L47.7741 24.1807L47.7479 19.9763C47.7457 19.7357 47.551 19.541 47.3104 19.541H24.1425C23.9019 19.541 23.705 19.7379 23.705 19.9785V24.1632L20.5747 45.9529C20.5441 46.1695 20.6753 46.3732 20.8831 46.4366ZM46.8728 20.4161L46.8946 23.7587H24.5797V20.4161H46.8728ZM26.2137 49.5844L24.6103 24.6335H46.9252L48.5287 49.5844H26.2137ZM23.9759 28.4292L25.2665 48.5103L23.8403 46.5022C23.7834 46.4234 23.7025 46.3644 23.6084 46.3359L21.4931 45.7059L23.9759 28.4292Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.9"
    />
    <path
      d="M43.2381 34.3239C43.214 34.103 43.0259 33.9346 42.8028 33.9346H30.1546C30.0299 33.9346 29.9118 33.9871 29.8287 34.0811C29.7455 34.173 29.7061 34.2977 29.7193 34.4224L30.0627 37.4761C30.0868 37.6971 30.2749 37.8655 30.498 37.8655L43.144 37.8633H43.1462C43.3868 37.8633 43.5837 37.6664 43.5837 37.4258C43.5837 37.393 43.5793 37.3624 43.5749 37.3339L43.2381 34.3239ZM42.4112 34.8096L42.6562 36.9883H30.8872L30.6422 34.8096H42.4112Z"
      fill="#7C4DFF"
      stroke="#7C4DFF"
      strokeWidth="0.9"
    />
  </svg>
);

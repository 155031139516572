import { memo } from "react";

import { Input, InputProps } from "../base";
import { EThemeType } from "redux/reducers";
import { ControlLabels, ControlLabelsProps } from "../LabeledControl";

type LabeledInputProps = Pick<
  ControlLabelsProps,
  "success" | "message" | "label" | "labelAdditionLength"
> &
  InputProps & { variant?: EThemeType, prompt?:boolean, inputSpace?:boolean };

const LabeledInputComponent = ({
  error,
  success,
  message,
  label,
  labelAdditionLength,
  focusedStyle = true,
  inputSpace,
  ...inputProps
}: LabeledInputProps) => (
  <ControlLabels
    error={error}
    success={success}
    message={message}
    label={label}
    labelAdditionLength={labelAdditionLength}
  >
    <Input {...inputProps} error={error} focusedStyle={focusedStyle}  inputSpace={ inputSpace}/>
  </ControlLabels>
);

export const LabeledInput = memo(LabeledInputComponent);

export const NavigateMenusIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <g clipPath="url(#clip0_5036_55671)">
      <path
        d="M62.7113 33.9175L59.1753 30.4536C58.5979 29.8763 57.732 29.8763 57.1546 30.4536C56.5773 31.0309 56.5773 31.8969 57.1546 32.4742L58.1649 33.5567H44.3814C43.732 29.5155 40.5567 26.3402 36.5155 25.6907V11.9072L37.5258 12.9175C37.8144 13.2062 38.1753 13.3505 38.5361 13.3505C38.8969 13.3505 39.2577 13.2062 39.5464 12.9175C40.1237 12.3402 40.1237 11.4742 39.5464 10.8969L36.0103 7.43299C35.433 6.85567 34.4948 6.85567 33.9897 7.43299L30.4536 10.8969C29.8763 11.4742 29.8763 12.3402 30.4536 12.9175C31.0309 13.4948 31.8969 13.4948 32.4742 12.9175L33.6289 11.9072V25.6186C29.5876 26.268 26.3402 29.4433 25.7629 33.4845H11.9794L12.9897 32.4742C13.567 31.8969 13.567 30.9588 12.9897 30.4536C12.4124 29.8763 11.5464 29.8763 10.9691 30.4536L7.43299 33.9175C6.85567 34.4948 6.85567 35.3608 7.43299 35.9381L10.9691 39.4021C11.2577 39.6907 11.6186 39.835 11.9794 39.835C12.3402 39.835 12.701 39.6907 12.9897 39.4021C13.567 38.8247 13.567 37.9588 12.9897 37.3814L11.9794 36.4433H25.6907C26.3402 40.4845 29.5155 43.732 33.6289 44.3814V58.0928L32.6186 57.0825C32.0412 56.5051 31.1753 56.5051 30.5979 57.0825C30.0206 57.6598 30.0206 58.5258 30.5979 59.1031L34.134 62.567C34.4227 62.8557 34.7835 63 35.1443 63C35.5052 63 35.866 62.8557 36.1546 62.567L39.6907 59.1031C40.268 58.5258 40.268 57.6598 39.6907 57.0825C39.1134 56.5051 38.2474 56.5051 37.6701 57.0825L36.5155 58.0928V44.3814C40.5567 43.732 43.8041 40.4845 44.4536 36.4433H58.1649L57.1546 37.4536C56.5773 38.0309 56.5773 38.8969 57.1546 39.4742C57.4433 39.7629 57.8041 39.9072 58.1649 39.9072C58.5258 39.9072 58.8866 39.7629 59.1753 39.4742L62.7113 36.0103C63.2165 35.433 63.2165 34.4948 62.7113 33.9175ZM35.0722 41.567C31.4639 41.567 28.5052 38.6082 28.5052 35C28.5052 31.3918 31.4639 28.433 35.0722 28.433C38.6804 28.433 41.6392 31.3918 41.6392 35C41.6392 38.6082 38.6804 41.567 35.0722 41.567Z"
        fill="#7C4DFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5036_55671">
        <rect width="70" height="70" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import  { createContext, useContext, useState, ReactNode } from 'react';

// Define the type for your context
type SidebarContextType = {
  sidebarWidth: number;
  setSidebarWidth: (width: number) => void;
  paneWidth: number;
  setPaneWidth: (width: number) => void;
  paneStyle: { width: string; marginRight: string; transition: string };
  setPaneStyle: React.Dispatch<React.SetStateAction<{ width: string; marginRight: string; transition: string }>>;
};

// Create the context with a default of `undefined` or a default value
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

// Context provider component
export const SidebarProvider = ({ children }: { children: ReactNode }) => {
  const savedWidth = localStorage.getItem('sidebarWidth');
  const [sidebarWidth, setSidebarWidth] = useState( savedWidth !== null ? parseInt(savedWidth, 10) : 280);
  const [paneWidth, setPaneWidth] = useState(savedWidth !== null ? parseInt(savedWidth, 10) : 280);
  
  const [paneStyle, setPaneStyle] = useState({ width: `${sidebarWidth}px`, marginRight: '', transition: 'none' }); // Store pane1 style

  return (
    <SidebarContext.Provider value={{ sidebarWidth, setSidebarWidth, paneWidth,setPaneWidth, setPaneStyle , paneStyle}}>
      {children}
    </SidebarContext.Provider>
  );
};
                       
// Hook to use the sidebar context
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context;
};

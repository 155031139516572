import { SetStateAction, useCallback } from 'react';
import { useEffectOnce } from "react-use";
import useRouter from "hooks/useRouter";
import { getShareChat } from "redux/actions";
import { ChatHistory } from '../chatHistory';

interface IProps {
    loadingShareChat?: boolean;
    setLoadingShareChat?: React.Dispatch<SetStateAction<boolean>>;
}

export const ShareChat = ({ loadingShareChat, setLoadingShareChat }: IProps) => {
    const { params } = useRouter();

    const getChat = useCallback(() => {
        
        const { token } = params;
        setLoadingShareChat!(true);
        getShareChat({ token })
            .then(() => {
                localStorage.removeItem('shareChat-Token')
                setLoadingShareChat!(false)
            })
            .catch(() => {
                setLoadingShareChat!(false);
            });
    }, []);

    useEffectOnce(() => getChat());

    return (
        <div >
            <ChatHistory
                shareChat={true}
                isloading={loadingShareChat}
            />
        </div>
    );
}

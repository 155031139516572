import { ReactNode, createContext, useContext, useMemo, useState } from "react";
import type { IntlConfig } from "react-intl";
import { IntlProvider } from "react-intl";
import { LOCALES, messages } from "locales";
import { useSelector } from "redux/hooks";

type Messages = IntlConfig["messages"];

interface I18nContext {
  setMessageOverwrite: (messages: Messages) => void;
}

const i18nContext = createContext<I18nContext>({
  setMessageOverwrite: () => null,
});

export const useI18nContext = () => {
  return useContext(i18nContext);
};

interface I18nProviderProps {
  messages?: Messages;
  locale?: string;
  children: ReactNode;
}

// interface IBold {
//   children: ReactNode;
// }

// const Bold = ({ children }: IBold) => {
//   return (
//     <div>
//       <strong>{children}</strong>
//     </div>
//   );
// };

export const I18nProvider = ({ children }: I18nProviderProps) => {
  const userDetail = useSelector((state) => state.authReducer.userDetail);
  const lanuage = userDetail?.user.language;

  const translatedMessages = messages[lanuage ? lanuage : LOCALES.ENGLISH];
  const [overwrittenMessages, setOvewrittenMessages] = useState<Messages>();

  const i18nOverwriteContext = useMemo<I18nContext>(
    () => ({
      setMessageOverwrite: (messages) => {
        setOvewrittenMessages(messages);
      },
    }),
    []
  );

  const mergedMessages = useMemo(
    () => ({
      ...translatedMessages,
      ...(overwrittenMessages ?? {}),
    }),
    [translatedMessages, overwrittenMessages]
  );

  return (
    <i18nContext.Provider value={i18nOverwriteContext}>
      <IntlProvider
        defaultLocale={LOCALES.ENGLISH}
        locale={LOCALES.ENGLISH}
        messages={mergedMessages}
        // defaultRichTextElements={{
        //   bold: (chunks) => <Bold>{chunks}</Bold>,
        // }}
      >
        {children}
      </IntlProvider>
    </i18nContext.Provider>
  );
};

import classNames from "classnames";
import styles from "../../ChatNav.module.scss";
import useRouter from "hooks/useRouter";

import { 
  // InfoIcon,
   RightArrow } from "../../icons";

import { useSelector } from "redux/hooks";
import { ChatRoute } from "pages/ChatPage";
import { IChat } from "redux/actions";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";

interface IProp {
  toggleChatModelsModel?: () => void;
  chatItem?: IChat;
  isMainScreenOpen?: boolean;
}

export const ChatModel = ({
  toggleChatModelsModel,
  chatItem,
  isMainScreenOpen,
}: IProp) => {
  const { pathname, includeRoute } = useRouter();
  const isShare = window.location.pathname.includes("share-chat");

  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { gptModel, theme } = useSelector((state) => state.authReducer);
  const { shareMessages } = useSelector((state) => state.chatReducer);
  const storedGptModel = localStorage.getItem("GptModel");
  const parsedGptModel = storedGptModel ? JSON.parse(storedGptModel) : null;
  const history = pathname.includes("/chat/history");
  const {width} = useWindowSize();

  const {sidebarWidth} = useSidebar();
  // Check if gptModel?.type includes "text"
  const modelToUse =
    (gptModel?.type?.includes("text") && !history && parsedGptModel) ||
    gptModel;

  return (
    <div className={classNames(styles.container,{
        [styles.shareChat]: isShare
    })}
    style={{marginLeft: sidebarWidth > 330 && width <= 800 ? '-15px': ''}}>
      {!isMainScreenOpen ||
      isMainScreenOpen ||
      includeRoute(ChatRoute.History) ||
      isShare ? (
        <button
          className={classNames(styles.modelButton, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
            [styles.shareChat]: isShare,
          })}
          data-testid="chatNav-model-btn"
          onClick={!isShare ? toggleChatModelsModel : () => {}}
        >
          <div className={styles.content}>
            {!isShare ? (
              <>
                <div className={styles.modelIconContainer}>
                  <img
                    className={
                      gptModel?.name === "flux-schnell" ? styles.image : ""
                    }
                    src={
                      chatItem?.model?.image_url ??
                      modelToUse?.image_url ??
                      "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"
                    }
                  />
                </div>
                <div className={styles.model}>{modelToUse?.name}</div>
              </>
            ) : (
              <>
                {chatModels?.map(
                  (model) =>
                    model.id === shareMessages?.model_id && (
                      <>
                        <div className={styles.modelIconContainer}>
                          <img
                            src={
                              model?.image_url ??
                              "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"
                            }
                          />
                        </div>
                        <div className={styles.model}>{model?.name}</div>
                      </>
                    )
                )}
              </>
            )}
            <RightArrow />
          </div>
        </button>
      ) : null}
      {isShare && (
        <span className="ml-4 opacity-80">
          {/* <InfoIcon theme={theme} /> */}
        </span>
      )}
    </div>
  );
};

import { useMemo } from "react";
import { IMessage } from "redux/actions"
import { clsx } from "clsx"

import styles from "../../ChatHistory.module.scss";

interface IProps {
  message: IMessage;
  share: boolean;
  selectedMessages?: any;
    handleCheckboxChange: (messageId?: any) => void;
}

export const ShareCheckbox: React.FC<IProps> = ({
  message,
  share,
  selectedMessages,
  handleCheckboxChange,
}) => {
  const isAssistantNewShare = useMemo(() => {
    return message?.type === "assistant" && message?.isNew && share;
  }, [message, share]);

  const hasFilesToShare = useMemo(() => {
    return share && message.files && message.files.length > 0;
  }, [message, share]);

  const classNames = useMemo(() => {
    return clsx("flex items-center mr-[5px]", {
      "-mt-[30px]": isAssistantNewShare,
      "-mt-[57px]": !isAssistantNewShare,
      "-mt-[35px]": !isAssistantNewShare &&  (!message.files || message?.files.length === 0) && message.type==='user',
      "py-3": isAssistantNewShare,
      "absolute right-0": hasFilesToShare,
    });
  }, [isAssistantNewShare, hasFilesToShare]);

  return (
    <div
      className={classNames}
    >
      <label className={styles.checkboxContainer}>
        <input
          type="checkbox"
          checked={
            message && selectedMessages.includes(message?.id || message.content)
          }
          onChange={() =>
            handleCheckboxChange(message?.id ? message?.id : message.content)
          }
        />
        <span className={styles.checkmark}></span>
      </label>
    </div>
  );
};
import { Link } from "react-router-dom";
import { RoutePaths } from "pages/routePaths";
// import { Suspense } from "react";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import settingStyles from "../../../SettingSidebar/SettingSidebar.module.scss";
import chatStyles from "./ChatSideBarFooter.module.scss"
import { ThemeSwitch } from "components/Header/ThemeSwitch/ThemeSwitch";
import {
  FeedbackIcon,
  AccountIcon,
  CurrentPlanIcon,
  TeamIcon
} from "../../../SettingSidebar/components/icons";
import { useSelector } from "redux/hooks";


interface IProps {
  toggleSidebar?:any;
  itemStyles?:any;
  userDetail?:any;
  theme?:any;
}

export const ChatSideBarFooter = (
  { itemStyles,toggleSidebar,userDetail,theme }: IProps)=> {
    const { PrivateChat} = useSelector(
      (state) => state.chatReducer
    );
  
  return (
    <div
    className={classNames(settingStyles.container, {
      [settingStyles.light]: theme === "light",
      [settingStyles.dark]: theme === "dark",
    })}
  >
    <div className={settingStyles.memu}>

      <Link
        className={`${itemStyles(RoutePaths.Account)} ${PrivateChat ? settingStyles.isbackdrop : ""}` }
        to={`/${RoutePaths.Settings}`}
        onClick={toggleSidebar}
        data-testid='chat-settings-account'
      >
        <AccountIcon  />
        <span className={`${settingStyles.text} ${chatStyles.textcolor}` }>
          <FormattedMessage id="sidebar.settings.account" />
        </span>
      </Link>
      {userDetail?.user.team.role !== "member" && (
        <>
          <Link
            className={`${itemStyles(RoutePaths.Team)} ${PrivateChat ? settingStyles.isbackdrop : ""}`}
            to={`/${RoutePaths.Settings}/${RoutePaths.Team}`}
            onClick={toggleSidebar}
            data-testid='chat-settings-team'
          >
            <TeamIcon />
            <span className={`${settingStyles.text} ${chatStyles.textcolor}`}>
              <FormattedMessage id="sidebar.settings.myTeam" />
            </span>
          </Link>
          {userDetail?.user.team.role === "admin" && (
            <Link
              className={`${itemStyles(RoutePaths.CurrentPlan)} ${PrivateChat ? settingStyles.isbackdrop : ""}`}
              to={`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`}
              onClick={toggleSidebar}
              data-testid='chat-settings-current'
            >
              <CurrentPlanIcon chatsidebar={true} theme={theme} />
              <span className={`${settingStyles.text} ${chatStyles.textcolor}`}>
                <FormattedMessage id="sidebar.settings.currentPlan" />
              </span>
              <div className={chatStyles.usageTag}>
                    {userDetail.user.activeSubscription.name}
                  </div>
            </Link>
          )}
        </>
      )}
      <div className={chatStyles.themeContainer}>
      <a
        className={`${itemStyles(RoutePaths.Feedback)} ${PrivateChat ? settingStyles.isbackdrop : ""}`}
        href="https://deftgpt.canny.io/feature-requests/create"
        target="_blank"
        rel="noreferrer"
        onClick={toggleSidebar}
        data-testid='chat-setting-feedback'
      >
        <FeedbackIcon />
        <span className={`${settingStyles.text} ${chatStyles.textcolor}`}>
          <FormattedMessage id="sidebar.settings.giveFeedback" />
        </span>
      </a>
      <ThemeSwitch ThemeDark={true} />
      </div>
      {/* <Suspense fallback={<></>}>
        {showReferralModal && (
          <FreeCreditsModal
            onClose={() => setShowReferralModal(false)}
          />
        )}
      </Suspense> */}
    </div>
  </div>
  );
};

export const markdownTermsContent = `
By using products, software, services, forums or websites of Sictec Infotech, Inc. ("**DeftGPT**"), you agree to the following terms and conditions, and any policies, guidelines or amendments thereto that may be presented to you from time to time, including but not limited to Program Policies and Legal Notices (collectively, the "**Terms**"). We may update the Terms in the future, and you will be able to find the most current version of this agreement at https://www.deftgpt.com/terms.
 
#### 1. Use of Products
Sictec Infotech, Inc., its subsidiaries, employees, and affiliated companies ("Sictec Infotech, Inc.") offer DeftGPT to you, provided that you are of legal age to form a binding contract and are not a person barred from receiving products under the laws of the United States or other applicable jurisdiction. In order to access certain products, you may be required to provide current, accurate identification, contact, and other information as part of the registration process and/or continued use of DeftGPT. You are responsible for maintaining the confidentiality of your account password, and are responsible for all activities that occur under your account. You agree to immediately notify Sictec Infotech, Inc. of any unauthorized use of your password or account or any other breach of security. Sictec Infotech, Inc. cannot and will not be liable for any loss or damage arising from your failure to provide us with accurate information or to keep your password secure.
 
#### 2. Appropriate Conduct
You understand that all information, data, text, software, music, sound, photographs, graphics, video, messages or other materials ("Content") are the sole responsibility of the person from which such Content originated. DeftGPT may download, request, or otherwise obtain content from the internet or your own computer at your request. You understand that by using DeftGPT you may be exposed to Content that is offensive, indecent or objectionable, and that you use DeftGPT at your own risk.
 
You agree that you are responsible for your own conduct and any Content that you create, transmit or display while using DeftGPT and for any consequences thereof. You agree to use DeftGPT only for purposes that are legal, proper and in accordance with the Terms and any applicable policies or guidelines. You agree that you will not engage in any activity that interferes with or disrupts DeftGPT or servers or networks connected to DeftGPT. To report any activity or Content that may violate the Terms, please contact at Support@DeftGPT.com
 
In addition to this agreement, your use of some specific DeftGPT is governed by the policies or guidelines specific to those products, and which are specifically incorporated into this agreement.
 
Users outside of the United States agree to comply with their own local rules regarding online conduct and acceptable content, including laws regulating the export of data to and from the United States or your country of residence.
 
#### 3. Sictec Infotech, Inc. Privacy Policy
For information about our data protection practices, please see our Privacy Policy at https://www.deftgpt.com/privacy. By using DeftGPT, you acknowledge and agree that Sictec Infotech, Inc. may access, preserve, and disclose your account information and any Content associated with that account if required to do so by law or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce the Terms, including investigation of potential violations hereof, (c) detect, prevent, or otherwise address fraud, security or technical issues (including, without limitation, the filtering of spam), or (d) protect against imminent harm to the rights, property or safety of Sictec Infotech, Inc., its users or the public as required or permitted by law.
 
You understand that the technical processing and transmission of DeftGPT, including your Content, may involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks, devices or products.
 
#### 4. Proprietary Rights Sictec Infotech, Inc.'s Rights
You acknowledge and agree that DeftGPT and any necessary software used in connection with DeftGPT ("Software") contain proprietary and confidential information that is protected by applicable intellectual property and other laws and treaties. You further acknowledge and agree that Content contained in sponsor advertisements or presented to you through DeftGPT is protected by copyrights, trademarks, service marks, patents or other proprietary rights and laws. Except as expressly authorized by Sictec Infotech, Inc. or other proper third party rights holders, you agree not to modify, rent, lease, loan, sell, distribute or create derivative works based on Content, DeftGPT or Software, in whole or in part except as specifically authorized in a separate written agreement.
 
Subject to the Terms and applicable License Agreements, Sictec Infotech, Inc. grants you a personal, non-transferable and non-exclusive right and license to use the object code of its Software; provided that you do not (and do not allow any third party to) copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, grant a security interest in or otherwise transfer any right in the Software, unless such activity is expressly permitted or required by law or has been expressly authorized by Sictec Infotech, Inc. in writing. You agree not to use modified versions of the Software, including (without limitation) for the purpose of obtaining unauthorized access to DeftGPT. You agree not to access DeftGPT by any means other than through the interface that is provided by Sictec Infotech, Inc. for use in accessing DeftGPT except as specifically authorized in a separate written agreement.
 
Except as expressly authorized by Sictec Infotech, Inc. you agree not to use, copy, imitate, or incorporate any trademark, service mark, trade dress, company name, or product name in a way that is likely to cause confusion among consumers. You also agree not to remove, obscure, or alter Sictec Infotech, Inc.'s or any third party's copyright notice, trademarks, or other proprietary rights notices affixed to or contained within or accessed in conjunction with or through the DeftGPT or Software.
 
**Your Rights**
Sictec Infotech, Inc. claims no ownership or control over any Content submitted, posted or displayed by you on or through DeftGPT. You or a third party licensor, as appropriate, retain all patent, trademark and copyright to any Content you submit, post or display on or through DeftGPT and you are responsible for protecting those rights, as appropriate. By submitting, posting or displaying Content on or through DeftGPT which are intended to be available to the members of the public, you grant Sictec Infotech, Inc. a worldwide, non-exclusive, royalty-free license to reproduce, adapt, modify, publish and distribute such Content on DeftGPT for the purpose of displaying, distributing and promoting DeftGPT. Sictec Infotech, Inc. reserves the right to syndicate Content submitted, posted or displayed by you on or through DeftGPT and use that Content in connection with any product offered by Sictec Infotech, Inc.. Sictec Infotech, Inc. furthermore reserves the right to refuse to accept, post, display or transmit any Content in its sole discretion.
 
You represent and warrant that you have all the rights, power and authority necessary to grant the rights granted herein to any Content submitted.
 
**Other**
Adobe, the Adobe logo, Adobe PDF, the Adobe PDF logo, Acrobat, Flash, the Flash logo (stylized "f"), LiveCycle, LiveCycle Forms ES, PostScript, the PostScript logo, Reader, and XMP are either registered trademarks or trademarks of Adobe Systems Incorporated in the United States and/or other countries.
 
Apple, the Apple logo, iPad, iPhone, iPod touch, Mac, the Mac logo, Mac OS, OS X, Safari, and TrueType are trademarks of Apple, Inc.
 
CNET, "cnet.com", and the CNET logo are trademarks of CBS Interactive, Inc.
 
Facebook and the "f" Logo are trademarks of Facebook, Inc.
 
Google, Google Chrome, Google logo, and Google Chrome logo are trademarks of Google Inc.
 
Intel and Pentium are trademarks of Intel Corporation in the U.S. and/or other countries.
 
Lifehacker and the Lifehacker logo are trademarkes of Kinja, Gawker Media, LLC, Blogwire KFT, and/or any of their affiliates.
 
Mashable, the Mashable Logo, "M" Logo, are trademarks of Mashable, Inc.
 
Microsoft, the .NET logo, ActiveX, Excel, the Excel launch icon, IntelliSense, Internet Explorer, the Internet Explorer logo, PowerPoint, the PowerPoint launch icon, the Security Shield logo, Silverlight, Silverlight logo, SQL Server, Visual Basic, Visual C#, Visual Studio, Windows, the Windows logo, Windows Server, Windows Vista, and the Word launch icon are trademarks of the Microsoft group of companies.
 
Mozilla, the Mozilla logo, Firefox, and the Firefox logo are trademarks of The Mozilla Foundation.
 
Opera and the Opera logo are trademarks of Opera Software ASA.
 
PC World, PCWORLD.COM, PCWORLD.COM online and their respective logos are trademarks of International Data Group, Inc. (IDG Consumer & SMB / IDGCSMB).
 
The Twitter name, Twitter logo, Twitter T, Tweet, and Twitter bird are trademarks of Twitter, Inc.
 
#### 5. SOFTWARE AND AUTOMATIC UPDATES
Your use of any Software provided by Sictec Infotech, Inc. will be governed by the Terms and any additional terms and conditions of the end user license agreement accompanying such Software. Sictec Infotech, Inc. Software may automatically report version number or other diagnostic information and may automatically download upgrades to the Software to update, enhance and further develop DeftGPT, including providing bug fixes, patches, enhanced functions, missing plug-ins and new versions. In the event of a conflict between the Terms and the license agreement of Software, the license agreement shall have precedence.
 
#### 6. POLICIES REGARDING COPYRIGHT AND TRADEMARKS
It is our policy to respond to notices of alleged infringement that comply with the United States' Digital Millennium Copyright Act or other applicable law and to terminating the accounts of repeat infringers.  
 
For information regarding our trademark complaint procedure, please support@DeftGPT.com. Any use of Sictec Infotech, Inc.'s trade names, trademarks, service marks, logos, domain names, and other distinctive brand features must be in compliance with the Terms and in compliance with Sictec Infotech, Inc.'s then current Brand Feature use guidelines.
 
#### 7. GENERAL PRACTICES REGARDING USE AND STORAGE
You agree that Sictec Infotech, Inc. has no responsibility or liability for the deletion or failure to store any Content and other communications maintained or transmitted by DeftGPT. You acknowledge that Sictec Infotech, Inc. may have set no fixed upper limit on the number of transmissions you may send or receive through DeftGPT or the amount of storage space used; however, we retain the right, at our sole discretion, to create limits at any time with or without notice.
 
Upon the termination of your use of DeftGPT, including upon receipt of a certificate or other legal document confirming your death, Sictec Infotech, Inc. will close your account and you will no longer be able to retrieve content contained in that account.
 
#### 8. MODIFICATIONS TO PRODUCT
Sictec Infotech, Inc. reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, DeftGPT (or any part thereof) with or without notice. You agree that Sictec Infotech, Inc. shall not be liable to you or to any third party for any modification, suspension or discontinuance of DeftGPT.
 
#### 9. TERMINATION
You may discontinue your use of DeftGPT at any time. You agree that Sictec Infotech, Inc. may at any time and for any reason, including a period of account inactivity, terminate your access to DeftGPT, terminate the Terms, or suspend or terminate your account. In the event of termination, your account will be disabled and you may not be granted access to DeftGPT, your account or any files or other content contained in your account. Sections 9 (Termination), 12 (Indemnity), 13 (Disclaimer of Warranties), 14 (Limitations of Liability), 15 (Exclusions and Limitations) and 18 (including choice of law, severability and statute of limitations), of the Terms, shall survive expiration or termination.
 
#### 10. ADVERTISEMENTS
Some DeftGPT are supported by advertising revenue and may display advertisements and promotions on the product. Such advertisements may be targeted to the content of information stored on the DeftGPT, queries made through DeftGPT or other information. The manner, mode and extent of advertising by Sictec Infotech, Inc. on its products are subject to change. As consideration for your use of DeftGPT, you agree that Sictec Infotech, Inc. may place such advertising and that Sictec Infotech, Inc. shall not be responsible or liable for any loss or damage of any sort incurred by you as a result of the presence of such advertisers on DeftGPT or your subsequent dealings with advertisers.
 
#### 11. LINKS
DeftGPT may provide, or third parties may provide, links to other World Wide Web sites or resources. Sictec Infotech, Inc. may have no control over such sites and resources and you acknowledge and agree that Sictec Infotech, Inc. is not responsible for the availability of such external sites or resources, and does not endorse and is not responsible or liable for any Content, advertising, products, or other materials on or available from such sites or resources. You further acknowledge and agree that Sictec Infotech, Inc. shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such Content, goods or products available on or through any such site or resource.
 
#### 12. INDEMNITY
You agree to hold harmless and indemnify Sictec Infotech, Inc., and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners, (collectively "Sictec Infotech, Inc. and Partners") from and against any third party claim arising from or in any way related to your use of DeftGPT, violation of the Terms or any other actions connected with use of DeftGPT, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys' fees, of every kind and nature. In such a case, Sictec Infotech, Inc. will provide you with written notice of such claim, suit or action.
 
#### 13. DISCLAIMER OF WARRANTIES
YOU EXPRESSLY UNDERSTAND AND AGREE THAT:
 
YOUR USE OF DEFTGPT IS AT YOUR SOLE RISK. DEFTGPT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, SICTEC INFOTECH, INC. AND PARTNERS EXPRESSLY DISCLAIM ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT.
 
SICTEC INFOTECH, INC. AND PARTNERS DO NOT WARRANT THAT (i) DEFTGPT WILL MEET YOUR REQUIREMENTS, (ii) DEFTGPT WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (iii) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF DEFTGPT WILL BE ACCURATE OR RELIABLE, (iv) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH DEFTGPT WILL MEET YOUR EXPECTATIONS, AND (V) ANY ERRORS IN THE SOFTWARE WILL BE CORRECTED.
 
ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF DEFTGPT IS DONE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR OTHER DEVICE OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
 
NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SICTEC INFOTECH, INC. OR THROUGH OR FROM DEFTGPT SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE TERMS.
 
#### 14. LIMITATION OF LIABILITY
YOU EXPRESSLY UNDERSTAND AND AGREE THAT SICTEC INFOTECH, INC. AND PARTNERS SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SICTEC INFOTECH, INC. OR PARTNERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES) RESULTING FROM: (i) THE USE OR THE INABILITY TO USE DEFTGPT; (ii) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM DEFTGPT; (iii) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON DEFTGPT; OR (v) ANY OTHER MATTER RELATING TO DEFTGPT.
 
#### 15. EXCLUSIONS AND LIMITATIONS
NOTHING IN THIS AGREEMENT IS INTENDED TO EXCLUDE OR LIMIT ANY CONDITION, WARRANTY, RIGHT OR LIABILITY WHICH MAY NOT BE LAWFULLY EXCLUDED OR LIMITED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR CONDITIONS OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR LOSS OR DAMAGE CAUSED BY NEGLIGENCE, BREACH OF CONTRACT OR BREACH OF IMPLIED TERMS, OR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, ONLY THE ABOVE LIMITATIONS IN SECTIONS 13 AND 14 WHICH ARE LAWFUL IN YOUR JURISDICTION WILL APPLY TO YOU AND OUR LIABILITY WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
 
#### 16. NO THIRD PARTY BENEFICIARIES
You agree that, except as otherwise expressly provided in the Terms, there shall be no third party beneficiaries to the Terms.
 
#### 17. NOTICE
You agree that Sictec Infotech, Inc. may provide you with notices, including those regarding changes to the Terms, by email, regular mail, or postings on DeftGPT.
 
 
 
#### 18. REFUND POLICY
Subscription:
Payments are nonrefundable. If you cancel, you will not receive a refund, including for partially used periods of Service. There are circumstances where DeftGPT may provide refunds on a case by case basis. The decision to provide them is at DeftGPT's sole and absolute discretion.

 
 
#### 19. GENERAL INFORMATION
Entire Agreement.
 
The Terms (including any policies, guidelines or amendments that may be presented to your form time to time such as Program Policies and Legal Notices) constitute the entire agreement between you and Sictec Infotech, Inc. and govern your use of DeftGPT, superceding any prior agreements between you and Sictec Infotech, Inc. for the use of DeftGPT. You also may be subject to additional terms and conditions that may apply when you use or purchase certain other DeftGPT, affiliate products, third-party content or third-party software.
 
Choice of Law and Forum.
 
The Terms and the relationship between you and Sictec Infotech, Inc. shall be governed by the laws of the State of Nevada without regard to its conflict of law provisions. You and Sictec Infotech, Inc. agree to submit to the personal and exclusive jurisdiction of the courts located within the county of Nevada, Las vegas.
 
Waiver and Severability of Terms.
 
The failure of Sictec Infotech, Inc. to exercise or enforce any right or provision of the Terms shall not constitute a waiver of such right or provision. If any provision of the Terms is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of the Terms remain in full force and effect.
 
Statute of Limitations.
 
You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to use of DeftGPT or the Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.
 
The section headings in the Terms are for convenience only and have no legal or contractual effect.

`

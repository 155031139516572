import { ReactNode } from "react";
import styled from "styled-components";

import { Main } from "components/Main";

interface IProps {
  children?: ReactNode;
}

const Container = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 769px) {
    min-height: calc(100vh - 154px);
    padding-top: 0;
  }
`;

export const AuthContainer = ({ children }: IProps) => {
  return (
    <Main>
      <Container>{children}</Container>
    </Main>
  );
};

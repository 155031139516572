import { FormattedMessage } from "react-intl";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import classNames from "classnames";
import styles from "./Privacy.module.scss";

import { Main } from "components/Main";
import { markdownPrivacyContent } from "./privacyMd";

import { useSelector } from "redux/hooks";

const Privacy = () => {
  const theme = useSelector((state) => state.authReducer.theme);

  const linkRenderer = ({ href, children }: any) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );

  const tableRenderer = ({ children }: any) => (
    <div style={{ overflowX: "auto" }}>
      <table style={{ minWidth: "500px" }}>{children}</table>
    </div>
  );

  const renderers = {
    a: linkRenderer,
    table: tableRenderer,
  };

  return (
    <Main>
      <h1
        className={classNames(styles.title, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <FormattedMessage id="landing.privacy.title" />
      </h1>
      <div
        className={classNames({
          [styles.contentLight]: theme === "light",
          [styles.contentDark]: theme === "dark",
        })}
      >
        <ReactMarkdown remarkPlugins={[remarkGfm]} components={renderers}>
          {markdownPrivacyContent}
        </ReactMarkdown>
      </div>
    </Main>
  );
};

export default Privacy;

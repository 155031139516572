import React from "react";
import classNames from "classnames";
import styles from "./chatFooter.module.scss";
import { useSelector } from "redux/hooks";
import { ChatType } from "redux/actions";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import useRouter from "hooks/useRouter";
import { IUploadFile } from "pages/ChatPage/ChatPage";
import { useSidebar } from "hooks/services/ReSizeSidebar/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";

interface ChatFooterWrapperProps {
    creditsPerQuery: boolean;
    selectIFile: boolean;
    isFileUploading: boolean;
    url: string | null;
    message: string;
    selectedFile: any;
    share: boolean | undefined;
    uploadingFiles: IUploadFile[] | undefined;
    children: React.ReactNode;
}

export const ChatFooterWrapper: React.FC<ChatFooterWrapperProps> = ({
    creditsPerQuery,
    selectIFile,
    isFileUploading,
    url,
    message,
    selectedFile,
    share,
    uploadingFiles,
    children,
}) => {
    const { pathname } = useRouter();
    const { isOpen } = useToggleSidebar();
    const { gptModel, theme } = useSelector(
        (state) => state.authReducer
    );
    const { messages, newMessages } = useSelector(
        (state) => state.chatReducer
    );
    const creditPerQuery = pathname.includes("chat/new");
    const isShareChat = window.location.pathname.includes("share-chat");
    const { sidebarWidth } = useSidebar();
    const {width} = useWindowSize();

    return (
        <div
            className={classNames(styles.footer, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
                [styles.isSidebarOpen]: isOpen && !isShareChat,
                [styles.IChatFooter]:
                    ((messages[0]?.images &&
                        messages[0]?.images.length > 0 &&
                        !creditPerQuery) ||
                        creditsPerQuery ||
                        (selectIFile && isFileUploading) ||
                        url ||
                        (message &&
                            (selectedFile === null || selectedFile.length === 0)) ||
                        creditsPerQuery) &&
                    gptModel?.type.includes("text"),
                [styles.IGFooter]:
                    (gptModel?.type?.includes(ChatType.image) &&
                        !gptModel?.type?.includes(ChatType.image_chat)) ||
                    (newMessages[0]?.images && newMessages[0]?.images.length > 0),
                [styles.shareChatFooter]: share,
                [styles.ReceiveShareChatFooter]: isShareChat,
                [styles.shareChat]: theme === "light" && isShareChat,
                [styles.isSideBarClose]: !isOpen,
                [styles.uplodingFile]: uploadingFiles && uploadingFiles.length > 0,
                [styles.footerResizer]: sidebarWidth >= 400, 
            })}
            style={{ width: width > 768 && !isShareChat ?  `calc(100% - ${ (sidebarWidth <= 400 )? sidebarWidth+30: sidebarWidth}px)` : "" }}
        >
            {children}
        </div>
    );
};
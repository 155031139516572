interface IProps {
  className?: string;
}

export const PaymentFrequency = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M27.2545 10.9978H4.74512"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.8778 17.2505C25.9857 17.2505 28.5052 19.7699 28.5052 22.8778C28.5052 25.9857 25.9857 28.5052 22.8778 28.5052C19.7699 28.5052 17.2505 25.9857 17.2505 22.8778C17.2505 19.7699 19.7699 17.2505 22.8778 17.2505"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3424 27.2545H8.49668C6.42475 27.2545 4.74512 25.5749 4.74512 23.5029V8.49668C4.74512 6.42475 6.42475 4.74512 8.49668 4.74512H23.5029C25.5749 4.74512 27.2545 6.42475 27.2545 8.49668V19.3424"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.155 20.1135V23.1041"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7192 23.104H23.1551"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49589 15.3745C9.49589 15.4091 9.4679 15.4371 9.43337 15.4371C9.39883 15.4371 9.37084 15.4091 9.37084 15.3745C9.37084 15.34 9.39883 15.312 9.43337 15.312"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43335 15.312C9.46788 15.312 9.49588 15.34 9.49588 15.3745"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8738 15.3745C13.8738 15.4091 13.8458 15.4371 13.8113 15.4371C13.7768 15.4371 13.7488 15.4091 13.7488 15.3745C13.7488 15.34 13.7768 15.312 13.8113 15.312"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8113 15.312C13.8458 15.312 13.8738 15.34 13.8738 15.3745"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49589 19.1262C9.49589 19.1608 9.4679 19.1888 9.43337 19.1888C9.39883 19.1888 9.37084 19.1608 9.37084 19.1262C9.37084 19.0917 9.39883 19.0637 9.43337 19.0637"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43335 19.0637C9.46788 19.0637 9.49588 19.0917 9.49588 19.1262"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8738 19.1262C13.8738 19.1608 13.8458 19.1888 13.8113 19.1888C13.7768 19.1888 13.7488 19.1608 13.7488 19.1262C13.7488 19.0917 13.7768 19.0637 13.8113 19.0637"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8113 19.0637C13.8458 19.0637 13.8738 19.0917 13.8738 19.1262"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.2498 15.3745C18.2498 15.4091 18.2218 15.4371 18.1873 15.4371C18.1527 15.4371 18.1247 15.4091 18.1247 15.3745C18.1247 15.34 18.1527 15.312 18.1873 15.312"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1873 15.312C18.2218 15.312 18.2498 15.34 18.2498 15.3745"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.49589 22.8777C9.49589 22.9122 9.4679 22.9402 9.43337 22.9402C9.39883 22.9402 9.37084 22.9122 9.37084 22.8777C9.37084 22.8432 9.39883 22.8152 9.43337 22.8152"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43335 22.8152C9.46788 22.8152 9.49588 22.8432 9.49588 22.8777"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8738 22.8777C13.8738 22.9122 13.8458 22.9402 13.8113 22.9402C13.7768 22.9402 13.7488 22.9122 13.7488 22.8777C13.7488 22.8432 13.7768 22.8152 13.8113 22.8152"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8113 22.8152C13.8458 22.8152 13.8738 22.8432 13.8738 22.8777"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

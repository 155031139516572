import { Fragment, useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";

import classNames from "classnames";
import styles from "./style.module.scss";

import { SwapIcon, UpscaleIcon, DownloadIcon } from "../icons";

import { Spinner } from "components";
import { UpscaleModal } from "./UpscaleModal";
import { UpscaleErrorModal } from "./UpscaleErrorModal";

import { IUpscaleErrorModal } from "./image";

import { calculateRenderedSize } from "utils/functions";

import { useSelector } from "redux/hooks";
import { IImage } from "redux/actions";

interface IProps {
  message: string;
  images: IImage[];
  image: IImage;
  onDownload: (path: string) => void;
  onSwap: () => void;
  upscaleModal: boolean;
  toggleUpscaleModal: () => void;
  upscaleLoading: boolean;
  onUpscale: (imageId: number) => void;
  upscaleErrorModal: IUpscaleErrorModal;
  onCloseUpscaleErrorModal: () => void;
}

export const LazyImage = ({
  message,
  images,
  image,
  onDownload,
  onSwap,
  upscaleModal,
  toggleUpscaleModal,
  upscaleLoading,
  onUpscale,
  upscaleErrorModal,
  onCloseUpscaleErrorModal,
}: IProps) => {
  const { showUpscaleModal, theme } = useSelector((state) => state.authReducer);
  const maxImageDimension = 350;
  const { size, id, path } = image;

  const { width, height } = calculateRenderedSize({
    originalWidth: Number(size?.split("x")[0]),
    originalHeight: Number(size?.split("x")[1]),
    containerWidth: maxImageDimension,
    containerHeight: maxImageDimension,
  });

  const [loaded, setLoaded] = useState(false);
  const handleImageLoad = () => {
    setLoaded(true);
  };
  const [isVisible, setIsVisible] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "100px 0px",
  });

  useEffect(() => {
    if (inView) setIsVisible(true);
  }, [inView]);

  return (
    <Fragment>
      <div
        className={classNames(styles.imageContainer, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
        style={{
          maxWidth: `${width}px`,
          minWidth: `${width}px`,
          maxHeight: `${height}px`,
          minHeight: `${height}px`,
        }}
      >
        {loaded && (
          <div className={styles.actionButtonsContainer}>
            {images.length > 1 ? (
              <button data-testid='swap-image' className={styles.button} onClick={onSwap}>
                <SwapIcon />
              </button>
            ) : (
              <button
              data-testid='toggle-upscale-model'
                className={styles.button}
                onClick={() => {
                  if (showUpscaleModal === "show") toggleUpscaleModal();
                  else onUpscale(image.id);
                }}
              >
                {upscaleModal === false && upscaleLoading ? (
                  <Spinner extraSmall isBlack />
                ) : (
                  <UpscaleIcon />
                )}
              </button>
            )}
            <button data-testid='download-btn' className={styles.button} onClick={() => onDownload(path)}>
              <DownloadIcon />
            </button>
          </div>
        )}
        {message && <div className={styles.messageContainer}>{message}</div>}
        <div ref={ref}>
          <img
            src={path}
            alt={`Image ${id}`}
            style={{
              opacity: isVisible ? 1 : 0,
              maxWidth: `${width}px`,
              minWidth: `${width}px`,
              maxHeight: `${height}px`,
              minHeight: `${height}px`,
            }}
            onLoad={handleImageLoad}
          />
        </div>
      </div>
      {upscaleModal && (
        <UpscaleModal
          onClose={toggleUpscaleModal}
          isloading={upscaleLoading}
          imageId={image.id}
          onUpscale={onUpscale}
        />
      )}
      {upscaleErrorModal.show && (
        <UpscaleErrorModal
          onClose={onCloseUpscaleErrorModal}
          message={upscaleErrorModal.message}
        />
      )}
    </Fragment>
  );
};

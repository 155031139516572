import { Main } from "components/Main";
import { useIntl } from "react-intl";
import { useSelector } from "redux/hooks";

import {
  OnTheGoAIIcon,
  InstantAssistanceIcon,
  SeamlessIntegrationIcon,
  CustomizableSettingsIcon,
  EnhancedProductivityIcon,
  ExpandedCapabilitiesIcon,
  InstallExtensionIcon,
  EnableExtensionIcon,
  EngageAIIcon,

} from "pages/Features/ChromeExtension/icons";

import {
  Hero,
  IImage,
  PerksOfFeature,
  IFeatureItem,
  HowToUseTheFeature,
  IFeature,
  Faqs,
  IFaq,
  iconSizeClassName,
  iconClassName,
} from "pages/Features/components";

const heroImages: IImage[] = [
  {
    src: "/feature/extension/image1.png",
    alt: "",
  },
  {
    src: "/feature/extension/image2.png",
    alt: "",
  },
  {
    src: "/feature/extension/image3.png",
    alt: "",
  },
  {
    src: "/feature/extension/image4.png",
    alt: "",
  },
  {
    src: "/feature/extension/image5.png",
    alt: "",
  },
  {
    src: "/feature/extension/image6.png",
    alt: "",
  },
];

const featureItems: IFeatureItem[] = [
  {
    icon: <OnTheGoAIIcon className={iconSizeClassName} />,
    title: "feature.extension.onTheGoAITitle",
    content: "feature.extension.onTheGoAIContent",
  },
  {
    icon: <InstantAssistanceIcon className={iconSizeClassName} />,
    title: "feature.extension.instantAssistanceTitle",
    content: "feature.extension.instantAssistanceContent",
  },
  {
    icon: <SeamlessIntegrationIcon className={iconSizeClassName} />,
    title: "feature.extension.seamlessIntegrationTitle",
    content: "feature.extension.seamlessIntegrationContent",
  },
  {
    icon: <CustomizableSettingsIcon className={iconSizeClassName} />,
    title: "feature.extension.customizableSettingsTitle",
    content: "feature.extension.customizableSettingsContent",
  },
  {
    icon: <EnhancedProductivityIcon className={iconSizeClassName} />,
    title: "feature.extension.enhancedProductivityTitle",
    content: "feature.extension.enhancedProductivityTitle",
  },
  {
    icon: <ExpandedCapabilitiesIcon className={iconSizeClassName} />,
    title: "feature.extension.expandedCapabilitiesTitle",
    content: "feature.extension.expandedCapabilitiesContent",
  },
];

const features: IFeature[] = [
  {
    icon: <InstallExtensionIcon className={iconClassName} />,
    title: "feature.extension.installExtensionTitle",
    content: "feature.extension.installExtensionContent",
  },
  {
    icon: <EnableExtensionIcon className={iconClassName} />,
    title: "feature.extension.enableExtensionTitle",
    content: "feature.extension.enableExtensionContent",
  },
  {
    icon: <EngageAIIcon className={iconClassName} />,
    title: "feature.extension.engageAITitle",
    content: "feature.extension.engageAIContent",
  },
];

const faqs: IFaq[] = [
  {
    title: "feature.extension.faq1Title",
    content: "feature.extension.faq1Content",
  },
  {
    title: "feature.extension.faq2Title",
    content: "feature.extension.faq2Content",
  },
  {
    title: "feature.extension.faq3Title",
    content: "feature.extension.faq3Content",
  },
];

export const ChromeExtensionPage = () => {
  const { formatMessage } = useIntl();
  const themeMode = useSelector((state) => state.authReducer.theme);
    
  return (
    <Main isHasBgOnFooter activeSection="chromeExtension">
      <Hero
        themeMode={themeMode}
        images={heroImages}
        title={formatMessage({ id: "feature.extension.title" })}
        content={formatMessage({ id: "feature.extension.content" })}
      />
      <PerksOfFeature features={featureItems} themeMode={themeMode} />
      <HowToUseTheFeature features={features} themeMode={themeMode} />
      <Faqs faqs={faqs} themeMode={themeMode} />
    </Main>
  );
};



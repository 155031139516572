export const DownloadIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="79"
    height="79"
    viewBox="0 0 79 79"
    fill="none"
  >
    <path
      d="M13.168 55.9585V62.5418C13.168 64.2878 13.8616 65.9623 15.0962 67.1969C16.3308 68.4316 18.0053 69.1252 19.7513 69.1252H59.2513C60.9973 69.1252 62.6718 68.4316 63.9064 67.1969C65.141 65.9623 65.8346 64.2878 65.8346 62.5418V55.9585"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.043 36.2085L39.5013 52.6668L55.9596 36.2085"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.5 13.1665V52.6665"
      stroke="#7C4DFF"
      strokeWidth="5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

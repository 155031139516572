import ReactDOM from "react-dom/client";
import "./index.css";
import "./reset.css";
import { App } from "./App";

// types.d.ts
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<App />);

interface IProps {
  color?: string;
  chatList?: boolean

}

export const ImageIcon = ({ color = "#FFFFFF", chatList }: IProps) => {
  return (
    <svg
      width={chatList ? "16" : "22"}
      height={chatList ? "16" : "22"}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.125 19.25C3.75833 19.25 3.4375 19.1125 3.1625 18.8375C2.8875 18.5625 2.75 18.2417 2.75 17.875V4.125C2.75 3.75833 2.8875 3.4375 3.1625 3.1625C3.4375 2.8875 3.75833 2.75 4.125 2.75H17.875C18.2417 2.75 18.5625 2.8875 18.8375 3.1625C19.1125 3.4375 19.25 3.75833 19.25 4.125V17.875C19.25 18.2417 19.1125 18.5625 18.8375 18.8375C18.5625 19.1125 18.2417 19.25 17.875 19.25H4.125ZM4.125 17.875H17.875V4.125H4.125V17.875ZM5.40833 15.6521H16.6146L13.2458 11.1604L10.2208 15.0792L8.08958 12.1687L5.40833 15.6521Z"
        fill={color}
      />
    </svg>
  );
};

export const NewImageIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8683 1.83594H5.10034C3.09234 1.83594 1.83301 3.2586 1.83301 5.27194V10.7026C1.83301 12.7159 3.08701 14.1386 5.10034 14.1386H10.865C12.8817 14.1386 14.135 12.7159 14.135 10.7026V5.27194C14.1377 3.2586 12.8837 1.83594 10.8683 1.83594Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M7.13516 5.85567C7.13516 6.53567 6.58449 7.08633 5.90449 7.08633C5.22516 7.08633 4.67383 6.53567 4.67383 5.85567C4.67383 5.17567 5.22516 4.625 5.90449 4.625C6.58383 4.62567 7.13449 5.17633 7.13516 5.85567Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.138 9.96735C13.5227 9.33402 12.3393 8.05469 11.0527 8.05469C9.76533 8.05469 9.02333 10.8767 7.78533 10.8767C6.54733 10.8767 5.42267 9.60069 4.43067 10.4187C3.43867 11.236 2.5 12.9074 2.5 12.9074" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export const ImageGenerationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8683 1.83203H5.10034C3.09234 1.83203 1.83301 3.2547 1.83301 5.26803V10.6987C1.83301 12.712 3.08701 14.1347 5.10034 14.1347H10.865C12.8817 14.1347 14.135 12.712 14.135 10.6987V5.26803C14.1377 3.2547 12.8837 1.83203 10.8683 1.83203Z" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M14.138 9.96735C13.5227 9.33402 12.3393 8.05469 11.0527 8.05469C9.76533 8.05469 9.02333 10.8767 7.78533 10.8767C6.54733 10.8767 5.42267 9.60069 4.43067 10.4187C3.43867 11.236 2.5 12.9074 2.5 12.9074" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M4 7L5.004 4.24H5.34L5.372 4.864L4.664 7H4ZM4.676 6.456V5.948H5.98V6.456H4.676ZM5.236 4.864L5.252 4.24H5.62L6.624 7H5.944L5.236 4.864ZM6.885 7V6.52H8.245V7H6.885ZM6.885 4.72V4.24H8.245V4.72H6.885ZM7.245 7V4.24H7.885V7H7.245Z" fill="white" />
    </svg>
  );
}
export const BottomArrow = () => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.53033 0.46967C1.23744 0.176776 0.762563 0.176776 0.46967 0.46967C0.176776 0.762563 0.176776 1.23744 0.46967 1.53033L6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033L13.5303 1.53033C13.8232 1.23744 13.8232 0.762563 13.5303 0.46967C13.2374 0.176776 12.7626 0.176776 12.4697 0.46967L7 5.93934L1.53033 0.46967ZM1.53033 7.46967C1.23744 7.17678 0.762563 7.17678 0.46967 7.46967C0.176776 7.76256 0.176776 8.23744 0.46967 8.53033L6.46967 14.5303C6.76256 14.8232 7.23744 14.8232 7.53033 14.5303L13.5303 8.53033C13.8232 8.23744 13.8232 7.76256 13.5303 7.46967C13.2374 7.17678 12.7626 7.17678 12.4697 7.46967L7 12.9393L1.53033 7.46967Z"
      fill="white"
    />
  </svg>
);

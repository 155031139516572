interface IProps {
  className?: string;
}

export const Calendar = ({ className }: IProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.6001 2.5V7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6001 2.5V7.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3501 11.25H26.8501"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.3501 5H6.8501C5.46885 5 4.3501 6.11875 4.3501 7.5V23.75C4.3501 25.1313 5.46885 26.25 6.8501 26.25H24.3501C25.7313 26.25 26.8501 25.1313 26.8501 23.75V7.5C26.8501 6.11875 25.7313 5 24.3501 5Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.36643 15.9111C9.19393 15.9111 9.05393 16.0511 9.05518 16.2236C9.05518 16.3961 9.19518 16.5361 9.36768 16.5361C9.54018 16.5361 9.68018 16.3961 9.68018 16.2236C9.68018 16.0511 9.54018 15.9111 9.36643 15.9111"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6164 15.9111C15.4439 15.9111 15.3039 16.0511 15.3052 16.2236C15.3052 16.3961 15.4452 16.5361 15.6177 16.5361C15.7902 16.5361 15.9302 16.3961 15.9302 16.2236C15.9302 16.0511 15.7902 15.9111 15.6164 15.9111"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.8664 15.9111C21.6939 15.9111 21.5539 16.0511 21.5552 16.2236C21.5552 16.3961 21.6952 16.5361 21.8677 16.5361C22.0402 16.5361 22.1802 16.3961 22.1802 16.2236C22.1802 16.0511 22.0402 15.9111 21.8664 15.9111"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.36643 20.9111C9.19393 20.9111 9.05393 21.0511 9.05518 21.2236C9.05518 21.3961 9.19518 21.5361 9.36768 21.5361C9.54018 21.5361 9.68018 21.3961 9.68018 21.2236C9.68018 21.0511 9.54018 20.9111 9.36643 20.9111"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6164 20.9111C15.4439 20.9111 15.3039 21.0511 15.3052 21.2236C15.3052 21.3961 15.4452 21.5361 15.6177 21.5361C15.7902 21.5361 15.9302 21.3961 15.9302 21.2236C15.9302 21.0511 15.7902 20.9111 15.6164 20.9111"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

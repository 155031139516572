// interface props{
//     isPrivateChat?:boolean;
//     lightTheme?:boolean;
// }
export const UploadIcon = () => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29" fill="none">
            <g clipPath="url(#clip0_6104_21181)">
                <path d="M14.6509 9.55727L12.8577 9.52727L12.7023 18.4965C12.6963 18.8498 12.7599 19.2008 12.8896 19.5296C13.0192 19.8583 13.2124 20.1583 13.4579 20.4124C13.7035 20.6665 13.9968 20.8698 14.3209 21.0106C14.645 21.1514 14.9937 21.2269 15.347 21.233C15.7004 21.239 16.0514 21.1754 16.3802 21.0457C16.7089 20.9161 17.0089 20.7229 17.263 20.4774C17.5171 20.2318 17.7204 19.9386 17.8612 19.6144C18.0019 19.2903 18.0775 18.9416 18.0835 18.5883L18.2698 7.82507C18.2902 6.63567 17.8373 5.4869 17.0106 4.63147C16.184 3.77604 15.0514 3.28402 13.862 3.26364C12.6726 3.24327 11.5239 3.69622 10.6684 4.52284C9.81301 5.34946 9.32098 6.48205 9.30061 7.67144L9.10506 18.972L9.10376 18.9958C9.04424 22.4513 11.7957 25.2992 15.2503 25.3579C18.7049 25.4165 21.5529 22.665 21.6133 19.2104L21.6128 19.1866L21.6146 19.1866L21.7484 11.4723L19.9552 11.4423L19.8206 19.1557L19.8202 19.1787C19.7986 20.3615 19.3087 21.4874 18.4579 22.3094C17.6071 23.1313 16.465 23.5822 15.2821 23.563C14.6946 23.5522 14.115 23.4256 13.5765 23.1904C13.0381 22.9551 12.5513 22.6159 12.1442 22.1922C11.7371 21.7685 11.4176 21.2686 11.2041 20.7211C10.9906 20.1737 10.8872 19.5895 10.9 19.002L10.8982 19.002L11.0947 7.70058C11.1193 6.21674 12.3473 5.03033 13.832 5.05591C15.3167 5.08148 16.5014 6.30946 16.4758 7.79239L16.2895 18.5556C16.2759 18.7867 16.1735 19.0035 16.0038 19.1609C15.834 19.3183 15.61 19.404 15.3786 19.4001C15.1471 19.3962 14.9262 19.3031 14.7618 19.1401C14.5974 18.9772 14.5023 18.757 14.4964 18.5256L14.6509 9.55551L14.6509 9.55727Z" fill="#527AE6" />
            </g>
            <defs>
                <clipPath id="clip0_6104_21181">
                    <rect width="20" height="20" fill="white" transform="translate(0.859863 14) rotate(-44.0131)" />
                </clipPath>
            </defs>
        </svg>
    )
}
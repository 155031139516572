import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";
import { Field, Formik, FieldProps } from "formik";

import classNames from "classnames";
import styles from "../ModalStyle.module.scss";
import styled from "styled-components";

import { CloseIcon } from "components/icons/CloseIcon";

import { FieldItem, Form } from "pages/AuthPages/components/FormComponents";
import {
  LabeledInput,
  DropDown,
  DropDownRow,
  Modal,
  Separator,
} from "components";
import CustomButton from "components/Button";

import {
  UserRolesType,
  UserRoles,
  IInviteMemberBody,
  IUserDetail,
} from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  userRole?: UserRolesType;
  userDetail?: IUserDetail;
  onClose?: () => void;
  onSubmit?: (data: IInviteMemberBody) => void;
  submitLoading?: boolean;
}

const FieldsRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FieldRowItem = styled(FieldItem)`
  border-radius: 10px;
  margin-bottom: 0;
`;

const Footer = styled.div`
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 16px 0 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 12px;
`;

export const InviteMemberModal = ({
  userRole,
  userDetail,
  onClose,
  onSubmit,
  submitLoading,
}: IProps) => {
  const { formatMessage } = useIntl();
  const { theme } = useSelector((state) => state.authReducer);

  const [status, setStatus] = useState<UserRolesType>(UserRoles.member);

  const roles: DropDownRow.IDataItem[] = [
    {
      label: formatMessage({ id: `team.invite.option.${UserRoles.manager}` }),
      value: UserRoles.manager,
    },
    {
      label: formatMessage({ id: `team.invite.option.${UserRoles.member}` }),
      value: UserRoles.member,
    },
  ];

  const AddMemberValidateionSchema = yup.object().shape({
    name: yup
    .string()
    .min(3, "Invite.name.empty.error.min").max(30, "Invite.name.empty.error.max")
    .required("Invite.name.empty.error"),
    email: yup
      .string()
      .email("modal.inviteTeam.email.error")
      .required("modal.inviteTeam.email.empty.error"),
    daily_limit: yup
      .number()
      .required("modal.inviteTeam.dailyLimit.empty.error")
      .typeError("modal.inviteTeam.dailyLimit.digitOnly.error")
      .min(
        10,
        formatMessage(
          { id: "modal.inviteTeam.dailyLimit.min.error" },
          { credits: 10 }
        )
      )
      .max(
        Number(userDetail?.user.activeSubscription.credits),
        formatMessage(
          { id: "modal.inviteTeam.dailyLimit.max.error" },
          { credits: Number(userDetail?.user.activeSubscription.credits) }
        )
      ),
  });

  const renderRolesOption = (): DropDownRow.IDataItem[] => {
    if (userRole === UserRoles.admin) {
      return roles;
    } else if (userRole === UserRoles.manager) {
      return roles.filter((role) => role.value !== UserRoles.manager);
    } else {
      return [];
    }
  };

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.header}>
        <div className={styles.headerTextContainer}>
          <div className={styles.title}>
            <FormattedMessage id="modal.inviteTeam.heading" />
          </div>
          <div className={styles.description}>
            <FormattedMessage id="modal.inviteTeam.subHeading" />
          </div>
        </div>
        <div className={styles.crossBtn} onClick={onClose} data-testid='team-form-cross-btn'>
          <CloseIcon />
        </div>
      </div>
      <Formik
        onSubmit={(values) => {
          onSubmit?.({
            role: status,
            email: values.email,
            daily_limit: Number(values.daily_limit),
            team_id: userDetail?.user.team.id as number,
            name: values.name,
          });
        }}
        initialValues={{ email: "", daily_limit: "", name: "" }}
        validationSchema={AddMemberValidateionSchema}
        validateOnBlur={false}
      >
        <Form data-testid='team-invite-form'>
          <Separator />
          <FieldsRow>
            <FieldRowItem>
              <Field name="name">
                {({ field, meta }: FieldProps<string>) => (
                  <LabeledInput
                    {...field}
                    placeholder={formatMessage({
                      id: "team.table.th.name",
                    })}
                    type="name"
                    error={!!meta.error && meta.touched}
                    message={
                      meta.touched &&
                      meta.error &&
                      formatMessage({ id: meta.error })
                    }
                    variant={theme}
                    data-testid='team-invite-name'
                  />
                )}
              </Field>
            </FieldRowItem>
          </FieldsRow>
          <Separator />
          <FieldsRow>
            <FieldRowItem>
              <Field name="email">
                {({ field, meta }: FieldProps<string>) => (
                  <LabeledInput
                    {...field}
                    placeholder={formatMessage({
                      id: "modal.inviteTeam.email.placeholder",
                    })}
                    type="email"
                    error={!!meta.error && meta.touched}
                    message={
                      meta.touched &&
                      meta.error &&
                      formatMessage({ id: meta.error })
                    }
                    variant={theme}
                    data-testid='team-invite-email'
                  />
                )}
              </Field>
            </FieldRowItem>
          </FieldsRow>
          <Separator />
          <FieldsRow>
            <FieldRowItem>
              <Field name="daily_limit">
                {({ field, meta }: FieldProps<string>) => (
                  <LabeledInput
                    {...field}
                    placeholder={formatMessage({
                      id: "modal.inviteTeam.dailyLimit.placeholder",
                    })}
                    type="text"
                    error={!!meta.error && meta.touched}
                    message={
                      meta.touched &&
                      meta.error &&
                      formatMessage({ id: meta.error })
                    }
                    variant={theme}
                    data-testid='team-invite-daily-limit'
                  />
                )}
              </Field>
            </FieldRowItem>
          </FieldsRow>
          <Separator height="25px" />
          <FieldsRow>
            <FieldRowItem>
              <DropDown
                height={43}
                options={renderRolesOption()}
                value={status}
                placeholder={formatMessage({
                  id: "modal.inviteTeam.status.placeholder",
                })}
                onChange={(item: DropDownRow.IDataItem) =>
                  setStatus(item.value)
                }
                variant={theme}
                data-testid='team-invite-role-dropdown'
              />
            </FieldRowItem>
          </FieldsRow>
          <Separator />
          <Footer>
            <CustomButton
              style={{ minWidth: "100px" }}
              variant="outlined-primary"
              type="button"
              onClick={onClose}
              data-testid='invite-team-btn-cancel'
            >
              <div
                className={classNames(styles.buttonText, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage id="modal.inviteTeam.btn.cancel" />
              </div>
            </CustomButton>
            <CustomButton
              style={{ minWidth: "100px" }}
              variant="primary"
              type="submit"
              isloading={submitLoading}
              data-testid='invite-team-btn-invite'
            >
              <FormattedMessage id="modal.inviteTeam.btn.invite" />
            </CustomButton>
          </Footer>
        </Form>
      </Formik>
    </Modal>
  );
};

export const SelectModelIcon = ({
  height = 70,
  width = 70,
  className,
}: {
  className?: string;
  height?: number;
  width?: number;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      d="M27.7905 37.6623C27.9265 37.7316 28.1319 37.7983 28.268 37.7983C28.6788 37.7983 29.0869 37.5929 29.2924 37.1848C29.5645 36.6379 29.3617 35.955 28.7455 35.6829C28.1319 35.3414 27.7211 34.7279 27.7211 33.9756V22.5019C27.7211 21.4081 28.6094 20.5225 29.6338 20.5225H41.1076C42.2013 20.5225 43.0869 21.4108 43.0869 22.5019V33.9756C43.0869 34.3171 43.0176 34.5892 42.8148 34.8639C42.4734 35.4108 42.6788 36.0937 43.1563 36.3658C43.7032 36.7073 44.3861 36.5019 44.6582 36.0244C44.9996 35.4108 45.205 34.7279 45.205 33.9756V22.5019C45.205 20.181 43.3617 18.335 41.0381 18.335L29.703 18.3376C27.3822 18.3376 25.5361 20.181 25.5361 22.5045V33.9783C25.5361 35.5469 26.4246 36.9794 27.7905 37.6623Z"
      fill="#7C4DFF"
    />
    <path
      d="M63.7097 18.3389H52.2387C49.9178 18.3389 48.0718 20.1823 48.0718 22.5058V33.9796C48.0718 36.3004 49.9151 38.1465 52.2387 38.1465H63.7124C66.0333 38.1465 67.8793 36.3031 67.8793 33.9796L67.8767 22.5031C67.8767 20.1823 66.0333 18.3389 63.7097 18.3389ZM65.6224 33.9764C65.6224 35.0702 64.7341 35.8892 63.7097 35.8892H52.2387C51.1449 35.8892 50.326 35.0008 50.326 33.9764V22.5027C50.326 21.4089 51.2143 20.59 52.2387 20.59H63.7124C64.8062 20.59 65.6251 21.4783 65.6251 22.5027L65.6224 33.9764Z"
      fill="#7C4DFF"
    />
    <path
      d="M18.6379 18.3389H7.1669C4.84607 18.3389 3 20.1823 3 22.5058V33.9796C3 36.3004 4.84334 38.1465 7.1669 38.1465H18.6406C20.9615 38.1465 22.8075 36.3031 22.8075 33.9796L22.8049 22.5031C22.8049 20.1823 20.9615 18.3389 18.6379 18.3389ZM20.6199 33.9764C20.6199 35.0702 19.7316 35.8892 18.7072 35.8892H7.16724C6.07348 35.8892 5.25452 35.0008 5.25452 33.9764V22.5027C5.25452 21.4089 6.14286 20.59 7.16724 20.59H18.641C19.7347 20.59 20.5537 21.4783 20.5537 22.5027L20.551 33.9764H20.6199Z"
      fill="#7C4DFF"
    />
    <path
      d="M50.2585 41.0772C49.645 41.0772 49.0981 41.2826 48.5512 41.5547C48.0043 40.3249 46.7745 39.4365 45.2727 39.4365C44.5897 39.4365 43.9762 39.642 43.496 39.9141C42.8824 38.8203 41.7193 38.0013 40.3535 38.0013C39.8759 38.0013 39.3985 38.0707 38.9876 38.2734V31.4442C38.9876 29.4649 37.3497 27.8242 35.3676 27.8242C33.3882 27.8242 31.7476 29.4621 31.7476 31.4442V47.6984L29.6988 45.7857C29.0159 45.1028 28.0609 44.8307 27.1032 44.8307C26.1482 44.9001 25.2598 45.3082 24.6436 46.0605C23.5498 47.357 23.5498 49.203 24.6436 50.4995L31.8142 59.2415C33.8017 61.7038 36.6 63.0003 39.5374 63.0003H45.1368C49.9866 63.0003 53.8788 59.1082 53.8788 54.2582V44.6973C53.8788 42.7179 52.2406 41.0772 50.2585 41.0772ZM51.6244 54.2582C51.6244 57.8088 48.6873 60.7459 45.1366 60.7459H39.5372C37.2164 60.7459 35.0289 59.7216 33.5964 57.9449L26.4257 49.2056C26.0149 48.7281 26.0149 48.0451 26.4257 47.5676C26.6311 47.2955 26.9726 47.1568 27.3141 47.0901C27.6555 47.0901 27.997 47.1595 28.269 47.4316L32.2305 51.1183C32.572 51.3904 33.0495 51.5291 33.4603 51.3237C33.8711 51.1183 34.1433 50.7768 34.1433 50.2993V31.5163C34.1433 30.764 34.7568 30.1504 35.5091 30.1504C36.2614 30.1504 36.8749 30.764 36.8749 31.5163V43.8785C36.8749 44.4921 37.3524 44.9723 37.9687 44.9723C38.5823 44.9723 39.0625 44.4947 39.0625 43.8785V41.6243C39.0625 40.872 39.676 40.2585 40.4283 40.2585C41.1806 40.2585 41.7941 40.872 41.7941 41.6243V44.8335C41.7941 45.447 42.2716 45.9272 42.8879 45.9272C43.5015 45.9272 43.9817 45.4497 43.9817 44.8335V42.9901C43.9817 42.2378 44.5952 41.6243 45.3475 41.6243C46.0998 41.6243 46.7133 42.2379 46.7133 42.9901V45.7912C46.7133 46.4048 47.1909 46.885 47.8071 46.885C48.4207 46.885 48.9009 46.4075 48.9009 45.7912V44.6308C48.9009 43.8785 49.5144 43.265 50.2667 43.265C51.019 43.265 51.6326 43.8785 51.6326 44.6308L51.6244 54.2582Z"
      fill="#7C4DFF"
    />
    <path
      d="M35.4385 13.4877C36.0521 13.4877 36.5323 13.0102 36.5323 12.394V8.16042C36.5323 7.4775 36.0521 7 35.4385 7C34.8249 7 34.3447 7.47751 34.3447 8.09376V12.3273C34.3447 13.0102 34.8249 13.4877 35.4385 13.4877Z"
      fill="#7C4DFF"
    />
    <path
      d="M29.293 14.4414C29.4984 14.7829 29.8399 14.9189 30.1814 14.9189C30.3868 14.9189 30.5922 14.8496 30.7949 14.7135C31.2724 14.3721 31.4085 13.6891 31.067 13.1423L28.6768 9.65827C28.3353 9.18076 27.6524 9.04471 27.1055 9.38617C26.628 9.72763 26.492 10.4105 26.8334 10.9574L29.293 14.4414Z"
      fill="#7C4DFF"
    />
    <path
      d="M40.6971 14.9219C41.0386 14.9219 41.3801 14.7858 41.5855 14.4443L43.9757 10.9604C44.3172 10.4828 44.1811 9.73056 43.7036 9.3891C43.1567 9.04764 42.4738 9.18369 42.1323 9.6612L39.7421 13.1452C39.4007 13.6227 39.5367 14.375 40.0142 14.7164C40.2196 14.8525 40.4917 14.9219 40.6971 14.9219Z"
      fill="#7C4DFF"
    />
  </svg>
);
interface Props{
  EditIcon?:boolean,
}
export const EditIcon = ({EditIcon}:Props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 16.25H4.66667L13.8958 7.02079L12.9792 6.10413L3.75 15.3333V16.25ZM16.5417 6.12496L13.875 3.45829L14.75 2.58329C14.9861 2.34718 15.2778 2.22913 15.625 2.22913C15.9722 2.22913 16.2639 2.34718 16.5 2.58329L17.4167 3.49996C17.6528 3.73607 17.7708 4.02774 17.7708 4.37496C17.7708 4.72218 17.6528 5.01385 17.4167 5.24996L16.5417 6.12496ZM15.6667 6.99996L5.16667 17.5H2.5V14.8333L13 4.33329L15.6667 6.99996ZM13.4375 6.56246L12.9792 6.10413L13.8958 7.02079L13.4375 6.56246Z"
        fill={EditIcon ? "#2A2831":"white"}
      />
    </svg>
  );
};
import { IAction, TYPES } from "../types";
import { produce } from "immer";
import { ICredit, IPlan, IActivePlan } from "../actions";

export interface IPlanSubscriptionState {
  plan: IPlan[];
  credits: ICredit[];
  activePlan: IActivePlan | undefined;
}

interface IGetPlanAction extends IAction {
  payload: IPlan[];
}

interface IGetCreditsAction extends IAction {
  payload: ICredit[];
}

interface IGetActivePlanAction extends IAction {
  payload: IActivePlan | undefined;
}

type Action = IGetPlanAction & IGetCreditsAction & IGetActivePlanAction;

const state: IPlanSubscriptionState = {
  plan: [],
  credits: [],
  activePlan: undefined,
};

export const planSubscriptionReducer = (
  mState = { ...state },
  action: Action
): IPlanSubscriptionState => {
  switch (action.type) {
    case TYPES.GET_PLAN:
      return produce(mState, (dState) => {
        dState.plan = action.payload;
      });

    case TYPES.GET_CREDITS_LIST:
      return produce(mState, (dState) => {
        dState.credits = action.payload;
      });

    case TYPES.GET_ACTIVE_PLAN:
      return produce(mState, (dState) => {
        dState.activePlan = action.payload;
      });

    default:
      return { ...mState };
  }
};

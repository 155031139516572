import { useState } from "react";
import { FormattedMessage } from "react-intl";

import classNames from "classnames";
import styles from "./AccountNav.module.scss";

import { LogoutIcon } from "./icons";

import { useAppNotification } from "hooks/services/AppNotification";

import { useSelector } from "redux/hooks";
import { logout } from "redux/actions";

export const AccountNav = () => {
  const { theme } = useSelector((state) => state.authReducer);
  const { triggerNotification } = useAppNotification();

  const [isloading, setIsloading] = useState<boolean>(false);

  const onLogout = () => {
    setIsloading(true);
    logout()
      .then(() => {
        localStorage.removeItem('email');
        window.postMessage({ logout: true });
        setIsloading(false);
      })
      .catch((err: any) => {
        triggerNotification({ message: err?.data?.message, type: "error" });
        setIsloading(false);
      });
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.light]: theme === "light",
        [styles.dark]: theme === "dark",
      })}
    >
      {/* <div
        className={classNames(styles.heading, {
          [styles.isSidebarOpen]: isOpen,
        })}
      >
        <FormattedMessage id="navbar.setting.account.title" />
      </div> */}

      <button
        className={classNames(styles.logoutButton, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
        onClick={onLogout}
        disabled={isloading}
        id="logout"
        data-testid='account-logout-btn'
      >
        <div
          className={classNames(styles.btnContent, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <div className={styles.text}>
            <FormattedMessage id="settings.account.logout" />
          </div>
          <LogoutIcon className={styles.icon} />
        </div>
      </button>
    </div>
  );
};

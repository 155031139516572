import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import CustomButton from "components/Button";
import { ThemeSwitch } from "../ThemeSwitch";
import { RoutePaths } from "pages/routePaths";
import useRouter from "hooks/useRouter";
import { AFFILIATE_SIGN_UP_LINK } from "pages/AffiliatePage/components";

interface Iprops {
  lang?: string;
}

interface DefaultSectionProps {
  lang?: string;
  push: (path: string) => void;
}

// AffiliateSection component for rendering the affiliate-specific section
// 用于渲染专属 Affiliate 页面的组件
const AffiliateSection = () => (
  <div className="pl-3">
    <div className="hidden items-center md:flex md:gap-3">
      <ThemeSwitch />
      <CustomButton
        variant="latest-primary"
        data-testid="affiliate-nav-btn-sign"
        onClick={() => window.open(AFFILIATE_SIGN_UP_LINK, "_blank")}
      >
        <FormattedMessage id="affiliate.nav.btn.sign-up.text" />
      </CustomButton>
    </div>
    <span
      onClick={() => window.open(AFFILIATE_SIGN_UP_LINK, "_blank")}
      className="md:hidden font-bold text-[15px] leading-6 rounded-[10px] no-underline ml-[10px] cursor-pointer text-primary"
      data-testid="affiliate-nav-btn-sign"
    >
      <FormattedMessage id="affiliate.nav.btn.sign-up.text" />
    </span>
  </div>
);

// DefaultSection component for rendering the default section
// 用于渲染默认页面的组件
const DefaultSection = ({ lang, push }: DefaultSectionProps) => (
  <>
    <ThemeSwitch />
    <Link to={`/${lang}/${RoutePaths.Login}`}>
      <span
        className="font-bold text-[18px] leading-6 rounded-[10px] no-underline ml-[10px] cursor-pointer text-primary"
        data-testid="landing-nav-login"
      >
        <FormattedMessage id="landing.nav.login" />
      </span>
    </Link>
    <div className="pl-3 hidden lg:block">
      <CustomButton
        variant="latest-primary"
        data-testid="landing-nav-SignUp"
        onClick={() => push(`/${lang}/${RoutePaths.Signup}`)}
      >
        <FormattedMessage id="landing.nav.SignUp" />
      </CustomButton>
    </div>
  </>
);

// RightSection component for rendering the right section of the header
// 用于渲染头部右侧部分的 RightSection 组件
export const RightSection = ({ lang }: Iprops) => {
  const { push, pathname } = useRouter();
  // Determine if the current path is the Affiliate page
  // 判断当前路径是否是 Affiliate 页面
  const isAffiliatePage = pathname.includes(RoutePaths.Affiliate);

  return (
    <div className="flex relative flex-row items-center z-[3]">
      {isAffiliatePage ? (
        <AffiliateSection />
      ) : (
        <DefaultSection lang={lang} push={push} />
      )}
    </div>
  );
};

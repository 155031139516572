interface Iprop {
  prompt?: boolean;
  theme?: any;
}

export const BackIcon = ({ prompt, theme }: Iprop) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9998 18.3334L3.6665 11.0001L10.9998 3.66675L11.9623 4.62925L6.279 10.3126H18.3332V11.6876H6.279L11.9623 17.3709L10.9998 18.3334Z"
        fill={prompt === true && theme === "light" ? "black" : "white"}
      />
    </svg>
  );
};

import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Range } from "react-range";

import { theme } from "theme";
import classNames from "classnames";
import styles from "../ModalStyle.module.scss";
import myStyles from "./CreditLimitModal.module.scss";

import { CloseIcon } from "components/icons/CloseIcon";

import { Input, Modal, Separator } from "components";
import CustomButton from "components/Button";

import { IMember } from "redux/actions";
import { useSelector } from "redux/hooks";

interface IProps {
  member?: IMember;
  onClose?: () => void;
  onSubmit?: (credits: number) => void;
  submitLoading?: boolean;
}

export const CreditLimitModal = ({
  member,
  onClose,
  onSubmit,
  submitLoading,
}: IProps) => {
  const { userDetail } = useSelector((state) => state.authReducer);
  const themeMode = useSelector((state) => state.authReducer.theme);
  const max = userDetail?.user.activeSubscription.total_credits as number;
  const min = 1;

  const initialValue = member?.daily_limit;
  const [inputRange, setInputRange] = useState<string | undefined>(
    String(initialValue)
  );
  const [rangeValue, setRangeValue] = useState<number[]>([
    Math.round((Number(initialValue) + Number.EPSILON) * 100) / 100,
  ]);

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.header}>
        <div className={styles.headerTextContainer}>
          <div className={styles.title}>
            <FormattedMessage id="modal.creditLimit.heading" />
          </div>
          <div className={styles.description}>
            <FormattedMessage
              id="modal.creditLimit.subHeading"
              values={{ userName: member?.name }}
            />
          </div>
        </div>
        <div data-testid='credit-cross-btn' className={styles.crossBtn} onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <Separator height="30px" />
      <div style={{ maxWidth: "150px" }}>
        <Input
          type="number"
          min={min}
          max={max}
          value={inputRange}
          variant={themeMode}
          onChange={(e) => {
            const numberValue = Number(e.target.value);
            const stringValue = e.target.value;
            if (numberValue <= min) {
              setRangeValue([min]);
              setInputRange(stringValue);
            } else if (numberValue >= max) {
              setRangeValue([max]);
              setInputRange(String(max));
            } else {
              setRangeValue([numberValue]);
              setInputRange(stringValue);
            }
          }}
        />
      </div>
      <Separator height="30px" />
      <div
        className={classNames(myStyles.rangeContainer, {
          [myStyles.light]: themeMode === "light",
          [myStyles.dark]: themeMode === "dark",
        })}
      >
        <Range
          step={1}
          min={min}
          max={max}
          values={rangeValue}
          onChange={(values) => {
            setRangeValue(values);
            setInputRange(String(values[0]));
          }}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              style={props.style}
              className={classNames(myStyles.range, {
                [myStyles.light]: themeMode === "light",
                [myStyles.dark]: themeMode === "dark",
              })}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "24px",
                width: "24px",
                borderRadius: "50%",
                backgroundColor: theme.white,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "50%",
                  backgroundColor: theme.primaryColor,
                }}
              />
            </div>
          )}
        />
      </div>
      <Separator height="12px" />
      <div className={classNames(myStyles.rangeValuesContainer)}>
        <div>{min}</div>
        <div>{max}</div>
      </div>
      <Separator height="30px" />
      <div
        className={classNames(myStyles.footer, {
          [myStyles.light]: themeMode === "light",
          [myStyles.dark]: themeMode === "dark",
        })}
      >
        <CustomButton
          style={{ minWidth: "100px" }}
          variant="outlined-primary"
          type="button"
          onClick={onClose}
          data-testid='close-credit-btn'
        >
          <div
            className={classNames(styles.buttonText, {
              [styles.light]: themeMode === "light",
              [styles.dark]: themeMode === "dark",
            })}
          >
            <FormattedMessage id="modal.creditLimit.btn.cancel" />
          </div>
        </CustomButton>
        <CustomButton
          style={{ minWidth: "100px" }}
          variant="primary"
          type="submit"
          onClick={() => onSubmit?.(rangeValue[0])}
          isloading={submitLoading}
          data-testid='credit-submit-btn'
        >
          <FormattedMessage id="modal.creditLimit.btn.Submit" />
        </CustomButton>
      </div>
    </Modal>
  );
};

export const DallEIcon = ({ className }: { className?: string }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="80"
    height="16"
    viewBox="0 0 80 16"
    fill="none"
  >
    <g clipPath="url(#clip0_5173_54407)">
      <path d="M16 0H0V16H16V0Z" fill="#FFFF66" />
      <path d="M32 0H16V16H32V0Z" fill="#42FFFF" />
      <path d="M48 0H32V16H48V0Z" fill="#51DA4C" />
      <path d="M64 0H48V16H64V0Z" fill="#FF6E3C" />
      <path d="M80 0H64V16H80V0Z" fill="#3C46FF" />
    </g>
    <defs>
      <clipPath id="clip0_5173_54407">
        <rect width="80" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
import { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import moment from "moment";
import classNames from "classnames";
import styles from "./TeamTable.module.scss";
import teamStyles from '../../Team.module.scss';

import { UserRole } from "../UserRole/UserRole";
import { ActionsMenu, IActionProps } from "../ActionMenu";
import { LoadingPage } from "components";

import { IMember, UserRoles, UserRolesType } from "redux/actions";
import { useSelector } from "redux/hooks";
import { TableHead } from "../TableHead";
import { TeamMdHead } from "../TeamMdHead";
import { useWindowSize } from "hooks/useWindowSize";

interface IProps extends IActionProps {
  members: IMember[];
  invitations: IMember[];
  isloading?: boolean;
}

export const TeamTable = (props: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);
  const { width } = useWindowSize();

  const allowActions = (): UserRolesType[] => {
    const { userRole } = props;
    if (userRole === "admin") {
      return [UserRoles.manager, UserRoles.member];
    } else if (userRole === "manager") {
      return [UserRoles.member];
    } else {
      return [];
    }
  };

  if (props.isloading) return <LoadingPage />;
  return (
    <>
      <div className={classNames(teamStyles.mainContainer, {
        [teamStyles.light]: theme === 'light',
        [teamStyles.dark]: theme === 'dark',
      })}>
        <div
          className={classNames(teamStyles.tableContainer, {
            [teamStyles.light]: theme === "light",
            [teamStyles.dark]: theme === "dark",
            [styles.scrollContainer]: theme === "light",
            [styles.scrollContainerDark]: theme === "dark",

          })}
        >
          <table
            className={classNames(styles.teamTable, {
              [styles.teamTableLight]: theme === "light",
              [styles.teamTableDark]: theme === "dark",
            })}
          >
            <TableHead allowActions={(props.members.some(member => allowActions().includes(member.role)) || (width > 862))} />
            <tbody>
              {props.members.map((member) => (
                <tr>
                  <td>
                    <div className={styles.name}>{member.name ?? "_"}</div>
                  </td>
                  <td>{member.email}</td>
                  <td>{moment.utc(member.created_at).format("MM.DD.YYYY")}</td>
                  <td>
                    <UserRole type={member.role} />
                  </td>
                  <td>{member.role === "admin" ? "_" : member.used_today ?? 0}</td>
                  <td>{member.role === "admin" ? "_" : member.daily_limit ?? 0}</td>
                  <td>{member.total_used_monthly ?? 0}</td>
                  {(allowActions().includes(member.role) || width > 862)&& (
                  <td>
                    {(allowActions().includes(member.role)) &&
                      <ActionsMenu isInvited={false} {...props} member={member} />
                    }
                  </td>
                    )}
                </tr>
              ))}

              {(props.invitations.length > 0 && width> 862 )&& (
                <Fragment>
                  <div className={styles.separator} />
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan={7}>
                      <div
                        className={classNames(styles.pendingHeading, {
                          [styles.light]: theme === "light",
                          [styles.dark]: theme === "dark",
                        })}
                      >
                        <FormattedMessage id="invite.teamMember.pending" />
                      </div>
                    </td>
                  </tr>
                  <div className={styles.separator} />
                </Fragment>
              )}
              {width > 862 && props.invitations.map((member) => (
                <tr>
                  <td>
                    <div className={styles.name}>{member.name ?? "_"}</div>
                    <div className={styles.firstChild}>
                      <UserRole type={member.role} />
                      <div>{member.name ?? "_"}</div>
                      <div>{member.email}</div>
                      <div>{moment.utc(member.created_at).format("MM.DD.YYYY")}</div>
                    </div>
                  </td>
                  <td>{member.email}</td>
                  <td>{moment.utc(member.created_at).format("MM.DD.YYYY")}</td>
                  <td>
                    <UserRole type={member.role} />
                  </td>
                  <td>{member.used_today ?? "_"}</td>
                  <td>{member.daily_limit ?? "_"}</td>
                  <td>{member.total_used_monthly ?? "_"}</td>
                  <td>
                    <ActionsMenu isInvited={true} {...props} member={member} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div>
        {(props.invitations.length > 0 && width <= 862) && (
          <Fragment>
            <TeamMdHead invitation={true} />
          </Fragment>
        )}
      </div>
      {(props.invitations.length > 0 && width <= 862) && (
        <div className={classNames(teamStyles.mainContainer, {
          [teamStyles.light]: theme === 'light',
          [teamStyles.dark]: theme === 'dark',
        })}>
          <div
            className={classNames(teamStyles.tableContainer, {
              [teamStyles.light]: theme === "light",
              [teamStyles.dark]: theme === "dark",
              [styles.scrollContainer]: theme === "light",
              [styles.scrollContainerDark]: theme === "dark",
            })}
          >
            <table
              className={classNames(styles.teamTable, {
                [styles.teamTableLight]: theme === "light",
                [styles.teamTableDark]: theme === "dark",
              })}
            >
              <TableHead allowActions={true} />
              <tbody>
                {props.invitations.map((member) => (
                  <tr>
                    <td>
                      <div className={styles.name}>{member.name ?? "_"}</div>
                    </td>
                    <td>{member.email}</td>
                    <td>{moment.utc(member.created_at).format("MM.DD.YYYY")}</td>
                    <td>
                      <UserRole type={member.role} />
                    </td>
                    <td>{member.used_today ?? "_"}</td>
                    <td>{member.daily_limit ?? "_"}</td>
                    <td>{member.total_used_monthly ?? "_"}</td>
                    <td>
                      <ActionsMenu isInvited={true} {...props} member={member} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

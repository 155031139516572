import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";
import styles from "./Features.module.scss";

import {
  RealTimeIcon,
  UserFriendlyIcon,
  MultiLanguageIcon,
} from "../components/Icons";

import { FeatureSection } from "../components/FeatureSection";

import { useSelector } from "redux/hooks";

export const Features = () => {
  const theme = useSelector((state) => state.authReducer.theme);
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.featureContainer, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
        style={{
          backgroundImage: `url(/landing/${theme}/feature-01.png)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h2
          className={classNames(styles.title, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <FormattedMessage id="landing.feature.title" />
        </h2>
        <p
          className={classNames(styles.desc, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
          })}
        >
          <FormattedMessage id="landing.feature.desc" />
        </p>
        <div className={styles.row}>
          <div className={styles.col}>
            <RealTimeIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.realTime" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.realTime.desc" />
            </p>
          </div>
          <div className={styles.col}>
            <UserFriendlyIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.userFriendly" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.userFriendly.desc" />
            </p>
          </div>
          <div className={styles.col}>
            <MultiLanguageIcon className={styles.icon} />
            <h4
              className={classNames(styles.h4, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.multiLanguage" />
            </h4>
            <p
              className={classNames(styles.text, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="landing.feature.multiLanguage.desc" />
            </p>
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.featureTypeContainer, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}
      >
        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.ask.deftgpt" })}
          normalText={formatMessage({ id: "landing.feature.ask" })}
          description={formatMessage({ id: "landing.feature.ask.desc" })}
          imgSrc={
            theme === "dark"
              ? "/landing/feature-1-dark.svg"
              : "/landing/feature-1-light.svg"
          }
          alt="Ask DeftGPT anything and get an answer"
          backgroundUrl={`/landing/${theme}/feature-02.png`}
        />

        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.generate" })}
          normalText={formatMessage({ id: "landing.feature.generateAiArt" })}
          description={formatMessage({
            id: "landing.feature.generateAiArt.desc",
          })}
          imgSrc={
            theme === "dark"
              ? "/landing/feature-2-dark.svg"
              : "/landing/feature-2-light.svg"
          }
          alt="Generate AI art"
          $reverse
          backgroundUrl={`/landing/${theme}/feature-03.png`}
        />

        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.team" })}
          normalText={formatMessage({ id: "landing.feature.teamTitle" })}
          description={formatMessage({ id: "landing.feature.team.desc" })}
          imgSrc={
            theme === "dark"
              ? "/landing/feature-3-dark.svg"
              : "/landing/feature-3-light.svg"
          }
          alt="Innovate like never before: AI-powered idea generation"
          backgroundUrl={`/landing/${theme}/feature-04.png`}
        />

        <FeatureSection
          gradientText={formatMessage({ id: "landing.feature.upload" })}
          normalText={formatMessage({ id: "landing.feature.uploadTitle" })}
          description={formatMessage({ id: "landing.feature.upload.desc" })}
          imgSrc={
            theme === "dark"
              ? "/landing/feature-4-dark.svg"
              : "/landing/feature-4-light.svg"
          }
          alt="Upload documents and chat regarding them"
          $reverse
          backgroundUrl={`/landing/${theme}/feature-05.png`}
        />
      </div>
    </div>
  );
};

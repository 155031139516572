import React from "react";
import classNames from "classnames";
import styles from "./answerContainer.module.scss";
import { DocIcon, PDFIcon, CSVIcon, TextIcon, XLSXIcon, XLSIcon, EMLIcon, SRTIcon, PPTXIcon, PPTIcon } from "pages/ChatPage/components/icons/DocIcon";
import { useSelector } from "redux/hooks";
import { IFile } from "redux/actions";
import { Tooltip } from "components";

interface IFileLinkProps {
    file: IFile[];
    contentFile?: boolean;
}
const renderFileIcon = (fileExtension: string) => {
    switch (fileExtension) {
        case 'pdf':
            return <PDFIcon />;
        case 'csv':
            return <CSVIcon />;
        case 'txt':
            return <TextIcon />;
        case 'xlsx':
            return <XLSXIcon />;
        case 'xls':
            return <XLSIcon />
        case 'srt':
            return <SRTIcon />;
        case 'eml':
            return <EMLIcon />;
        case 'pptx':
            return <PPTXIcon />;
        case 'ppt':
            return <PPTIcon />;
        default:
            return <DocIcon />;
    }
};

const getFileTypeLabel = (fileExtension: string) => {
    switch (fileExtension) {
        case 'pdf':
            return 'PDF';
        case 'csv':
            return 'CSV';
        case 'txt':
            return 'Text';
        case 'xlsx':
            return 'XSLX';
        case 'xls':
            return 'XLS'
        case 'srt':
            return 'SRT'
        case 'eml':
            return 'EML';
        case 'pptx':
            return 'PPTX';
        case 'ppt':
            return 'PPT';
        default:
            return 'Document';
    }
};

const extractFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
};

const FileLink: React.FC<IFileLinkProps> = ({ file, contentFile }) => {
    const { theme } = useSelector((state) => state.authReducer);

    return (
        <div
            className={classNames(styles.fileContainer, {
                [styles.docPadding]: contentFile,
            })}
        >
            <div
                className={classNames(styles.fileText, {
                    [styles.light]: theme === "light",
                    [styles.dark]: theme === "dark",
                })}
                style={{ display: 'flex', gap: '20px', overflowX: 'auto', flexDirection:'row-reverse' }}
            >
                {file && file.map((file) => {
                    const extractFileName = (path: string) => {
                        const decodedPath = decodeURIComponent(path);
                        const parts = decodedPath.split('/');
                        const lastSegment = parts[parts.length - 1];
                        const firstHyphenIndex = lastSegment.indexOf('-');
                        const secondHyphenIndex = lastSegment.indexOf('-', firstHyphenIndex + 1);
                        return secondHyphenIndex !== -1 ? lastSegment.substring(secondHyphenIndex + 1) : lastSegment;
                    };

                    const fileName = extractFileName(file?.path as string);
                    const fileExtension = extractFileExtension(fileName);
                    return (
                        <Tooltip
                        regenrate={true}
                            key={fileName}
                            control={
                                <a href={file?.path as string} target="_blank"
                                    rel="noreferrer"
                                    className="mt-3 w-[20px]">
                                    <div className={classNames(styles.fileDisplay, {
                                        [styles.light]: theme === "light",
                                        [styles.dark]: theme === "dark",
                                    })}>
                                        <div className={styles.fileIcon}>
                                            {renderFileIcon(fileExtension || '')}
                                        </div>
                                        <div className={styles.fileInfo}>
                                            <div className={styles.fileName}>{fileName}</div>
                                            <div className={styles.fileType}>
                                                {getFileTypeLabel(fileExtension || '')}
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            }
                            placement="top"
                            theme="light"
                        >
                            {fileName}

                        </Tooltip>
                    );
                })}
            </div>
        </div>
    );
};

export default FileLink;
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { I18nProvider } from "core/i18n";
import { AppNotificationProvider } from "hooks/services/AppNotification";
import { ToggleSidebarProvider } from "hooks/services/ToggleSidebarProvider";
import { NetworkProvider } from "hooks/services/NetworkProvider";
import { Provider } from "react-redux";
import { store } from "redux/store";
import ReactGA from "react-ga4";
import { useSelector } from "redux/hooks";

import GlobalStyle from "./global-styles";
import { theme } from "theme";
import { Routing } from "pages/routes";
import { AudioProvider } from "hooks/services/AudioContext";
import { SidebarProvider } from "hooks/services/ReSizeSidebar/ReSizeSidebar";

ReactGA.initialize("G-GQJ90EXZHC");

interface IStyle {
  children: React.ReactNode;
}

const StyleProvider = ({ children }: IStyle) => {
  const { theme: currentTheme } = useSelector((state) => state.authReducer);
  useEffect(() => {
    const htmlElement = document.documentElement;
    const darkAttr = htmlElement.classList.contains("dark");
    if (currentTheme === "light" && darkAttr) {
      htmlElement.removeAttribute("class");
    } else if (currentTheme === "dark" && !darkAttr) {
      htmlElement.classList.add("dark");
    }
  }, [currentTheme]);

  // const {sidebarWidth} = useSidebar();

  useEffect(() => {
    const env = process.env.REACT_APP_ENVIRONMENT;
    if (env !== 'production') {
      const metaTag = document.createElement('meta');
      metaTag.setAttribute('name', 'robots');
      metaTag.setAttribute('content', 'noindex, nofollow');
      document.head.appendChild(metaTag);
    }
  }, []);
const sidebarWidth= Number(localStorage.getItem('sidebarWidth'));
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle currenttheme={currentTheme} 
      sidebarWidth={sidebarWidth}
      />
      {children}
    </ThemeProvider>
  );
};

export const App = () => {
  return (
    <Provider store={store}>
      <StyleProvider>
        <Router>
          <I18nProvider>
            <AppNotificationProvider>
              <NetworkProvider>
                <SidebarProvider>
                  <ToggleSidebarProvider>
                    <AudioProvider>
                      <Routing />
                    </AudioProvider>
                  </ToggleSidebarProvider>
                </SidebarProvider>
              </NetworkProvider>
            </AppNotificationProvider>
          </I18nProvider>
        </Router>
      </StyleProvider>
    </Provider>
  );
};

export const SimplifiedNavigationIcon = ({
  width = 70,
  height = 70,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 70 70"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 7C50.4547 7 63 19.5453 63 35C63 50.4547 50.4547 63 35 63C19.5453 63 7 50.4547 7 35C7 19.5453 19.5453 7 35 7ZM35 9C20.6499 9 9 20.6499 9 35C9 49.3501 20.6499 61 35 61C49.3501 61 61 49.3501 61 35C61 20.6499 49.3501 9 35 9ZM11 35C11 21.7552 21.7552 11 35 11C48.2448 11 59 21.7552 59 35C59 48.2448 48.2448 59 35 59C21.7552 59 11 48.2448 11 35ZM34.0003 13.0224C22.6531 13.5324 13.5331 22.6525 13.023 33.9997H15.0005C15.553 33.9997 16.0005 34.4472 16.0005 34.9997C16.0005 35.5522 15.553 35.9997 15.0005 35.9997H13.023C13.533 47.3469 22.6531 56.4669 34.0003 56.977V54.9995C34.0003 54.447 34.4478 53.9995 35.0003 53.9995C35.5528 53.9995 36.0003 54.447 36.0003 54.9995V56.977C47.3475 56.467 56.4675 47.3469 56.9776 35.9997H55.0001C54.4476 35.9997 54.0001 35.5522 54.0001 34.9997C54.0001 34.4472 54.4476 33.9997 55.0001 33.9997H56.9776C56.4676 22.6525 47.3475 13.5325 36.0003 13.0224V14.9999C36.0003 15.5524 35.5528 15.9999 35.0003 15.9999C34.4478 15.9999 34.0003 15.5524 34.0003 14.9999V13.0224ZM46.4726 24.895L38.6954 38.3299C38.6054 38.4799 38.4804 38.6049 38.3304 38.6949L24.8955 46.4722C24.503 46.6997 24.0055 46.6347 23.6855 46.3147C23.3655 45.9947 23.3005 45.4972 23.528 45.1047L31.3053 31.6698C31.3953 31.5198 31.5203 31.3948 31.6703 31.3048L45.1052 23.5276C45.4977 23.3001 45.9951 23.3651 46.3151 23.6851C46.6351 24.0051 46.7001 24.5025 46.4726 24.895ZM35 33C33.895 33 33 33.895 33 35C33 36.105 33.895 37 35 37C36.105 37 37 36.105 37 35C37 33.895 36.105 33 35 33Z"
      fill="#7C4DFF"
    />
  </svg>
);

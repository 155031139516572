import { FormattedMessage } from "react-intl";

import { PromptItem } from "./PromptItem";

import { IPrompt } from "redux/actions";

interface IProps {
  isloading: boolean;
  prompts: IPrompt[];
  filteredPrompts: IPrompt[];
  onSelect: (prompt: string) => void;
  dataTestId: string;
  setEditPrompt?: React.Dispatch<React.SetStateAction<number | null>>;
  setAllowEditPrompt?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const PromptsList = ({
  isloading,
  prompts,
  filteredPrompts,
  onSelect,
  dataTestId,
  setEditPrompt,
  setAllowEditPrompt,
}: IProps) => {
  return (
    <>
      {!isloading && filteredPrompts.length === 0 && (
        <div className="flex text-base items-center justify-center h-45 text-light dark:text-prompt-dark opacity-90">
          <FormattedMessage id="prompt.not.found" />
        </div>
      )}
      {prompts.length > 0 && (
        <>
          {filteredPrompts.map((prompt) => (
            <PromptItem prompt={prompt} onSelect={onSelect} dataTestId={dataTestId} setEditPrompt={setEditPrompt} setAllowEditPrompt={setAllowEditPrompt}/>
          ))}
        </>
      )}
    </>
  );
};

export const WhatsappIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#51CE5F" />
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        fill="none"
        x="20%"
        y="20%"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8553_18651)">
          <path
            d="M30.7547 5.23154C27.3705 1.8599 22.8705 0.00205712 18.0761 0C8.19644 0 0.156387 8.00212 0.152432 17.8379C0.151281 20.9819 0.976406 24.0509 2.54475 26.7562L0.00195312 36L9.5035 33.5195C12.1214 34.9409 15.0689 35.6899 18.0684 35.6911H18.076H18.0761C27.9542 35.6911 35.9954 27.6876 35.9996 17.8523C36.0013 13.0855 34.1385 8.60342 30.7547 5.23154ZM18.0761 32.6783H18.0703C15.3967 32.6768 12.7748 31.9622 10.488 30.6112L9.94372 30.29L4.3053 31.7618L5.81068 26.2906L5.45609 25.7296C3.96473 23.3688 3.17742 20.6405 3.17854 17.839C3.18182 9.66386 9.8644 3.013 18.0817 3.013C22.061 3.01464 25.8011 4.55889 28.6139 7.3613C31.4266 10.1637 32.9744 13.8891 32.9732 17.8511C32.9698 26.0265 26.2869 32.6783 18.0761 32.6783Z"
            fill="#E0E0E0"
          />
          <path
            d="M0.824219 34.9743L3.25086 26.1528C1.75378 23.571 0.966492 20.642 0.967436 17.6421C0.971362 8.25587 8.64422 0.619507 18.0718 0.619507C22.6474 0.621564 26.9419 2.39445 30.1708 5.61216C33.4004 8.82984 35.1779 13.1068 35.1761 17.6555C35.1721 27.042 27.4988 34.679 18.0724 34.679C18.0718 34.679 18.0729 34.679 18.0724 34.679H18.0648C15.2023 34.6781 12.3897 33.9627 9.89156 32.6072L0.824219 34.9743Z"
            fill="url(#paint0_linear_8553_18651)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.647 10.2648C13.3154 9.53081 12.9662 9.51611 12.6508 9.50327C12.3927 9.49225 12.0973 9.49293 11.8024 9.49293C11.5072 9.49293 11.0275 9.60337 10.6219 10.0444C10.2161 10.4855 9.07227 11.5517 9.07227 13.7201C9.07227 15.8888 10.6588 17.9843 10.88 18.2786C11.1013 18.5725 13.9426 23.1647 18.4424 24.9314C22.1821 26.3996 22.9431 26.1076 23.7548 26.0341C24.5665 25.9606 26.374 24.9681 26.7429 23.9389C27.1119 22.9099 27.1119 22.0278 27.0012 21.8434C26.8905 21.6597 26.5954 21.5495 26.1526 21.3291C25.7098 21.1087 23.5334 20.0423 23.1276 19.8954C22.7217 19.7484 22.4265 19.6749 22.1313 20.1162C21.8362 20.557 20.9882 21.5495 20.73 21.8434C20.4717 22.138 20.2134 22.1747 19.7706 21.9543C19.3279 21.7332 17.9021 21.2683 16.2106 19.7668C14.8945 18.5986 14.006 17.1559 13.7477 16.7146C13.4895 16.2738 13.7201 16.035 13.9421 15.8153C14.1409 15.6178 14.3849 15.3008 14.6063 15.0434C14.8272 14.786 14.901 14.6024 15.0486 14.3085C15.1961 14.0141 15.1224 13.7568 15.0117 13.5364C14.901 13.316 14.0406 11.1363 13.647 10.2648Z"
            fill="white"
          />
          <path
            d="M30.6068 5.1748C27.2614 1.84186 22.8131 0.0053824 18.0737 0.0032959C8.30745 0.0032959 0.359694 7.91355 0.355769 17.6364C0.354617 20.7444 1.17027 23.7781 2.7206 26.4524L0.207031 35.59L9.59951 33.138C12.1873 34.5431 15.101 35.2835 18.0661 35.2847H18.0736H18.0737C27.8384 35.2847 35.7873 27.373 35.7915 17.6506C35.7931 12.9386 33.9517 8.50797 30.6068 5.1748ZM18.0737 32.3064H18.068C15.425 32.3051 12.8333 31.5986 10.5727 30.2631L10.0347 29.9456L4.46097 31.4005L5.94905 25.9921L5.59853 25.4376C4.12429 23.1039 3.34601 20.4069 3.34716 17.6376C3.35038 9.55628 9.95625 2.98176 18.0793 2.98176C22.0129 2.98338 25.71 4.50991 28.4906 7.28014C31.271 10.0504 32.801 13.733 32.7999 17.6495C32.7964 25.731 26.1903 32.3064 18.0737 32.3064Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8553_18651"
            x1="18.0002"
            y1="34.9743"
            x2="18.0002"
            y2="0.619564"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#20B038" />
            <stop offset="1" stop-color="#60D66A" />
          </linearGradient>
          <clipPath id="clip0_8553_18651">
            <rect width="36" height="36" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </svg>
  );
};

import styles from "./Switch.module.scss";
import classnames from "classnames";

interface IProps {
  checked?: boolean;
  disabled?: boolean;
  icon?: boolean;
  onChange?: () => void;
  chatSetting?:boolean;
}

const Swtich = ({ checked, icon, onChange,chatSetting,}: IProps) => {
  const sliderStyles = classnames(styles.slider, {
    [styles.iconSlider]: icon,
    [styles.chat]:chatSetting
    
  });

  const inputStyles = classnames(styles.input, {
    [styles.iconInput]: icon,
    [styles.chatInput]:chatSetting,
  });

  return (
    <label className={classnames(styles.label,{
      [styles.chat]:chatSetting
    })}>
      <input
        type="checkbox"
        checked={checked}
        className={inputStyles}
        onChange={onChange}
      />
      <span className={sliderStyles}></span>
    </label>
  );
};
export default Swtich;

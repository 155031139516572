import { theme } from "theme";

export const TickIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.87467 14.8749L3.20801 10.2083L4.10384 9.31242L7.87467 13.0833L15.8747 5.08325L16.7705 5.97909L7.87467 14.8749Z"
        fill={theme.primaryColor}
      />
    </svg>
  );
};

export const FacebookIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#3C5997" />
            <svg width="19" height="36" viewBox="0 0 19 36" fill="none" xmlns="http://www.w3.org/2000/svg" x="33%" y="20%" >
                <path d="M18.6923 0.259615V5.97115H15.2957C14.0553 5.97115 13.2188 6.23077 12.7861 6.75C12.3534 7.26923 12.137 8.04808 12.137 9.08654V13.1755H18.476L17.6322 19.5793H12.137V36H5.51683V19.5793H0V13.1755H5.51683V8.45914C5.51683 5.77644 6.26683 3.69591 7.76683 2.21755C9.26683 0.739183 11.2644 0 13.7596 0C15.8798 0 17.524 0.0865385 18.6923 0.259615Z" fill="white" />
            </svg>
        </svg>
    );
};

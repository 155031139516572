import classNames from "classnames";
import styles from "./assistanceIcon.module.scss";

interface IProps {
  className?: string;
}

export const AssistanceIcon = ({ className }: IProps) => {
  return (
    <img
      className={classNames(styles.icon, { [String(className)]: className })}
      src={require("./logo_deftgpt.png")}
      alt="assistance-icon"
    />
  );
};


export const TickIcon = () => {
  return (
    <svg
      width="15"
      height="11"
      viewBox="0 0 15 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.24714 10.687L0.611816 5.73441L1.50163 4.78369L5.24714 8.78555L13.1934 0.29541L14.0832 1.24613L5.24714 10.687Z"
        fill="white"
      />
    </svg>
  );
};

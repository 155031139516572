import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import styles from "./CancelPaynentModal.module.scss";

import { Modal, Separator } from "components";
import Button from "components/Button";

import { useSelector } from "redux/hooks";

interface IProps {
  onCancel?: () => void;
  cancelSubLoading?: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
}

export const CancelPaymentModal = ({
  onClose,
  onCancel,
  onConfirm,
  cancelSubLoading,
}: IProps) => {
  const { theme } = useSelector((state) => state.authReducer);

  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.heading}>
        <div className={styles.headline}>
          <FormattedMessage id="cancelPaymet.modal.heading" />
        </div>
        <Separator height="30px" />
        <div className={styles.subHealine}>
          <FormattedMessage id="cancelPaymet.modal.description" />
        </div>
      </div>
      <Separator height="30px" />
      <div className={styles.modalFooter}>
        <Button
          style={{ minWidth: "100px" }}
          variant="outlined-primary"
          onClick={onCancel}
          data-testid='cacel-payment-cancel-btn'
        >
          <div
            className={classNames(styles.buttonText, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <FormattedMessage id="cancelPaymet.modal.cancelBtn.text" />
          </div>
        </Button>
        <Button
          style={{ minWidth: "100px" }}
          variant="primary"
          onClick={onConfirm}
          isloading={cancelSubLoading}
          data-testid='cancel-payment-confirm-btn'
        >
          <FormattedMessage id="cancelPaymet.modal.confirmBtn.text" />
        </Button>
      </div>
    </Modal>
  );
};

import { FormattedMessage } from "react-intl";
import styles from "./SuccessModal.module.scss";

import { Modal, Separator } from "components";
import Button from "components/Button";

interface IProps {
  heading?: string;
  message?: string;
  onClose?: () => void;
}

export const SuccessModal = ({ heading, message, onClose }: IProps) => {
  return (
    <Modal size="sm" onClose={onClose} isPadding>
      <div className={styles.modalBody}>
        <div className={styles.headline}>
          <FormattedMessage id={heading} />
        </div>
        {message && (
          <>
            <Separator height="6px" />
            <div className={styles.successText}>
              <FormattedMessage id={message} />
            </div>
          </>
        )}
        <Separator height="40px" />
        <Button
          variant="primary"
          onClick={onClose}
          style={{ padding: "10px 60px" }}
          data-testid='notify-modal-ok-btn'
        >
          <FormattedMessage id="notify.modal.ok.btn" />
        </Button>
      </div>
    </Modal>
  );
};

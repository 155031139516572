export const EmailIcon = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="30" fill="#888888" />
      <svg
        width="36"
        height="36"
        viewBox="0 0 36 36"
        x="20%"
        y="20%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.5 6C1.5 5.60218 1.65804 5.22064 1.93934 4.93934C2.22064 4.65804 2.60218 4.5 3 4.5H33C33.3978 4.5 33.7794 4.65804 34.0607 4.93934C34.342 5.22064 34.5 5.60218 34.5 6V30C34.5 30.3978 34.342 30.7794 34.0607 31.0607C33.7794 31.342 33.3978 31.5 33 31.5H3C2.60218 31.5 2.22064 31.342 1.93934 31.0607C1.65804 30.7794 1.5 30.3978 1.5 30V6ZM4.5 7.5V28.5H31.5V7.5H4.5Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.58418 5.5035C1.68709 5.21011 1.87863 4.95594 2.13231 4.77617C2.38598 4.59639 2.68926 4.49989 3.00018 4.5H33.0002C33.3107 4.50024 33.6135 4.59686 33.8668 4.77653C34.1201 4.9562 34.3114 5.21005 34.4143 5.50306C34.5172 5.79607 34.5266 6.11378 34.4412 6.41236C34.3558 6.71094 34.1799 6.97567 33.9377 7.17L18.9377 19.17C18.6716 19.383 18.341 19.4991 18.0002 19.4991C17.6594 19.4991 17.3287 19.383 17.0627 19.17L2.06268 7.17C1.82021 6.97593 1.64396 6.71139 1.55825 6.41289C1.47255 6.11438 1.4816 5.79664 1.58418 5.5035ZM7.27668 7.5L18.0002 16.08L28.7222 7.5H7.27668Z"
          fill="white"
        />
      </svg>
    </svg>
  );
};

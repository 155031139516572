export const LinkedinIcon = () => {
    return (
        <svg width="50" height="50" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="30" fill="#0177B5" />
            <svg width="38" height="36" viewBox="0 0 38 36" x="20%" y="18%" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M37.6744 22.0718V36H29.5995V23.0044C29.5995 19.7397 28.4316 17.5119 25.5085 17.5119C23.2773 17.5119 21.9495 19.0134 21.3656 20.4656C21.1525 20.9851 21.0977 21.7076 21.0977 22.4347V36H13.0203C13.0203 36 13.1291 13.9898 13.0203 11.7092H21.0973V15.1522C21.0809 15.1782 21.0596 15.2058 21.0441 15.2309H21.0973V15.1522C22.1701 13.4996 24.0865 11.1387 28.376 11.1387C33.6906 11.1387 37.6744 14.6106 37.6744 22.0718ZM4.57074 0C1.80753 0 0 1.81256 0 4.19609C0 6.52772 1.75521 8.39512 4.46358 8.39512H4.51758C7.33437 8.39512 9.08623 6.52814 9.08623 4.19609C9.03307 1.81256 7.33437 0 4.57074 0ZM0.479721 36H8.55419V11.7092H0.479721V36Z" fill="white" />
            </svg>

        </svg>
    );
};

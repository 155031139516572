export const SeeTranslationIcon = ({
  width = 56,
  height = 56,
  className,
}: {
  width?: number;
  height?: number;
  className?: string;
}) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 56 56"
    fill="none"
  >
    <path
      d="M51.2176 23.52H32.48V4.7824C32.48 2.1504 30.3296 0 27.6976 0H4.7824C2.1504 0 0 2.1504 0 4.7824V27.6976C0 30.3296 2.1504 32.48 4.7824 32.48H23.52V51.2176C23.52 53.8608 25.6704 56 28.3024 56H51.2176C53.8608 56 56 53.8496 56 51.2176V28.3024C56 25.6704 53.8496 23.52 51.2176 23.52ZM4.7824 30.24C3.3824 30.24 2.24 29.0976 2.24 27.6976V4.7824C2.24 3.3824 3.3824 2.24 4.7824 2.24H27.6976C29.0976 2.24 30.24 3.3824 30.24 4.7824V23.52H28.3024C25.6704 23.52 23.52 25.6704 23.52 28.3024V30.24H4.7824ZM53.76 51.2176C53.76 52.6176 52.6176 53.76 51.2176 53.76H28.3024C26.9024 53.76 25.76 52.6176 25.76 51.2176V28.3024C25.76 26.9024 26.9024 25.76 28.3024 25.76H51.2176C52.6176 25.76 53.76 26.9024 53.76 28.3024V51.2176Z"
      fill="#7C4DFF"
    />
    <path
      d="M46.6471 44.853C44.7207 44.4386 43.0967 43.3522 41.7639 42.053C43.4999 39.9138 44.5751 37.7186 45.0679 36.5986H48.1031C48.7191 36.5986 49.2231 36.0946 49.2231 35.4786C49.2231 34.8626 48.7191 34.3586 48.1031 34.3586H41.3607V32.1298C41.3607 31.5138 40.8567 31.0098 40.2407 31.0098C39.6247 31.0098 39.1207 31.5138 39.1207 32.1298V34.3586H32.3895C31.7735 34.3586 31.2695 34.8626 31.2695 35.4786C31.2695 36.0946 31.7735 36.5986 32.3895 36.5986H35.4247C35.9063 37.7074 36.9927 39.9138 38.7287 42.053C37.3959 43.3522 35.7607 44.4386 33.8455 44.853C33.2407 44.9874 32.8599 45.581 32.9943 46.1858C33.1063 46.7122 33.5767 47.0706 34.0919 47.0706C34.1703 47.0706 34.2487 47.0594 34.3271 47.0482C36.7015 46.533 38.6615 45.245 40.2519 43.7106C41.8423 45.2338 43.8023 46.5218 46.1767 47.0482C46.2551 47.0706 46.3335 47.0706 46.4119 47.0706C46.9271 47.0706 47.3863 46.7122 47.5095 46.1858C47.6327 45.581 47.2519 44.9874 46.6471 44.853ZM42.5815 36.6098C42.0775 37.6178 41.3047 38.9954 40.2407 40.3618C39.1767 38.9954 38.4039 37.6178 37.8999 36.6098H42.5815Z"
      fill="#7C4DFF"
    />
    <path
      d="M16.7921 6.29453C16.6129 5.88013 16.2097 5.61133 15.7617 5.61133C15.3137 5.61133 14.8993 5.88013 14.7313 6.29453L10.1953 17.1473L8.19055 21.9521C7.95535 22.5233 8.22415 23.1729 8.79535 23.4193C9.36655 23.6545 10.0161 23.3857 10.2625 22.8145L11.9873 18.7041H19.5809L21.3057 22.8145C21.4849 23.2401 21.8993 23.4977 22.3361 23.4977C22.4817 23.4977 22.6273 23.4753 22.7729 23.4081C23.3441 23.1729 23.6129 22.5121 23.3777 21.9409L16.7921 6.29453ZM12.9057 16.4753L15.7617 9.63213L18.6177 16.4753H12.9057Z"
      fill="#7C4DFF"
    />
    <path
      d="M9.33094 34.7202C8.71494 34.7202 8.21094 35.2242 8.21094 35.8402V42.3922C8.21094 45.3714 10.6301 47.7906 13.6093 47.7906H20.1613C20.7773 47.7906 21.2813 47.2866 21.2813 46.6706C21.2813 46.0546 20.7773 45.5506 20.1613 45.5506H13.6093C11.8621 45.5506 10.4509 44.1282 10.4509 42.3922V35.8402C10.4509 35.2242 9.94694 34.7202 9.33094 34.7202Z"
      fill="#7C4DFF"
    />
    <path
      d="M46.6711 21.2799C47.2871 21.2799 47.7911 20.7759 47.7911 20.1599V13.6079C47.7911 10.6287 45.3719 8.20947 42.3927 8.20947H35.8407C35.2247 8.20947 34.7207 8.71347 34.7207 9.32947C34.7207 9.94547 35.2247 10.4495 35.8407 10.4495H42.3927C44.1399 10.4495 45.5511 11.8719 45.5511 13.6079V20.1599C45.5511 20.7759 46.0551 21.2799 46.6711 21.2799Z"
      fill="#7C4DFF"
    />
  </svg>
);

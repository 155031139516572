import { useState, useCallback } from "react";
import axios, { CancelTokenSource } from "axios";

type FileUploadHook = {
  uploadProgress: number | null;
  uploadFile: (data: { file: File; preSignedUrl?: string }) => Promise<any>;
  // cancelUpload: () => void;
  cancelUpload: () => Promise<boolean>;
};

export const useS3FileUpload = (): FileUploadHook => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [cancelToken, setCancelToken] = useState<CancelTokenSource | null>(
    null
  );
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const uploadFile = useCallback(
    async ({
      file,
      preSignedUrl,
    }: {
      file: File;
      preSignedUrl?: string;
    }): Promise<any> => {
      return new Promise(async (resolve, reject) => {
        const source = axios.CancelToken.source();
        setCancelToken(source);
        setIsUploading(true);

        try {
          let result;
          if(preSignedUrl)
          {
           result = await axios.put(preSignedUrl, file, {
            headers: {
              "Content-Type": file.type,
            },
            onUploadProgress: (progressEvent) => {
              const total = progressEvent.total || 0;
              const progress = Math.round((progressEvent.loaded / total) * 100);
              setUploadProgress(progress);
            },
            cancelToken: source.token,
          });
        }
        else{
          result = await axios.put('',file, {
            headers: {
              "Content-Type": file.type,
            },
            onUploadProgress: (progressEvent) => {
              const total = progressEvent.total || 0;
              const progress = Math.round((progressEvent.loaded / total) * 100);
              setUploadProgress(progress);
            },
            cancelToken: source.token,
          });
        }
          resolve(result);
        } catch (error) {
          if (axios.isCancel(error)) {
            reject(error);
          } else {
            reject(error);
          }
        } finally {
          setUploadProgress(0);
          setCancelToken(null);
          setIsUploading(false);
        }
      });
    },
    []
  );

  // const cancelUpload = useCallback(() => {
  //   if (cancelToken) {
  //     cancelToken.cancel("File upload canceled by user.");
  //   }
  // }, [cancelToken]);
  const cancelUpload = useCallback(() => {
    return new Promise<boolean>((resolve) => {
      if (isUploading && cancelToken) {
        cancelToken.cancel("File upload canceled by user.");
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }, [cancelToken]);

  return {
    uploadProgress,
    uploadFile,
    cancelUpload,
  };
};
import styled, { keyframes } from "styled-components";
import { Theme } from "theme";

import { useSelector } from "redux/hooks";
import { EThemeType } from "redux/reducers";

interface IProps {
  gradientText: string;
  normalText: string;
  description: string;
  imgSrc: string;
  alt?: string;
  $reverse?: boolean;
  backgroundUrl?: string;
}

const fadeInUpAnimation = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Section = styled.div<{
  $reverse?: boolean;
}>`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 80px;
  padding: 60px 15px 0 15px;
  // opacity: 0;
  // animation: ${fadeInUpAnimation} 0.5s ease-in-out forwards;

  @media screen and (min-width: 769px) {
    padding-top: 100px;
    padding: 0 5%;
  }

  @media screen and (min-width: 1024px) {
    flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
    justify-content: space-between;
    padding-top: 200px;
  }

  @media screen and (min-width: 1440px) {
    padding: 160px 10%;
  }
`;

const SectionLeft = styled.div<{
  $reverse?: boolean;
}>`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  border-radius: 6px;
  box-shadow: ${(props) =>
    props.$reverse
      ? "-425px 523px 189px 0px rgba(58, 54, 63, 0.00), -272px 334px 172px 0px rgba(58, 54, 63, 0.01), -153px 188px 145px 0px rgba(58, 54, 63, 0.05), -68px 84px 108px 0px rgba(58, 54, 63, 0.09), -17px 21px 59px 0px rgba(58, 54, 63, 0.10)"
      : "642px 535px 234px 0px rgba(69, 67, 96, 0.00), 411px 342px 214px 0px rgba(69, 67, 96, 0.01), 231px 193px 181px 0px rgba(69, 67, 96, 0.05), 103px 86px 134px 0px rgba(69, 67, 96, 0.09), 26px 21px 74px 0px rgba(69, 67, 96, 0.10)"};
  @media screen and (min-width: 1024px) {
    flex-direction: ${(props) => (props.$reverse ? "row-reverse" : "row")};
    justify-content: space-between;
    width: auto;
  }
  img {
    border-radius: 6px;
  }
`;

const SectionRight = styled.div<{
  $reverse?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin-bottom: 40px;
  white-space: pre-line;

  @media screen and (min-width: 1024px) {
    width: 35%;
    margin: ${(props) => (props.$reverse ? "0 5% 0 0" : "0 0 0 5%")};
  }
`;

const Title = styled.p<
  {
    themeMode?: EThemeType;
    $reverse?: boolean;
  } & { theme: Theme }
>`
  font-size: 26px;
  font-weight: 700;
  margin: 24px 0 18px 0;
  text-align: center;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};

  @media screen and (min-width: 1024px) {
    // text-align: left;
    text-align: ${(props) => (props.$reverse ? "left" : "right")};
    font-size: 40px;
    line-height: 40px;
    margin: 0 0 24px 0;
  }
`;

const DescriptionText = styled.p<
  {
    themeMode?: EThemeType;
    $reverse?: boolean;
  } & { theme: Theme }
>`
  text-align: center;
  line-height: 24px;
  color: ${({ themeMode, theme }) =>
    themeMode === "dark" ? theme.white : theme.textColorLight};

  opacity: ${({ themeMode }) => (themeMode === "dark" ? 0.7 : 1)};
  font-weight: 700;

  @media screen and (min-width: 1024px) {
    // text-align: left;
    text-align: ${(props) => (props.$reverse ? "left" : "right")};
    line-height: 26px;
    font-size: 18px;
  }
  @media screen and (max-width: 576px) {
    text-align: left;
  }
`;

const GradientText = styled.span`
  background: linear-gradient(90deg, #6f53ff 0%, #c757ff 54.67%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin-right: 8px;
`;

export const FeatureSection = (props: IProps) => {
  const theme = useSelector((state) => state.authReducer.theme);

  return (
    <Section
      $reverse={props.$reverse}
      style={{
        backgroundImage: `url(${props.backgroundUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <SectionLeft $reverse={props.$reverse}>
        <img src={props.imgSrc} alt={props.alt} />
      </SectionLeft>
      <SectionRight $reverse={props.$reverse}>
        <Title themeMode={theme} $reverse={props.$reverse}>
          <GradientText>{props.gradientText}</GradientText>
          {props.normalText}
        </Title>
        <DescriptionText themeMode={theme} $reverse={props.$reverse}>
          {props.description}
        </DescriptionText>
      </SectionRight>
    </Section>
  );
};

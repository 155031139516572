import { FormattedMessage } from "react-intl";
import { CheckMarkIcon } from "pages/AffiliatePage/icons";
import { EThemeType } from "redux/reducers";

const benefits = [
  {
    text: "affiliate.benefit.table.benefitOne",
  },
  {
    text: "affiliate.benefit.table.benefitTwo",
  },
  {
    text: "affiliate.benefit.table.benefitThree",
  },
  {
    text: "affiliate.benefit.table.benefitFour",
  },
  {
    text: "affiliate.benefit.table.benefitFive",
  },
  {
    text: "affiliate.benefit.table.benefitSix",
  },
];

export const Benefits = ({ theme }: { theme?: EThemeType }) => {
  return (
    <div
      className="text-light dark:text-dark py-14 md:py-28"
      style={{
        backgroundImage: `url(/affiliate/benefit-bg-${theme}.png)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className=" text-center flex flex-col items-center pt-[80px] px-5">
        <h2 className="text-[20px] sm:text-[24px] md:text-[28px] lg:text-[32px] bg-gradient-primary-to-right bg-clip-text text-transparent">
          <FormattedMessage id="affiliate.intoduction.header" />
        </h2>
        <p className="opacity-70 text-[14px] md:text-[16px] lg:text-[18px] px-10 font-medium mt-4 leading-8 max-w-[1200px] dark:opacity-80">
          <FormattedMessage id="affiliate.intoduction.desc" />
        </p>
      </div>
      <div className="flex flex-col md:flex-row items-center md:justify-evenly md:px-[10%] lg:px-0 md:gap-[10px] lg:mx-auto md:w-full max-w-[1000px] mt-[60px] box-border">
        <div className="flex-1 mx-[15%] md:mx-0 flex flex-col items-center justify-center md:items-start">
          <h4 className="text-[18px] sm:text-[20px] md:text-[24px] lg:text-[28px] bg-gradient-primary-to-right bg-clip-text text-transparent w-fit">
            <FormattedMessage id="affiliate.benefit.table.header" />
          </h4>
          <ul className="mt-3">
            {benefits.map((benefit, index) => (
              <li
                key={index}
                className="font-medium text-[17px] opacity-70 dark:opacity-80 flex items-center gap-2 py-3 break-words w-fit"
              >
                <CheckMarkIcon />
                <FormattedMessage id={benefit.text} />
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 flex items-center justify-center mt-8 md:mt-0">
          <img
            src="/affiliate-benefit-banner.svg"
            alt="benefitImg"
            className="max-w-[70%] md:max-w-[100%]"
          />
        </div>
      </div>
    </div>
  );
};

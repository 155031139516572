import { ReactNode, useState } from "react";
import styled from "styled-components";
import ReactGA from "react-ga4";

import { LoadingButton } from "components";
import { GoogleIcon } from "./GoogleIcon";
import { EThemeType } from "redux/reducers";

import { useAppNotification } from "hooks/services/AppNotification";

import { socialRedirect } from "redux/actions";

interface IProps {
  children?: ReactNode;
  themeMode?:EThemeType;
  dataTestID?: string;
}

const GoogleAuthTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const GoogleAuthBtn = ({ children ,themeMode,dataTestID}: IProps) => {
  const { triggerNotification } = useAppNotification();

  const [isloading, setIsloading] = useState<boolean>(false);

  const onSocialRedirect = () => {
    ReactGA.event({
      action: "WebsiteGoogleAuthScreenGoogleBtn_action",
      category: "WebsiteGoogleAuthScreenGoogleBtn_category",
      label: "WebsiteGoogleAuthScreenGoogleBtn_label",
      value: 3,
    });
    setIsloading(true);
    socialRedirect()
      .then((res: any) => {
        setIsloading(false);
        window.open(res?.url, "_self");
      })
      .catch((err: any) => {
        setIsloading(false);
        triggerNotification({ message: err?.data?.message, type: "error" });
      });
  };

  return (
    <LoadingButton
      white={true}
      full
      size="lg"
      onClick={onSocialRedirect}
      isloading={isloading}
      themeMode={themeMode}
      data-testid={dataTestID}
    >
      <GoogleAuthTextContainer>
        <GoogleIcon />
        <div style={{ marginLeft: "12px" }}>{children}</div>
      </GoogleAuthTextContainer>
    </LoadingButton>
  );
};

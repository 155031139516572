export const TickIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66239 16.3625L3.52905 11.2292L4.51447 10.2438L8.66239 14.3917L17.4624 5.59167L18.4478 6.57709L8.66239 16.3625Z"
        fill="white"
      />
    </svg>
  );
};

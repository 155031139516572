
export const TeamIcon = ({ route }: { route?: boolean }) => {
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
          <path
            d="M19.0602 8.72991C19.7844 9.45411 19.7844 10.6283 19.0602 11.3525C18.336 12.0766 17.1619 12.0766 16.4377 11.3525C15.7135 10.6283 15.7135 9.45411 16.4377 8.72991C17.1619 8.00572 18.336 8.00572 19.0602 8.72991"
            stroke={route? "rgba(124, 77, 255, 1)":"white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.019 5.41958C14.1341 6.53469 14.1341 8.34264 13.019 9.45775C11.9039 10.5729 10.096 10.5729 8.98086 9.45775C7.86575 8.34265 7.86575 6.5347 8.98086 5.41958C10.096 4.30448 11.9039 4.30448 13.019 5.41958"
            stroke={route? "rgba(124, 77, 255, 1)":"white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M5.56314 8.72991C6.28734 9.45411 6.28734 10.6283 5.56314 11.3525C4.83895 12.0766 3.6648 12.0766 2.94061 11.3525C2.21641 10.6283 2.21641 9.45411 2.94061 8.72991C3.6648 8.00572 4.83895 8.00572 5.56314 8.72991"
            stroke={route? "rgba(124, 77, 255, 1)":"white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M21.0835 17.4167V16.412C21.0835 15.1461 20.0578 14.1204 18.7919 14.1204H18.0576"
            stroke={route? "rgba(124, 77, 255, 1)":"white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M0.916992 17.4167V16.412C0.916992 15.1461 1.94274 14.1204 3.20866 14.1204H3.94291"
            stroke={route? "rgba(124, 77, 255, 1)":"white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.8946 17.4166V15.9491C15.8946 14.1771 14.4581 12.7407 12.6862 12.7407H9.3138C7.54189 12.7407 6.10547 14.1771 6.10547 15.9491V17.4166"
            stroke={route? "rgba(124, 77, 255, 1)":"white"}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
      </svg>
    );
};
